type Digito = number | string

export function secondsToHours(seconds:number) {
    let hour:Digito = Math.floor(seconds / 3600);
    hour = (hour < 10)? '0' + hour : hour;
    let minute:Digito = Math.floor((seconds / 60) % 60);
    minute = (minute < 10)? '0' + minute : minute;
    let second:Digito = seconds % 60;
    second = (second < 10)? '0' + second : second;
    return hour + ':' + minute + ':' + second;
  }