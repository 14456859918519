import { AddButtonPrimary } from '@/components/Buttons/AddButtons';
import { useLicenses } from '../hooks/useLicenses';
import { PaginationView } from '@/components/pagination/Pagination';
import { LicenseProps } from '../models/license.model';
import classNames from 'classnames';
import { currencyFormatter } from '@/utilities/currencyFormatter';
import { useNavigate } from 'react-router-dom';
import { statusMapData } from '../data/status';
import { t } from 'i18next';

export const LicensesTable = () => {
  const navigate = useNavigate();

  const headersLicenses = [
    { textAlign: 'text-left', name: t('generics.licenses') },
    { textAlign: 'text-left', name: t('generics.description') },
    { textAlign: 'text-center', name: t('generics.currency') },
    { textAlign: 'text-center', name: t('generics.price') },
    { textAlign: 'text-center', name: t('generics.state') },
    { textAlign: 'text-center', name: t('generics.modify') }
  ];

  const {
    isLoading,
    licenses,
    pagination,
    nextPage,
    previousPage,
    initialPage,
    finalPage,
    changePage,
    value,
    setValue
  } = useLicenses();

  const handleCreateLicense = () => {
    navigate('/licencias/crud');
  };

  const handleInput = (e: any): void => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleSelectLicense = (code: string | undefined) => {
    navigate('/licencias/crud', { state: { code } });
  };

  return (
    <div>
      <article className='bg-[#F4F8FE] h-full overflow-auto'>
        <div className='bg-functional-el-l sticky top-0 flex items-center justify-between px-14 py-7 mb-4 z-10'>
          <div>
            <p className='font-bold text-2xl text-[#1E293B]'>{t('generics.licenses')}</p>
            <p className='font-medium text-[#5F6060]'>{t('pages.licenses.licensesAdmin')}</p>
          </div>
          <div className='w-1/3 flex justify-end'>
            <label htmlFor='simple-search' className='sr-only text-black'>
              {t('generics.search')}
            </label>
            <div className='relative'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <span className='material-symbols-outlined text-gray-500'>search</span>
              </div>
              <input
                value={value}
                onChange={handleInput}
                type='text'
                id='simple-search'
                className='bg-functional-l-l rounded-lg block w-52 pl-10 p-2.5 py-4'
                placeholder={t('generics.search')}
              />
            </div>
            <AddButtonPrimary name={t('generics.licenses')} onClick={() => handleCreateLicense()} />
          </div>
        </div>
        {!isLoading && licenses !== null ? (
          <div className='bg-functional-el-l rounded-3xl shadow-lg mx-10 p-10 my-4'>
            {value || licenses.length > 0 ? (
              <>
                <table className='table-auto w-full rounded-none'>
                  <thead className='text-black'>
                    <tr className=' '>
                      {headersLicenses.map(({ name, textAlign }) => (
                        <th
                          key={name}
                          className={classNames(
                            'first:rounded-l-2xl last:rounded-r-2xl bg-functional-el-m text-lg p-3',
                            textAlign
                          )}>
                          {name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {licenses.map(({ id, name, status, description, currency, basePrice, code }: LicenseProps) => {
                      return (
                        <tr key={id} className='border border-functional-el-l border-b-functional-l-d'>
                          <td className='text-left'>{name}</td>
                          <td className='text-left'>{description}</td>
                          <td className='text-center'>{currency}</td>
                          <td className='text-center'>{currencyFormatter(currency, basePrice)}</td>
                          <td className='text-center'>
                            {/* <div className={`bg-[${statusMapData[status].color}] m-1`}> */}
                            <div style={{ backgroundColor: statusMapData[status].color }} className={`p-1 rounded`}>
                              {statusMapData[status].label}
                            </div>
                          </td>
                          <td className='text-center py-2'>
                            <span
                              title='Editar'
                              onClick={() => handleSelectLicense(code)}
                              className='cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined'>
                              edit_square
                            </span>
                            {/* <span title="Eliminar" onClick={() => (setShowModalDelete(true), setLicenseId({ userId: id, name }))} className="cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined">delete</span> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>{' '}
              </>
            ) : (
              <div className='flex w-full h-96 justify-center items-center'>
                <p className='font-medium text-3xl'>{t('pages.licenses.notLicenses')}</p>
              </div>
            )}
          </div>
        ) : null}
        {licenses?.length > 0 ? (
          <PaginationView {...{ pagination, nextPage, previousPage, changePage, finalPage, initialPage }} />
        ) : null}
      </article>
    </div>
  );
};
