import { configureStore } from "@reduxjs/toolkit";
import { authSlice, uiSlice } from ".";
import { empresaSlice } from "./states/empresas/empresa.slice";
import { crudEmpresaSlice } from "./states/empresas/crudEmpresa.slice";
import { usersSlice } from "./states/users/users.slice";
import { crudUserSlice } from "./states/users/crudUsuario.slice";
import { modulesSlice } from "./states/modules/modules.slice";
import { crudModuleSlice } from "./states/modules/crudModule.slice";
import { areaSlice } from "./states/areas/areaSlice";
import { lessonsSlice } from "./states/lessons/lessons.slice";
import { crudLessonSlice } from "./states/lessons/crudLesson.slice";
import { partsSlice } from "./states/lessons/parts.slice";
import { rewardsSlice } from "./states/rewards/rewards.slice";
import { crudRewardSlice } from "./states/rewards/crudPremio";
import { dashboardSlice } from "./states/dashboard/dashboard.slice";
import { personalizationCompanySlice } from "./states/empresas/personalizationCompany.slice";
import { licensesSlice } from "./states/licenses/licenses.slice";
import { crudLicenseSlice } from "./states/licenses/CrudLicense.slice";
import { arealicenseSlice } from "./states/empresas/areaLicense.slice";

export interface AppStore {
    ui: any
    empresa: any
    auth: any
    crudEmpresa: any
    users: any
    crudUsuario: any
    modules: any
    crudModulo: any
    areas: any
    lessons: any
    crudLesson: any
    parts: any
    rewards: any
    crudReward: any
    dashboard: any
    persanalization: any
    licenses: any
    crudLicense: any
    areaLicenses: any

}

export default configureStore<AppStore>({
    reducer: {
        ui: uiSlice.reducer,
        empresa: empresaSlice.reducer,
        auth: authSlice.reducer,
        crudEmpresa: crudEmpresaSlice.reducer,
        users: usersSlice.reducer,
        crudUsuario: crudUserSlice.reducer,
        modules: modulesSlice.reducer,
        crudModulo: crudModuleSlice.reducer,
        areas: areaSlice.reducer,
        lessons: lessonsSlice.reducer,
        crudLesson: crudLessonSlice.reducer,
        parts: partsSlice.reducer,
        rewards: rewardsSlice.reducer,
        crudReward: crudRewardSlice.reducer,
        dashboard: dashboardSlice.reducer,
        persanalization: personalizationCompanySlice.reducer,
        licenses: licensesSlice.reducer,
        crudLicense: crudLicenseSlice.reducer,
        areaLicenses: arealicenseSlice.reducer,
        // cliente, crear empresa
        // 
    }
})