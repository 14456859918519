import { Pagination } from '@/models/pagination.models';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    isLoading: boolean
    licenses: any,
    pagination: Pagination | {},
    errorMessage: string | null,
    per_page: number,
    licenseSelected: string | number | null
}

const initialState: InitialStateProps = {
    isLoading: false,
    licenses: null,
    pagination: {},
    errorMessage: null,
    per_page: 20,
    licenseSelected: null,
}

export const licensesSlice = createSlice({
    name: 'licencias',
    initialState,
    reducers: {
        loadLicenses: (state, action) => {
            state.licenses = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setSelectLicense: (state, action) => {
            state.licenseSelected = action.payload
        },
        setToggleActiveLicense: (state, {payload}) => {
            const ix = state.licenses.findIndex(({id}:{id:number}) => id === payload.id)
            state.licenses[ix].isActive = payload.isActive
        },
        setDeleteLicenses: (state, {payload}) =>{
            const ix = state.licenses.findIndex(({ id }: { id: number }) => id === payload)
            let newLicenses = [...state.licenses]
            newLicenses.splice(ix,1)
            state.licenses = [...newLicenses]
            state.isLoading = false
        },
        setResetState:  (_) => ({...initialState})
    }
});


// Action creators are generated for each case reducer function
export const { loadLicenses, setIsLoading, setError, setSelectLicense, setDeleteLicenses, setResetState } = licensesSlice.actions;
