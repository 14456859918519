import { createSlice } from '@reduxjs/toolkit';

export const crudModuleSlice = createSlice({
    name: 'crudModulo',
    initialState: {
        companies:[],
        isLoading: false,
        errorMessage: null,
        status:'creating',
        module:{},
        areas:[],
        campanies:[]
    },
    reducers: {
        loadModule: (state, action) => {
            state.module = action.payload
            state.status = 'viewing'
            state.isLoading = false
        },
        createModule: (state, action) => {
            state.isLoading = false
            state.module = action.payload
            state.status = 'viewing'
        },
        setIsLoading: (state,action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setStatus:(state, action) => {
            state.status = action.payload
        },
        resetModule: (state) => {
            state.module = {}
            state.isLoading = false
        },
    }
});


// Action creators are generated for each case reducer function
export const { createModule, setIsLoading, setError, loadModule, setStatus, resetModule } = crudModuleSlice.actions;

