import { t } from 'i18next';
import {useState} from 'react';

type Value = 'lastUpdate' | 'initialDate' | 'typeLicense';

interface TableInfo {
  id: number;
  label: string;
  value: Value;
}

interface StatisticsTypes {
  id: number;
  icon: React.ReactElement;
  value: 'peopleCounter' | 'areasCounter' | 'modulesCounter';
}

export const CardCompany: React.FC<any> = (props) => {
  const buttons = [
    {
      action: 'config',
      id: 1,
      icon: <span className='material-symbols-rounded text-center text-functional-d-d text-3xl'>settings</span>,
      title: t('generics.configuration')
    },
    {
      action: 'lessons',
      id: 2,
      icon: <span className='material-symbols-rounded text-center text-functional-d-d text-3xl'>file_copy</span>,
      title: t('generics.lessons')
    },
    {
      action: 'dashboard',
      id: 3,
      icon: <span className='material-symbols-rounded text-center text-functional-d-d text-3xl'>dashboard</span>,
      title: 'Dashboard'
    }
  ];

  const tableInfo: TableInfo[] = [
    { id: 1, label: t('pages.companies.lastUpdate'), value: 'lastUpdate' },
    { id: 2, label: t('pages.companies.operationStart'), value: 'initialDate' },
    { id: 3, label: t('pages.companies.typeLicense'), value: 'typeLicense' }
  ];

  const statistics: StatisticsTypes[] = [
    {
      id: 1,
      icon: <span className='material-symbols-rounded text-center text-primary-l-m text-xl'>person</span>,
      value: 'peopleCounter'
    },
    {
      id: 2,
      icon: <span className='material-symbols-rounded text-center text-primary-l-m text-xl'>corporate_fare</span>,
      value: 'areasCounter'
    },
    {
      id: 3,
      icon: <span className='material-symbols-rounded text-center text-primary-l-m text-xl'>content_copy</span>,
      value: 'modulesCounter'
    }
  ];

  const CopyButton: React.FC<{ url: string | null | undefined }> = ({ url }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = async () => {
      if (url) {
        try {
          await navigator.clipboard.writeText(url);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        } catch (err) {
          console.error('Error al copiar la URL: ', err);
        }
      }
    };

    if (!url) {
      return null;
    }

    return (
        <div className="relative group">
          <button
              onClick={copyToClipboard}
              className={`absolute top-0 right-0 m-2 p-2 rounded-full transition duration-300 ease-in-out ${
                  copied ? 'text-success-m' : ''
              }`}
              title="Copiar URL"
          >
        <span
            className={`material-symbols-outlined ${copied ? 'text-white' : ''}`}
        >
          {copied ? 'check_circle' : 'content_copy'}
        </span>
          </button>
        </div>
    );
  };

  return (
    <article className='h-[30rem] w-[28rem] m-4 rounded-3xl overflow-hidden shadow-lg'>
      <CopyButton url={props?.short_url} />
      <div className='bg-[#FCCC4E] h-1/2 flex justify-center items-center flex-col'>
        <span className='material-symbols-outlined text-center text-5xl text-[#1E293B]'>apartment</span>
        <p className='truncate overflow-hidden font-bold text-3xl text-[#1E293B]'>{props?.name}</p>
      </div>
      <div className='bg-[#FFFFFF] h-1/2 px-5 py-1'>
        {/* statistics */}
        <div className='flex justify-between items-center my-1'>
          {statistics.map(({ icon, id, value }) => (
            <div key={id} className='flex'>
              {icon}
              <p className='truncate overflow-hidden mx-2 font-semibold text-lg text-[#1E293B] text-center'>
                {props[value]}
              </p>
            </div>
          ))}
        </div>
        <p className='truncate overflow-hidden font-bold text-3xl text-[#1E293B]'>{props?.name}</p>
        {/* table information */}
        <div>
          {tableInfo.map(
            ({ id, label, value }): React.ReactElement => (
              <div key={id} className='flex justify-between my-1'>
                <p className='bg-red text-sm font-bold text-[#545555]'>{label}</p>
                <p className='bg-red text-sm font-medium text-[#545555]'>{props[value].toString()}</p>
              </div>
            )
          )}
        </div>
        {/* Buttons to other links */}
        <div className='flex justify-between items-center my-4 mx-3'>
          {buttons.map(({ icon, title, id, action }) => (
            <div
              onClick={() => (action && props?.[action] ? props[action]() : null)}
              className='cursor-pointer'
              key={id}>
              <div className='flex justify-center'>{icon}</div>
              <p className='text-center text-xs my-1 text-[#1E293B]'>{title}</p>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};
