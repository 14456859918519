
export interface Quiz {
    title: string
    not_correct_video_url: string
    options: Option[]
}

export interface Option {
    title: string
    is_correct: boolean
}

export const quizInitialState:Quiz = {
    title:'',
    not_correct_video_url:'',
    options:[
        {title:'',is_correct:false},
        {title:'',is_correct:false},
        {title:'',is_correct:false},
        {title:'',is_correct:false}
    ]
}