import { Pagination } from '@/models/pagination.models';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    isLoading: boolean
    licenses: unknown,
    license: unknown,
    pagination: Pagination | {},
    errorMessage: string | null,
    per_page: number,
    licenseSelected: string | number | null
    licenseToSelect: unknown
    crudDisabled: boolean
    status: 'withoutLicense' | 'withLicense' | 'canceled' | 'selectingLicense' | null,
}

const initialState: InitialStateProps = {
    license: null,
    isLoading: false,
    licenses: null,
    pagination: {},
    errorMessage: null,
    per_page: -1,
    licenseSelected: null,
    licenseToSelect: null,
    crudDisabled: false,
    status: null
}

export const arealicenseSlice = createSlice({
    name: 'areaLicenses',
    initialState,
    reducers: {
        loadLicenses: (state, action) => {
            state.licenses = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        loadLicense: (state, action) => {
            state.license = action.payload
            state.isLoading = false
        },
        crudDisabled: (state, action) => {
            state.crudDisabled = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setSelectLicense: (state, action) => {
            state.licenseSelected = action.payload
        },
        setLicenseToSelect: (state, action) => {
            state.licenseToSelect = action.payload
        },
        setChangeState: (state, action) => {
            state.status = action.payload
        },
        resetLicenses: (state) => {
            state.licenses = null
            state.license = null
        },
        setResetState: (_) => ({ ...initialState })

    }
});


// Action creators are generated for each case reducer function
export const { resetLicenses, setChangeState, loadLicense, loadLicenses, setIsLoading, setError, setSelectLicense, setResetState, setLicenseToSelect } = arealicenseSlice.actions;
