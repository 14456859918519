import { Companies } from "@/models/companies.model";

export const loadCompaniesAdapter = (data: { items: Companies }) => {
    return data.items
        .map((company) => ({
            name: company.name,
            id: company.id,
            slug: company.slug,
            vimeo_folder_id: company.vimeo_folder_id,
            countries: company.countries ?? [],
        }))
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
}