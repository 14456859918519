import { Loader } from "@/pages/dashboard/components/Loader"
import { useSurveys } from "../hooks/useSurveys"
import { MdSearch } from "react-icons/md"
import { t } from "i18next";
import { useEffect } from "react";
import { statusConverter } from "../helpers/stateConverter";
import { useNavigate } from "react-router-dom";
import { Survey } from "../interfaces/survey.interface";

interface Props {
  areaId: number
}

export const Surveys = ({ areaId }: Props) => {
  const { isLoading, currentPage, totalPages, nextPage, previousPage, firstPage, lastPage, loadSurveys, surveys } = useSurveys()
  const navigate = useNavigate()

  useEffect(() => {
    loadSurveys(areaId)
  }, [areaId])

  const navigateSurveyDetails = (survey: Survey) => {
    if (!survey) return
    navigate(`/interacciones/encuestas/area/${areaId}/encuesta/${survey.slug}`)
  }

  return (
    isLoading ? (
      <Loader />
    ) : (
      <>
        <div className='relative h-auto min-h-[300px] w-full rounded-lg overflow-hidden bg-white overflow-y-auto shadow-md mt-[40px] p-4'>
          <div className='sticky w-full flex justify-between top-0 bg-[#ededed] z-[2] rounded-3xl mb-[10px]'>
            <div className={`${headerCell} min-w-[70px]`}>Id</div>
            <div className={`${headerCell} w-full max-w-[400px]`}>{t('pages.interactions.survey')}</div>
            <div className={`${headerCell} w-full`}>{t('pages.interactions.part')}</div>
            <div className={`${headerCell} min-w-[150px] max-w-[250px]`}>{t('pages.interactions.answers')}</div>
            <div className={`${headerCell} min-w-[140px] max-w-[250px]`}>{t('pages.interactions.status')}</div>
            <div className={`${headerCell} min-w-[150px] max-w-[150px]`}>{t('pages.interactions.actions')}</div>
          </div>
            {
              surveys.map((survey) => (
                <>
                <div className={`w-full flex justify-between bg-white border-b border-gray-200`} key={survey.id}>
                  <div className={`${bodycell} min-w-[70px]`}>{survey.id}</div>
                  <div className={`${bodycell} w-full max-w-[400px]`}>{survey.title}</div>
                  <div className={`${bodycell} w-full`}>
                    {survey.parts.map((part) => part.title).join(', ')}
                  </div>
                  <div className={`${bodycell} min-w-[150px] max-w-[250px]`}>{survey.questions.length}</div>
                  <div className={`${bodycell} min-w-[140px] max-w-[250px]`}>{statusConverter(survey.status)}</div>
                  <div className={`${bodycell} min-w-[150px] max-w-[150px] justify-center`}>
                    <button
                      onClick={() => { navigateSurveyDetails(survey) }}
                      className={`${buttonAction} bg-gray-500 text-white hover:bg-gray-600`}
                      title="Editar"
                      type="button">
                      <MdSearch />
                    </button>
                  </div>
              </div>
                </>
              ))
            }

        </div>
        <div className='flex justify-center mt-4'>
          {
            currentPage > 1 && (
              <>
                <button type="button"
                  onClick={() => {
                    firstPage(areaId)
                  }}>
                  <span className="material-symbols-outlined cursor-pointer text-3xl">first_page</span>
                </button>
                <button type="button"
                  onClick={() => {
                    previousPage(areaId)
                  }}
                >
                  <span className="material-symbols-outlined cursor-pointer text-3xl">keyboard_arrow_left</span>
                </button>
              </>
            )
          }
          <p className='p-2'>{currentPage}-{totalPages}</p>
          {
            currentPage < totalPages && (
              <>
                <button type="button"
                  onClick={() => {
                    nextPage(areaId)
                  }}
                >
                    <span className="material-symbols-outlined cursor-pointer text-3xl">keyboard_arrow_right</span>
                </button>
                <button type="button"
                  onClick={() => {
                    lastPage(areaId)
                  }}
                >
                  <span className="material-symbols-outlined cursor-pointer text-3xl">last_page</span>
                </button>
              </>
            )
          }
        </div>
      </>
    )
  )
}

const headerCell = 'text-left min-h-[50px] px-4 font-semibold flex items-center truncate';
const buttonAction = 'bg-primary-l-m text-[20px] p-2 rounded-lg right-0 mr-2';
const bodycell = 'relative text-left min-h-[50px] px-4 flex items-center truncate';