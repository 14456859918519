type SaveToBrowserStorage = (key: string, state: any) => void

export const saveToLocalStorage: SaveToBrowserStorage = (key, state) => {
    try {
        const serialisedState = JSON.stringify(state);
        localStorage.setItem(key, serialisedState);
    } catch (e) {
        console.warn(e);
    }
}

export const saveToSessionStorage: SaveToBrowserStorage = (key, state) => {
    try {
        const serialisedState = JSON.stringify(state);
        sessionStorage.setItem(key, serialisedState);
    } catch (e) {
        console.warn(e);
    }
}