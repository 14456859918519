import { createSlice } from '@reduxjs/toolkit';

// type Status = 'editing' | 'creating' | 'viewing' | 'loading' | null

// TODO: REVISAR ROLES DE CADA USUARIO

export const crudUserSlice = createSlice({
    name: 'crudUsuario',
    initialState: {
        isLoadingRoles: false,
        isLoading: false,
        errorMessage: null,
        status:'viewing',
        user:{},
        roles:[],
        companies:[]
    },
    reducers: {
        loadUser: (state, action) => {
            state.user = action.payload
            state.status = 'viewing'
            state.isLoading = false
        },
        createUser: (state, action) => {
            state.isLoading = false
            state.user = action.payload
        },
        setIsLoading: (state,action) => {
            state.isLoading = action.payload && true
        },
        setIsLoadingRoles: (state,action) => {
            state.isLoadingRoles = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setStatus:(state, action) => {
            state.status = action.payload
        },
        resetUser: (state) => {
            state.user = {}
            state.isLoading = false
        },
        setRoles: (state, action) => {
            state.roles = action.payload
            state.isLoadingRoles = false
        },
        setCompanies: (state, action) => {
            state.companies = action.payload
        }

    }
});


// Action creators are generated for each case reducer function
export const { createUser, setIsLoading, setIsLoadingRoles,setError, loadUser, setStatus, resetUser, setRoles, setCompanies } = crudUserSlice.actions;

