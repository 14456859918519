import { useState } from 'react'
import toast from "react-hot-toast"

// import { apiV2 } from "@/apis/apiV2"
import { loadModulesAdapterList } from '@/adapters/loadModules.adapter'
import useAxiosPrivate from './useApiV2Private'


let controller: AbortController, signal
export const useModulesList = (companySlug: string) => {

    const apiV2 = useAxiosPrivate()

    const [modules, setModules] = useState<{ id: number, name: string, slug: string }[]>()
    const [isLoadingModules, setIsloadingModules] = useState<boolean>()

    const startLoadModules = async () => {
        setIsloadingModules(true)
        try {
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/modules`, {
                params: { per_page: -1, },
                signal
            })
            setModules(loadModulesAdapterList(resp.data.items))
            toast.success('Módulos cargados')
        } catch (error: any) {
            if (error.message !== 'canceled') {
                toast.error('Existe un error en el servidor')
            }
        } finally {
            setIsloadingModules(false)
        }
    }

    return {
        isLoadingModules,
        modules,
        startLoadModules
    }
}
