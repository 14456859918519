import useAxiosPrivate from "@/hooks/useApiV2Private"
import { setIsLoadingGeneral, setIsLoadingEngagement, setSelectAllCompanies, loadDataEngagement, loadDataGeneral } from "@/redux/states/dashboard/dashboard.slice"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { loadDataGeneralAdapter, loadDataManagementAdapter } from "../adapters/loadData.adapter"
import { AppStore } from "@/redux"

interface Dates {
  dateStart: string
  dateEnd: string
}

let controller: AbortController, signal
export const useDashboard = () => {

  const { isLoadingGeneral, isLoadingEngagement, dataGeneral, dataEngagement, selectAllCompanies } = useSelector((state: AppStore) => state.dashboard)
  const { companySlug } = useSelector((state: AppStore) => state.ui)

  const { user: { roles } } = useSelector((state: AppStore) => state.auth)

  const isSuperAdmin = roles?.find(({ name }: { name: string }) => name === 'super_admin')?.name === 'super_admin'

  const dispatch = useDispatch()

  const apiV2 = useAxiosPrivate()

  const startLoadData = ({ dateStart, dateEnd }: Dates) => {
    try {
      if (controller) {
        controller.abort()
      }
      controller = new AbortController()
      signal = controller.signal
      // Here we have to call 2 different endpoints
      if (!selectAllCompanies) {
        dispatch(setIsLoadingGeneral(true))
        dispatch(setIsLoadingEngagement(true))
        // General
        apiV2.get(`/companies/dashboard/${dateStart}/${dateEnd}/${companySlug ?? ''}`, { signal })
          .then((resp) => {
            dispatch(loadDataGeneral(loadDataGeneralAdapter(resp.data)))
          }).catch((error) => {
            toast.error('Error al cargar los datos principales.')
            console.error(error)
          }).finally(() => {
            dispatch(setIsLoadingGeneral(false))
          })
        // Engagement
        apiV2.get(`/companies/dashboard/engagement/${dateStart}/${dateEnd}/${companySlug ?? ''}`, { signal })
          .then((resp) => {
            dispatch(loadDataEngagement(loadDataManagementAdapter(resp.data)))
          }).catch((error) => {
            toast.error('Error al cargar los datos de participación.')
            console.error(error)
          }).finally(() => {
            dispatch(setIsLoadingEngagement(false))
          })
      }
      else {
        dispatch(setIsLoadingGeneral(true))
        dispatch(setIsLoadingEngagement(true))
        // General
        apiV2.get(`/companies/dashboard/${dateStart}/${dateEnd}`, { signal })
          .then((resp) => {
            dispatch(loadDataGeneral(loadDataGeneralAdapter(resp.data)))
          }).catch((error) => {
            toast.error('Error al cargar los datos principales.')
            console.error(error)
          }).finally(() => {
            dispatch(setIsLoadingGeneral(false))
          })
        // Engagement
        apiV2.get(`/companies/dashboard/engagement/${dateStart}/${dateEnd}`, { signal })
          .then((resp) => {
            dispatch(loadDataEngagement(loadDataManagementAdapter(resp.data)))
          }).catch((error) => {
            toast.error('Error al cargar los datos de participación.')
            console.error(error)
          }).finally(() => {
            dispatch(setIsLoadingEngagement(false))
          })
      }
    } catch (error) {
      toast.error('Error al cargar los datos, por favor intenta de nuevo.')
      dispatch(setIsLoadingEngagement(false))
      dispatch(setIsLoadingGeneral(false))
    }
  }

  const startSelectAllCompanies = (isAllCampanies: boolean) => {
    dispatch(setSelectAllCompanies(isAllCampanies))
  }

  const resetData = () => {
    dispatch(loadDataGeneral({}))
    dispatch(loadDataEngagement({}))
  }


  return {
    companySlug,
    isLoadingEngagement,
    isLoadingGeneral,
    dataEngagement,
    dataGeneral,
    isSuperAdmin,
    selectAllCompanies,
    startSelectAllCompanies,
    startLoadData,
    resetData
  }
}
