import { IconPlusButton } from '@/components';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { SmallSpinner } from '@/components/Spinners/Spinner';
import { PaginationView } from '@/components/pagination/Pagination';
import { useLoadAreas, useModal } from '@/hooks';
import { AreaPropLocal } from '@/models/areas.models';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TabCompany } from '../layout/TabCompany';
import { t } from 'i18next';

let perPage: number = 20;
export const Areas = () => {
  const { state } = useLocation();

  const {
    loadAreas,
    areas,
    isLoading,
    pagination,
    flagToKnowSomethingChange,
    currentPage,
    nextPage,
    previousPage,
    initialPage,
    finalPage,
    changePage,
    toggleActive,
    resetInitalAreas
  } = useLoadAreas(state.companyId);

  const { startShowModal } = useModal();
  const handleAddArea = () => {
    startShowModal({
      title: t('pages.companies.addArea'),
      name: 'CreateArea',
      data: {
        companySlug: state.companyId
      }
    });
  };

  const handleUpdateArea = (id: number, isPublic: boolean) => {
    startShowModal({
      title: t('pages.companies.updateArea'),
      name: 'CreateArea',
      data: {
        companySlug: state.companyId,
        idArea: id,
        isPublic
      }
    });
  };

  const handleToggleChange = ({ id, isActive, name }: { name: string; id: number; isActive: boolean }) => {
    toggleActive({ id, name, isActive });
  };

  useEffect(() => {
    loadAreas(perPage);
    return () => {
      resetInitalAreas();
    };
  }, [flagToKnowSomethingChange, currentPage]);

  const headersArea: { name: string; styleHeader: string }[] = [
    { name: t('generics.area'), styleHeader: 'text-left' },
    { styleHeader: 'text-left', name: t('generics.description') },
    { styleHeader: 'text-center', name: 'User' },
    { styleHeader: 'text-center', name: t('generics.activate') },
    { styleHeader: 'text-center', name: t('generics.modify') }
  ];

  return (
    <>
      <TabCompany companyId={state?.companyId} />
      <article className='bg-[#F4F8FE] h-full overflow-auto'>
        <div className='bg-functional-el-l flex justify-between items-center px-10'>
          <div className=' flex items-center justify-start py-7'>
            <Link to='/empresas'>
              <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
            </Link>
            <div>
              <p className={`font-bold text-2xl text-functional-d-l`}>{t('pages.companies.companyArea')}</p>
              <p className={`font-medium text-functional-d-l`}>{t('pages.companies.addAreas')}</p>
            </div>
          </div>
          <div className=''>
            <IconPlusButton onClick={handleAddArea} className='justify-items-end w-40'>
              {t('generics.area')}
            </IconPlusButton>
          </div>
        </div>
        <div className='bg-functional-el-l rounded-3xl shadow-lg mx-11 p-10 m-10'>
          {!isLoading ? (
            <table className='table-auto w-full rounded-none max-h-[20vh] overflow-auto'>
              <thead className='text-white'>
                <tr className=' '>
                  {headersArea.map(({ name, styleHeader }) => (
                    <th
                      key={name}
                      className={classNames(
                        'first:rounded-l-2xl last:rounded-r-2xl bg-functional-el-m text-lg p-3 text-black',
                        { 'w-2/3': 'Descripción' === name, [styleHeader]: true }
                      )}>
                      {name !== 'User' ? (
                        name
                      ) : (
                        <div className='flex justify-center items-center'>
                          <span className='material-symbols-outlined text-black text-3xl'>person</span>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {areas.map((area: AreaPropLocal) => (
                  <tr key={area.id} className='border border-functional-el-l border-b-functional-l-d'>
                    <td className=' py-2 max-h-12'>
                      <p className={classNames('px-2 text-left line-clamp-1')}>{area.name}</p>
                    </td>
                    <td className=' py-2 max-h-12'>
                      <p
                        title={area.info?.desc}
                        className={classNames('px-2 line-clamp-2 text-justify', { 'text-center': true })}>
                        {area.info?.desc}
                      </p>
                    </td>
                    <td className='py-2 max-h-6 '>
                      <p className={classNames('text-center h-6 text-ellipsis overflow-hidden break-words')}>
                        {area.userCount}
                      </p>
                    </td>
                    <td className=' text-center py-2 max-h-12'>
                      <ToggleSwitch
                        disabled={area.isPublic}
                        onChange={() => handleToggleChange({ name: area.name, id: area.id, isActive: area.isActive })}
                        checked={area.isActive}
                        message=''
                      />
                    </td>
                    <td className='text-center py-2'>
                      <span
                        title='Editar'
                        onClick={() => (handleUpdateArea(area.id, area.isPublic))}
                        className={classNames(
                          'bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined cursor-pointer'
                        )}>
                        edit_square
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <SmallSpinner />
          )}
        </div>
        <PaginationView {...{ pagination, nextPage, previousPage, changePage, finalPage, initialPage }} />
        {/* <div className={cn(`flex flex-wrap p-10 `, { 'gap-x-32': showSideBar, 'gap-x-20': !showSideBar })}> */}
      </article>
    </>
  );
};
