import { useEffect } from 'react';
import { PaginationView } from '@/components/pagination/Pagination';
import { useRewards } from '../hooks/useRewards';
import { AppStore } from '@/redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SpinnerSize } from '@/components/Spinners/Spinner';
import { PrimaryButton, Select } from '@/components';
import { TableRewards } from '../views/TableRewards';
import { t } from 'i18next';

export const PremiosApp: React.FC = () => {
   const navigate = useNavigate();

   const { companySlug } = useSelector((state: AppStore) => state.ui);

   const {
      // Metodos
      nextPage,
      previousPage,
      initialPage,
      finalPage,
      changePage,
      setValue,
      startSelectArea,
      startDeleteReward,
      startLoadRewards,
      loadAreas,
      startResetState,
      areas,
      per_page,
      currentPage,
      selectedArea,
      isLoadingAreas,
      // Valores
      value,
      premios,
      pagination,
      isLoading
   } = useRewards(companySlug);

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
   };

   const selectReward = (id: string | number | undefined) => {
      navigate(`/premios/crud/${selectedArea}`, { state: { rewardSelected: id } });
   };

   const handleSelectArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
      startSelectArea(event.target.value);
   };

   useEffect(() => {
      loadAreas(-1);
      return () => {
         startResetState();
      };
   }, []);

   return (
      <>
         <article className='bg-[#F4F8FE] h-full overflow-auto'>
            <div className='bg-functional-el-l sticky top-0 flex items-center justify-between px-14 py-7 mb-4 z-10'>
               <div>
                  <p className='font-bold text-2xl text-[#1E293B]'>{t('generics.awards')}</p>
                  <p className='font-medium text-[#5F6060]'>{t('pages.awards.awardsAdmin')}</p>
               </div>
               <div className='w-1/3 flex justify-end'>
                  {areas.length > 0 ? (
                     <div className='flex justify-center items-center'>
                        <p className='font-bold'>{t('generics.area')}: </p>
                        {!isLoadingAreas ? (
                           <Select
                              onChange={handleSelectArea}
                              defaultValue={'default'}
                              className='w-52 p-4 h-14 ml-3'
                              options={areas}
                              optionmap={(value) => value.name}
                              optionValueMap={(value) => value.id}
                              optionKeyMap={(value) => value.id}
                              value={!selectedArea ? 'default' : selectedArea}
                           />
                        ) : (
                           <div className='px-16'>
                              <SpinnerSize className='w-10' />
                           </div>
                        )}
                     </div>
                  ) : null}
                  <label htmlFor='simple-search' className='sr-only text-black'>
                     {t('generics.search')}
                  </label>
                  <div className='relative'>
                     <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <span className='material-symbols-outlined text-gray-500'>search</span>
                     </div>
                     <input
                        value={value}
                        onChange={handleInput}
                        type='text'
                        id='simple-search'
                        className='bg-functional-l-l rounded-lg block w-52 pl-10 p-2.5 py-4'
                        placeholder={t('generics.search')}
                     />
                  </div>
                  {selectedArea ? (
                     <PrimaryButton className='ml-2' onClick={() => selectReward(undefined)}>
                        <div className='flex items-center justify-center text-white'>
                           <span className='material-symbols-outlined text-functional-d-d mr-3.5 rounded-sm'>add</span>
                           <p className='font-bold'>{t('generics.awards')}</p>
                        </div>
                     </PrimaryButton>
                  ) : null}
               </div>
            </div>
            {!isLoadingAreas && selectedArea ? (
               <>
                  <TableRewards
                     {...{
                        isLoading,
                        per_page,
                        currentPage,
                        startLoadRewards,
                        premios,
                        selectReward,
                        startDeleteReward,
                        value,
                        selectedArea
                     }}
                  />
                  <PaginationView {...{ pagination, nextPage, previousPage, changePage, finalPage, initialPage }} />
               </>
            ) : null}
         </article>
      </>
   );
};
