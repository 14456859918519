import { useForm } from 'react-hook-form';

import { PrimaryButton, SecondaryButton } from '@/components';
import { InputText } from '@/components/Inputs/InputText';
import toast from 'react-hot-toast';
import { useProfile } from '../hooks/useProfile';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
// import { useState } from "react"

export const ChangePassword = () => {
  // const [showPassword, setShomPassword] = useState<number>()
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();

  const { updatePassword } = useProfile();

  const handleChangePassword = (data: any) => {
    if (data.password === data.confirmPassword) {
      updatePassword({ oldPassword: data.oldPassword, newPassword: data.confirmPassword });
    } else {
      toast.error(t('errorMessage.passwordNotMatch'));
    }
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/perfil');
  };

  const inputMap = [
    { id: 1, label: t('pages.password.currentPassword'), value: 'oldPassword' },
    { id: 2, label: t('pages.password.newPassword'), value: 'password' },
    { id: 3, label: t('pages.password.confirmPassword'), value: 'confirmPassword' }
  ];
  return (
    <article className='bg-functional-el-m p-5 h-full'>
      <div className='m-11 p-8 px-20 bg-functional-el-l rounded-3xl shadow-lg'>
        <form onSubmit={handleSubmit(handleChangePassword)}>
          <div className='flex justify-between items-center'>
            <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.password.title')}</p>
            {/* <PrimaryButton type='button' onClick={() => isEditing ? setIsEditing(false) : setIsEditing(true)} >{isEditing ? 'Editar' : 'Ver información'}</PrimaryButton> */}
          </div>
          <div className='grid grid-rows-3 grid-cols-1 '>
            {inputMap.map(({ id, value, label }) => (
              <div key={id} className='p-4'>
                <InputText
                  register={() => register(value, { required: true })}
                  type='password'
                  className='w-2/5'
                  placeholder={label}
                />
              </div>
            ))}
          </div>
          <div className='flex justify-end'>
            <PrimaryButton type='submit'>{t('generics.save')}</PrimaryButton>
            <SecondaryButton className='px-5' onClick={handleCancel}>
              {t('generics.cancel')}
            </SecondaryButton>
          </div>
        </form>
      </div>
    </article>
  );
};
