export type AuthLayoutProps = {
    children: React.ReactElement | React.ReactElement[]
}

const authLayoutStyle = 'w-screen min-w-[1024px] h-screen flex items-center justify-center bg-[#F8F8F8]'

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    return (
        <div className={authLayoutStyle}>
            {children}
        </div>
    )
}

export default AuthLayout;

