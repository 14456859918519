import { useTranslation } from 'react-i18next';
import { NewModal } from '../../new-modal';
import { useNavigate } from 'react-router-dom';

interface Props {
  closeClick: React.Dispatch<React.SetStateAction<boolean>>;
}

enum ELanguage {
  spanish = 'es',
  english = 'en'
}

export function LanguageModal({ closeClick }: Props): JSX.Element {
  // configuration
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // methods
  const changeLanguage = (lenguage: ELanguage): void => {
    i18n.changeLanguage(lenguage);
    navigate('/');
  };

  // styles
  const optionLeguage: string =
    'custom-button !bg-[#ffffff] text-black flex items-center gap-[10px] w-[200px] active:bg-gray-200';

  return (
    <NewModal closeClick={closeClick} className='text-[#ffffff]'>
      <div className='flex flex-col gap-[20px]'>
        <button
          onClick={() => {
            changeLanguage(ELanguage.spanish);
          }}
          className={optionLeguage}>
          <span>{t('language.spanish')}</span>
        </button>
        <button
          onClick={() => {
            changeLanguage(ELanguage.english);
          }}
          className={optionLeguage}>
          <span>{t('language.english')}</span>
        </button>
      </div>
    </NewModal>
  );
}
