import { AppStore, showModal } from "@/redux"
import { useDispatch, useSelector } from "react-redux"


export const useModal = () => {
    const dispatch = useDispatch()

    const { modal } = useSelector((state: AppStore) => state.ui)

    const startShowModal = (data:any) => {
        dispatch(showModal(data))
    }
    
    return {
        modal,
        startShowModal
    }
}
