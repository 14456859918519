import { createSlice } from '@reduxjs/toolkit';

export const crudRewardSlice = createSlice({
    name: 'crudPremio',
    initialState: {
        isLoading: false,
        errorMessage: null,
        status: 'viewing',
        reward: {}
    },
    reducers: {
        loadReward: (state, action) => {
            state.reward = action.payload
            state.status = 'viewing'
            state.isLoading = false
        },
        createReward: (state, action) => {
            state.isLoading = false
            state.reward = action.payload
        },
        setIsLoading: (state, { payload = true }) => {
            state.isLoading = payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        resetReward: (state) => {
            state.reward = {}
            state.isLoading = false
        }
    }
});


// Action creators are generated for each case reducer function
export const { createReward, setIsLoading, setError, loadReward, setStatus, resetReward, } = crudRewardSlice.actions;

