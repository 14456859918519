import { AppStore, checkingCredentials, clearErrorMessage, login, logout } from "@/redux"
import { useDispatch, useSelector } from "react-redux"
import { useState } from 'react'
import toast from "react-hot-toast"

import { LoginProp, View } from "../models/login.models"
import { apiV2 } from "@/apis/apiV2"
import { proccessError } from "@/utilities/stringsError"
import { loadFromLocalStore, loadFromSessionStore, saveToLocalStorage, saveToSessionStorage } from '@/utilities/browser-storage';

const messageStatic: string = 'Too many fail login attempt your ip has restricted for 1 minute.'

export const useAuthStore = () => {
    const dispatch = useDispatch()
    const { status, user, errorMessage } = useSelector((state: AppStore) => state.auth)

    const [view, setView] = useState<View>('login')

    const startLogin = async ({ email, password, rememberMe }: LoginProp) => {
        dispatch(checkingCredentials())
        try {
            const { data } = await apiV2.post('/auth/login', { email, password })
            rememberMe ? saveToLocalStorage('token', data.authorization.token) : saveToSessionStorage('token', data.authorization.token)
            rememberMe ? saveToLocalStorage('token-init-date', new Date().getTime().toString()) : saveToSessionStorage('token-init-date', new Date().getTime().toString())
            dispatch(login({ user: data.user }))
        } catch (error) {
            const e = error as any;
            const message: string = e.response?.data?.message
            dispatch(logout({ errorMessage: message === messageStatic ? message : 'No fue exitosa la combinación', statusHttp: e.response?.status }))
            setTimeout(() => {
                dispatch(clearErrorMessage())
            }, 10);
        }
    }

    const checkAuthToken = async () => {
        dispatch(checkingCredentials())
        let userToken = ""
        let rememberMe = false
        if (localStorage.token) {
            userToken = loadFromLocalStore('token')
            rememberMe = true
        } else {
            userToken = loadFromSessionStore('token')
        }
        if (!userToken) return dispatch(logout(''))
        try {
            const { data } = await apiV2.post('/auth/refresh')
            if (rememberMe) {
                saveToLocalStorage('token', data.authorization.token)
                saveToLocalStorage('token-init-date', new Date().getTime().toString())
            } else {
                saveToSessionStorage('token', data.authorization.token)
                saveToSessionStorage('token-init-date', new Date().getTime().toString())
            }
            dispatch(login({ user: data.user }))
        } catch (error) {
            localStorage.removeItem('token')
            localStorage.removeItem('token-init-date')
            dispatch(logout('La sesión expiro'))
            toast.error('La sesión expiro')
        }
    }

    const forgotPwrequest = async (email: string) => {
        try {
            const resp = await apiV2.post('/auth/forgot-my-password', { email })
            toast.success(resp.data.message)
            return { message: 'success' }
        } catch (error) {
            toast.error('Correo no enviado')
        }
    }

    const forgotPwReset = async ({ email, token, password }: { email: string, token?: string, password: string }) => {
        try {
            await apiV2.post('/auth/reset', { email, token, password })
            toast.success('Contraseña actualizada, vuelva a ingresar')
            return { message: 'success' }
        } catch (error: any) {
            proccessError(error.response.data.message)
            toast.error('Contraseña no actulizada')
        }
    }

    const startLogout = async () => {
        console.log('start logout')
        localStorage.clear()
        sessionStorage.clear()
        dispatch(logout(''))
    }

    return {
        // propiedades
        status, user, errorMessage,
        // Metodos
        startLogin,
        checkAuthToken,
        startLogout,
        forgotPwrequest,
        forgotPwReset,
        // 
        view,
        setView
    }

}