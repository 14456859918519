import { t } from 'i18next';
import { CardLessonProps } from '../models/cardLesson';

export const CardLesson = (data: CardLessonProps) => {
  const handleButtonContent = () => {
    data.linkParts();
  };

  const handleButtonEdit = () => {
    data.linkEdit();
  };

  return (
    <div className='card card-compact w-[22rem] bg-base-100 shadow-xl h-80'>
      <figure className='w-full h-40 bg-functional-l-l relative'>
        <img src={data.web_image_path ?? '/imgLayout.png'} alt='' className='object-contain h-40' />
        <div
          className={`${
            data.isActive ? 'bg-success-l' : 'bg-functional-l-d'
          } rounded p-1 absolute top-2 right-2 font-bold`}>
          <p className='text-sm'>{data.isActive ? t('generics.activated') : t('generics.disabled')}</p>
        </div>
      </figure>
      <div className='card-body w-full h-40'>
        <p title={data.name} className='text-lg font-bold text-ellipsis overflow-hidden whitespace-nowrap'>
          {data.name}
        </p>
        <p title={data.description} className='line-clamp-1'>
          {data.description}
        </p>
        <div className='flex justify-between'>
          <p className='text-sm font-bold text-[#545555]'>{t('generics.creationDate')}:</p>
          <p className='text-sm text-right font-medium text-[#545555]'>{data.createdData}</p>
        </div>
        <div className='flex card-actions justify-start h-10 items-center'>
          {/* <button onClick={handleButtonContent} className="flex bg-primary-l-m justify-center items-center button_extend rounded-md bg-functional-l-d">
                        <span title="Contenido" className="h-10 w-10 cursor-pointer p-2 text-center rounded text-functional-el-l material-symbols-outlined">smart_display</span>
                        <p className="transition-all ease-in-out delay-150 mr-2 text-functional-el-l font-medium">Contenido</p>
                    </button> */}
          <button onClick={handleButtonContent} className='flex justify-center items-center'>
            <p className='transition-all ease-in-out delay-150 mr-2 text-base text-primary-l-m font-bold underline'>
              {t('generics.content').toUpperCase()}
            </p>
          </button>
          {/* <PrimaryButton className="h-10 p-2 mx-0 rounded-md">Contenido</PrimaryButton> */}
          {/* <button onClick={handleButtonEdit} className="flex justify-center items-center button_extend rounded-md bg-functional-l-d">
                        <span title="Editar" className="h-10 w-10 cursor-pointer p-2 text-center rounded text-functional-el-l material-symbols-outlined">edit_square</span>
                        <p className="transition-all ease-in-out delay-150 mr-2 text-functional-el-l font-medium">Editar lección</p>
                    </button> */}
          <button onClick={handleButtonEdit} className='flex justify-center items-center'>
            <p className='transition-all ease-in-out delay-150 mr-2 text-base text-primary-l-m font-bold underline'>
              {t('generics.edit').toUpperCase()}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};
