import { apiV2Files } from '@/apis/apiV2';
import { useState } from 'react';
import toast from 'react-hot-toast';

export interface SetImageProps {
   image_id?: number;
   image_path: string | undefined;
   file?: File;
   width?: number;
   height?: number;
}

export type loadImageWithValidationsProps = (file: File, size?: { width: number; height: number }) => void;

const fileSize1MB = 1166400;

export const useLoadImage = () => {
   const [image, setImage] = useState<SetImageProps>();

   const uploadImage = async (file: File) => {
      const imageToDelete = await image?.image_id?.toString();
      try {
         const resp = await apiV2Files.post('', { upload_file: file });
         setImage({
            image_id: resp.data.file_id,
            image_path: resp.data.path
         });
         if (imageToDelete) {
            await apiV2Files
               .get(imageToDelete)
               .then(async ({ status }) => {
                  if (status === 200) {
                     await apiV2Files.delete(imageToDelete ?? '');
                  }
               })
               .catch((_) => []);
         }
         toast.success('Imagen cargada');
         return resp.data.file_id;
      } catch (error) {
         toast.error('Imagen no ha sido carga');
      }
   };

   const deleteImage = async (imageToDelete: string) => {
      if (imageToDelete) {
         await apiV2Files
            .get(imageToDelete)
            .then(async ({ status }) => {
               if (status === 200) {
                  await apiV2Files.delete(imageToDelete ?? '');
               }
            })
            .catch((_) => toast.error('Imagen no ha sido eliminada'));
      }
   };

   const loadImageWithValidations: loadImageWithValidationsProps = (file, size) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
         toast.error('La imagen debe ser de tipo JPG o PNG');
         return;
      }
      if (file.size > fileSize1MB) {
         toast.error('La imagen no puede pesar más de 1MB');
         return;
      }
      if (size) {
         const imageSrc = URL.createObjectURL(file);
         const img = new Image();
         img.src = imageSrc;
         img.onload = () => {
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            const widthSizeMinimum = size.width - 100;
            const widthSizeMaximum = size.width + 100;
            const heightSizeMinimum = size.height - 100;
            const heightSizeMaximum = size.height + 100;
            if (width < widthSizeMinimum || width > widthSizeMaximum || height < heightSizeMinimum || height > heightSizeMaximum) {
               toast.error(
                  `La imagen debe tener un tamaño mínimo de ${widthSizeMinimum}px x ${heightSizeMinimum}px y máximo de ${widthSizeMaximum}px x ${heightSizeMaximum}px`
               );
            } else {
               setImage({ image_path: URL.createObjectURL(file), file, width, height });
            }
         };
      } else {
         setImage({ image_path: URL.createObjectURL(file), file });
      }
   };

   return {
      image,
      setImage,
      uploadImage,
      deleteImage,
      loadImageWithValidations
   };
};
