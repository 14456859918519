import { useEffect } from 'react';
import { SpinnerMedium } from '@/components/Spinners/Spinner';
import { useNavigate } from 'react-router-dom';
import { CardLessonProps } from '../models/cardLesson';
import classNames from 'classnames';
import { CardLesson } from '../components/CardLesson';
import { t } from 'i18next';

interface ModuleLesson {
  name: string;
  slug: string;
}

interface LessonListProps {
  isLoading: boolean;
  lessons: any;
  startLoadLessons: () => Promise<void>;
  per_page: number;
  value: string;
  currentPage: number;
  moduleSelected: string;
}

export const LessonList: React.FC<LessonListProps> = ({
  moduleSelected,
  lessons,
  isLoading,
  startLoadLessons,
  per_page,
  value,
  currentPage
}) => {
  const navigate = useNavigate();

  const handleSelectLesson = (id: string | number | undefined, moduleSlug: ModuleLesson | string) => {
    navigate('/lecciones/crud', { state: { moduleSelected: moduleSlug, lessonSelected: id } });
  };

  const handleParts = (moduleSlug: ModuleLesson, lessonSlug: string) => {
    navigate('/lecciones/partes', { state: { moduleSelected: moduleSlug, lessonSelected: lessonSlug } });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      startLoadLessons();
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [per_page, value, currentPage, moduleSelected]);

  return (
    <>
      {!isLoading && lessons !== undefined ? (
        <div className={classNames(`flex flex-wrap py-1 overflow-auto`)}>
          {lessons.length > 0 ? (
            lessons?.map((lesson: CardLessonProps) => {
              return (
                <div key={lesson.slug} className='m-2'>
                  <CardLesson
                    isActive={lesson.is_active}
                    createdData={lesson.created_at}
                    web_image_path={lesson.web_image_path}
                    linkEdit={() => handleSelectLesson(lesson.slug, lesson.module.slug)}
                    module={lesson.module}
                    linkParts={() => handleParts(lesson.module, lesson.slug)}
                    id={lesson.id}
                    name={lesson.name}
                    description={lesson.description}
                    slug={lesson.slug}
                  />
                </div>
              );
            })
          ) : (
            <div className='flex w-full h-96 justify-center items-center'>
              <p className='font-medium text-3xl'>{t('pages.lessons.notLessons')}</p>
            </div>
          )}
        </div>
      ) : (
        <div className='w-full h-full flex justify-center items-center'>
          <SpinnerMedium />
        </div>
      )}
    </>
  );
};
