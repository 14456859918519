interface CardProps {
  textOne: string
  textTwo: string
  textTree: string
}

export const CardInfoPrimary: React.FC<CardProps> = ({ textOne, textTwo, textTree }) => {
  return (
    <div className="bg-functional-el-l justify-self-center flex rounded-3xl justify-center flex-col w-64 items-center py-3 shadow-xl">
      <p className="my-1 text-sm">{textOne}</p>
      <p className="my-1 text-primary-l-m text-5xl font-bold">{textTwo}</p>
      <p className="my-1 text-sm">{textTree}</p>
    </div>
  )
}

export const CardInfoSecondary: React.FC<CardProps> = ({ textOne, textTwo, textTree }) => {
  return (
    <div className="bg-functional-el-l justify-self-center flex rounded-3xl justify-center flex-col w-64 items-center py-3 shadow-xl">
      <p className="my-1 text-sm">{textOne}</p>
      <p className="my-1 text-[#094081] text-5xl font-bold">{textTwo}</p>
      <p className="my-1 text-sm">{textTree}</p>
    </div>
  )
}
