import { PrimaryButton } from '@/components';
import { t } from 'i18next';

interface CardLicenseProps {
  className?: string;
  title: string;
  buttonAction: () => void;
  users: number;
  modules: number;
  videos: number;
  message: string;
  annualPrice: number | string;
  price: string;
  features?: string[];
}

export const CardLicense: React.FC<CardLicenseProps> = ({
  features,
  price,
  annualPrice,
  title,
  users,
  modules,
  videos,
  buttonAction
}) => {
  return (
    <>
      <article className='w-96 min-h-96 h-[26rem] border rounded-lg p-5 shadow'>
        <div className='h-[30%]'>
          <p className='text-3xl font-semibold mb-4'>{title}</p>
          <div className='flex items-center'>
            <p className='text-3xl'>{`${price}`}</p>
            <div className='mx-3 text-base'>
              <p> {` ${t('generics.perMonth')} /`}</p>
              <p> {`${annualPrice} ${t('generics.perYear')}`}</p>
            </div>
          </div>
        </div>
        <div className='divider'></div>
        <ul className=' h-[40%] overflow-y-scroll'>
          {features?.map((feature) => (
            <li key={feature} className='flex items-center'>
              <span className='material-symbols-outlined mx-2'>done</span> {feature}
            </li>
          )) ?? null}
          <li className='flex items-center'>
            <span className='material-symbols-outlined mx-2'>done</span> {`${users} ${t('generics.users')}`}
          </li>
          <li className='flex items-center'>
            <span className='material-symbols-outlined mx-2'>done</span>
            {`${modules} ${t('generics.modules')}`}
          </li>
          <li className='flex items-center'>
            <span className='material-symbols-outlined mx-2'>done</span>
            {`${videos} ${t('generics.videos')}`}
          </li>
        </ul>
        <div className='flex justify-end items-center h-[20%]'>
          <PrimaryButton onClick={buttonAction} className=''>
            {t('pages.companies.buyLicense')}
          </PrimaryButton>
        </div>
      </article>
    </>
  );
};
