import { useEffect } from 'react';
import { Reward } from '../models/rewards.models';
import { SpinnerMedium } from '@/components/Spinners/Spinner';
import { t } from 'i18next';

interface TableRewardsProps {
   premios: any;
   selectedArea: string;
   value: string;
   selectReward: (id: string | number | undefined) => void;
   startDeleteReward: (id: number) => Promise<void>;
   startLoadRewards: (areaId: string) => Promise<void>;
   per_page: number;
   currentPage: number;
   isLoading: boolean;
}

export const TableRewards: React.FC<TableRewardsProps> = ({
   isLoading,
   startLoadRewards,
   per_page,
   currentPage,
   premios,
   selectedArea,
   value,
   startDeleteReward,
   selectReward
}) => {
   const handleDeleteReward = (id: number) => {
      startDeleteReward(id);
   };

   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
         startLoadRewards(selectedArea);
      }, 250);
      return () => clearTimeout(delayDebounceFn);
   }, [per_page, value, currentPage, selectedArea]);

   return (
      <>
         {!isLoading && premios !== undefined ? (
            <div className='bg-functional-el-l rounded-3xl shadow-lg mx-10 p-10 my-4'>
               {value || (premios?.length > 0 && selectedArea) ? (
                  <table className='table-auto w-full rounded-none'>
                     <thead className='text-black'>
                        <tr className=' '>
                           <th className='first:rounded-l-2xl last:rounded-r-2xl bg-functional-el-m text-lg p-3 text-start'>
                              {t('generics.award')}
                           </th>
                           <th className='first:rounded-l-2xl last:rounded-r-2xl bg-functional-el-m text-lg p-3 text-center'>
                              {t('generics.points')}
                           </th>
                           <th className='first:rounded-l-2xl last:rounded-r-2xl bg-functional-el-m text-lg p-3 text-center'>
                              {t('generics.modify')}
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {premios.map(({ id, points, image_path, name }: Reward) => {
                           return (
                              <tr key={id} className='border border-functional-el-l border-b-functional-l-d'>
                                 <td className='text-center py-2'>
                                    <div className='flex items-center space-x-3'>
                                       <div className='avatar'>
                                          <div className='mask mask-squircle w-12 h-12'>
                                             <img src={image_path ?? '/imgLayout.png'} alt='' />
                                          </div>
                                       </div>
                                       <div>
                                          <p>{name}</p>
                                       </div>
                                    </div>
                                 </td>
                                 <td className='py-2 text-center'>{points}</td>
                                 <td className='text-center py-2'>
                                    <span
                                       title='Editar'
                                       onClick={() => selectReward(id)}
                                       className='cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined'>
                                       edit_square
                                    </span>
                                    <span
                                       title='Eliminar'
                                       onClick={() => handleDeleteReward(id)}
                                       className='cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined'>
                                       delete
                                    </span>
                                 </td>
                              </tr>
                           );
                        })}
                     </tbody>
                  </table>
               ) : (
                  <div className='flex w-full h-96 justify-center items-center'>
                     <p className='font-medium text-3xl'>{t('pages.awards.notAwards')}</p>
                  </div>
               )}
            </div>
         ) : (
            <SpinnerMedium />
         )}
      </>
   );
};
