import { useEffect } from 'react'
import { useLoadImage } from "@/hooks/useLoadImage"
import { AppStore } from "@/redux"
import { loadModule, resetModule, setIsLoading, setStatus } from "@/redux/states/modules/crudModule.slice"
import { useDispatch, useSelector } from "react-redux"
import toast from 'react-hot-toast'
import { useLoadAreas } from '@/hooks/useLoadAreas'
import { loadModuleAdapter } from '../adapters/loadModule.adapter'
import { proccessError } from '@/utilities/stringsError'
import useAxiosPrivate from '@/hooks/useApiV2Private'

export const useCrudModule = (moduleSelected?: string) => {

    const apiV2 = useAxiosPrivate()
    const dispatch = useDispatch()
    const { isLoading, status, module } = useSelector((state: AppStore) => state.crudModulo)
    const { companySlug } = useSelector((state: AppStore) => state.ui)

    const { image, setImage, uploadImage, loadImageWithValidations } = useLoadImage()

    const { loadAreas, areas, isLoading: isLoadingAreas } = useLoadAreas(companySlug)

    const startloadModule = async (slug: string) => {
        dispatch(setIsLoading(true))
        try {
            const resp = await apiV2.get(`/companies/${companySlug}/modules/${slug}`)
            dispatch(loadModule(loadModuleAdapter(resp.data)))
            setImage({
                image_id: resp.data.image_id,
                image_path: resp.data.image_path
            })
            toast.success('Datos del módulo cargados')
        } catch (error) {
            dispatch(setIsLoading(false))
            toast.error('Error en el servidor')
        }
    }

    const createModule = async (data: any) => {
        delete data.image
        let respImage: number | undefined | void
        try {
            if (image?.file) {
                await uploadImage(image.file).then(
                    (e) => {
                        respImage = e
                    }
                )
            }
            await apiV2.post(`/companies/${companySlug}/modules`,
                {
                    name: data.name,
                    description: data.description,
                    company_area_id: data.areas,
                    is_active: data.isActive,
                    image_id: respImage ?? image?.image_id
                })
            toast.success('Módulo creado con éxito')
            return {message:'success'}
        } catch (error: any) {
            if (error.response.status === 400) {
                toast(proccessError(error.response.data))
                toast.error('El módulo no ha sido creado:')
            }
        }
    }

    const updateModule = async (data: any) => {
        delete data.image
        let respImage: number | undefined | void
        try {
            if (image?.file) {
                await uploadImage(image.file).then(
                    (e) => {
                        respImage = e
                    }
                )
            }
            await apiV2.patch(`/companies/${companySlug}/modules/${module.slug}`,
                {
                    ...module,
                    name: data.name,
                    description: data.description,
                    is_active: data.isActive,
                    company_area_id: data.areas,
                    image_id: respImage ?? image?.image_id
                })
            toast.success('Módulo actualizado con éxito')
            return {message:'success'}
        } catch (error: any) {
            if (error.response.status === 400) {
                toast(proccessError(error.response.data))
                toast.error('El módulo no ha sido modificado:')
            }
        }
    }

    const editing = () => {
        dispatch(setStatus('editing'))
    }

    const viewing = () => {
        dispatch(setStatus('viewing'))
    }


    useEffect(() => {
        loadAreas(-1)
        if (moduleSelected) {
            startloadModule(moduleSelected)
        } else {
            dispatch(setStatus('creating'))
        }
        return () => {
            dispatch(resetModule())
        }
    }, [moduleSelected])


    return {
        image,
        module,
        status,
        isLoading,
        areas,
        isLoadingAreas,
        // roles,
        uploadImage,
        editing,
        viewing,
        updateModule,
        createModule,
        setImage,
        loadImageWithValidations
    }

}
