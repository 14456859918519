import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    isLoadingGeneral: boolean,
    isLoadingEngagement: boolean,
    dataGeneral: any,
    dataEngagement: any,
    errorMessage: string | null
    selectAllCompanies: boolean
}

const initialState: InitialStateProps = {
    isLoadingEngagement: false,
    isLoadingGeneral: false,
    dataGeneral: {},
    dataEngagement: {},
    errorMessage: null,
    selectAllCompanies: false
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setSelectAllCompanies: (state, action) => {
            state.selectAllCompanies = action.payload
        },
        loadDataEngagement: (state, action) => {
            state.dataEngagement = action.payload
            state.isLoadingEngagement = false
        },
        loadDataGeneral: (state, action) => {
            state.dataGeneral = action.payload
            state.isLoadingGeneral = false
        },
        setIsLoadingGeneral: (state, action) => {
            state.isLoadingGeneral = action.payload
        },
        setIsLoadingEngagement: (state, action) => {
            state.isLoadingEngagement = action.payload
        },
        setErrorGeneral: (state, action) => {
            state.errorMessage = action.payload
            state.isLoadingGeneral = false
        },
        setErrorEngagement: (state, action) => {
            state.errorMessage = action.payload
            state.isLoadingEngagement = false
        },
        setResetState: () => initialState
    }
});


// Action creators are generated for each case reducer function
export const { setSelectAllCompanies, setResetState, setIsLoadingEngagement, setIsLoadingGeneral, loadDataGeneral, loadDataEngagement, setErrorGeneral, setErrorEngagement } = dashboardSlice.actions;
