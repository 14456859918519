import { AppStore } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import {
  loadUser,
  setIsLoading,
  setStatus,
  resetUser,
  setRoles,
  setIsLoadingRoles
} from '@/redux/states/users/crudUsuario.slice';
import { loadUserAdapter } from '../adapters/loadUser.adapter';
import { proccessError } from '@/utilities/stringsError';
import { loadRolesAdapter } from '@/adapters/loadRoles.adapter';
import { useLoadAreas } from '@/hooks/useLoadAreas';
import { useLoadImage } from '@/hooks/useLoadImage';
import useAxiosPrivate from '@/hooks/useApiV2Private';
import { useNavigate } from 'react-router-dom';

export const useCrudUser = (userSelected?: number) => {
  const apiV2 = useAxiosPrivate();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { status, user, isLoading, roles, companies, isLoadingRoles } = useSelector(
    (state: AppStore) => state.crudUsuario
  );
  const {
    companySlug,
    currentCompany: { countries }
  } = useSelector((state: AppStore) => state.ui);

  const { image, setImage, uploadImage, loadImageWithValidations } = useLoadImage();

  const { loadAreas, areas, isLoading: isLoadingAreas } = useLoadAreas(companySlug);

  const loadRoles = async () => {
    dispatch(setIsLoadingRoles(true));
    try {
      const resp = await apiV2.get(`/roles`, { params: { per_page: 10000 } });
      dispatch(setRoles(loadRolesAdapter(resp.data)));
    } catch (error) {
      toast.error('Las empresas no han sido descargadas');
    }
  };

  const startLoadUser = async (id: string | number) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await apiV2.get(`/users/${id}`);
      dispatch(loadUser(loadUserAdapter(resp.data)));
      setImage({
        image_id: resp.data.avatar_id,
        image_path: resp.data.avatar_url
      });
      toast.success('Datos del usuario cargados');
    } catch (error) {
      toast.error('Error en el servidor');
    }
  };

  const updateUser = async ({ data, roles, areas, default_area_id }: any) => {
    delete data.image;
    let respImage: number | undefined | void;
    try {
      if (image?.file) {
        await uploadImage(image.file).then((e) => {
          respImage = e;
        });
      }
      await apiV2.patch(`/users/${user.id}`, {
        ...user,
        ...data,
        company_slug: companySlug,
        avatar_id: respImage ?? image?.image_id,
        role_id: roles,
        company_area_id: areas,
        default_area_id: default_area_id
      });
      navigate('/usuarios');
      toast.success('Usuario actualizado con éxito');
      return { message: 'success' };
    } catch (error: any) {
      if (error.response.status === 400) {
        toast(proccessError(error.response.data));
        toast.error('El usuario no ha sido modificado:');
      }
      return { message: 'error' };
    }
  };

  const createUser = async (data: any) => {
    delete data.image;
    let respImage: number | undefined | void;
    try {
      if (image?.file) {
        await uploadImage(image.file).then((e) => {
          respImage = e;
        });
      }
      await apiV2.post(`/users`, {
        ...data,
        avatar_id: respImage ?? image?.image_id,
        role_id: [...data.roles],
        company_area_id: [...data.areas],
        company_slug: companySlug,
        default_area_id: data.default_area_id
      });
      navigate('/usuarios');
      toast.success('Usuario creado con éxito');
      return { message: 'success' };
    } catch (error: any) {
      dispatch(setIsLoading(false));
      if (error.response.status === 400) {
        toast(proccessError(error.response.data));
        toast.error('El usuario no ha sido creado:');
      }
      return { message: 'error' };
    }
  };

  const editing = () => {
    dispatch(setStatus('editing'));
  };

  const viewing = () => {
    dispatch(setStatus('viewing'));
  };

  useEffect(() => {
    loadRoles();
    loadAreas(-1);
    if (userSelected) {
      startLoadUser(userSelected);
    } else {
      dispatch(setStatus('creating'));
    }
    return () => {
      dispatch(resetUser());
    };
  }, [userSelected]);

  return {
    // propiedades
    image,
    user,
    status,
    isLoading,
    isLoadingAreas,
    isLoadingRoles,
    companies,
    roles,
    areas,
    countries,
    // Metodos,
    uploadImage,
    editing,
    viewing,
    setImage,
    updateUser,
    createUser,
    loadImageWithValidations
  };
};
