import { createSlice } from '@reduxjs/toolkit';

// type Status = 'editing' | 'creating' | 'viewing' | 'loading' | null

// import users from './data/users.json'
import { users } from '@/data/users.ts'


export const crudEmpresaSlice = createSlice({
    name: 'empresa',
    initialState: {
        usuarios: users,
        isLoading: false,
        errorMessage: null,
        status: 'viewing',
        company: {},
        paises: [],
        preferences: {},
        isLoadingCountries: false,
        companiesChanged: false,
        crudDisabled: false
    },
    reducers: {
        setCrudDisabled: (state,action) => {
            state.crudDisabled = action.payload
        },
        loadCompany: (state, action) => {
            state.company = action.payload
            state.status = 'viewing'
            state.isLoading = false
        },
        createCompany: (state, action) => {
            state.isLoading = false
            state.company = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload && true
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        resetCompany: (state) => {
            state.company = {}
            state.paises = []
            state.preferences = {}
            state.isLoading = false
        },
        setPreferences: (state, action) => {
            state.preferences = action.payload
            state.isLoading = false
        },
        setCountries: (state, action) => {
            state.paises = action.payload
            state.isLoadingCountries = false
        },
        setIsLoadingCountries: (state, action) => {
            state.isLoadingCountries = action.payload
        },
        setCampaniesChanged: (state) => {
            state.companiesChanged = !(state.companiesChanged)
        }
    }
});


// Action creators are generated for each case reducer function
export const { setCrudDisabled ,setCampaniesChanged, createCompany, setIsLoading, setError, loadCompany, setStatus, resetCompany, setPreferences, setCountries, setIsLoadingCountries } = crudEmpresaSlice.actions;

