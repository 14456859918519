import dayjs from "dayjs";
import { EmpresaInitial } from "../models";


export const loadCompaniesAdapter = (companies: EmpresaInitial[]) => {
    return companies.map((company) => ({
        ...company,
        peopleCounter: company.users_count ?? 0,
        areasCounter: company.areas_count ?? 0,
        modulesCounter: company.areas_count ?? 0,
        initialDate: dayjs(company.created_at).format('DD/MM/YYYY'),
        lastUpdate: dayjs(company.updated_at).format('DD/MM/YYYY'),
        typeLicense: 'Premium (1-2500)'
    }))
}
