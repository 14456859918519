import { useEffect } from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import { useAuthStore } from "@/pages/auth/hooks"
import { LoginRoutes } from "@/pages/auth/routes"
import { PrivateRoutes } from './Private.routes'
import { Spinner } from '@/components/Spinners/Spinner'


export const AppRouter = () => {

    const { status, checkAuthToken } = useAuthStore()

    useEffect(() => {
        checkAuthToken()
    }, [])

    if (status === 'checking') {
        return <Spinner />
    }

    return (
        <Routes>
            {(status === 'not-authenticated') ? (
                <>
                    <Route path="/auth/*" element={<LoginRoutes />} />
                    <Route path='/*' element={<Navigate to="/auth/login" />} />
                </>
            ) :
                <Route path="/*" element={<PrivateRoutes />} />
            }
        </Routes>
    )
}