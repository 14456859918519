import classNames from 'classnames';
import { t } from 'i18next';

interface ToggleSwitchProps {
  register?: any;
  message: string;
  color?: string;
  closeButton?: {
    initialValue: boolean;
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export const ToggleSwitch = ({
  register,
  message,
  closeButton,
  ...props
}: React.ComponentProps<'input'> & ToggleSwitchProps) => {
  return (
    <label className='relative inline-flex items-center cursor-pointer'>
      <input
        {...props}
        type='checkbox'
        onClick={() => {
          if (closeButton) {
            closeButton.setValue(!closeButton.initialValue);
          }
        }}
        className='sr-only peer disabled:cursor-no-drop'
        {...(register ? register() : {})}
      />
      <div
        className={classNames(
          "flex justify-between items-center w-[4.4rem] border border-functional-d-l h-9 bg-functional-l-l after:peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-functional-el-l after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-functional-l-d after:border-functional-el-l after:border after:rounded-full after:h-8 after:w-8 after:transition-all after:peer-checked:bg-primary-l-m peer-checked:bg-functional-el-l",
          { 'cursor-no-drop': props.disabled ?? false }
        )}>
        <span className='font-semibold text-[14px] uppercase text-black px-1.5'> {t('generics.yes')} </span>
        <span className='font-semibold text-[14px] uppercase text-black px-1'> NO </span>
      </div>
      {message ? <span className='ml-3 text-sm font-medium  gray-900 dark:text-gray-300'>{message}</span> : null}
    </label>
  );
};
