import { PrimaryButton, SecondaryButton } from '@/components'
import { ModalLayout } from '@/layouts/Modal.layout'
import { featuresMap } from '@/pages/licenses/data/features'
import { LicenseProps } from '@/pages/licenses/models/license.model'
import { currencyFormatter } from '@/utilities/currencyFormatter'

interface SelectLicenseProps {
    close: () => void,
    label: string,
    save: () => void
    data: LicenseProps
    isOpen: boolean
}

export const SelectLicenseModal: React.FC<SelectLicenseProps> = ({ close, save, data, isOpen }) => {

    const handleSelectLicense = () => {
        save()
        close()
    }

    return (
        <ModalLayout {...{ close, title: data.name, isOpen, size: 'w-6/12' }}>
            <div className="h-full overflow-hidden">
                <div className="">
                    <div className='flex justify-between'>
                        <div className="flex items-center">
                            <p className="text-3xl">{`${currencyFormatter(data.currency, data.basePrice)}`}</p>
                            <div className="mx-3 text-base">
                                <p> {` Por mes /`}</p>
                                <p> {`${currencyFormatter(data.currency, (+data.basePrice * +data.annualDiscount / 100 + +data.basePrice))} por año`}</p>
                            </div>
                        </div>
                    </div>
                    <p className="text-base mt-10">{data.description}</p>
                </div>
                <div className="divider"></div>
                <div className='flex justify-around'>
                    <ul className="my-3">
                        {data.features?.map((feature) => <li key={feature} className="flex items-center"><span className="material-symbols-outlined mx-2">done</span> {featuresMap[feature].label}</li>) || null}
                        <li className="flex items-center"><span className="material-symbols-outlined mx-2">done</span> {`${data.activeUsers} usuarios`}</li>
                        <li className="flex items-center"><span className="material-symbols-outlined mx-2">done</span>{`${data.activeModules} módulos`}</li>
                        <li className="flex items-center"><span className="material-symbols-outlined mx-2">done</span>{`${data.activeVideos} videos`}</li>
                    </ul>
                    <ul className="my-3">
                        <li className="flex items-center my-2"><span className="material-symbols-outlined mx-2">warning</span> {`Precio adicional por usuario:  ${currencyFormatter(data.currency, data.pricePerExtraUser)}`}</li>
                        <li className="flex items-center my-2"><span className="material-symbols-outlined mx-2">warning</span>{`Precio adicional por módulo:  ${currencyFormatter(data.currency, data.pricePerExtraModule)}`}</li>
                        <li className="flex items-center my-2"><span className="material-symbols-outlined mx-2">warning</span>{`Precio adicional por video:  ${currencyFormatter(data.currency, data.pricePerExtraVideo)}`}</li>
                    </ul>
                </div>
            </div>
            <div className="divider"></div>
            <div className="sticky bottom-0 flex justify-end">
                <PrimaryButton onClick={handleSelectLicense}>Confirmar licencia</PrimaryButton>
                <SecondaryButton className="px-8 bg-functional-el-l" onClick={close}>Cancelar</SecondaryButton>
            </div>
        </ModalLayout>
    )
}
