import { Navigate, Route, Routes } from "react-router-dom"
import { ChangePassword, PerfilApp } from "../pages"
// import { PerfilApp } from "../pages/Perfil.app"


export const PerfilRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<PerfilApp />} />
            <Route path="/contrasena" element={<ChangePassword />} />
            <Route path="/*" element={<Navigate to='/perfil' />} />
        </Routes>
    )
}
