import dayjs from "dayjs"
import { LoadLicenseAdapterProps } from "../models/areaLicense.model"

export const loadAreaLicenseAdapter = (data: LoadLicenseAdapterProps) => {
    return ({
        id: data.id,
        areaId: data.area_id,
        licenseId: data.license_id,
        startDate: dayjs(data.start_date).format("YYYY-MM-DD"),
        endDate: dayjs(data.end_date).format("YYYY-MM-DD"),
        discount: data.discount,
        status: data.status,
        discountAppliedBy: data.discount_applied_by,
        discountStartDate: dayjs(data.discount_start_date).format("YYYY-MM-DD"),
        trialDays: data.trial_days,
        createdBy: data.created_by,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        deletedAt: data.deleted_at,
        license: {
            code: data.license.code,
            id: data.license.id,
            name: data.license.name,
            description: data.license.description,
            basePrice: data.license.base_price,
            currency: data.license.currency,
            activeUsers: data.license.active_users,
            activeModules: data.license.active_modules,
            activeVideos: data.license.active_videos,
            pricePerExtraUser: data.license.price_per_extra_user,
            pricePerExtraModule: data.license.price_per_extra_module,
            pricePerExtraVideo: data.license.price_per_extra_video,
            annualDiscount: data.license.annual_discount,
            features: data.license.features,
            role: data.license.role,
            status: data.license.status,
        }
    })
}
