import { Area } from "@/models/areas.models"
import { OPTION_TYPE_OBJECTIVES } from "@/pages/modules/constants"


/**
 * Transforms a type search string into its corresponding label.
 * @param typeSearch - The type search string to transform.
 * @returns The label corresponding to the type search string, or an empty string if no match is found.
 */
export const transformType = (typeSearch: string) => {
  const copyTypes = [...OPTION_TYPE_OBJECTIVES]
  const typeIndex = copyTypes.find((type) => type.value === typeSearch)
  if (typeIndex) return typeIndex.label
  return ''
}


/**
 * Transforms the area ID into its corresponding name.
 * @param areaId - The ID of the area.
 * @param areas - An array of areas.
 * @returns The name of the area corresponding to the given ID, or an empty string if no matching area is found.
 */
export const transformArea = (areaId: number | string, areas: Area[]) => {
  const area = areas.find((area) => String(area.id) === String(areaId))
  return area ? area.name : ''
}