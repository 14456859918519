import useAxiosPrivate from "@/hooks/useApiV2Private"
import { AppStore } from "@/redux"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Survey } from "../interfaces/survey.interface"
import toast from "react-hot-toast"


export const useSurveys = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [surveys, setSurveys] = useState<Survey[]>([])
  const apiV2 = useAxiosPrivate()
  const { companySlug } = useSelector((state: AppStore) => state.ui)

  /**
   * Loads surveys for a specific area.
   *
   * @param areaId - The ID of the area.
   * @returns A Promise that resolves when the surveys are loaded.
   */
  const loadSurveys = async (areaId: number, page = 1) => {
    try {
      setIsLoading(true)
      const res = await apiV2.get(`/surveys?page=${page}&company_slug=${companySlug}&area_id=${areaId}`)
      const data = res.data
      setSurveys(data.items)
      setCurrentPage(data.pagination.current_page)
      setTotalPages(data.pagination.last_page)
    }
    catch(err) {
      console.error('Error cargando las encuestas', err)
      toast.error('Error cargando las encuestas')
    }
    finally {
      setIsLoading(false)
    }
  }

  /**
   * The nextPage function increments the current page number and loads surveys for a specific area
   * asynchronously.
   * @param {number} areaId - The `areaId` parameter is a number that is used as an identifier for a
   * specific area in the application. It is passed to the `nextPage` function to load surveys related
   * to that particular area.
   */
  const nextPage = async (areaId: number) => {
    if (currentPage < totalPages) {
      await loadSurveys(areaId, currentPage + 1)
    }
  }

  /**
   * The function `firstPage` sets the current page to 1 and loads surveys based on the provided area
   * ID.
   * @param {number} areaId - The `areaId` parameter is a number that represents the unique identifier
   * of a specific area. It is used as a parameter in the `firstPage` function to load surveys related
   * to that particular area.
   */
  const firstPage = (areaId: number) => {
    loadSurveys(areaId)
  }

  /**
   * The function `previousPage` decreases the current page number by 1 if it's greater than 1 and then
   * loads surveys for a specific area.
   * @param {number} areaId - The `areaId` parameter in the `previousPage` function is a number that
   * represents the identifier of a specific area. This parameter is used to load surveys related to
   * that particular area when navigating to the previous page.
   */
  const previousPage = (areaId: number) => {
    if (currentPage > 1) {
      loadSurveys(areaId, currentPage - 1)
    }
  }

  /**
   * The function `lastPage` sets the current page to the total number of pages and loads surveys for a
   * specific area.
   * @param {number} areaId - The `areaId` parameter is a number that represents the unique identifier
   * of a specific area or location. It is used as a reference to load surveys related to that
   * particular area.
   */
  const lastPage = (areaId: number) => {
    loadSurveys(areaId, totalPages)
  }

  return {
    isLoading,
    currentPage,
    totalPages,
    nextPage,
    firstPage,
    previousPage,
    lastPage,
    loadSurveys,
    surveys
  }

}