import { useDispatch, useSelector } from "react-redux"
import { useLoadCampanies, useLoadConf } from "."
import { AppStore, setCompany, setShowSideBar } from "@/redux"
import { useEffect, useState } from "react"

export const useConfigDefault = () => {

    const dispatch = useDispatch()

    const [isLoadingSelectCompany, setIsLoadingSelectCompany] = useState(false)

    const { showSideBar, companySlug } = useSelector((state: AppStore) => state.ui)
    const { isLoading: isLoadingConfig, error, status, configCompany } = useLoadConf()
    const { companiesChanged } = useSelector((state: AppStore) => state.crudEmpresa)
    const { loadCompanies, companies, isLoading } = useLoadCampanies()

    const switchSideBar = () => {
        dispatch(setShowSideBar(!showSideBar))
    }

    const shomSideBarC = () => {
        dispatch(setShowSideBar(true))
    }


    const selectedCompany = async () => {
        const resp = await loadCompanies()
        if (resp && resp?.length > 0) {
            const company = resp?.[0]
            if (company?.slug) {
                configCompany(company.slug)
            }
            else {
                configCompany(undefined)
            }
            dispatch(setCompany(company))
        } else {
            configCompany(undefined)
        }
    }

    const selectCompany = async (slugCompany: string) => {
        setIsLoadingSelectCompany(true)
        configCompany(slugCompany)
        setTimeout(() => {
            const company = companies?.find(({ slug }: { slug: string }) => slug === slugCompany) ?? companies?.[0]
            dispatch(setCompany(company))
            setIsLoadingSelectCompany(false)
        }, 250);
    }


    useEffect(() => {
        selectedCompany()
    }, [companiesChanged])

    return {
        shomSideBarC, switchSideBar, isLoadingSelectCompany, companies, isLoading, showSideBar, companySlug, isLoadingConfig, error, status, selectCompany
    }
}