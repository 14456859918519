import httpCallService, { IHttpCallResponse } from "./http-call.service";

export interface IUploadFileResponse {
  status: string;
  message: string;
  file_id: number;
  path: string;
}

class FileService {
  public async uploadFile(file: File): Promise<IHttpCallResponse<IUploadFileResponse>> {
    const url = `${import.meta.env.VITE_API_URL}/files`;
    const response: IHttpCallResponse<IUploadFileResponse> = await httpCallService.post(
      url,
      { upload_file: file },
      { 'Content-type': 'multipart/form-data' }
    );

    return response;
  }
}

export default new FileService();
