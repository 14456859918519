export const currencyCodes = [
    {
        "AlphabeticCode": "COP",
        "Currency": "Colombian Peso",
        "Entity": "COLOMBIA",
        "MinorUnit": "2",
        "NumericCode": 170,
        "WithdrawalDate": null
    },
    {
        "AlphabeticCode": "MXN",
        "Currency": "Mexican Peso",
        "Entity": "MEXICO",
        "MinorUnit": "2",
        "NumericCode": 484,
        "WithdrawalDate": null
    },
    {
        "AlphabeticCode": "USD",
        "Currency": "US Dollar",
        "Entity": "MICRONESIA (FEDERATED STATES OF)",
        "MinorUnit": "2",
        "NumericCode": 840,
        "WithdrawalDate": null
    },
    {
        "AlphabeticCode": "GTQ",
        "Currency": "Quetzal",
        "Entity": "GUATEMALA",
        "MinorUnit": "2",
        "NumericCode": 320,
        "WithdrawalDate": null
    }
]