import cn from 'classnames';
import { t } from 'i18next';
export interface SelectProps {
  register?: any;
  options?: string[] | number[] | any[];
  optionmap: (value: any) => string | number;
  optionValueMap?: ((value: any) => string | number) | null;
  optionKeyMap?: ((value: any) => string | number) | null;
}

export const Select = ({
  register,
  options = [],
  optionmap,
  optionValueMap,
  optionKeyMap,
  className,
  ...props
}: React.ComponentProps<'select'> & SelectProps) => {
  return (
    <select
      {...props}
      disabled={props.disabled ?? false}
      placeholder={t('generics.chooseOne')}
      className={cn('h-12 bg-[#EAEAEA] rounded-lg px-3 mr-2 disabled:cursor-no-drop', className)}
      {...(register ? register() : {})}>
      <option disabled={true} value='default'>
        {t('generics.chooseOne')}
      </option>
      {options.map((value) => (
        <option
          key={optionKeyMap ? optionKeyMap(value) : optionmap(value)}
          value={optionValueMap ? optionValueMap(value) : optionmap(value)}>
          {optionmap(value)}
        </option>
      ))}
    </select>
  );
};
