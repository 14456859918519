import { useState } from "react"

export const usePagination = ({ lastPage }: { lastPage: number }) => {
    const [currentPage, setPage] = useState(1)

    const nextPage = () => {
        if (currentPage < lastPage) {
            setPage(page => page + 1)
        }
    }

    const previousPage = () => {
        if (currentPage > 1) {
            setPage(page => page - 1)
        }
    }

    const initialPage = () => {
        setPage(1)
    }

    const finalPage = () => {
        setPage(lastPage)
    }

    const changePage = (page:number) => {
        setPage(page)
    }

    return (
        {
            currentPage,
            nextPage,
            previousPage,
            initialPage,
            finalPage,
            changePage
        }
    )
}