import { Module, Modules } from "@/pages/usuarios/models/users.models"

export const loadModulesAdapterList = (modules: Modules) => {
    return modules.map((module) => {
        return {
            id: module.id,
            name: module.name,
            slug: module.slug
        }
    })
}

export const loadModuleAdapter = (module: Module) => {
        return {
            id: module.id,
            name: module.name,
            slug: module.slug
        }
}