import { useState } from 'react'
import toast from 'react-hot-toast'
import useAxiosPrivate from '@/hooks/useApiV2Private'

interface Plays {
    id: number
    name: string
    is_active: boolean
    key: string
    player_type: string
    value: string
    daily: string
    max_points: string
    min_points: string
}

let controller: AbortController, signal
export const usePlays = () => {

    const apiV2 = useAxiosPrivate()

    const [plays, setPlays] = useState<Plays[]>()
    const [isLoadingPlays, setIsloadingPlays] = useState(false)

    const loadPlays = async () => {
        setIsloadingPlays(true)
        try {
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get('/plays', {
                params: { per_page: -1, },
                signal
            })
            setPlays(resp.data.items)
            toast.success('Jugadas cargadas')
        } catch (error) {
            toast.error("Error con el servidor")
        } finally {
            setIsloadingPlays(false)
        }
    }
    return { plays, loadPlays, isLoadingPlays}
}
