import { PrimaryButton, SecondaryButton, Select } from "@/components"
import { STATE_OBJECTIVES } from "../../constants/statesObjectives";
import { t } from "i18next";
import { TitleInput } from "@/components/Inputs/TitleInput";
import { OPTION_TYPE_OBJECTIVES, TYPE_OBJECTIVES } from "../../constants";
import { FormEvent, useRef, useState } from "react";
import toast from "react-hot-toast";
import { validFormObjectives } from "./helpers/formValidatorObjectives";
import { Area } from "@/models/areas.models";
import { MdDelete } from "react-icons/md";


type Props = {
  updateStateObjectives: (state: string) => void;
  areas: Area[];
  createObjective: (moduleSlug: string, data: any) => Promise<void>;
  moduleSlug: string;
}
const typesObjectives = [...OPTION_TYPE_OBJECTIVES]

export const CreateObjective = ({ updateStateObjectives, areas, createObjective, moduleSlug }: Props) => {
  const [typeSelected, setTypeSelected] = useState<string | undefined>(undefined)
  const [askActualState, setaskActualState] = useState<boolean>(false)
  const [askFinalState, setaskFinalState] = useState<boolean>(false)
  const [optionsIds, setOptionsIds] = useState<string[]>([crypto.randomUUID()])
  const divOptionsRef = useRef(null)
  // Handle click create objective
  const handleCreateObjective = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!typeSelected) {
      return toast.error('Por favor, seleccione un tipo de objetivo.')
    }
    const { objectiveTitle, objectiveDescription, area } = e.target as HTMLFormElement
    if (!objectiveTitle.value || !objectiveDescription.value || !area.value || area.value === 'default') {
      return toast.error('Por favor, complete todos los campos.')
    }
    const current: HTMLDivElement | null = divOptionsRef.current
    if (current === null && typeSelected === TYPE_OBJECTIVES.SELECT) return

    const { isValid: isValidForm, data } = validFormObjectives(typeSelected, e, current)
    if (!isValidForm) {
      return toast.error('Por favor, revise los campos.')
    }
    data['area'] = parseInt(area.value)
    data['config']['question'] = objectiveTitle.value
    data['config']['question_description'] = objectiveDescription.value
    data['active'] = true
    try {
      await createObjective(moduleSlug, data)
      updateStateObjectives(STATE_OBJECTIVES.SEE)
    }
    catch (err) {
      toast.error('Error al crear el objetivo')
      console.error(err)
    }
  }

  /**
   * Handles the deletion of an option.
   * @param id - The ID of the option to be deleted.
   */
  const handleDeleteOption = (id: string) => {
    if (optionsIds.length === 1) return toast.error('No puedes eliminar todas las opciones.')
    const newOptions = optionsIds.filter((option) => option !== id)
    setOptionsIds(newOptions)
  }

  return (
    <>
      <div className='p-10 bg-functional-el-l rounded-3xl shadow-lg'>
        {/* Title */}
        <div className="title">
          <div className='flex justify-between'>
            <div>
              <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.modules.objectives')}</p>
              <p className='font-medium text-functional-d-l'>{t('pages.modules.enterDataObjectives')}</p>
            </div>
            <SecondaryButton className='px-5'
              onClick={() => updateStateObjectives(STATE_OBJECTIVES.SEE)}
            >
              {t('generics.goBack')}
            </SecondaryButton>
          </div>
        </div>
        {/* Form */}
        <form className="mt-6" onSubmit={handleCreateObjective}>
          <TitleInput>{t('generics.type')}</TitleInput>
          <Select
            className='w-full'
            options={typesObjectives}
            optionmap={(option) => option.label}
            optionValueMap={(option) => option.value}
            onChange={(el) => {
              setTypeSelected(el.target.value)
              setOptionsIds([crypto.randomUUID()])
            }}
            defaultValue={'default'}
          />
          <TitleInput className="mt-6">{t('generics.area')}</TitleInput>
          <Select
            className='w-full'
            options={areas}
            optionmap={(option) => option.name}
            optionValueMap={(option) => option.id}
            defaultValue={'default'}
            name="area"
          />
          <TitleInput className="mt-6">{t('pages.modules.questionObjective')}</TitleInput>
          <input className='w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2' type="text" inputMode="text" name="objectiveTitle" />

          <TitleInput className="mt-6">{t('pages.modules.descriptionObjective')}</TitleInput>
          <input className='w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2' type="text" inputMode="text" name="objectiveDescription" />

          {/* Type Range Number */}
          {typeSelected === TYPE_OBJECTIVES.NUMBER_RANGE && (
            <>
              <div className="w-100 flex justify-between mt-6">
                <div className="w-1/2 pr-2">
                  <TitleInput>{t('generics.minimum')}</TitleInput>
                  <input className='w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2' type="number" inputMode="numeric" name="objectiveMin" />
                </div>
                <div className="w-1/2 pl-2">
                  <TitleInput>{t('generics.maximum')}</TitleInput>
                  <input className='w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2' type="number" inputMode="numeric" name="objectiveMax" />
                </div>
              </div>
            </>
          )}
          {
            typeSelected === TYPE_OBJECTIVES.SELECT && (
              <>
                <TitleInput className="mt-6">{t('generics.options')}</TitleInput>
                <div className="options-selection"
                  ref={divOptionsRef}>
                  {
                    optionsIds.map((value, index) => (
                      <div key={value} className="flex items-center mt-2">
                        <input className='w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2' type="text" inputMode="text" name={`option${index}`} />
                        {/* Button delete but with icon */}
                        <button className="ml-2 text-red-500 bg-red-100 rounded-full p-2 mt-3" type="button" onClick={() => handleDeleteOption(value)}>
                          <MdDelete />
                        </button>
                      </div>
                    ))
                  }
                </div>
                {/* Button Add Option Max 4 option */}
                {
                  optionsIds.length < 4 && (
                    <div className="flex justify-center mt-6">
                      <SecondaryButton
                        onClick={() => setOptionsIds([...optionsIds, crypto.randomUUID()])}
                        type="button"
                        className="px-5"
                      >
                        {t('generics.add')}
                      </SecondaryButton>
                    </div>
                  )
                }

              </>
            )
          }
          {
            typeSelected && (
              <>
                <div className="flex items-center mt-6">
                  <input type="checkbox" className="h-5 w-5" name="objectiveCheckboxActualState" onChange={(e) => setaskActualState(e.target.checked)} />
                  <p className="ml-2 text-functional-d-l">{t('pages.modules.questionActualState')}</p>
                </div>
                <TitleInput className="mt-6">{t('pages.modules.questionActualStateText')}</TitleInput>
                <input className={`w-full h-12 rounded-lg px-3 mt-2 ${!askActualState ? 'bg-[#B8B8B8] cursor-not-allowed' : 'bg-[#EAEAEA]'} `} type="text" inputMode="text" name="actualStateQuestion" disabled={!askActualState} />
                <TitleInput className="mt-6">{t('pages.modules.descriptionActualState')}</TitleInput>
                <input className={`w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2 ${!askActualState ? 'bg-[#B8B8B8] cursor-not-allowed' : 'bg-[#EAEAEA]'}`} type="text" inputMode="text" name="actualStateDescription" disabled={!askActualState} />
                {/* Final State */}
                <div className="flex items-center mt-6">
                  <input type="checkbox" className="h-5 w-5" name="objectiveCheckboxFinalState" onChange={(e) => setaskFinalState(e.target.checked)} />
                  <p className="ml-2 text-functional-d-l">{t('pages.modules.questionFinalState')}</p>
                </div>
                <>
                  <TitleInput className="mt-6">{t('pages.modules.questionFinalStateText')}</TitleInput>
                  <input className={`w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2 ${!askFinalState ? 'bg-[#B8B8B8] cursor-not-allowed' : 'bg-[#EAEAEA]'}`} type="text" inputMode="text" name="finalStateQuestion" disabled={!askFinalState} />
                  <TitleInput className="mt-6">{t('pages.modules.descriptionFinalState')}</TitleInput>
                  <input className={`w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mt-2 ${!askFinalState ? 'bg-[#B8B8B8] cursor-not-allowed' : 'bg-[#EAEAEA]'}`} type="text" inputMode="text" name="finalStateDescription" disabled={!askFinalState} />
                </>
              </>
            )
          }

          {/* Button Create */}
          <div className='mt-6 flex justify-center'>
            <PrimaryButton className='px-10' type="submit"
            >
              {t('generics.create')}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  )
}
