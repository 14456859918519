import { PrimaryButton, SecondaryButton } from '@/components';
import { createPart } from '@/redux/states/lessons/parts.slice';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from '@/components/Inputs/InputText';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { useForm } from 'react-hook-form';
import { AppStore } from '@/redux';
import { t } from 'i18next';

interface Answer {
  required: boolean;
  label: 'opcion1' | 'opcion2' | 'opcion3';
  name: string;
  id: number;
  labelCorrect: 'opcion1Answer' | 'opcion2Answer' | 'opcion3Answer';
}

interface FormQuestionsProps {
  close: () => void;
}

const defaultValuesAdapter = (data: any) => {
  return {
    question: data?.title,
    opcion1: data?.options?.[0]?.title,
    opcion2: data?.options?.[1]?.title,
    opcion3: data?.options?.[2]?.title,
    opcion1Answer: data?.options?.[0]?.is_correct,
    opcion2Answer: data?.options?.[1]?.is_correct,
    opcion3Answer: data?.options?.[2]?.is_correct
  };
};

export const FormQuestions: React.FC<FormQuestionsProps> = ({ close }) => {
  const answers: Answer[] = [
    { required: true, label: 'opcion1', name: `${t('generics.option')} 1*`, id: 1, labelCorrect: 'opcion1Answer' },
    { required: true, label: 'opcion2', name: `${t('generics.option')} 2*`, id: 2, labelCorrect: 'opcion2Answer' },
    { required: false, label: 'opcion3', name: `${t('generics.option')} 3`, id: 3, labelCorrect: 'opcion3Answer' }
  ];

  const dispatch = useDispatch();

  const { part, videoIncorrectSelected } = useSelector((state: AppStore) => state.parts);

  const { register, handleSubmit } = useForm({
    defaultValues: { ...defaultValuesAdapter({ ...part?.quiz }) }
  });

  const onSubmit = async (data: any) => {
    dispatch(
      createPart({
        ...part,
        quiz: {
          title: data.question,
          not_correct_video_url: videoIncorrectSelected,
          options: answers
            .map((answer) => ({ title: data?.[answer.label], is_correct: data?.[answer.labelCorrect] }))
            .filter(({ title }) => title)
        }
      })
    );
    close();
  };

  const closeModal = (e: React.FormEvent) => {
    e.preventDefault();
    close();
  };

  const disabled = false;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='h-full overflow-hidden'>
        <div className='p-4 w-5/6'>
          <InputText
            className='w-full'
            disabled={disabled}
            register={() => register('question', { required: true })}
            placeholder={`${t('generics.question')}*`}
          />
        </div>
        <TitleInput className='mx-4'>{t('generics.answer')}</TitleInput>
        {answers.map((answer) => {
          return (
            <div key={answer.id} className='p-4 w-5/6'>
              <InputText
                className='w-full'
                disabled={disabled}
                register={() => register(answer.label, { required: answer.required })}
                placeholder={answer.name}
              />
              <label className='label cursor-pointer'>
                <span className='label-text'>{t('generics.isCorrect')}:</span>
                <input type='checkbox' className='checkbox' {...register(answer.labelCorrect)} />
              </label>
            </div>
          );
        })}
      </div>
      <div className=' flex justify-end'>
        <PrimaryButton type='submit'>{t('generics.save')}</PrimaryButton>
        <SecondaryButton className='px-5' onClick={closeModal}>
          {t('generics.cancel')}
        </SecondaryButton>
      </div>
    </form>
  );
};
