import { Navigate, Route, Routes } from "react-router-dom"
import { LicensesTable } from "../pages/LicensesTable"
import { CRUDLicense } from "../pages/CrudLicense"

export const LicensesRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<LicensesTable />} />
            <Route path="crud" element={<CRUDLicense />} />
            <Route path="/*" element={<Navigate to='/licencias' />} />
        </Routes>
    )
}
