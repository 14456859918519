import { useEffect } from 'react';
import { AppStore } from '@/redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PaginationView } from '@/components/pagination/Pagination';
import { AddButtonPrimary } from '@/components/Buttons/AddButtons';
import { ModuleList } from '../views/ModuleList';
import { LessonList } from '../views/LessonList';
import { useLessons } from '../hooks/useLessons';
import { t } from 'i18next';

interface ModuleLesson {
  name: string;
  slug: string;
}

export const LessonsApp = () => {
  const navigate = useNavigate();

  const { companySlug } = useSelector((state: AppStore) => state.ui);

  const {
    // paginación
    nextPage,
    previousPage,
    initialPage,
    finalPage,
    changePage,
    selectModule,
    isLoading,
    pagination,
    lessons,
    moduleSelected,
    isLoadingModules,
    modules,
    per_page,
    currentPage,
    // Busqueda de modulos
    value,
    setValue,
    startLoadModules,
    startLoadLessons,
    startInitialState
  } = useLessons(companySlug);

  const handleInput = (e: any): void => {
    setValue(e.target.value);
  };

  const handleSelectLesson = (id: string | number | undefined, moduleSlug: ModuleLesson | string) => {
    navigate('/lecciones/crud', { state: { moduleSelected: moduleSlug, lessonSelected: id } });
  };

  useEffect(() => {
    startLoadModules();
    return () => {
      startInitialState();
    };
  }, []);

  return (
    <>
      <article className='bg-[#F4F8FE]'>
        <div className='bg-functional-el-l sticky top-0 flex items-center justify-between px-14 py-7 mb-4 z-10'>
          <div>
            <p className='font-bold text-2xl text-[#1E293B]'>{t('generics.lessons')}</p>
            <p className='font-medium text-[#5F6060]'>{t('pages.lessons.title')}</p>
          </div>
          <div className='w-1/3 flex justify-end'>
            <label htmlFor='simple-search' className='sr-only text-black'>
              {t('generics.search')}
            </label>
            <div className='relative'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <span className='material-symbols-outlined text-gray-500'>search</span>
              </div>
              <input
                value={value}
                onChange={handleInput}
                type='text'
                id='simple-search'
                className='bg-functional-l-l rounded-lg block w-52 pl-10 p-2.5 py-4'
                placeholder={t('generics.search')}
              />
            </div>
            {moduleSelected !== 'todos' && moduleSelected !== undefined && moduleSelected !== '' ? (
              <AddButtonPrimary
                name={t('generics.add')}
                onClick={() => handleSelectLesson(undefined, moduleSelected)}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='p-4 flex px-10'>
          <ModuleList {...{ modules, isLoadingModules, selectModule, moduleSelected }} />
          <div className='px-5'>
            <p className='text-4xl font-bold'>{t('generics.lessons')}</p>
            {isLoadingModules === false ? (
              <LessonList {...{ startLoadLessons, lessons, isLoading, per_page, value, currentPage, moduleSelected }} />
            ) : null}
          </div>
        </div>
      </article>
      {<PaginationView {...{ pagination, nextPage, previousPage, changePage, finalPage, initialPage }} />}
    </>
  );
};
