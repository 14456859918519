import classNames from 'classnames';

interface ButtonsMobileProps {
   buttonsColor: string;
   secondaryColor: string;
}

interface ProgressBarMobileProps {
   barColor: string;
   primaryColor: string;
}

export const ButtonsMobile: React.FC<ButtonsMobileProps> = ({ buttonsColor, secondaryColor }) => {
   return (
      <>
         <div className="relative bg-[url('/imagenInicioSimon.png')] bg-contain bg-no-repeat bg-origin-content">
            <div className=' h-[500px] w-[250px] overflow-hidden'>
               <div className='absolute top-0 right-0 left-0 h-[70px] w-[90%] mx-auto flex items-center justify-center z-[2] text-[18px]'>
                  <button disabled={true} className='w-[100px] h-[40px] rounded-md bg-white font-semibold shadow-md bg-functional-el-l'>
                     Explorar
                  </button>
               </div>
               <div className='absolute bottom-0 left-0 right-0 mx-auto py-6 z-[2] backdrop-blur-sm bg-[#F4F3F4]/80 flex flex-col items-center justify-center rounded-t-md gap-6 transition-all duration-500 ease-out '>
                  <p className='w-[85%] text-center text-black-custom font-semibold text-[18px] leading-[26px]'>
                     Elige entre estas dos opciones:
                  </p>
                  <div className='w-[85%] max-w-[400px] flex flex-col gap-[12px] max-h-[256px] overflow-y-auto'>
                     <a
                        style={{ backgroundColor: secondaryColor, color: buttonsColor }}
                        className={`min-h-[50px] flex justify-center items-center rounded-md gap-2`}>
                        <div className={`w-[30px] text-black h-[30px] rounded-md bg-white flex justify-center items-center`}>A</div>
                        <p className={'w-[75%] text-left font-medium py-1'}>Empezar</p>
                     </a>
                     <a
                        style={{ backgroundColor: secondaryColor, color: buttonsColor }}
                        className={`min-h-[50px] flex justify-center items-center rounded-md gap-2`}>
                        <div className='w-[30px] h-[30px] rounded-md bg-white text-black flex justify-center items-center'>B</div>
                        <h2 className='w-[75%] text-left text-blue-primary font-medium py-1'>Descubre</h2>
                     </a>
                  </div>
                  <a className='flex flex-col justify-center items-center'>
                     <svg
                        stroke='currentColor'
                        fill='currentColor'
                        strokeWidth={0}
                        viewBox='0 0 24 24'
                        className='text-[40px] text-black-custom'
                        height='1em'
                        width='1em'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z' />
                     </svg>
                     <p className='leading-none font-semibold'>Volver a reproducir</p>
                  </a>
               </div>
            </div>
            <div className='relative text-white z-[1]'>
               <div className='absolute bottom-0 h-[80px] w-full flex justify-between items-center px-2 pb-1 z-[1]'>
                  <a className='w-full flex flex-col items-center gap-1 cursor-pointer text-primary-l-m'>
                     <svg
                        stroke='currentColor'
                        fill='currentColor'
                        strokeWidth={0}
                        viewBox='0 0 20 20'
                        aria-hidden='true'
                        className='text-[30px]'
                        height='1em'
                        width='1em'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
                     </svg>
                     <h2 className='leading-none'>Inicio</h2>
                  </a>
                  <a className='w-full flex flex-col items-center gap-1 cursor-pointer text-functional-el-l'>
                     <svg
                        stroke='currentColor'
                        fill='currentColor'
                        strokeWidth={0}
                        viewBox='0 0 512 512'
                        className='text-[25px]'
                        height='1em'
                        width='1em'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z' />
                     </svg>
                     <h2 className='leading-none'>Puntos</h2>
                  </a>
                  <a className='w-full flex flex-col items-center gap-1 cursor-pointer text-functional-el-l'>
                     <svg
                        stroke='currentColor'
                        fill='currentColor'
                        strokeWidth={0}
                        viewBox='0 0 24 24'
                        className='text-[30px]'
                        height='1em'
                        width='1em'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z' />
                        <circle cx={9} cy={13} r={1} />
                        <circle cx={15} cy={13} r={1} />
                        <path d='M18 11.03A6.04 6.04 0 0012.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 004.86-5.89c1.31 2.63 4 4.44 7.12 4.47z' />
                     </svg>
                     <h2 className='leading-none'>Ayuda</h2>
                  </a>
                  <a className='w-full flex flex-col items-center gap-1 cursor-pointer text-functional-el-l'>
                     <svg
                        stroke='currentColor'
                        fill='currentColor'
                        strokeWidth={0}
                        viewBox='0 0 24 24'
                        className='text-[30px]'
                        height='1em'
                        width='1em'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z' />
                     </svg>
                     <h2 className='leading-none'>Cuenta</h2>
                  </a>
               </div>
            </div>
         </div>
      </>
   );
};

export const ProgressBarMobile: React.FC<ProgressBarMobileProps> = ({ barColor, primaryColor }) => {
   return (
      <>
         <div className="relative bg-[url('/imagenInicioSimon.png')] bg-contain bg-no-repeat bg-origin-content">
            <div className=' h-[500px] w-[250px] overflow-hidden'>
               <div className='absolute right-0 left-0 top-0 bottom-0 h-fit w-fit m-auto pb-[120px]'>
                  <svg
                     stroke={primaryColor}
                     fill={primaryColor}
                     stroke-width='0'
                     viewBox='0 0 16 16'
                     className='text-[80px] text-primary-color'
                     height='1em'
                     width='1em'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path d='m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z'></path>
                  </svg>
               </div>
               <div className='relative h-[500px] w-[250px] text-white z-[1]'>
                  <div className='absolute bottom-[80px] w-full flex flex-col gap-2 transition-all duration-1000 ease-out opacity-100'>
                     <div className='flex gap-2 w-[90%] mx-auto z-[1]'>
                        <div className='w-full'>
                           <p className={`text-[15px] text-functional-el-l font-semibold`}>¡Bienvenido a Simón!</p>
                           <p className='leading-[22px]' />
                        </div>
                        <div className='text-[35px] flex flex-col items-center justify-center gap-5 w-fit'>
                           <svg
                              stroke='#ffffff'
                              fill='#ffffff'
                              strokeWidth={0}
                              viewBox='0 0 512 512'
                              height='0.7em'
                              width='0.7em'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path d='M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z' />
                           </svg>
                        </div>
                     </div>
                     <div className='relative w-[100%] px-4 z-[1]'>
                        <input
                           style={{ accentColor: barColor }}
                           className={classNames(`w-full h-[8px] rounded-full z-[1]`)}
                           type='range'
                           value={50}
                           min={0}
                           max={100}
                           defaultValue={100}
                        />
                     </div>
                  </div>
                  <div className='absolute bottom-0 h-[80px] w-full flex justify-between items-center px-2 pb-1 z-[1]'>
                     <a style={{ color: barColor }} className='w-full flex flex-col items-center gap-1 cursor-pointer'>
                        <svg
                           stroke={barColor}
                           fill={barColor}
                           strokeWidth={0}
                           viewBox='0 0 20 20'
                           aria-hidden='true'
                           className='text-[30px]'
                           height='1em'
                           width='1em'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
                        </svg>
                        <h2 className='leading-none'>Inicio</h2>
                     </a>
                     <a className='w-full flex flex-col items-center gap-1 cursor-pointer text-functional-el-l'>
                        <svg
                           stroke='currentColor'
                           fill='currentColor'
                           strokeWidth={0}
                           viewBox='0 0 512 512'
                           className='text-[25px]'
                           height='1em'
                           width='1em'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path d='M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z' />
                        </svg>
                        <h2 className='leading-none'>Puntos</h2>
                     </a>
                     <a className='w-full flex flex-col items-center gap-1 cursor-pointer text-functional-el-l'>
                        <svg
                           stroke='currentColor'
                           fill='currentColor'
                           strokeWidth={0}
                           viewBox='0 0 24 24'
                           className='text-[30px]'
                           height='1em'
                           width='1em'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path fill='none' d='M0 0h24v24H0z' />
                           <path d='M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z' />
                           <circle cx={9} cy={13} r={1} />
                           <circle cx={15} cy={13} r={1} />
                           <path d='M18 11.03A6.04 6.04 0 0012.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 004.86-5.89c1.31 2.63 4 4.44 7.12 4.47z' />
                        </svg>
                        <h2 className='leading-none'>Ayuda</h2>
                     </a>
                     <a className='w-full flex flex-col items-center gap-1 cursor-pointer text-functional-el-l'>
                        <svg
                           stroke='currentColor'
                           fill='currentColor'
                           strokeWidth={0}
                           viewBox='0 0 24 24'
                           className='text-[30px]'
                           height='1em'
                           width='1em'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path fill='none' d='M0 0h24v24H0z' />
                           <path d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z' />
                        </svg>
                        <h2 className='leading-none'>Cuenta</h2>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};
