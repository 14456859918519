import { secondsToHours } from '@/utilities/time';
import { t } from 'i18next';

interface StatisticsTypes {
  id: number;
  icon: React.ReactElement;
  value: 'score' | 'lessonsCount' | 'durationSec';
}

const statistics: StatisticsTypes[] = [
  { id: 1, icon: <span className='material-symbols-outlined text-[#FCCC4E] text-2xl'>database</span>, value: 'score' },
  {
    id: 2,
    icon: <span className='material-symbols-outlined text-[#FCCC4E] text-2xl'>schedule</span>,
    value: 'durationSec'
  },
  {
    id: 3,
    icon: <span className='material-symbols-outlined text-[#FCCC4E] text-2xl'>folder_data</span>,
    value: 'lessonsCount'
  }
];

export const CardModule: React.FC<any> = (props) => {
  const buttons = [
    {
      action: 'config',
      id: 1,
      icon: <span className='material-symbols-rounded text-center text-[#1E293B] text-4xl'>settings</span>,
      title: t('generics.configuration')
    },
    {
      action: 'lessons',
      id: 2,
      icon: <span className='material-symbols-rounded text-center text-[#1E293B] text-4xl'>file_copy</span>,
      title: t('generics.lessons')
    },
    {
      action: 'dashboard',
      id: 3,
      icon: <span className='material-symbols-rounded text-center text-[#1E293B] text-4xl'>dashboard</span>,
      title: 'Dashboard'
    }
  ];

  return (
    <article className='h-[26rem] w-[28rem] m-4 rounded-3xl overflow-hidden shadow-lg'>
      <div className='bg-[#FCCC4E] p-2 h-1/2 flex justify-center items-center flex-col relative'>
        <div className={`${props.isActive ? 'bg-success-l' : 'bg-functional-l-d'} rounded p-1 absolute top-3 right-3`}>
          <p className='text-sm'>{props.isActive ? t('generics.activated') : t('generics.disabled')}</p>
        </div>
        <span className='material-symbols-outlined text-[#1E293B] text-7xl'>extension</span>
        <p title={props?.name} className='break-all line-clamp-1 font-bold text-3xl text-[#1E293B]'>
          {props?.name}
        </p>
      </div>
      <div className='bg-[#FFFFFF] h-1/2 px-5 py-1 flex flex-col justify-between'>
        {/* statistics */}
        <div className='flex justify-between items-center my-1 '>
          {statistics.map(({ icon, id, value }) => (
            <div key={id} className='flex'>
              {icon}
              <p className='truncate overflow-hidden mx-2 font-semibold text-lg text-[#1E293B] text-center'>
                {value === 'durationSec' ? secondsToHours(props[value]) : props[value]}
              </p>
            </div>
          ))}
        </div>
        <p className='truncate font-bold text-3xl text-[#1E293B]'>{props?.name}</p>
        {/* table information */}
        <div title={props.description} className='truncate overflow-hidden text-[#1E293B]'>
          <p className='truncate'>{props.description}</p>
        </div>
        {/* Buttons to other links */}
        <div className='flex justify-between items-center my-4 mx-3'>
          {buttons.map(({ icon, title, id, action }) => (
            <div
              onClick={() => (action && props?.[action] ? props[action]() : null)}
              className='cursor-pointer'
              key={id}>
              <div className='flex justify-center'>{icon}</div>
              <p className='text-center text-xs my-1 text-[#1E293B]'>{title}</p>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};
