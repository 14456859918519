import { useNavigate } from 'react-router-dom';
import { useModules } from '../hooks/useModules';
import { AppStore } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { CardModule } from '../components/CardModule';
import { AddButtonPrimary } from '@/components/Buttons/AddButtons';
import { Module } from '@/pages/usuarios/models/users.models';
import { setSelectModule } from '@/redux/states/lessons/lessons.slice';
import { PaginationView } from '@/components/pagination/Pagination';
import { t } from 'i18next';

export const ModulosApp = () => {
  const { companySlug } = useSelector((state: AppStore) => state.ui);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    // Metodos
    nextPage,
    previousPage,
    initialPage,
    finalPage,
    changePage,
    setValue,
    // Valores
    value,
    modules,
    pagination
  } = useModules(companySlug);

  const handleInput = (e: any): void => {
    setValue(e.target.value);
  };

  const handleSelectModule = (id: string | number | undefined) => {
    navigate('/modulos/crud', { state: { moduleSelected: id } });
  };

  const handleLinkLessons = async (id: string | number) => {
    dispatch(setSelectModule(id));
    navigate('/lecciones');
  };

  const handleLinkDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <article className='bg-[#F4F8FE] h-full overflow-auto'>
        <div className='bg-functional-el-l sticky top-0 flex items-center justify-between px-14 py-7 mb-4 z-10'>
          <div>
            <p className='font-bold text-2xl text-[#1E293B]'>{t('generics.modules')}</p>
            <p className='font-medium text-[#5F6060]'>{t('pages.modules.moduleAdmin')}</p>
          </div>
          <div className='w-1/3 flex justify-end'>
            <label htmlFor='simple-search' className='sr-only text-black'>
              {t('generics.search')}
            </label>
            <div className='relative'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <span className='material-symbols-outlined text-gray-500'>search</span>
              </div>
              <input
                value={value}
                onChange={handleInput}
                type='text'
                id='simple-search'
                className='bg-functional-l-l rounded-lg block w-52 pl-10 p-2.5 py-4'
                placeholder={t('generics.search')}
              />
            </div>
            <AddButtonPrimary name={t('generics.add')} onClick={() => handleSelectModule(undefined)} />
          </div>
        </div>
        <div
          className={cn(`grid-cols-4 grid-rows-2 px-10`, {
            grid: modules?.length > 0,
            'flex justify-center items-center': !(modules?.length > 0)
          })}>
          {modules?.length > 0 ? (
            modules.map((module: Module) => {
              return (
                <div className='flex justify-center items-center' key={module.id}>
                  <CardModule
                    {...{
                      ...module,
                      config: () => handleSelectModule(module.slug),
                      lessons: () => handleLinkLessons(module.slug),
                      dashboard: () => handleLinkDashboard()
                    }}
                  />
                </div>
              );
            })
          ) : (
            <div className='flex w-full h-96 justify-center items-center'>
              <p className='font-medium text-3xl'>{t('pages.modules.notModules')}</p>
            </div>
          )}
        </div>
        {/* </div> */}
        {modules?.length > 0 ? (
          <PaginationView {...{ pagination, nextPage, previousPage, changePage, finalPage, initialPage }} />
        ) : null}
      </article>
    </>
  );
};
