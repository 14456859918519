import { Modules } from "@/pages/usuarios/models/users.models"

export const loadModulesAdapter = (modules: Modules) => {
    return modules.map((module) => {
        return {
            id: module.id,
            name: module.name,
            description: module.description,
            slug: module.slug,
            imageId: module.image_id,
            lessonsCount: module.lessons_count,
            companyAreas: module.company_areas,
            imagePath: module.image_path,
            durationSec: module.duration_sec ?? 0,
            score: module.score ?? 0,
            isActive: module.is_active
        }
    })
}