import AuthLayout from './layout/AuthLayout';
import { boxStyle, containerStyle } from './styles/login.styled';
import { useAuthStore } from './hooks';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import UserBlocked from './views/UserBlocked';


export type LoginAppProps = {
}

interface ViewMap {
	login: React.ReactNode
	forgotPassword: React.ReactNode
	userBlocked: React.ReactNode
}

const LoginApp: React.FC<LoginAppProps> = () => {

	const { errorMessage, startLogin, view, setView, forgotPwReset, forgotPwrequest } = useAuthStore()


	useEffect(() => {
		if (errorMessage === 'Too many fail login attempt your ip has restricted for 1 minute.') {
			setView('userBlocked')
		}
		if (errorMessage !== null) {
			toast.error('Error en la autenticación')
		}
	}, [errorMessage])

	const viewMap: ViewMap = {
		'login': <Login startLogin={startLogin} setView={setView} />,
		'forgotPassword': <ForgotPassword setView={setView} resetPassword={forgotPwReset} requestPassword={forgotPwrequest} />,
		'userBlocked': <UserBlocked setView={setView} />
	}


	return <AuthLayout>
		<div className={containerStyle}>
			<div className={boxStyle} >
				<img src="/logo/simon_logo-06.png" className='w-[30rem] h-64' />
			</div>
			{viewMap?.[view] ?? <p>Error </p>}
		</div>
	</AuthLayout>
};

export default LoginApp;
