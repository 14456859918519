import { useEffect } from 'react';
import { useLoadImage } from '@/hooks/useLoadImage';
import { AppStore } from '@/redux';
import { loadReward, resetReward, setIsLoading, setStatus } from '@/redux/states/rewards/crudPremio';
import { useDispatch, useSelector } from 'react-redux';
import { loadRewardAdapter } from '../adapters/loadReward.adapter';
import toast from 'react-hot-toast';
import { proccessError } from '@/utilities/stringsError';
import useAxiosPrivate from '@/hooks/useApiV2Private';

export const useCrudReward = (areaId: number, rewardSelected?: number) => {
   const apiV2 = useAxiosPrivate();

   const dispatch = useDispatch();
   const { status, reward, isLoading, selectedArea } = useSelector((state: AppStore) => state.crudReward);

   const { companySlug } = useSelector((state: AppStore) => state.ui);

   const { image, setImage, uploadImage, loadImageWithValidations } = useLoadImage();

   const startLoadReward = async (id: string | number) => {
      dispatch(setIsLoading(true));
      try {
         const resp = await apiV2.get(`/companies/${companySlug}/areas/${areaId}/rewards/${id}`);
         dispatch(loadReward(loadRewardAdapter(resp.data)));
         setImage({
            image_id: resp.data.image_id,
            image_path: resp.data.image_path
         });
         toast.success('Datos del premio cargados');
      } catch (error) {
         toast.error('Error en el servidor');
      }
   };

   const updateReward = async ({ data }: any) => {
      delete data.image;
      let respImage: number | undefined | void;
      try {
         if (image?.file) {
            await uploadImage(image.file).then((e) => {
               respImage = e;
            });
         }
         await apiV2.patch(`/companies/${companySlug}/areas/${areaId}/rewards/${reward.id}`, {
            ...reward,
            ...data,
            image_id: respImage ?? image?.image_id,
            available: data.available || null,
            exchanged: data.exchanged || null,
            unit_cost: data.unitCost || null
         });
         toast.success('Premio actualizado con éxito');
         return { message: 'success' };
      } catch (error: any) {
         if (error.response.status === 400) {
            toast(proccessError(error.response.data));
            toast.error('El premio no ha sido modificado');
         }
      }
   };

   const createReward = async (data: any) => {
      let respImage: number | undefined;
      try {
         if (image?.file) {
            await uploadImage(image.file).then((e) => {
               respImage = e;
            });
         }
         await apiV2.post(`/companies/${companySlug}/areas/${areaId}/rewards`, {
            ...data,
            terms_conditions: data.termsConditions,
            delivery_policy: data.deliveryPolicy,
            is_active: data.isActive,
            image_id: respImage ?? image?.image_id,
            available: data.available || null,
            exchanged: data.exchanged || null,
            unit_cost: data.unitCost || null
         });
         toast.success('Premio creado con éxito');
         return { message: 'success' };
      } catch (error: any) {
         if (error.response.status === 400) {
            toast(proccessError(error.response.data));
            toast.error('El premio no ha sido creado:');
         }
      }
   };

   const editing = () => {
      dispatch(setStatus('editing'));
   };

   const viewing = () => {
      dispatch(setStatus('viewing'));
   };

   useEffect(() => {
      if (rewardSelected) {
         startLoadReward(rewardSelected);
      } else {
         dispatch(setStatus('creating'));
      }
      return () => {
         dispatch(resetReward());
      };
   }, [rewardSelected]);

   return {
      // propiedades
      image,
      reward,
      status,
      isLoading,
      selectedArea,
      // Metodos,
      uploadImage,
      editing,
      viewing,
      setImage,
      updateReward,
      createReward,
      loadImageWithValidations
   };
};
