import { useEffect, useState } from 'react';
import { useUsers } from '../hooks/useUsers';
import { useNavigate } from 'react-router-dom';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { UserBasicInfo } from '../models/users.models';
import { useSelector } from 'react-redux';
import { AppStore } from '@/redux';
import { AddButtonPrimary } from '@/components/Buttons/AddButtons';
import { PaginationView } from '@/components/pagination/Pagination';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '@/components';
import { t } from 'i18next';
import { IoMdDownload } from 'react-icons/io';
import { getEnvVariables } from '@/utilities';

export const UsuariosApp: React.FC = () => {
   const navigate = useNavigate();
   const { VITE_BASE_URL, VITE_PUBLIC_KEY } = getEnvVariables();

   const headersUsuarios = [
      { textAlign: 'text-left', name: t('generics.users') },
      { textAlign: 'text-left', name: t('generics.mail') },
      { textAlign: 'text-left', name: 'Rol' },
      { textAlign: 'text-center', name: t('generics.activate') },
      { textAlign: 'text-center', name: t('generics.modify') }
   ];

   const { companySlug, currentCompany } = useSelector((state: AppStore) => state.ui);

   const [showModalDelete, setShowModalDelete] = useState(false);
   const [userSelected, setUserId] = useState<{ userId: number | undefined; name: string | undefined }>();

   const {
      // Metodos
      nextPage,
      previousPage,
      initialPage,
      finalPage,
      changePage,
      setValue,
      toggleActiveUser,
      startDeleteUser,
      selectOnlyPublic,
      startLoadUsers,
      startResetState,
      per_page,
      currentPage,
      // Valores
      value,
      usuarios,
      pagination,
      isLoading,
      onlyPublic
   } = useUsers(companySlug);

   const handleInput = (e: any): void => {
      setValue(e.target.value);
   };

   const handleSelectUser = (id: string | number | undefined) => {
      navigate('/usuarios/crud', { state: { userSelected: id } });
   };

   const handleToggleActive = (data: { id: number; isActive: boolean }) => {
      toggleActiveUser(data);
   };

   const handleDeleteUser = (id: number) => {
      startDeleteUser(id);
      setShowModalDelete(false);
      setUserId(undefined);
   };

   const handleSelectOnlyPublic = (isSelect: boolean) => {
      selectOnlyPublic(isSelect);
   };

   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
         startLoadUsers();
      }, 250);
      return () => {
         clearTimeout(delayDebounceFn);
         startResetState();
      };
   }, [per_page, value, currentPage, onlyPublic]);

   return (
      <>
         <dialog id='my_modal_1' className={classNames('modal', { 'modal-open': showModalDelete })}>
            <div className={classNames('modal-box')}>
               <div className='flex justify-between mb-5'>
                  <h3 className='font-bold text-2xl'>{t('generics.confirm')}</h3>
                  <span
                     onClick={() => {
                        setShowModalDelete(false);
                        setUserId(undefined);
                     }}
                     className='material-symbols-outlined cursor-pointer'>
                     close
                  </span>
               </div>
               <div>
                  <p>{`${t('pages.users.deleteMessage')} "${userSelected?.name ?? ''}"`}</p>
               </div>
               <div className=' flex justify-end mt-5'>
                  <PrimaryButton onClick={() => (userSelected?.userId ? handleDeleteUser(userSelected.userId) : null)}>
                     {t('pages.users.deleteUser')}
                  </PrimaryButton>
                  <SecondaryButton
                     className='px-8'
                     onClick={() => {
                        setShowModalDelete(false);
                        setUserId(undefined);
                     }}>
                     {t('generics.cancel')}
                  </SecondaryButton>
               </div>
            </div>
         </dialog>
         <article className='bg-[#F4F8FE] h-full overflow-auto'>
            <div className='bg-functional-el-l sticky top-0 flex items-center justify-between px-14 py-7 mb-4 z-10'>
               <div>
                  <p className='font-bold text-2xl text-[#1E293B]'>{t('generics.users')}</p>
                  <p className='font-medium text-[#5F6060]'>{t('pages.users.usersAdmin')}</p>
               </div>
               <div className='w-1/3 flex justify-end'>
                  <label htmlFor='simple-search' className='sr-only text-black'>
                     {t('generics.search')}
                  </label>
                  <div className='relative'>
                     <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <span className='material-symbols-outlined text-gray-500'>search</span>
                     </div>
                     <input
                        value={value}
                        onChange={handleInput}
                        type='text'
                        id='simple-search'
                        className='bg-functional-l-l rounded-lg block w-52 pl-10 p-2.5 py-4'
                        placeholder={t('generics.search')}
                     />
                  </div>
                  <AddButtonPrimary name={t('generics.users')} onClick={() => handleSelectUser(undefined)} />
               </div>
            </div>
            {!isLoading && usuarios !== null ? (
               <div className='bg-functional-el-l rounded-3xl shadow-lg mx-10 p-10 my-4'>
                  {currentCompany.id && (
                     <div className='w-full flex justify-end items-center gap-2'>
                        <p>{t('pages.users.downloadDataBase')}</p>
                        <a
                           href={`${VITE_BASE_URL}/report/users?company=${currentCompany.id}&apikey=${VITE_PUBLIC_KEY}`}
                           download={true}
                           target='_blank'
                           className='bg-gray-300 py-2 px-4 rounded-md font-semibold flex items-center gap-1'>
                           {t('generics.download')}
                           <IoMdDownload className='text-[25px]' />
                        </a>
                     </div>
                  )}
                  <div className='tabs my-3'>
                     <a onClick={() => handleSelectOnlyPublic(false)} className={`tab tab-lifted ${onlyPublic ? '' : 'tab-active'}`}>
                        {t('generics.all')}
                     </a>
                     <a onClick={() => handleSelectOnlyPublic(true)} className={`tab tab-lifted ${onlyPublic ? 'tab-active' : ''}`}>
                        {t('pages.users.onlyPublic')}
                     </a>
                  </div>
                  {value || usuarios.length > 0 ? (
                     <>
                        <table className='table-auto w-full rounded-none'>
                           <thead className='text-black'>
                              <tr className=' '>
                                 {headersUsuarios.map(({ name, textAlign }) => (
                                    <th
                                       key={name}
                                       className={classNames(
                                          'first:rounded-l-2xl last:rounded-r-2xl bg-functional-el-m text-lg p-3',
                                          textAlign
                                       )}>
                                       {name}
                                    </th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {usuarios.map(({ id, email, avatarUrl, name, rol, isActive }: UserBasicInfo) => {
                                 const rolString = Object.entries(rol)
                                    .map(([_, value]) => value)
                                    .join(', ');
                                 return (
                                    <tr key={id} className='border border-functional-el-l border-b-functional-l-d'>
                                       <td className='text-center py-2'>
                                          <div className='flex items-center space-x-3'>
                                             <div className='avatar'>
                                                <div className='mask mask-squircle w-12 h-12'>
                                                   <img src={avatarUrl ?? '/imgLayout.png'} alt='' />
                                                </div>
                                             </div>
                                             <div>
                                                <p>{name}</p>
                                             </div>
                                          </div>
                                       </td>
                                       <td className='py-2'>{email}</td>
                                       <td className=' py-2'>{rolString}</td>
                                       <td className='text-center py-2'>
                                          <ToggleSwitch
                                             message=''
                                             checked={isActive}
                                             onChange={() => handleToggleActive({ id, isActive })}
                                          />
                                       </td>
                                       <td className='text-center py-2'>
                                          <span
                                             title='Editar'
                                             onClick={() => handleSelectUser(id)}
                                             className='cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined'>
                                             edit_square
                                          </span>
                                          <span
                                             title={t('generics.delete')}
                                             onClick={() => {
                                                setShowModalDelete(true);
                                                setUserId({ userId: id, name });
                                             }}
                                             className='cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined'>
                                             delete
                                          </span>
                                       </td>
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>{' '}
                     </>
                  ) : (
                     <div className='flex w-full h-96 justify-center items-center'>
                        <p className='font-medium text-3xl'>{t('pages.users.notUsers')}</p>
                     </div>
                  )}
               </div>
            ) : null}
            {/* </div> */}
            {usuarios?.length > 0 ? (
               <PaginationView {...{ pagination, nextPage, previousPage, changePage, finalPage, initialPage }} />
            ) : null}
         </article>
      </>
   );
};
