import { AppStore } from "@/redux"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { loadCompaniesAdapter } from "../adapters"
import { paginationAdapter } from "@/adapters/pagination.adapter"
import { usePagination } from "@/hooks/usePagination"
import { loadCompanies, setResetState } from "@/redux/states/empresas/empresa.slice"
import useAxiosPrivate from "@/hooks/useApiV2Private"

let controller:AbortController, signal,oldValue = ''
export const useCompany = () => {

    const apiV2 = useAxiosPrivate()
    
    const [value, setValue] = useState('')
    
    const dispatch = useDispatch()
    
    const { isLoading, companies, pagination, per_page } = useSelector((state: AppStore) => state.empresa)
    const { user: {roles} } = useSelector((state: AppStore) => state.auth)

    const isSuperAdmin = roles?.find(({name}:{name:string}) => name === 'super_admin')?.name === 'super_admin'
    
    const { nextPage, previousPage, initialPage, finalPage, changePage, currentPage } = usePagination({ lastPage: pagination.last_page })
    
    const startLoadCompanies = async () => {
        try {
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get('/companies', { params: { per_page, page:oldValue === value ? currentPage : 1, search:value }, signal })
            dispatch(loadCompanies({ pagination: paginationAdapter(resp.data.pagination), items: loadCompaniesAdapter(resp.data.items) }))
            oldValue = value
        } catch (error) {
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            startLoadCompanies()
        }, 250)
        return () => {
            clearTimeout(delayDebounceFn)
            dispatch(setResetState())
        }
    }, [per_page, value, currentPage])

    return {
        // propiedades
        isLoading,
        companies,
        pagination,
        per_page,
        value,
        currentPage,
        isSuperAdmin,
        // Metodos
        startLoadCompanies,
        // Metodos de paginación
        nextPage,
        previousPage,
        initialPage,
        finalPage,
        changePage,
        // Metodos para busqueda de empresas
        setValue
    }

}