import { Pagination } from '@/models/pagination.models';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    isLoading: boolean
    premios: any,
    pagination: Pagination | {},
    errorMessage: string | null,
    per_page: number,
    rewardSelected: string | null,
    selectedArea: string | null
}

const initialState: InitialStateProps = {
    isLoading: false,
    premios: [],
    pagination: {},
    errorMessage: null,
    per_page: 20,
    rewardSelected: null,
    selectedArea: ''
}

export const rewardsSlice = createSlice({
    name: 'premios',
    initialState, 
    reducers: {
        loadRewards: (state, action) => {
            state.premios = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        setIsLoading: (state, { payload = true }) => {
            state.isLoading = payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setSelectReward: (state, action) => {
            state.rewardSelected = action.payload
        },
        setToggleActiveReward: (state, { payload }) => {
            const ix = state.premios.findIndex(({ id }: { id: number }) => id === payload)
            state.premios[ix].isActive = payload.isActive
        },
        setDeleteReward: (state, { payload }) => {
            const ix = state.premios.findIndex(({ id }: { id: number }) => id === payload.id)
            state.premios.splice(ix, 1)
            state.isLoading = false
        },
        setSelectArea: (state, action) => {
            state.selectedArea = action.payload
            state.premios = undefined
        },
        setResetRewards: (state) => {
            state.premios = undefined
        },
        setResetState: () => initialState
    }
});


// Action creators are generated for each case reducer function
export const {setResetState , setResetRewards, setSelectArea, setIsLoading, loadRewards, setError, setSelectReward, setToggleActiveReward, setDeleteReward } = rewardsSlice.actions;
