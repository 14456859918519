import { Area } from "@/models/areas.models"

export const loadAreaAdapter = (area: Area) => {
    return  ({
        licenseActive: area.license_active,
        name: area.name,
        id: area.id,
        info: area.info,
        isActive: area.is_active,
        expectedUsers: area.expected_users,
        signatureName: area.signature_name,
        signaturePosition: area.signature_position,
        certificateLogo: area.certificate_logo,
        certificateLogoPath: area.certificate_logo_path,
        willGrantCertificate: area.will_grant_certificate
    })
}
