import { boxFormStyle, pStyle } from '../styles/login.styled';
import { View } from '../models/login.models';

interface UserBlockedProps {
    setView: React.Dispatch<React.SetStateAction<View>>
}

const UserBlocked: React.FC<UserBlockedProps> = ({ setView }) => {

    const handleLogin = () => {
        setView('login')
    }

    return <div className={boxFormStyle}>
        <div className='w-8/12'>
            <p className={pStyle + ' text-center'}>Usuario bloqueado por superar los intentos fallidos de inicio de sesión. Espere 1 minuto y vuelva a intentar</p>
            <img src="/lineas/simon_plataforma-13.svg" width={'50%'} />
            <p className='text-functional-l-d w-3/4 my-4'>Comuníquese con dime@holasimon.com</p>
            <p className='text-functional-d-d'>Regresar al <b onClick={handleLogin} className='cursor-pointer text-primary-l-m'>Ingreso de usuario</b></p>
        </div>
    </div>
};

export default UserBlocked;
