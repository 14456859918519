import { useEffect, useState } from 'react';
import { useVideos } from '../hooks/useVideos';
import cn from 'classnames';
import { PrimaryButton, SecondaryButton } from '@/components';
import { SpinnerMedium } from '@/components/Spinners/Spinner';
import { selectVideo } from '@/redux/states/lessons/parts.slice';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';

interface ChooseVideoProps {
  close: () => void;
  label: string;
}

export const ChooseVideo: React.FC<ChooseVideoProps> = ({ close, label }) => {
  const dispatch = useDispatch();

  const { videos, isLoadingVideos, startLoadVideos } = useVideos();
  const [videoSelected, setVideoSelected] = useState<{
    link: string;
    duration: string | number;
    qualityVideos: IQualityVideos;
  }>();

  useEffect(() => {
    startLoadVideos();
  }, []);

  const handleSelectVideo = (link: string, duration: string | number, qualityVideos: IQualityVideos) => {
    setVideoSelected((videoInfo) => (videoInfo?.link === link ? undefined : { link, duration, qualityVideos }));
  };

  const handleSaveLink = () => {
    if (videoSelected) {
      dispatch(selectVideo({ label, videoSelected }));
      close();
    }
  };

  const showVideoGrid = (): JSX.Element => {
    return (
      <div className='flex flex-wrap overflow-x-auto h-2/4'>
        {videos &&
          videos.length &&
          videos.map((item: any) => {
            let fileList: IFiles = item.files;
            let qualityVideos = {
              video_link_240p: fileList['240p']?.link ?? '',
              video_link_360p: fileList['360p']?.link ?? '',
              video_link_540p: fileList['540p']?.link ?? '',
              video_link_720p: fileList['720p']?.link ?? '',
              video_link_1080p: fileList['1080p']?.link ?? ''
            };
            let link: string = '';
            if (fileList['720p']) {
              link = fileList['720p'].link;
            }
            if (fileList['540p']) {
              link = fileList['540p'].link;
            }
            if (fileList['360p']) {
              link = fileList['360p'].link;
            }
            const checked = link === videoSelected?.link;
            return (
              <div
                key={link}
                className={cn('m-4 rounded-lg', {
                  'w-[240px]': link !== videoSelected?.link,
                  'w-[256px]': checked,
                  'border-8': checked,
                  'border-primary-l-m': checked
                })}>
                <iframe className='rounded-lg' src={`${link}`} title={item.name} width='240px' height='350px' />
                <div className='w-full mt-1'>
                  <p title={item.name} className='line-clamp-2 w-full h-15 min-h-15 break-all'>
                    <b>{t('generics.name')}:</b> {item.name}
                  </p>
                  <p>
                    <b>{t('generics.duration')}:</b> {item.duration} {t('generics.seconds')}
                  </p>
                </div>
                <label className='label cursor-pointer sticky bottom-0'>
                  <span className='label-text'>{t('generics.select')}</span>
                  <input
                    onChange={() => handleSelectVideo(link, item.duration, qualityVideos)}
                    type='checkbox'
                    checked={checked}
                    className='checkbox'
                  />
                </label>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div className='h-full overflow-hidden'>{!isLoadingVideos ? showVideoGrid() : <SpinnerMedium />}</div>
      <div className='sticky bottom-0 flex justify-end'>
        <PrimaryButton onClick={handleSaveLink}>{t('generics.save')}</PrimaryButton>
        <SecondaryButton className='px-8 bg-functional-el-l' onClick={close}>
          {t('generics.cancel')}
        </SecondaryButton>
      </div>
    </>
  );
};

export interface IFiles {
  '240p': IFileProps;
  '360p': IFileProps;
  '540p': IFileProps;
  '720p': IFileProps;
  '1080p': IFileProps;
}

export interface IFileProps {
  created_time: string;
  fps: number;
  height: number;
  link: string;
  md5: any;
  public_name: string;
  quality: string;
  rendition: string;
  size: number;
  size_short: string;
  type: string;
  width: number;
}

export interface IQualityVideos {
  video_link_240p: string;
  video_link_360p: string;
  video_link_540p: string;
  video_link_720p: string;
  video_link_1080p: string;
}
