import { SpinnerMedium } from '@/components/Spinners/Spinner';
import classNames from 'classnames';
import { t } from 'i18next';

interface ModuleListProps {
  selectModule: (slug: string) => void;
  isLoadingModules: boolean | undefined;
  modules: { id: number; name: string; slug: string }[] | undefined;
  moduleSelected: string;
}

export const ModuleList: React.FC<ModuleListProps> = ({ moduleSelected, isLoadingModules, modules, selectModule }) => {
  const handleSelectModule = (slug: string) => {
    selectModule(slug);
  };

  const isSelectedTodos = !moduleSelected || moduleSelected === 'todos';

  return (
    <div>
      <p className='text-4xl font-bold'>{t('generics.modules')}</p>
      <img src='/lineas/simon_plataforma-11.svg' className='w-56' />
      <ul className='w-56 h-[63vh] flex-nowrap overflow-x-auto pr-2'>
        {/* <ul className="flex fle-col p-[0.5rem] w-56 h-[70vh] leading-5"> */}
        <li className='cursor-pointer' onClick={() => handleSelectModule('todos')}>
          <div
            className={classNames('rounded-lg p-4 my-3 text-center border', {
              'bg-functional-d-l': isSelectedTodos,
              'text-functional-el-l': isSelectedTodos
            })}>
            {t('generics.all')}
          </div>
        </li>
        {!isLoadingModules ? (
          modules?.map((module) => (
            <li title={module.name} key={module.id}>
              <div
                className={classNames(
                  'max-w-56 rounded-lg overflow-hidden border p-4 truncate my-3 text-center cursor-pointer',
                  {
                    'bg-functional-d-l': moduleSelected === module.slug,
                    'text-functional-el-l': moduleSelected === module.slug
                  }
                )}
                onClick={() => handleSelectModule(module.slug)}>
                {module.name}
              </div>
              {/* <a className={classNames(" text-center", { 'bg-functional-d-l': moduleSelected === module.slug, 'text-functional-el-l': moduleSelected === module.slug })} >{module.name}</a> */}
            </li>
          ))
        ) : (
          <SpinnerMedium></SpinnerMedium>
        )}
      </ul>
    </div>
  );
};
