import { AppStore } from "@/redux"
import { useDispatch, useSelector } from "react-redux"
import { usePagination } from "@/hooks/usePagination"
import { useState } from "react"
import { paginationAdapter } from "@/adapters/pagination.adapter"
import toast from "react-hot-toast"
import { loadRewards, setDeleteReward, setIsLoading, setResetRewards, setResetState, setSelectArea } from "@/redux/states/rewards/rewards.slice"
import { loadRewardsAdapter } from "../adapters/loadRewards.adapter"
import { useLoadAreas } from "@/hooks/useLoadAreas"
import useAxiosPrivate from "@/hooks/useApiV2Private"


let controller: AbortController, signal, oldValue = ''
export const useRewards = (companySlug: string) => {

    const apiV2 = useAxiosPrivate()
    const dispatch = useDispatch()
    
    const { isLoading, usuarios, pagination, per_page, premios, selectedArea } = useSelector((state: AppStore) => state.rewards)

    const [value, setValue] = useState('')

    const { nextPage, previousPage, initialPage, finalPage, changePage, currentPage } = usePagination({ lastPage: pagination.last_page })

    const { loadAreas, areas, isLoading: isLoadingAreas } = useLoadAreas(companySlug)

    const startLoadRewards = async (areaId:string) => {
        dispatch(setIsLoading(true))
        try {
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/areas/${areaId}/rewards`, {
                params: { per_page, page: oldValue === value ? currentPage : 1, search: value },
                signal
            })
            oldValue = value
            dispatch(loadRewards({ pagination: paginationAdapter(resp.data.pagination), items: loadRewardsAdapter(resp.data.items) }))
        } catch (error: any) {
            dispatch(setIsLoading(false))
        }
    }

    const startDeleteReward = async (id:number) => {
        dispatch(setIsLoading(true))
        try {
            await apiV2.delete(`/companies/${companySlug}/areas/${selectedArea}/rewards/${id}`)
            dispatch(setDeleteReward(id))
        } catch (error) {
            toast.error('Existe un error en el servidor al eliminar el premio')
            dispatch(setIsLoading(false))
        } 
    }

    const startSelectArea = (id:number | string) => {
        dispatch(setSelectArea(id))
    }

    const startResetRewards = () => {
        dispatch(setResetRewards())
    }

    const startResetState = () => {
        dispatch(setResetState()) 
    }

    return {
        // propiedades
        isLoading,
        usuarios,
        pagination,
        per_page,
        value,
        currentPage,
        premios,
        areas, 
        isLoadingAreas,
        selectedArea,
        // Metodos
        loadAreas, 
        nextPage,
        previousPage,
        initialPage,
        finalPage,
        changePage,
        setValue,
        // Metodos para busqueda de usuarios
        // toggleActiveReward,
        startDeleteReward,
        startSelectArea,
        startLoadRewards,
        startResetRewards,
        startResetState
    }

}