import { useEffect, useState } from 'react'
import { AppStore } from "@/redux"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux"

import { useLoadImage } from "@/hooks/useLoadImage";
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '@/hooks/useApiV2Private';
import { loadCompany, setCrudDisabled, setIsLoading, setStatus } from '@/redux/states/empresas/personalizationCompany.slice';
import { loadConfigurationCompanyAdapter } from '../adapters/loadConfigurationCompany';

export const usePersonalizationCompany = (companyId: string) => {

    const apiV2 = useAxiosPrivate()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState<string | undefined>()
    const [initialVideoSelected, setInitialSelectVideo] = useState<{ label: string, videoSelected: { link: string, duration?: string | number } }>()
    const [moduleVideoSelected, setModuleSelectVideo] = useState<{ label: string, videoSelected: { link: string, duration?: string | number } }>()

    const { company, status, isLoading,  crudDisabled } = useSelector((state: AppStore) => state.persanalization)

    const { image:loaderImage, setImage: setloaderImage, uploadImage: uploadLoaderImage, loadImageWithValidations: loadLoaderImageWithValidations } = useLoadImage()
    const { image:transitionImage , setImage: setTransitionImage, uploadImage:uploadTransitionImage, loadImageWithValidations: loadTransitionImageWithValidations } = useLoadImage()

    const startLoadConfiguration = async (slug: string) => {
        dispatch(setIsLoading(true))
        try {
            const resp = await apiV2.get(`/companies/${slug}/configuration/`)
            dispatch(loadCompany(loadConfigurationCompanyAdapter(resp.data)))
            setloaderImage({
                image_id: resp.data.logoLoaderId,
                image_path: resp.data.logoLoaderPath ?? ''
            })
            setTransitionImage({
                image_id: resp.data.logoTransitionId,
                image_path: resp.data.logoTransitionPath ?? ''
            })
            setInitialSelectVideo({label:'',videoSelected:{link:resp.data.home_data?.url_video}})
            setModuleSelectVideo({label:'',videoSelected:{link:resp.data.modules_data?.url_video}})
        } catch (error) {
            toast.success('Información de la empresa no ha sido cargada')
        }
    }

    const updateCompany = async (data: any) => {
        dispatch(setCrudDisabled(true))
        let respLoaderImage: number | undefined
        let respTransitionImage: number | undefined
        try {
            if (loaderImage?.file) {
                await uploadLoaderImage(loaderImage.file).then((e) => { respLoaderImage = e })
            }
            if (transitionImage?.file) {
                await uploadTransitionImage(transitionImage.file).then((e) => { respTransitionImage = e })
            }
            const configuration = {
                ...company,
                ...data,
                logoLoaderId: respLoaderImage ?? loaderImage?.image_id,
                logoTransitionId: respTransitionImage ?? transitionImage?.image_id,
                home_data: {
                    ...data.home_data,
                    url_video: initialVideoSelected?.videoSelected?.link
                },
                modules_data: {
                    ...data.modules_data,
                    url_video: moduleVideoSelected?.videoSelected?.link
                }
            }
            await apiV2.post(`/companies/${companyId}/configuration/`, {...configuration })
            toast.success('La empresa ha sido personalizada')
            navigate('/empresas')
        } catch (error) {
            toast.error('No se actualizo la empresa')
        } finally {
            dispatch(setCrudDisabled(false))
        }
    }

    const editing = () => {
        dispatch(setStatus('editing'))
    }

    const viewing = () => {
        dispatch(setStatus('viewing'))
    }

    useEffect(() => {
        startLoadConfiguration(companyId)
    }, [])

    return {
        company,isLoading, status, crudDisabled, loaderImage, transitionImage, isOpen, setIsOpen, initialVideoSelected,moduleVideoSelected,
        setInitialSelectVideo, setModuleSelectVideo,
        viewing, editing, updateCompany, loadLoaderImageWithValidations, loadTransitionImageWithValidations
    }

}