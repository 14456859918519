import { PrimaryButton, SecondaryButton, Select } from '@/components';
import { createPart } from '@/redux/states/lessons/parts.slice';
import { useDispatch, useSelector } from 'react-redux';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { AppStore } from '@/redux';
import { useForm } from 'react-hook-form';
import { usePlays } from '../hooks/usePlays';
import { useEffect } from 'react';
import { t } from 'i18next';

const defaultValuesAdapter = (data: any) => {
  return {
    play_id: data?.play_id?.toString()
  };
};

interface ChoosePlayProps {
  close: () => void;
}

export const ChoosePlay: React.FC<ChoosePlayProps> = ({ close }) => {
  const dispatch = useDispatch();

  const { part } = useSelector((state: AppStore) => state.parts);

  const { plays, loadPlays, isLoadingPlays } = usePlays();

  const { register, handleSubmit } = useForm({
    defaultValues: { ...defaultValuesAdapter(part) }
  });
  const onSubmit = async (data: any) => {
    const intPlayId = parseInt(data.play_id);
    dispatch(
      createPart({
        ...part,
        play_id: intPlayId,
        play: {
          name: plays?.find((play) => play.id === intPlayId)?.name
        }
      })
    );
    close();
  };

  const closeModal = (e: React.FormEvent) => {
    e.preventDefault();
    close();
  };

  useEffect(() => {
    loadPlays();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isLoadingPlays ? (
        <div className='p-4 w-5/6'>
          <TitleInput className=''>{t('generics.move')}</TitleInput>
          <Select
            className='p-4 w-full mt-4'
            options={plays}
            optionmap={(value) => value.name}
            optionValueMap={(value) => value.id}
            optionKeyMap={(value) => `${value.id}-${value.name}`}
            register={() => register('play_id', { required: true })}
          />
        </div>
      ) : null}
      <div className=' flex justify-end'>
        <PrimaryButton type='submit'>{t('generics.save')}</PrimaryButton>
        <SecondaryButton className='px-10' onClick={closeModal}>
          {t('generics.cancel')}
        </SecondaryButton>
      </div>
    </form>
  );
};
