import { AppRouter } from "./router/AppRouter"

import { Toaster } from 'react-hot-toast';
import { Modal } from "./components/Modals/Modal";
import { useModal } from "./hooks/useModal";

function App() {

  console.log('render')

  const { modal, startShowModal } = useModal()

  return (
    <>
      <AppRouter />
      <Toaster position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 3000,
          // Default options for specific types
        }} />
      {modal?.title ?
        <Modal name={modal.name} size={modal.size} close={() => (startShowModal(undefined))} data={modal.data} title={modal.title} />
        : <></>}
    </>
  )

}

export default App
