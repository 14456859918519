import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useEffect } from 'react';

import { AppStore } from '@/redux';

import { PrimaryButton } from '@/components';
import { InputText } from '@/components/Inputs/InputText';
import { useProfile } from '../hooks/useProfile';
import { TitleInput } from '@/components/Inputs/TitleInput';
import toast from 'react-hot-toast';
import { TertiaryButton } from '@/components/Buttons/TertiaryButton';
import { t } from 'i18next';

export const PerfilApp = () => {
   const navigate = useNavigate();

   const { updateProfile, setImage, image, isLoading, loadImageWithValidations } = useProfile();

   const { user } = useSelector((state: AppStore) => state.auth);

   const { register, handleSubmit } = useForm({
      defaultValues: { email: user.email, name: user.name }
   });

   const handleSaveInfo = async (data: any) => {
      if (typeof image?.image_path === 'string') {
         updateProfile({ ...data });
      } else {
         toast.error(t('errorMessage.profilePicture'));
      }
   };

   const handleChooseLogo = (e: any) => {
      e.preventDefault();
      const file = e.target.files?.[0] || undefined;
      if (file) {
         loadImageWithValidations(file);
      }
   };

   const handleChangePassword = (e: React.FormEvent) => {
      e.preventDefault();
      navigate('/perfil/contrasena');
   };

   useEffect(() => {
      setImage({
         image_id: user.avatar_id,
         image_path: user.avatar_path ?? ''
      });
   }, [user]);

   return (
      <article className='bg-functional-el-m p-5 h-full'>
         {!isLoading ? (
            <div className='m-11 p-8 bg-functional-el-l rounded-3xl shadow-lg'>
               <form onSubmit={handleSubmit(handleSaveInfo)}>
                  <div className='flex justify-between items-center'>
                     <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.profile.title')}</p>
                     {/* <PrimaryButton type='button' onClick={() => isEditing ? setIsEditing(false) : setIsEditing(true)} >{isEditing ? 'Editar' : 'Ver información'}</PrimaryButton> */}
                  </div>
                  <div className='grid grid-rows-2 grid-cols-2'>
                     <div className='row-span-2 p-4'>
                        <TitleInput>{t('pages.profile.userPicture')}</TitleInput>
                        <div className='flex flex-col items-center justify-center w-5/6'>
                           <div className={cn('w-full my-2 h-[150px] row-span-2')}>
                              {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                              <img src={image?.image_path ?? '/imgLayout.png'} alt='' className='row-span-2 w-full h-full object-contain' />
                           </div>
                           {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
                           <input
                              type='file'
                              id='actual-btn'
                              accept='image/jpg, image/jpeg, image/png'
                              onChange={handleChooseLogo}
                              hidden
                           />
                           <label
                              htmlFor='actual-btn'
                              className={cn(
                                 'disabled:cursor-no-drop cursor-pointer self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14'
                              )}>
                              {/* <PlusIcon className="h-5 w-5 bg-functional-el-l text-functional-l-d mr-3.5 rounded-sm" /> */}
                              <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5'>add</span>
                              {t('generics.upload')} (512x512px en jpg o png)
                           </label>
                        </div>
                     </div>
                     <div className='p-4'>
                        {/* <InputText disabled={!isEditing} placeholder='Nombre' /> */}
                        <InputText
                           className='w-5/6'
                           type='text'
                           placeholder={t('generics.name')}
                           register={() => register('name', { required: true, minLength: 3 })}
                        />
                     </div>
                     <div className='p-4'>
                        <InputText
                           className='w-5/6'
                           type='email'
                           placeholder={t('generics.mail')}
                           register={() => register('email', { required: true, minLength: 3 })}
                        />
                     </div>
                     {/* <div className="p-4">
                            <InputText disabled={!isEditing} >Correo electronico</InputText>
                        </div> */}
                  </div>
                  <div className='flex justify-between mt-5'>
                     <TertiaryButton onClick={handleChangePassword}>{t('generics.changePassword')}</TertiaryButton>
                     <div className='flex'>
                        <PrimaryButton type='submit'>{t('generics.saveChanges')}</PrimaryButton>
                        {/* <SecondaryButton >Cancelar</SecondaryButton> */}
                     </div>
                  </div>
               </form>
            </div>
         ) : null}
      </article>
   );
};
