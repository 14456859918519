import { MagnifyingGlass } from 'react-loader-spinner'
import '../styles/Loader.css'

export const Loader = () => {
  return (
    <>
      <div className='search-loader m-auto h-fit w-fit' id="loader">
        <MagnifyingGlass
          visible={true}
          height='80'
          width='80'
          ariaLabel='magnifying-glass-loading'
          wrapperClass='magnifying-glass-wrapper'
          glassColor='#c0efff'
          color='#000000'
        />
      </div>
    </>
  )
}
