import { useState } from 'react'
import cn from "classnames"
import { TitleInput } from "./TitleInput"



export const InputText = ({ register, ...props }: React.ComponentProps<"input"> & { register?: any }) => {
    return <>
        <TitleInput>{props.placeholder}</TitleInput>
        <input disabled={props.disabled ?? false} type="text" {...props} className={cn('h-12 bg-[#EAEAEA] rounded-lg p-1 input pl-3', props.className)} {...register ? register() : {}} />
    </>
}

export const InputDate = ({ register, ...props }: React.ComponentProps<"input"> & { register?: any }) => {
    return <>
        <input disabled={props.disabled ?? false} type="date" {...props} className={cn('h-12 bg-[#EAEAEA] rounded-lg p-1 input pl-3', props.className)} {...register ? register() : {}} />
    </>
}

export const InputLabel: React.FC<React.ComponentProps<"input"> & { register?: any }> = ({ register, ...props }) => {
    const isPassword = props.type === 'password'
    const [showPassword, setShowPassword] = useState(false)

    return <label>
        <p className='mb-1 mt-4 font-normal text-lg text-[#383838]'>{props.placeholder}</p>
        <div className={isPassword ? "relative" : ''}>
            <input
                {...props}
                {...register}
                className='h-12 w-full bg-[#DEDFE3] rounded-lg p-3'
                type={showPassword ? 'text' : props.type}
            />
            {isPassword ? <span onClick={() => setShowPassword(show => !show)} className="material-symbols-outlined absolute top-3 right-0 cursor-pointer mr-2">
                {showPassword ? 'visibility_off' : 'visibility'}
            </span> : null}
        </div>
    </label>
}
