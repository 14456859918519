import { Pagination } from '@/models/pagination.models';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    isLoading: boolean,
    modules: any,
    pagination: Pagination | {},
    errorMessage: string | null,
    per_page: number,
    moduleSelected: string |null
}

const initialState: InitialStateProps = {
    isLoading: false,
    modules: undefined,
    pagination: {},
    errorMessage: null,
    per_page: 8,
    moduleSelected: null
}

export const modulesSlice = createSlice({
    name: 'modulos',
    initialState,
    reducers: {
        loadModules: (state, action) => {
            state.modules = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        setIsLoading: (state) => {
            state.isLoading = true
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setSelectModule: (state, action) => {
            state.moduleSelected = action.payload
        },
        setResetState: () => initialState
    }
});


// Action creators are generated for each case reducer function
export const { setResetState ,setIsLoading, loadModules, setError, setSelectModule } = modulesSlice.actions;

