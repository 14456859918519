import axios, { AxiosResponse } from 'axios';

export interface IHttpCallResponse<T> {
   data: T;
   status: number;
   successful: boolean;
}

class HttpCall {
   // configuration
   private defaultHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' };

   constructor() {
      this.initInterceptor();
   }

   private initInterceptor(): void {
      // add a request interceptor
      axios.interceptors.request.use(
         (config) => {
            // configure new headers
            const tokenLS: string | null = localStorage.getItem('token');
            const tokenSS: string | null = sessionStorage.getItem('token');
            const publicKey: string | undefined = import.meta.env.VITE_PUBLIC_KEY;

            if (publicKey) {
               config.headers['Public-key'] = publicKey;
            }

            if (tokenLS) {
               const token: string = JSON.parse(tokenLS);
               config.headers['Authorization'] = `Bearer ${token}`;
            }

            if (tokenSS) {
               const token: string = JSON.parse(tokenSS);
               config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
         },
         (error) => {
            // handle response errors here
            return Promise.reject(error);
         }
      );

      // add a response interceptor
      axios.interceptors.response.use(
         (response) => {
            // process the answer here
            return response;
         },
         (error) => {
            // handle response errors here
            const currentRoute: string = window.location.pathname.split('/')[2];

            if (error.response.status === 401 && currentRoute !== 'login') {
               window.location.reload();
            }

            return Promise.reject(error);
         }
      );
   }

   public async get<T>(url: string, customHeaders?: Record<string, string>): Promise<IHttpCallResponse<T>> {
      try {
         const config = { headers: { ...this.defaultHeaders, ...customHeaders } };
         const response: AxiosResponse<T> = await axios.get(url, config);

         return { data: response.data, status: response.status, successful: true };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, successful: false };
         }

         return { successful: false } as IHttpCallResponse<T>;
      }
   }

   public async post<T>(url: string, body?: Record<any, any>, customHeaders?: Record<string, string>): Promise<IHttpCallResponse<T>> {
      try {
         const config = { headers: { ...this.defaultHeaders, ...customHeaders } };
         const response: AxiosResponse<T> = await axios.post(url, body, config);

         return { data: response.data, status: response.status, successful: true };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, successful: false };
         }

         return { successful: false } as IHttpCallResponse<T>;
      }
   }

   public async put<T>(url: string, body?: Record<any, any>, customHeaders?: Record<string, string>): Promise<IHttpCallResponse<T>> {
      try {
         const config = { headers: { ...this.defaultHeaders, ...customHeaders } };
         const response: AxiosResponse<T> = await axios.put(url, body, config);

         return { data: response.data, status: response.status, successful: true };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, successful: false };
         }

         return { successful: false } as IHttpCallResponse<T>;
      }
   }

   public async patch<T>(url: string, body?: Record<any, any>, customHeaders?: Record<string, string>): Promise<IHttpCallResponse<T>> {
      try {
         const config = { headers: { ...this.defaultHeaders, ...customHeaders } };
         const response: AxiosResponse<T> = await axios.patch(url, body, config);

         return { data: response.data, status: response.status, successful: true };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, successful: false };
         }

         return { successful: false } as IHttpCallResponse<T>;
      }
   }

   public async delete<T>(url: string, customHeaders?: Record<string, string>): Promise<IHttpCallResponse<T>> {
      try {
         const config = { headers: { ...this.defaultHeaders, ...customHeaders } };
         const response: AxiosResponse<T> = await axios.delete(url, config);

         return { data: response.data, status: response.status, successful: true };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, successful: false };
         }

         return { successful: false } as IHttpCallResponse<T>;
      }
   }
}

export default new HttpCall();
