import { useMemo } from 'react';
import { Pagination } from '@/models/pagination.models';
import { Select } from '..';
import { t } from 'i18next';

interface PaginationProps {
  changePage: (e: number) => void;
  pagination: Pagination;
  initialPage: () => void;
  previousPage: () => void;
  nextPage: () => void;
  finalPage: () => void;
}

export const PaginationView: React.FC<PaginationProps> = ({
  pagination,
  changePage,
  initialPage,
  previousPage,
  nextPage,
  finalPage
}) => {
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    changePage(parseInt(e.target.value));
  };
  const { from, to, total, last_page, current_page } = pagination;
  const options = useMemo(() => Array.from({ length: last_page }, (_, i) => i + 1), [last_page]);
  return (
    <div className='h-12 flex px-10 items-center sticky bottom-0 bg-functional-el-l'>
      <p>{t('others.rowsPages')}</p>
      <Select
        register={() => ({ onChange: handleSelect, value: current_page })}
        className='bg-white rounded-lg px-1 mx-3 h-7 text-sm border border-black'
        options={options}
        optionmap={(x) => x}
      />
      <p className='mx-4 text-[#1E293B]'>{`${from}-${to} de ${total}`}</p>
      <span onClick={initialPage} className='material-symbols-outlined cursor-pointer text-3xl'>
        first_page
      </span>
      <span onClick={previousPage} className='material-symbols-outlined cursor-pointer text-3xl'>
        keyboard_arrow_left
      </span>
      <span onClick={nextPage} className='material-symbols-outlined cursor-pointer text-3xl'>
        keyboard_arrow_right
      </span>
      <span onClick={finalPage} className='material-symbols-outlined cursor-pointer text-3xl'>
        last_page
      </span>
    </div>
  );
};
