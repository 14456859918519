import { useForm, SubmitHandler } from 'react-hook-form';
import { useState } from 'react';

import { PrimaryButton } from '@/components';
import { InputLabel } from '@/components/Inputs/InputText';

import { InputAttrs, LoginProp, View } from '../models/login.models';
import { boxFormStyle, pStyle } from '../styles/login.styled';

const inputAttrMap: InputAttrs[] = [
    { type: 'email', name: 'email', key: 1, placeholder: 'Correo electrónico: *', message: 'El correo es requerido' },
]

const inputAttrMapPassword: InputAttrs[] = [
    { type: 'password', name: 'token', key: 2, placeholder: 'Token enviado al correo: *', message: 'El token es requerido' },
    { type: 'password', name: 'password', key: 3, placeholder: 'Contraseña nueva: *', message: 'La contraseña es requerida' },
]

interface ForgotPasswordProps {
    setView: React.Dispatch<React.SetStateAction<View>>
    resetPassword: ({ email, token, password }: { email: string, token?: string, password: string }) => Promise<{
        message: string;
    } | undefined>
    requestPassword: (email: string) => Promise<{
        message: string;
    } | undefined>
}


const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setView, requestPassword, resetPassword }) => {

    const [isRequested, setIsRequested] = useState(false)

    const { register, handleSubmit, formState: { errors }, } = useForm<LoginProp>()

    const onSubmit: SubmitHandler<LoginProp> = (data) => {
        if (!isRequested) {
            requestPassword(data.email)
            setIsRequested(true)
        } else {
            resetPassword({ email: data.email, password: data.password, token: data.token }).then((data) => {
                if (data?.message === 'success') {
                    setView('login')
                }
            })
        }
    }

    const handleLogin = () => {
        setView('login')
    }

    return <div className={boxFormStyle}>
        <div className='w-8/12'>
            <p className={pStyle}>¿Olvidaste tu contraseña?</p>
            <img src="/lineas/simon_plataforma-13.svg" width={'50%'} />
            <p className='text-functional-l-d w-3/4 my-1'>Llena la siguiente forma para actulizar su contraseña</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                {inputAttrMap.map(({ name, key, placeholder, message, type }) => {
                    return <div key={key}>
                        <InputLabel placeholder={placeholder} name={name} type={type} register={{ ...register(name, { required: { value: true, message } }) }} />
                        <p className={errors?.[name]?.message ? '' : 'hidden' + ' text-[#ffdcec]'} >{errors?.[name]?.message}</p>
                    </div>
                }
                )}
                {isRequested ? inputAttrMapPassword.map(({ name, key, placeholder, message, type }) => {
                    return <div key={key}>
                        <InputLabel placeholder={placeholder} name={name} type={type} register={{ ...register(name, { required: { value: true, message } }) }} />
                        <p className={errors?.[name]?.message ? '' : 'hidden' + ' text-[#ffdcec]'} >{errors?.[name]?.message}</p>
                    </div>
                }
                ) : null}
                <PrimaryButton type="submit" className='cursor-pointer bg-[#F9D53E] my-3 w-full rounded-xl h-12'>{isRequested ? 'Recuperar contraseña' : 'Enviar solicitud'}</PrimaryButton>
                <p className='text-functional-d-d'>Regresar al <b onClick={handleLogin} className='cursor-pointer text-[#F9D53E]'>Ingreso de usuario</b></p>
            </form>
        </div>
    </div>
};

export default ForgotPassword;
