import { AppStore } from "@/redux"
import { useDispatch, useSelector } from "react-redux"

import { paginationAdapter } from "@/adapters/pagination.adapter"
import toast from "react-hot-toast"
import { loadPartsAdapter } from "../adapters/loadParts.adapter"
import { loadParts, resetAll, resetPart, setDeletePart, setIsLoading, setIsLoadingSavePart, setSelectPart, setStatus } from "@/redux/states/lessons/parts.slice"
import { Part } from "../models/parts.model"
import { useState } from 'react'
import useAxiosPrivate from "@/hooks/useApiV2Private"


let controller:AbortController , signal
export const useLoadParts = (companySlug:string, moduleSelected:string,lessonSelected:string) => {

    const apiV2 = useAxiosPrivate()

    const dispatch = useDispatch()

    const [lessonActive, setLessonActive] = useState(true)

    const { isLoading, parts, status, part, isLoadingSavePart } = useSelector((state: AppStore) => state.parts)

    const startLoadParts = async () => {
        dispatch(setIsLoading(true))
        try {
            if (controller) {
                controller.abort()
              }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/modules/${moduleSelected}/lessons/${lessonSelected}/parts`, { 
                params: { per_page:-1 }, 
                signal
            })

            dispatch(loadParts({ pagination: paginationAdapter(resp.data.pagination), items: loadPartsAdapter(resp.data.items) }))
            toast.success('Pasos cargados')
        } catch (error) {
            dispatch(setIsLoading(false))
            // toast.error('Existe un error en el servidor')
        }
    }

    const changeStatus = (status:string) => {
        dispatch(setStatus(status))
    }

    const selectPart = (part:Part) => {
        dispatch(setSelectPart(part))
    }

    const startDeletePart = async (slug:string) => {
        try {
            await apiV2.delete(`/parts/${slug}`)
            dispatch(setDeletePart(slug))
            toast.success('Paso eliminado')
        } catch (error) {
        }
    }

    const saveNewPart = async () =>{
        dispatch(setIsLoadingSavePart(true))
        try {
            await apiV2.post(`/parts`, {
                ...part,
                quiz: part.isQuiz ? part.quiz : null
            })
            dispatch(resetPart())
            toast.success('Paso creada')
        } catch (error) {
            dispatch(setIsLoadingSavePart(false))
            toast.error('Existe un error en el servidor')
        }
    }

    //TODO: Integrar validador para poder activar la lección
    const startToggleLesson = async (isActive:boolean) => {
        try {
            const respValidate = await apiV2.get(`/companies/${companySlug}/modules/${moduleSelected}/lessons/${lessonSelected}/validate`)
            const resp = await apiV2.post(`/companies/${companySlug}/modules/${moduleSelected}/lessons/${lessonSelected}/status_toggle`,{
                is_active:isActive
            })
            console.log({respValidate})
            setLessonActive(resp.data.is_active)
        } catch (error) {
            
        }
    }

    const startResetAll = () => {
        dispatch(resetAll())
    }

    return {
        lessonActive, setLessonActive,
        startToggleLesson,
        isLoadingSavePart,
        isLoading, startLoadParts, parts, changeStatus, status, part, saveNewPart, startResetAll, selectPart, startDeletePart
    }
}
