import { usePagination } from "@/hooks/usePagination"
import { AppStore } from "@/redux"
import { useDispatch, useSelector } from "react-redux"
import { useState } from 'react'

import { paginationAdapter } from "@/adapters/pagination.adapter"
import toast from "react-hot-toast"
import { loadLessonsAdapter } from "../adapters/loadLessons.adapter"
import { loadLessons, setIsLoading, setSelectModule, setError, setInitialState } from "@/redux/states/lessons/lessons.slice"
import { useModulesList } from "@/hooks/useModules"
import useAxiosPrivate from "@/hooks/useApiV2Private"


let controller:AbortController , signal, oldValue = ''
export const useLessons = (companySlug:string) => {

    const apiV2 = useAxiosPrivate()

    const dispatch = useDispatch()

    const [value, setValue] = useState('')

    const { isLoading, lessons, pagination, per_page, moduleSelected } = useSelector((state: AppStore) => state.lessons)

    const { nextPage, previousPage, initialPage, finalPage, changePage, currentPage } = usePagination({ lastPage: pagination.last_page })

    const {
        modules,
        isLoadingModules,
        startLoadModules
    } = useModulesList(companySlug)

    const startLoadLessons = async () => {
        const isAllLessons =  !moduleSelected || moduleSelected === 'todos'
        dispatch(setIsLoading(true))
        try {
            if (controller) {
                controller.abort()
              }
            controller = new AbortController()
            signal = controller.signal
            const resp = await (isAllLessons ?  
            apiV2.get(`/companies/${companySlug}/lessons`, { 
                params: { per_page, page: oldValue === value ? currentPage : 1, search: value }, 
                signal
            }) :
             apiV2.get(`/companies/${companySlug}/modules/${moduleSelected}/lessons`, { 
                params: { per_page, page: oldValue === value ? currentPage : 1, search: value }, 
                signal
            }))
            oldValue = value
            dispatch(loadLessons({ pagination: paginationAdapter(resp.data.pagination), items: loadLessonsAdapter(resp.data.items) }))
            toast.success('Lecciones cargadas')
        } catch (error:any) {
            dispatch(setIsLoading(false))
        }
    }

    const selectModule = (slug:string) => {
        dispatch(setSelectModule(slug))
    } 

    const startInitialState = () => {
        dispatch(setInitialState())
    }

    return {
        companySlug, 
        nextPage, previousPage, initialPage, finalPage, changePage, selectModule,currentPage,
        isLoading, moduleSelected, pagination, per_page, setError, lessons, modules, isLoadingModules,
        // Busqueda de modulos
        value, setValue,
        startLoadModules,
        startLoadLessons,
        startInitialState
    }
}
