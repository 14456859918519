import { useEffect } from 'react'
import { AppStore } from "@/redux"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux"

import { loadCompany, setIsLoading, setStatus, resetCompany, setCountries, setIsLoadingCountries, setCampaniesChanged, setCrudDisabled } from '@/redux/states/empresas/crudEmpresa.slice';
import { useLoadImage } from "@/hooks/useLoadImage";
import { loadCompanyAdapter } from '../adapters/loadCompany.adapter';
import loadCountriesAdapter from "../adapters/loadCountries.adapter";
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '@/hooks/useApiV2Private';


export const useCrudCompany = (companyId: string | null) => {

    const apiV2 = useAxiosPrivate()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { company, status, usuarios, isLoading, preferences, paises, isLoadingCountries, crudDisabled } = useSelector((state: AppStore) => state.crudEmpresa)

    const { image, setImage, uploadImage, loadImageWithValidations } = useLoadImage()

    const startLoadCompany = async (slug: string | number) => {
        dispatch(setIsLoading(true))
        try {
            const resp = await apiV2.get(`/companies/${slug}`)
            dispatch(loadCompany(loadCompanyAdapter(resp.data)))
            setImage({
                image_id: resp.data.image_id,
                image_path: resp.data.image_path
            })
        } catch (error) {
            toast.success('Información de la empresa no ha sido cargada')
        }
    }

    const updateCompany = async (data: any) => {
        dispatch(setCrudDisabled(true))
        delete data.image
        let respImage: number | undefined
        try {
            if (image?.file) {
                await uploadImage(image.file).then((e) => { respImage = e })
            }
            await apiV2.patch(`/companies/${company.slug}`, {
                    address: data.address,
                    is_active: data.isActive,
                    image_id: respImage ?? image?.image_id,
                    name: data.name,
                    description: data.description,
                    vimeo_folder_id: data.vimeo_folder_id,
                    configuration: company.configuration,
                    default_country_id: +data.default_country_id,
                    country_ids: data.optionsCountries.map(({id}:({id:number}) ) => id)
                })
            toast.success('La empresa ha sido actualizada')
            navigate('/empresas')
            dispatch(setCampaniesChanged())
        } catch (error) {
            toast.error('No se actualizo la empresa')
        } finally {
            dispatch(setCrudDisabled(false))
        }
    }
    

    const createCompany = async (data: any) => {
        dispatch(setCrudDisabled(true))
        delete data.image
        let respImage: number | undefined | void
        try {
            if (image?.file) await uploadImage(image.file).then((e) => { respImage = e })
            const resp = await apiV2.post(`/companies`, {
                address: data.address,
                is_active: data.isActive,
                image_id: respImage ?? image?.image_id,
                name: data.name,
                description: data.description,
                vimeo_folder_id: data.vimeo_folder_id,
                default_country_id: +data.default_country_id,
                country_ids: data.optionsCountries.map(({id}:({id:number}) ) => id)
            })
            toast.success('Empresa creada')
            navigate("/empresas/personalizar/", { state: { companyId : resp.data.slug } } )
            dispatch(setCampaniesChanged())
        } catch (error) {
            toast.error('No se creo la nueva empresa')
        } finally {
            dispatch(setCrudDisabled(false))
        }
    }

    const editing = () => {
        dispatch(setStatus('editing'))
    }

    const viewing = () => {
        dispatch(setStatus('viewing'))
    }

    // per_page es de 500 para traer todos los paises
    const loadCountries = async () => {
        dispatch(setIsLoadingCountries(true))
        try {
            const resp = await apiV2.get('/app/countries', { params: { per_page: -1 } })
            dispatch(setCountries(loadCountriesAdapter(resp.data)))
        } catch (error) {
            dispatch(setIsLoadingCountries(false))
            toast.error('Los paises no han sido cargados')
        }
    }

    useEffect(() => {
        loadCountries()
        if (companyId) {
            startLoadCompany(companyId)
        } else {
            dispatch(setStatus('creating'))
        }
        return () => {
            dispatch(resetCompany())
        }
    }, [companyId])



    return {
        // propiedades
        company,
        status,
        usuarios,
        image,
        isLoading,
        preferences,
        paises,
        isLoadingCountries,
        crudDisabled,
        // Metodos,
        uploadImage,
        editing,
        viewing,
        setImage,
        updateCompany,
        createCompany,
        loadImageWithValidations
        // loadUsers
    }

}