interface SelectProps {
  label: string;
  value: string;
}

export const PARTS_TYPES_OPTIONS: SelectProps[] = [
  {
    label: 'Video',
    value: 'video'
  }, {
    label: 'Encuesta',
    value: 'survey'
  }]

export const PARTS_TYPES = {
  VIDEO: 'video',
  ENCUESTA: 'survey',
  DEFAULT: 'default'
}

export const QUESTION_TYPES_OPTIONS: SelectProps[] = [
  {
    label: 'Selección múltiple',
    value: 'multiple_choice'
  },
  {
    label: 'Selección única',
    value: 'single_choice'
  },
  {
    label: 'Abierta',
    value: 'open'
  }]

export const QUESTION_TYPES = {
  MULTIPLE: 'multiple_choice',
  UNIQUE: 'single_choice',
  OPEN: 'open'
}