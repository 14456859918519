import { configAdapter } from '@/adapters/config.adapter';
import { loadConf } from '@/redux/states/UI/ui.slice';
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { config } from '@/data/config';
import { Config } from '@/models/modeloConfig';
import useAxiosPrivate from './useApiV2Private';



export const useLoadConf = () => {

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | Error | string>()
  const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'empty'>('idle')
  const [isLocalStorageEmpty, setIsLocalStorageEmpty] = useState<boolean>(false)

  const apiV2 = useAxiosPrivate()

  const dispatch = useDispatch();

  //LLamar a la Api y cargar datos en el localStorage
  const downloadConf = async (companySlug: string, abortController: AbortController) => {
    try {
      const { data } = await apiV2(`/companies/${companySlug}/configuration/`, { signal: abortController.signal })
      dispatch(loadConf(configAdapter(data)))
      const now = new Date()
      const serialisedState = JSON.stringify({ expiry: now.getTime() + 21600000, data });
      localStorage.setItem('conf', serialisedState)
      const styleElement: HTMLStyleElement = document.createElement('style');
      styleElement.innerHTML = generateStyles(data?.style);
      document.head.appendChild(styleElement);
      setStatus('success')
      setLoading(false)
      setIsLocalStorageEmpty(true)
    } catch (error) {
      setLoading(false)
      setError(error as Error)
      setStatus('error')
    }
  }

  const downloadConfDefault = async (config:Config) => {
    try {
      dispatch(loadConf(configAdapter(config)))
      const now = new Date()
      const serialisedState = JSON.stringify({ expiry: now.getTime() + 21600000, data:config });
      localStorage.setItem('conf', serialisedState)
      const styleElement: HTMLStyleElement = document.createElement('style');
      styleElement.innerHTML = generateStyles(config?.style);
      document.head.appendChild(styleElement);
      setStatus('success')
      setLoading(false)
      setIsLocalStorageEmpty(true)
    } catch (error) {
      setLoading(false)
      setError(error as Error)
      setStatus('error')
    }
  }

  const generateStyles = (configApp: any): string => {
    const { colorPalette: { primary, secondary, functional, system } } = configApp;
    return `
          :root {
            --primary-light-l: ${primary?.light?.light};
            --primary-light-d: ${primary?.light?.dark};
            --primary-light-m: ${primary?.light?.medium};
            --primary-dark-l: ${primary?.dark?.light};
            --primary-dark-d: ${primary?.dark?.dark};
            --primary-dark-m: ${primary?.dark?.medium};
            --secondary-light-l: ${secondary?.light?.light};
            --secondary-light-d: ${secondary?.light?.dark};
            --secondary-light-m: ${secondary?.light?.medium};
            --functional-light-l: ${functional?.light?.light};
            --functional-light-m: ${functional?.light?.medium};
            --functional-light-d: ${functional?.light?.dark};
            --functional-dark-l: ${functional?.dark?.light};
            --functional-dark-m: ${functional?.dark?.medium};
            --functional-dark-d: ${functional?.dark?.dark};
            --functional-extraLight-l: ${functional?.extraLight?.light};
            --functional-extraLight-m: ${functional?.extraLight?.medium};
            --error-l:${system?.error?.light};
            --error-m:${system?.error?.medium};
            --error-d:${system?.error?.dark};
            --information-l:${system?.information?.light};
            --information-m:${system?.information?.medium};
            --information-d:${system?.information?.dark};
            --success-l:${system?.success?.light};
            --success-m:${system?.success?.medium};
            --success-d:${system?.success?.dark};
            --warning-l:${system?.warning?.light};
            --warning-m:${system?.warning?.medium};
            --warning-d:${system?.warning?.dark};
          }
        `;
  };

  const configCompany = (companySlug: string | undefined) => {
    setLoading(true)
    if (companySlug) {
      const conf = localStorage.getItem('conf')
      let abortController = new AbortController();
      if (conf !== null) {
        const { data, expiry }: any = JSON.parse(conf);
        const now = new Date()
        if (now.getTime() < expiry) {
          const styleElement: HTMLStyleElement = document.createElement('style');
          styleElement.innerHTML = generateStyles(data?.style);
          document.head.appendChild(styleElement);
          dispatch(loadConf(configAdapter(data)))
          setError(null)
          setLoading(false)
          setStatus('success')
        }
        else {
          localStorage.removeItem('conf')
          downloadConf(companySlug, abortController)
        }
      }
      else {
        downloadConf(companySlug, abortController)
      }
    } else {
      downloadConfDefault(config)
    }
  
  }

  return { isLoading, status, error, isLocalStorageEmpty, configCompany }
}
