import { SetImageProps, loadImageWithValidationsProps } from '@/hooks/useLoadImage';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { termsConditionsRewardText } from '../data/terminosYCondiciones';
import { setSelectArea } from '@/redux/states/rewards/rewards.slice';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { PrimaryButton, SecondaryButton } from '@/components';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { InputText } from '@/components/Inputs/InputText';
import { Spinner } from '@/components/Spinners/Spinner';
import { TextArea } from '@/components/Inputs/TextArea';
import { useCrudReward } from '../hooks/useCrudReward';
import { Reward } from '../models/rewards.models';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import cn from 'classnames';
import { t } from 'i18next';

interface PremioFormProp {
   image: any;
   reward: any;
   status: string;
   updateReward: (data: any) => Promise<{ message: string } | undefined>;
   editing: () => void;
   viewing: () => void;
   createReward: (data: any) => Promise<{ message: string } | undefined>;
   setImage: React.Dispatch<React.SetStateAction<SetImageProps | undefined>>;
   areaId?: string;
   loadImageWithValidations: loadImageWithValidationsProps;
}

const defaultValuesAdapter = (reward: Reward): any => {
   return {
      name: reward.name,
      isActive: reward.isActive,
      points: reward.points,
      deliveryPolicy: reward.delivery_policy,
      termsConditions: reward.terms_conditions,
      available: reward.available,
      exchanged: reward.exchanged,
      unitCost: reward.unit_cost
   };
};

const PremioForm: React.FC<PremioFormProp> = ({
   loadImageWithValidations,
   areaId,
   image,
   reward,
   status,
   editing,
   createReward,
   updateReward
}) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const infoStatus: any = {
      viewing: {
         title: t('pages.awards.awardInfo'),
         message: ''
      },
      editing: {
         title: t('pages.awards.awardModify'),
         message: t('pages.awards.modifyMessage')
      },
      creating: {
         title: t('pages.awards.addAward'),
         message: t('pages.awards.addMessage')
      }
   };

   const isCreating = status === 'creating';
   const {
      register,
      handleSubmit,
      formState: { errors }
   } = useForm<RewardProps>({
      defaultValues: {
         ...defaultValuesAdapter({
            ...reward,
            terms_conditions: isCreating ? termsConditionsRewardText : reward.terms_conditions
         })
      }
   });

   const disabled = false;

   const onSubmit = async (data: any) => {
      if (image?.image_path) {
         if (typeof image.image_path === 'string') {
            if (status === 'editing') {
               updateReward({ data }).then((e) => {
                  if (e?.message === 'success') {
                     dispatch(setSelectArea(areaId));
                     navigate('/premios');
                  }
               });
            } else if (isCreating && image?.image_path) {
               createReward({ ...data }).then((e) => {
                  if (e?.message === 'success') {
                     dispatch(setSelectArea(areaId));
                     navigate('/premios');
                  }
               });
            } else {
               toast.error(t('pages.awards.errorMessage'));
            }
         }
      } else {
         toast.error(t('errorMessage.selectPicture'));
      }
   };

   const handleChooseLogo = (e: any) => {
      e.preventDefault();
      const file = e.target.files?.[0] || undefined;
      if (file) {
         loadImageWithValidations(file);
      }
   };

   useEffect(() => {
      editing();
   }, []);

   const handleCancel = (event: React.FormEvent) => {
      event.preventDefault();

      dispatch(setSelectArea(areaId));
      navigate('/premios');
   };

   return (
      <>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className='bg-functional-el-l flex items-center justify-start px-10 py-7'>
               <Link to='/premios' onClick={() => dispatch(setSelectArea(areaId))}>
                  <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
               </Link>
               <div>
                  <p className={`font-bold text-2xl text-functional-d-l`}>{infoStatus[status].title}</p>
                  <p className={`font-medium text-functional-d-l`}>{infoStatus[status].message}</p>
               </div>
            </div>
            <div className='m-11 p-10 bg-functional-el-l rounded-3xl shadow-lg'>
               <div className='flex justify-between'>
                  <div>
                     <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.companies.generalData')}</p>
                     <p className='font-medium text-functional-d-l'>{t('pages.awards.addData')}</p>
                  </div>
               </div>
               <div className={cn('grid grid-cols-2 grid-rows-5')}>
                  <div className='p-4'>
                     <InputText
                        className='w-5/6'
                        register={() => register('name', { required: t('errorMessge.requiredField'), minLength: 3 })}
                        placeholder={`${t('generics.name')}*`}
                     />
                     {errors.name ? <p className='text-warning-d'>{errors.name.message}</p> : null}
                  </div>
                  <div className='p-4 flex w-5/6 items-center justify-between'>
                     <div className='w-[49%]'>
                        <InputText
                           className='w-full'
                           type='number'
                           register={() => register('points', { required: t('errorMessge.requiredField'), valueAsNumber: true })}
                           placeholder={`${t('generics.points')}*`}
                        />
                        {errors.points ? <p className='text-warning-d'>{errors.points.message}</p> : null}
                     </div>
                     <div className='w-[49%]'>
                        <InputText
                           className='w-full'
                           type='number'
                           register={() => register('available', { required: t('errorMessge.requiredField'), valueAsNumber: true })}
                           placeholder={`${t('pages.awards.awardsAvailable')}*`}
                        />
                        {errors.available ? <p className='text-warning-d'>{errors.available.message}</p> : null}
                     </div>
                  </div>
                  <div className='p-4 flex w-5/6 justify-between'>
                     <div className='w-[49%]'>
                        <InputText
                           className='w-full'
                           type='number'
                           register={() => register('unitCost', { required: t('errorMessge.requiredField'), valueAsNumber: true })}
                           placeholder={`${t('pages.awards.unitCost')}*`}
                        />
                        {errors.unitCost ? <p className='text-warning-d'>{errors.unitCost.message}</p> : null}
                     </div>
                     <div className='w-[49%]'>
                        <InputText
                           className='w-full'
                           type='number'
                           register={() => register('exchanged', { valueAsNumber: true })}
                           placeholder={t('generics.redeemed')}
                        />
                        {errors.exchanged ? <p className='text-warning-d'>{errors.exchanged.message}</p> : null}
                     </div>
                  </div>
                  <div className='row-span-2 p-4 w-5/6'>
                     <TitleInput>{t('pages.awards.awardPicure')}*</TitleInput>
                     <div className='flex flex-col items-center justify-center'>
                        <div className={cn('w-full my-2 h-[150px] row-span-2')}>
                           {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                           <img src={image?.image_path ?? '/imgLayout.png'} alt='' className='row-span-2 w-full h-full object-contain' />
                        </div>
                        {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
                        <input type='file' id='actual-btn' accept='image/jpg, image/jpeg, image/png' onChange={handleChooseLogo} hidden />
                        <label
                           htmlFor='actual-btn'
                           className={cn(
                              'self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14',
                              { 'cursor-no-drop': disabled, 'cursor-pointer': !disabled }
                           )}>
                           {/* <PlusIcon className="h-5 w-5 bg-functional-el-l text-functional-l-d mr-3.5 rounded-sm" /> */}
                           <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5'>add</span>
                           {t('generics.upload')} (512x512px en jpg o png)
                        </label>
                     </div>
                  </div>
                  <div className='flex items-center p-4'>
                     <TitleInput>{t('generics.active')}</TitleInput>
                     <div className='mx-2'></div>
                     <ToggleSwitch message='' register={() => register('isActive')} />
                  </div>
                  <div className='p-4 row-span-2'>
                     <TextArea
                        className='w-5/6'
                        register={() => register('termsConditions', { required: t('errorMessge.requiredField') })}
                        placeholder={`${t('generics.termsConditions')}*`}
                     />
                     {errors.termsConditions ? <p className='text-warning-d'>{errors.termsConditions.message}</p> : null}
                  </div>
                  <div className='p-4 row-span-2'>
                     <TextArea
                        className='w-5/6'
                        register={() => register('deliveryPolicy', { required: t('errorMessge.requiredField') })}
                        placeholder={`${t('pages.awards.deliveryPolicy')}*`}
                     />
                     {errors.deliveryPolicy ? <p className='text-warning-d'>{errors.deliveryPolicy.message}</p> : null}
                  </div>
               </div>
               {!disabled ? (
                  <div className='flex justify-end'>
                     <PrimaryButton type='submit'>{t('pages.awards.saveAward')}</PrimaryButton>
                     <SecondaryButton className='px-8' onClick={handleCancel}>
                        {isCreating ? t('generics.goBack') : t('generics.cancel')}
                     </SecondaryButton>
                  </div>
               ) : null}
            </div>
         </form>
      </>
   );
};

export const CRUDPremios = () => {
   const { state } = useLocation();

   const { areaId } = useParams();

   const {
      image,
      reward,
      status,
      isLoading,
      // Metodos,
      editing,
      viewing,
      setImage,
      updateReward,
      createReward,
      loadImageWithValidations
   } = useCrudReward(parseInt(areaId ?? ''), state.rewardSelected);

   const isLoadingPremioForm = !isLoading;
   return (
      <article className='bg-functional-el-m'>
         {isLoadingPremioForm ? (
            <PremioForm
               {...{
                  areaId,
                  setImage,
                  image,
                  reward,
                  status,
                  isLoading,
                  editing,
                  viewing,
                  updateReward,
                  createReward,
                  loadImageWithValidations
               }}
            />
         ) : (
            <Spinner />
         )}
      </article>
   );
};
