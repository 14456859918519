import useAxiosPrivate from "@/hooks/useApiV2Private"
import { AppStore } from "@/redux"
import { useState } from "react"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Response, SurveyInfoResponse } from "../interfaces/survey.interface"


export const useSurveyInfo = () => {

  const { companySlug } = useSelector((state: AppStore) => state.ui)
  const navigate = useNavigate()
  const [isLoadingSurveyInfo, setIsLoadingSurveyInfo] = useState(false)
  const [isLoadingResponses, setIsLoadingResponses] = useState(false)
  const [surveyInfo, setSurveyInfo] = useState<SurveyInfoResponse | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [questionId, setQuestionId] = useState<number | null>(null)
  const [responses, setResponses] = useState<Response[]>([])
  const apiV2 = useAxiosPrivate()

  // Load survey info
  const loadSurveyInfo = async (areaId: number, surveySlug: string) => {
    setIsLoadingSurveyInfo(true)
    try {
      const res = await apiV2.get(`/surveys/${surveySlug}?company_slug=${companySlug}&area_id=${areaId}`)
      const data: SurveyInfoResponse = res.data
      setSurveyInfo(data)
      const questionId = data.questions[0]?.id
      setQuestionId(questionId)
      if (questionId) loadResponses(areaId, surveySlug, questionId)
    }
    catch(error) {
      console.error('Error cargando la información de la encuesta', error)
      toast.error('Error cargando la información de la encuesta')
    }
    finally {
      setIsLoadingSurveyInfo(false)
    }
  }

  // Load responses for a specific question
  const loadResponses = async (areaId: number, surveySlug: string, questionId: number, page = 1) => {
    try {
      setIsLoadingResponses(true)
      const res = await apiV2.get(`/surveys/${surveySlug}/questions/${questionId}/responses?page=${page}&company_slug=${companySlug}&area_id=${areaId}`)
      setResponses(res.data.items)
      setCurrentPage(res.data.pagination.current_page)
      setTotalPages(res.data.pagination.last_page)

    }
    catch(err) {
      console.error('Error cargando las respuestas', err)
      toast.error('Error cargando las respuestas')
    }
    finally {
      setIsLoadingResponses(false)
    }
  }

  // Delete a response for a specific question
  const deleteResponse = async (areaId: number, surveySlug: string, questionId: number, responseId: number) => {
    try {
      await apiV2.delete(`/surveys/${surveySlug}/questions/${questionId}/responses/${responseId}?company_slug=${companySlug}&area_id=${areaId}`)
      const newResponses = responses.filter((response) => response.id !== responseId)
      setResponses(newResponses)
      toast.success('Respuesta eliminada')
    }
    catch(err) {
      console.error('Error eliminando la respuesta', err)
      toast.error('Error eliminando la respuesta')
    }
  }

  // Redirect to interactions page
  const navigateToInteractions = () => {
    navigate('/interacciones')
  }

  /**
 * The nextPage function increments the current page number and loads surveys for a specific area
 * asynchronously.
 * @param {number} areaId - The `areaId` parameter is a number that is used as an identifier for a
 * specific area in the application. It is passed to the `nextPage` function to load surveys related
 * to that particular area.
 */
  const nextPage = async (areaId: number, surveySlug: string, questionId: number) => {
    if (currentPage < totalPages) {
      await loadResponses(areaId, surveySlug, questionId, currentPage + 1)
    }
  }

  /**
   * The function `firstPage` sets the current page to 1 and loads surveys based on the provided area
   * ID.
   * @param {number} areaId - The `areaId` parameter is a number that represents the unique identifier
   * of a specific area. It is used as a parameter in the `firstPage` function to load surveys related
   * to that particular area.
   */
  const firstPage = (areaId: number, surveySlug: string, questionId: number,) => {
    loadResponses(areaId, surveySlug, questionId)
  }

  /**
   * The function `previousPage` decreases the current page number by 1 if it's greater than 1 and then
   * loads surveys for a specific area.
   * @param {number} areaId - The `areaId` parameter in the `previousPage` function is a number that
   * represents the identifier of a specific area. This parameter is used to load surveys related to
   * that particular area when navigating to the previous page.
   */
  const previousPage = (areaId: number, surveySlug: string, questionId: number,) => {
    if (currentPage > 1) {
      loadResponses(areaId, surveySlug, questionId, currentPage - 1)
    }
  }

  /**
   * The function `lastPage` sets the current page to the total number of pages and loads surveys for a
   * specific area.
   * @param {number} areaId - The `areaId` parameter is a number that represents the unique identifier
   * of a specific area or location. It is used as a reference to load surveys related to that
   * particular area.
   */
  const lastPage = (areaId: number, surveySlug: string, questionId: number,) => {
    loadResponses(areaId, surveySlug, questionId, totalPages)
  }

  return {
    loadSurveyInfo,
    navigateToInteractions,
    isLoadingSurveyInfo,
    isLoadingResponses,
    surveyInfo,
    responses,
    deleteResponse,
    currentPage,
    lastPage,
    nextPage,
    totalPages,
    previousPage,
    firstPage,
    questionId,
    loadResponses
  }
}