import classNames from 'classnames';
import { t } from 'i18next';
import { Link, useLocation } from 'react-router-dom';

interface TabCompanyProps {
  companyId: string;
  isCreating?: boolean;
}

export const TabCompany: React.FC<TabCompanyProps> = ({ companyId, isCreating = false }) => {
  const location = useLocation();
  return (
    <div className='sticky top-0 tabs px-3 mt-3 bg-functional-el-l z-20'>
      <Link
        className={classNames(`tab tab-lifted tab-lg`, { 'tab-active': location.pathname === '/empresas/crud' })}
        state={{ companyId }}
        to={'/empresas/crud'}>
        {t('generics.company')}
      </Link>
      {/* <Link className={`tab tab-lifted tab-lg ${location.pathname === '/empresas/personalizar' ? 'tab-active' : ''}}`} */}
      {!isCreating ? (
        <>
          <Link
            className={classNames(`tab tab-lifted tab-lg`, {
              'tab-active': location.pathname === '/empresas/personalizar'
            })}
            state={{ companyId }}
            to={{ pathname: '/empresas/personalizar' }}>
            {t('generics.personalize')}
          </Link>
          <Link
            className={classNames(`tab tab-lifted tab-lg`, { 'tab-active': location.pathname === '/empresas/areas' })}
            state={{ companyId }}
            to={{ pathname: '/empresas/areas' }}>
            {t('generics.areas')}
          </Link>
          <Link
            className={classNames(`tab tab-lifted tab-lg`, {
              'tab-active': location.pathname === '/empresas/licencias'
            })}
            state={{ companyId }}
            to={{ pathname: '/empresas/licencias' }}>
            {t('generics.licenses')}
          </Link>
        </>
      ) : null}
    </div>
  );
};
