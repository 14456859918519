import { useState } from 'react';
import toast from 'react-hot-toast';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SetImageProps, loadImageWithValidationsProps } from '@/hooks/useLoadImage';
import { PrimaryButton } from '@/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/Buttons/SecondaryButton';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { InputText } from '@/components/Inputs/InputText';
import { SmallSpinner, Spinner } from '@/components/Spinners/Spinner';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { TextArea } from '@/components/Inputs/TextArea';
import { useCrudLesson } from '../hooks/useCrudLesson';
import { Select } from '@/components';
import { getEnvVariables } from '@/utilities';
import { useDispatch } from 'react-redux';
import { setSelectModule } from '@/redux/states/lessons/lessons.slice';
import { t } from 'i18next';

const defaultValuesAdapter = (lesson: any): any => {
  return {
    name: lesson.name,
    description: lesson.description,
    order: lesson?.order?.toString() ?? 'default',
    isActive: lesson.is_active ?? false,
    nextLessonSlug: lesson.next_lesson_slug ?? null,
    require_auth: lesson.require_auth ?? false
  };
};

interface LessonFormProp {
  image: SetImageProps | undefined;
  status: string;
  lesson: any; //cambiar
  editing: () => void;
  viewing: () => void;
  updateLesson: (data: any) => Promise<
    | {
        message: string;
      }
    | undefined
  >;
  createLesson: (data: any) => Promise<
    | {
        message: string;
      }
    | undefined
  >;
  setImage: React.Dispatch<React.SetStateAction<SetImageProps | undefined>>;
  orders: any;
  isLoadingOrders: boolean;
  moduleSlug: string;
  isLoadingLessons: boolean;
  lessons: any;
  loadImageWithValidations: loadImageWithValidationsProps;
}

interface LessonProps {
  name: string;
  description: string;
  isActive: boolean;
  order: number;
  nextLessonSlug: string | null;
  require_auth: boolean;
}

const LessonForm: React.FC<LessonFormProp> = ({
  isLoadingLessons,
  lessons,
  moduleSlug,
  isLoadingOrders,
  status,
  lesson,
  editing,
  viewing,
  updateLesson,
  createLesson,
  setImage,
  loadImageWithValidations,
  image,
  orders = []
}) => {
  const messageRequired = t('errorMessage.requiredField');

  const infoStatus: any = {
    viewing: {
      title: t('pages.lessons.lessonInfo'),
      message: ''
    },
    editing: {
      title: t('pages.lessons.modifyLesson'),
      message: t('pages.lessons.modifyMessage')
    },
    creating: {
      title: t('pages.lessons.addLesson'),
      message: t('pages.lessons.addMessage')
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isCreating = status === 'creating';

  const { VITE_PUBLIC_IMAGE } = getEnvVariables();

  const [disabledButton, setDisabledButton] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm<LessonProps>({
    defaultValues: { ...defaultValuesAdapter(lesson) }
  });
  const disabled = status === 'viewing';

  const onSubmit = async (data: any) => {
    setDisabledButton(true);
    if (typeof image?.image_path === 'string') {
      if (status === 'editing') {
        await updateLesson({ ...data })
          .then((e) => {
            if (e?.message === 'success') {
              dispatch(setSelectModule(moduleSlug));
              navigate('/lecciones');
            }
          })
          .catch((_) => {
            setDisabledButton(false);
          });
      } else if (isCreating) {
        await createLesson({ ...data })
          .then((e) => {
            if (e?.message === 'success') {
              dispatch(setSelectModule(moduleSlug));
              navigate('/lecciones');
            }
          })
          .catch((_) => {
            setDisabledButton(false);
          });
      } else {
        setDisabledButton(false);
        toast.error(t('pages.lessons.notPossible'));
      }
    } else {
      toast.error(t('errorMessage.selectPicture'));
    }
    setDisabledButton(false);
  };

  const handleChooseLogo = (e: any) => {
    e.preventDefault();
    const file = e.target.files?.[0] || undefined;
    if (file) {
      loadImageWithValidations(file);
    }
  };

  const handleChangeStatus = (event: React.FormEvent) => {
    event.preventDefault();
    disabled ? editing() : viewing();
  };

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault();
    if (isCreating) {
      dispatch(setSelectModule(moduleSlug));
      navigate('/lecciones');
    } else {
      reset({ ...defaultValuesAdapter(lesson) });
      setImage(() => ({ image_id: lesson.web_image_id, image_path: `${VITE_PUBLIC_IMAGE}/${lesson.web_image_id}` }));
      viewing();
    }
  };

  const handleDeleteNextLesson = () => {
    setValue('nextLessonSlug', null);
  };

  const optionsOrders = isCreating
    ? [...(orders || []), { order: orders.length > 0 ? orders[orders.length - 1].order + 1 : 0, name: '' }]
    : orders;

  return (
    <>
      <form className='h-[calc(100vh_-_24rem)]' onSubmit={handleSubmit(onSubmit)}>
        <div className='bg-functional-el-l flex items-center justify-start px-10 py-7'>
          <Link to='/lecciones' onClick={() => dispatch(setSelectModule(moduleSlug))}>
            <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
          </Link>
          <div>
            <p className={`font-bold text-2xl teLessonctional-d-l`}>{infoStatus[status].title}</p>
            <p className={`font-medium text-functional-d-l`}>{infoStatus[status].message}</p>
          </div>
        </div>
        <div className='m-11 p-10 bg-functional-el-l rounded-3xl shadow-lg'>
          <div className='flex justify-between'>
            <div>
              <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.companies.generalData')}</p>
              <p className='font-medium text-functional-d-l'>{t('pages.lessons.addData')}</p>
            </div>
            {!isCreating ? (
              <PrimaryButton className='px-3' onClick={handleChangeStatus}>
                {disabled ? t('pages.lessons.editLesson') : t('generics.seeInformation')}
              </PrimaryButton>
            ) : (
              <></>
            )}
          </div>
          <div className={cn('grid grid-cols-2', { 'grid-rows-3': true })}>
            <div className='p-4'>
              <InputText
                className='w-5/6'
                disabled={disabled}
                register={() => register('name', { required: messageRequired })}
                placeholder={t('generics.name')}
              />
              {errors.name ? <p className='text-warning-d'>{errors.name.message}</p> : null}
            </div>
            <div className='p-4 row-span-2'>
              <TextArea
                disabled={disabled}
                register={() => register('description', { required: messageRequired })}
                placeholder={t('generics.description')}
              />
              {errors.description ? <p className='text-warning-d'>{errors.description.message}</p> : null}
            </div>
            <div className='p-4 w-5/6 row-span-2'>
              <TitleInput>{t('pages.lessons.lessonImage')}</TitleInput>
              <div className='flex flex-col items-center justify-center'>
                <div className={cn('w-full my-2 h-[150px] row-span-2')}>
                  {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                  <img
                    src={image?.image_path ?? '/imgLayout.png'}
                    alt=''
                    className='row-span-2 w-full h-full object-contain'
                  />
                </div>
                {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
                <input
                  disabled={disabled}
                  type='file'
                  id='actual-btn'
                  accept='image/jpg, image/jpeg, image/png'
                  onChange={handleChooseLogo}
                  hidden
                />
                <label
                  htmlFor='actual-btn'
                  className={cn(
                    'self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14',
                    { 'cursor-no-drop': disabled, 'cursor-pointer': !disabled }
                  )}>
                  {/* <PlusIcon className="h-5 w-5 bg-functional-el-l text-functional-l-d mr-3.5 rounded-sm" /> */}
                  <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5'>add</span>
                  {t('generics.upload')} (300x500 px en jpg o png)
                </label>
              </div>
            </div>
            {orders.length > 0 ? (
              <>
                {' '}
                {!disabled ? (
                  <div className='p-4 w-5/6'>
                    <TitleInput>{t('generics.order')}</TitleInput>
                    {isLoadingOrders ? (
                      <SmallSpinner></SmallSpinner>
                    ) : (
                      <Select
                        className='w-full'
                        options={optionsOrders}
                        optionmap={(value) => `${value.order}`}
                        optionKeyMap={(value) => value.name + Math.random()}
                        optionValueMap={(value) => value.order}
                        register={() => register('order', { required: orders.length > 0 ? messageRequired : false })}
                      />
                    )}
                    {errors.order ? <p className='text-warning-d'>{errors.order.message}</p> : null}
                  </div>
                ) : (
                  <div className='flex justify-start items-center'>
                    <TitleInput>{t('generics.order')}</TitleInput>
                    <p className='mx-3 font-medium text-xl'>{lesson.order}</p>
                  </div>
                )}{' '}
              </>
            ) : null}
            {
              <div className='p-4 w-5/6'>
                <TitleInput>{t('pages.lessons.nextLesson')}</TitleInput>
                <div className='flex items-center'>
                  {isLoadingLessons ? (
                    <SmallSpinner></SmallSpinner>
                  ) : (
                    <Select
                      placeholder={t('pages.lessons.nextLesson')}
                      disabled={disabled}
                      className='w-full'
                      options={lessons}
                      optionmap={(value) => `${value.name}`}
                      optionKeyMap={(value) => value.slug}
                      optionValueMap={(value) => value.slug}
                      register={() => register('nextLessonSlug')}
                    />
                  )}
                  <span
                    onClick={(e: React.FormEvent) => {
                      e.preventDefault();
                      disabled ? null : handleDeleteNextLesson();
                    }}
                    className='material-symbols-outlined cursor-pointer'>
                    close
                  </span>
                </div>
              </div>
            }
            {!isCreating ? (
              <div className='flex items-center p-4'>
                <TitleInput>{t('generics.activated')}</TitleInput>
                <div className='mx-2'></div>
                <ToggleSwitch disabled={disabled} message='' register={() => register('isActive')} />
              </div>
            ) : null}
            <div className='flex items-center p-4'>
              <TitleInput>{t('pages.lessons.RequiresAuthentication')}</TitleInput>
              <div className='mx-2'></div>
              <ToggleSwitch disabled={disabled} message='' register={() => register('require_auth')} />
            </div>
          </div>
          {!disabled ? (
            <div className='flex justify-end'>
              <PrimaryButton className='px-16' disabled={disabled || disabledButton} type='submit'>
                {t('pages.lessons.saveLesson')}
              </PrimaryButton>
              <SecondaryButton className='px-8' disabled={disabled || disabledButton} onClick={handleCancel}>
                {t('generics.cancel')}
              </SecondaryButton>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export const CRUDLesson = () => {
  const { state } = useLocation();

  const {
    // propiedades
    image,
    lesson,
    status,
    isLoading,
    orders,
    isLoadingOrders,
    moduleSelected,
    isLoadingLessons,
    lessons,
    // Metodos,
    editing,
    viewing,
    updateLesson,
    createLesson,
    setImage,
    loadImageWithValidations
  } = useCrudLesson(state);

  const loading = !isLoading;

  return (
    <article className='bg-functional-el-m'>
      {loading ? (
        <LessonForm
          {...{
            loadImageWithValidations,
            isLoadingLessons,
            lessons,
            moduleSlug: moduleSelected,
            isLoadingOrders,
            orders,
            status,
            lesson,
            image,
            editing,
            viewing,
            updateLesson,
            createLesson,
            setImage
          }}
        />
      ) : (
        <Spinner />
      )}
    </article>
  );
};
