import { Feature } from "../models/Feature.model";

export const features: Feature[] = [{ name: 'basic_access', label: 'Acceso básico' },
{ name: 'full_access', label: 'Acceso completo' },
{ name: 'new_features_access', label: 'Acceso a nuevas funciones' },
{ name: 'interactive_content', label: 'Contenido interactivo' },
{ name: 'multi_language_support', label: 'Soporte multilenguaje' },
{ name: 'advange_reporting', label: 'Informes avanzados' },
{ name: 'certificate_generation', label: 'Generación de certificados' },
{ name: 'white_labeling', label: 'Etiquetado en blanco' }]

export const featuresMap = {
    basic_access: { name: 'basic_access', label: 'Acceso básico' },
    full_access: { name: 'full_access', label: 'Acceso completo' },
    new_features_access: { name: 'new_features_access', label: 'Acceso a nuevas funciones' },
    interactive_content: { name: 'interactive_content', label: 'Contenido interactivo' },
    multi_language_support: { name: 'multi_language_support', label: 'Soporte multilenguaje' },
    advange_reporting: { name: 'advange_reporting', label: 'Informes avanzados' },
    certificate_generation: { name: 'certificate_generation', label: 'Generación de certificados' },
    white_labeling: { name: 'white_labeling', label: 'Etiquetado en blanco' },
};