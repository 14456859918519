import { createSlice } from '@reduxjs/toolkit';

const areas : any = []

export const areaSlice = createSlice({
    name: 'auth',
    initialState: {
        areas,
        isLoading:false,
        isLoadingArea:false,
        area:'',
        pagination:{}
    },
    reducers: {
        setArea: (state, { payload }) => {
            state.area = payload
            state.isLoadingArea = false
        },
        setAreas: (state, action) => {
            state.areas = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        setIsloading:(state, action) => {
            state.isLoading = action.payload
        },
        setIsLoadingArea: (state, action) => {
            state.isLoadingArea = action.payload
        },
        toggleActiveArea: (state, {payload}) => {
            const ix = state.areas.findIndex(({id}:{id:number}) => id === payload)
            state.areas[ix].isActive = !(state.areas[ix].isActive)
        },
        resetArea: (state) => {
            state.area = ''
        },
        resetAreas: (state) => {
            state.areas = []
        }

    }
});

export const { setArea, setAreas, setIsloading, toggleActiveArea, setIsLoadingArea, resetArea, resetAreas } = areaSlice.actions;