import { loadLicenses, setIsLoading, setResetState } from "@/redux/states/licenses/licenses.slice"
import { useDispatch, useSelector } from "react-redux"
import { paginationAdapter } from "@/adapters/pagination.adapter"
import { loadLicensesAdapter } from "../adapters/loadLicenses.adapter"
import { useEffect, useState } from "react"
import { AppStore } from "@/redux"
import { usePagination } from "@/hooks"
import useAxiosPrivate from "@/hooks/useApiV2Private"

let controller: AbortController, signal, oldValue = ''
export const useLicenses = () => {

  const dispatch = useDispatch()
  const apiV2 = useAxiosPrivate()

  const [value, setValue] = useState('')

  const { isLoading, licenses, pagination, per_page } = useSelector((state: AppStore) => state.licenses)
  const { nextPage, previousPage, initialPage, finalPage, changePage, currentPage } = usePagination({ lastPage: pagination.last_page })

  const startLoadLicenses = async () => {
    dispatch(setIsLoading(true));
    try {
      if (controller) {
        controller.abort()
      }
      controller = new AbortController()
      signal = controller.signal
      const resp = await apiV2.get(`/licenses/`, {
        params: {
          per_page,
          page: oldValue === value ? currentPage : 1,
          search: value,
        },
        signal
      })
      oldValue = value
      dispatch(
        loadLicenses({
          pagination: paginationAdapter(resp.data.pagination),
          items: loadLicensesAdapter(resp.data.items),
        })
      );
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    startLoadLicenses()
    return () => {
      controller?.abort()
      dispatch(setResetState())
    }
  }, [])

  return ({
    value, setValue, isLoading, licenses, pagination, per_page, nextPage, previousPage, initialPage, finalPage, changePage, currentPage
  }
  )
}

