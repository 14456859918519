export const IconPlusButton = (props: React.ComponentProps<"button">) => {
    return (
        <button
            onClick={props.onClick}
            className={"bg-[#9A9A9A] text-lg flex items-center font-bold justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14 " + props.className}>
            {/* <PlusIcon className="h-5 w-5 bg-functional-el-l text-functional-l-d mr-3.5 rounded-sm" /> */}
            <span className="material-symbols-outlined bg-functional-el-l text-functional-l-d rounded-sm mr-3.5">add</span>
            {props.children}
        </button>
    )
}
