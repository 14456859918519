import useAxiosPrivate from "@/hooks/useApiV2Private"
import { Area } from "@/models/areas.models"
import { AppStore } from "@/redux"
import { useState } from "react"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"


export const useAreas = () => {

  const [areas, setAreas] = useState<Area[]>([])
  const apiV2 = useAxiosPrivate()
  const { companySlug } = useSelector((state: AppStore) => state.ui)

  /**
   * Loads the areas from the server and sets them in the state.
   * @returns {Promise<void>} A promise that resolves when the areas are loaded and set in the state.
   */
  const loadAreas = async (): Promise<void> => {
    try {
      const res = await apiV2.get(`/companies/${companySlug}/areas?per_page=-1`)
      setAreas(res.data.items)
    }
    catch(err) {
      console.error('Error cargando las areas', err)
      toast.error('Error cargando las areas')
    }
  }

  return {
    areas,
    loadAreas
  }
}