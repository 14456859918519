import { PrimaryButton } from '@/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/Buttons/SecondaryButton';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCrudLicenses } from '../hooks/useCrudLicenses';
import { useEffect, useMemo, useState } from 'react';
import { LicenseProps } from '../models/license.model';
import { InputText } from '@/components/Inputs/InputText';
import { TextArea } from '@/components/Inputs/TextArea';
import { Spinner } from '@/components/Spinners/Spinner';
import { toCurrency } from '@/utilities/currencyFormatter';
import { Select } from '@/components';
import { currencyCodes } from '../data/currencyCode';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { features } from '../data/features';
import { Rol } from '@/models/roles.models';
import { Feature } from '../models/Feature.model';
import { statusData } from '../data/status';
import { t } from 'i18next';

const defaultValuesAdapter = (license: LicenseProps): any => {
  return {
    name: license.name || '',
    currency: license.currency || 'USD',
    description: license.description || '',
    basePrice: `${license.basePrice || 0}`,
    activeUsers: license.activeUsers || 0,
    activeModules: license.activeModules || 0,
    activeVideos: license.activeVideos || 0,
    pricePerExtraUser: license.pricePerExtraUser || 0,
    pricePerExtraModule: license.pricePerExtraModule || 0,
    pricePerExtraVideo: license.pricePerExtraVideo || 0,
    annualDiscount: license.annualDiscount || 0,
    features: license.features || [],
    role: license.role || [],
    status: license.status || 'draft'
  };
};

interface LicenseFormProp {
  license: LicenseProps;
  status: 'viewing' | 'editing' | 'creating';
  crudDisabled?: boolean;
  changeStatus: (status: 'viewing' | 'editing' | 'creating') => void;
  startCreateLicense: (data: LicenseProps) => Promise<void>;
  startUpdateLicense: (data: LicenseProps) => Promise<void>;
  roles: Rol[];
}

type LicensePropsString = Omit<
  LicenseProps,
  'basePrice' | 'annualDiscount' | 'pricePerExtraUser' | 'pricePerExtraModule' | 'pricePerExtraVideo'
> & {
  basePrice: string | number;
  annualDiscount: string | number;
  pricePerExtraUser: string | number;
  pricePerExtraModule: string | number;
  pricePerExtraVideo: string | number;
};

const LicenseForm: React.FC<LicenseFormProp> = ({
  roles,
  license,
  crudDisabled,
  status,
  changeStatus,
  startCreateLicense,
  startUpdateLicense
}) => {
  const navigate = useNavigate();

  const infoStatus: any = {
    viewing: {
      title: t('pages.licenses.licensesInfo'),
      message: ''
    },
    editing: {
      title: t('pages.licenses.modifyLicense'),
      message: t('pages.licenses.licenseMessage')
    },
    creating: {
      title: t('pages.licenses.addLicense'),
      message: t('pages.licenses.licenseMessage')
    }
  };

  const rolesMapId: any = roles?.reduce((acc: any, cur: Rol) => ({ ...acc, [cur.id]: cur }), {}) || {};

  const [optionFeature, setOptionFeature] = useState<string[]>(license?.features || []);

  const [optionsRol, setOptionRol] = useState<number[]>(
    license?.role
      ?.filter((rol: number) => {
        return !!rolesMapId[rol];
      })
      ?.map((rol: number) => {
        return rolesMapId[rol]?.id;
      }) || []
  );

  const feturesMapId: any = useMemo(
    () => features?.reduce((acc: any, cur: Feature) => ({ ...acc, [cur.name]: cur }), {}) || {},
    [features]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset
  } = useForm<LicensePropsString>({
    defaultValues: { ...defaultValuesAdapter(license) }
  });

  const disabled = status === 'viewing';
  const onSubmit = async (data: any) => {
    const dataAdapter: LicenseProps = {
      ...data,
      basePrice: parseInt(data.basePrice),
      annualDiscount: parseInt(data.annualDiscount),
      pricePerExtraUser: parseInt(data.pricePerExtraUser),
      pricePerExtraModule: parseInt(data.pricePerExtraModule),
      pricePerExtraVideo: parseInt(data.pricePerExtraVideo),
      features: optionFeature,
      role: optionsRol
    };
    if (status === 'creating') {
      startCreateLicense(dataAdapter);
    } else {
      startUpdateLicense(dataAdapter);
    }
  };

  const handleChangeStatus = (event: React.FormEvent) => {
    event.preventDefault();
    if (disabled) {
      changeStatus('editing');
    } else {
      changeStatus('viewing');
    }
  };

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault();
    if (isCreating) {
      navigate('/licencias');
    } else {
      changeStatus('viewing');
      reset({ ...defaultValuesAdapter(license) });
      setOptionFeature(license?.features || []);
      setOptionRol(
        license?.role
          ?.filter((rol: number) => {
            return !!rolesMapId[rol];
          })
          ?.map((rol: number) => {
            return rolesMapId[rol]?.id;
          }) || []
      );
    }
  };

  const handleSelectOptionFeature = (event: React.FormEvent) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    const valueExist = optionFeature.find((option) => option === value);
    if (value && !valueExist) {
      setOptionFeature([...optionFeature, value]);
    }
  };

  const handleDeleteOptionFeature = (option: string) => {
    if (disabled) return;
    const newOptionFeature = optionFeature.filter((optionFeature) => optionFeature !== option);
    setOptionFeature(newOptionFeature);
  };

  const handleSelectOptionRol = (event: React.FormEvent) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    const valueExist = optionsRol.find((option) => option === +value);
    if (value && !valueExist) {
      setOptionRol([...optionsRol, +value]);
    }
  };

  const handleDeleteOptionRol = (value: number) => {
    if (!disabled) {
      setOptionRol((options) => options.filter((option) => option !== value));
    }
  };

  const isCreating = status === 'creating';
  const isEditing = status === 'editing';

  const currentCurrency = watch('currency');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='bg-functional-el-l flex items-center justify-start px-10 py-7'>
          <Link to='/licencias'>
            <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
          </Link>
          <div>
            <p className={`font-bold text-2xl text-functional-d-l`}>{infoStatus[status].title}</p>
            <p className={`font-medium text-functional-d-l`}>{infoStatus[status].message}</p>
          </div>
        </div>
        <div className='m-11 p-10 bg-functional-el-l rounded-3xl shadow-lg'>
          <div className='flex justify-between'>
            <div>
              <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.companies.generalData')}</p>
              <p className='font-medium text-functional-d-l'>{t('pages.licenses.addData')}</p>
            </div>
            {!isCreating ? (
              <PrimaryButton onClick={handleChangeStatus}>
                {disabled ? t('generics.edit') : t('generics.seeInformation')}
              </PrimaryButton>
            ) : (
              <></>
            )}
          </div>
          <div className={cn('grid grid-cols-2 grid-rows-10')}>
            <div className='p-4'>
              <InputText
                className='w-5/6'
                disabled={disabled}
                register={() => register('name', { required: t('errorMessage.completeName'), minLength: 3 })}
                placeholder={t('generics.name')}
              />
              {errors.name ? <p className='text-warning-d'>{errors.name.message}</p> : null}
            </div>
            <div className='p-4 row-span-2'>
              <TextArea
                disabled={disabled}
                register={() => register('description', { required: t('errorMessage.completeDescription') })}
                placeholder={t('generics.description')}
              />
              {errors.description ? <p className='text-warning-d'>{errors.description.message}</p> : null}
            </div>
            <div className='p-4'>
              <TitleInput>{t('pages.licenses.currencyCode')}</TitleInput>
              <Select
                className='w-5/6'
                disabled={disabled}
                options={currencyCodes}
                optionmap={(value) => `${value.AlphabeticCode} - ${value?.Currency || ''}`}
                optionValueMap={(value) => value.AlphabeticCode}
                register={() => register('currency')}
              />
            </div>
            <div className='flex justify-between w-5/6'>
              <div className='p-4'>
                <InputText
                  type='text'
                  className='w-full'
                  disabled={disabled}
                  value={toCurrency(currentCurrency, parseInt(`${watch('basePrice')}` || '0'))}
                  onInput={(e: any) => {
                    setValue('basePrice', e.target.value.replace(/\D/g, ''));
                  }}
                  placeholder={t('pages.licenses.basePrice')}
                />
                {errors.basePrice ? <p className='text-warning-d'>{errors.basePrice.message}</p> : null}
              </div>
              <div className='p-4'>
                <InputText
                  type='number'
                  step='0.01'
                  className='w-full'
                  disabled={disabled}
                  register={() => register('annualDiscount')}
                  placeholder={t('pages.licenses.discount')}
                />
                {errors.annualDiscount ? <p className='text-warning-d'>{errors.annualDiscount.message}</p> : null}
              </div>
            </div>
            <div className='p-4'>
              <TitleInput>{t('generics.state')}</TitleInput>
              <Select
                className='w-5/6'
                disabled={disabled}
                options={statusData}
                optionmap={(value) => value.label}
                optionValueMap={(value) => value.name}
                register={() => register('status')}
              />
            </div>
            <div className='p-4 row-span-2'>
              <TitleInput>{t('pages.licenses.featuresIncluded')}</TitleInput>
              <Select
                className='w-5/6'
                value={'default'}
                disabled={disabled}
                options={features}
                optionmap={(value) => value.label}
                optionKeyMap={(value) => value.name}
                optionValueMap={(value) => value.name}
                onChange={handleSelectOptionFeature}
              />
              <div className='flex flex-wrap mt-2 w-5/6'>
                {optionFeature.length > 0 ? (
                  optionFeature.map((option) => (
                    <div
                      onClick={() => handleDeleteOptionFeature(option)}
                      key={option}
                      className='h-10 p-2 mt-2 mr-2 bg-functional-l-l rounded-lg flex justify-center items-center'>
                      <p>{feturesMapId?.[option].label ?? option}</p>
                      <div className='border border-functional-l-l h-full'></div>
                      <i className='mx-1 fa-solid fa-xmark hover:text-error-m' />
                    </div>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className='p-4 row-span-2'>
              <p className='mb-1 text-lg text-black font-semibold'>{t('pages.users.companyRol')}:</p>
              <div className='flex flex-col'>
                <Select
                  value={'default'}
                  disabled={disabled}
                  className='w-5/6'
                  options={roles}
                  optionValueMap={(value) => value.id}
                  optionmap={(value) => value.name}
                  optionKeyMap={(value) => value.id}
                  onChange={handleSelectOptionRol}
                />
                <div className='flex flex-wrap mt-2 w-5/6'>
                  {optionsRol.length > 0 ? (
                    optionsRol.map((option) => (
                      <div
                        onClick={() => handleDeleteOptionRol(option)}
                        key={option}
                        className='h-10 mt-2 mr-2 bg-functional-l-l rounded-lg p-1 flex justify-center items-center'>
                        <p>{rolesMapId[option]?.name ?? option}</p>
                        <div className='border border-functional-l-l h-full'></div>
                        <i className='mx-1 fa-solid fa-xmark hover:text-error-m' />
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div className='divider col-span-2'></div>
            <div className='px-4 row-span-2 col-span-2'>
              <TitleInput>{t('generics.limits')}</TitleInput>
              <p className='font-medium text-functional-d-l'>{t('pages.licenses.addAmount')}</p>
              <div className='flex justify-between items-center w-11/12'>
                <div className='w-3/12'>
                  <InputText
                    type='number'
                    className='w-full'
                    disabled={disabled}
                    register={() => register('activeUsers', { valueAsNumber: true })}
                    placeholder={t('generics.users')}
                  />
                  {errors.activeUsers ? <p className='text-warning-d'>{errors.activeUsers.message}</p> : null}
                </div>
                <div className='w-3/12'>
                  <InputText
                    type='number'
                    className='w-full'
                    disabled={disabled}
                    register={() => register('activeModules', { valueAsNumber: true })}
                    placeholder={t('generics.modules')}
                  />
                  {errors.activeModules ? <p className='text-warning-d'>{errors.activeModules.message}</p> : null}
                </div>
                <div className='w-3/12'>
                  <InputText
                    type='number'
                    className='w-full'
                    disabled={disabled}
                    register={() => register('activeVideos', { valueAsNumber: true })}
                    placeholder='Videos'
                  />
                  {errors.activeVideos ? <p className='text-warning-d'>{errors.activeVideos.message}</p> : null}
                </div>
              </div>
            </div>
            <div className='divider col-span-2'></div>
            <div className='px-4 row-span-2 col-span-2'>
              <TitleInput>{t('pages.licenses.additionalCosts')}</TitleInput>
              <p className='font-medium text-functional-d-l'>{t('pages.licenses.addAdditionalCosts')}</p>
              <div className='flex justify-between items-center w-11/12'>
                <div className='w-3/12'>
                  <InputText
                    type='text'
                    className='w-full'
                    disabled={disabled}
                    value={toCurrency(currentCurrency, parseInt(`${watch('pricePerExtraUser')}` || '0'))}
                    onInput={(e: any) => {
                      setValue('pricePerExtraUser', e.target.value.replace(/\D/g, ''));
                    }}
                    placeholder={t('pages.licenses.perUser')}
                  />
                  {errors.pricePerExtraUser ? (
                    <p className='text-warning-d'>{errors.pricePerExtraUser.message}</p>
                  ) : null}
                </div>
                <div className='w-3/12'>
                  <InputText
                    type='text'
                    className='w-full'
                    disabled={disabled}
                    value={toCurrency(currentCurrency, parseInt(`${watch('pricePerExtraModule')}` || '0'))}
                    onInput={(e: any) => {
                      setValue('pricePerExtraModule', e.target.value.replace(/\D/g, ''));
                    }}
                    placeholder={t('pages.licenses.perModule')}
                  />
                  {errors.pricePerExtraModule ? (
                    <p className='text-warning-d'>{errors.pricePerExtraModule.message}</p>
                  ) : null}
                </div>
                <div className='w-3/12'>
                  <InputText
                    type='text'
                    className='w-full'
                    disabled={disabled}
                    value={toCurrency(currentCurrency, parseInt(`${watch('pricePerExtraVideo')}` || '0'))}
                    onInput={(e: any) => {
                      setValue('pricePerExtraVideo', e.target.value.replace(/\D/g, ''));
                    }}
                    placeholder={t('pages.licenses.perVideo')}
                  />
                  {errors.pricePerExtraVideo ? (
                    <p className='text-warning-d'>{errors.pricePerExtraVideo.message}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {isCreating || isEditing ? (
            <div className='flex justify-end'>
              <PrimaryButton disabled={disabled || crudDisabled} type='submit'>
                {t('pages.licenses.saveLicense')}
              </PrimaryButton>
              <SecondaryButton className='px-8' disabled={disabled} onClick={handleCancel}>
                {isCreating ? t('generics.goBack') : t('generics.cancel')}
              </SecondaryButton>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export const CRUDLicense = () => {
  const { state } = useLocation();

  const {
    loadRoles,
    resetLicense,
    changeStatus,
    startLoadLicense,
    startCreateLicense,
    startUpdateLicense,
    isLoading,
    status,
    license,
    crudDisabled,
    roles,
    isLoadingRoles
  } = useCrudLicenses();

  useEffect(() => {
    loadRoles();
    if (state?.code) {
      startLoadLicense(state.code);
    } else {
      changeStatus('creating');
    }
    return () => {
      resetLicense();
    };
  }, [state?.code]);

  return (
    <article className='bg-functional-el-m'>
      {!isLoading && !isLoadingRoles ? (
        <LicenseForm
          {...{ roles, license, status, crudDisabled, changeStatus, startCreateLicense, startUpdateLicense }}
        />
      ) : (
        <Spinner />
      )}
    </article>
  );
};
