import { loadLicense, setCrudDisabled, setIsLoading, setIsLoadingRoles, setRoles, setStatus } from "@/redux/states/licenses/CrudLicense.slice"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { loadLicenseAdapter } from "../adapters/loadLicense.adapter"
import useAxiosPrivate from "@/hooks/useApiV2Private"
import { proccessError } from "@/utilities/stringsError"
import { AppStore } from "@/redux"
import { loadRolesAdapter } from "@/adapters/loadRoles.adapter"
import { camelToSnake } from "@/utilities/convertsObjectsKey"

export const useCrudLicenses = () => {

    const apiV2 = useAxiosPrivate()

    const { isLoading, status, license, crudDisabled, roles, isLoadingRoles } = useSelector((state: AppStore) => state.crudLicense)

    const dispatch = useDispatch()

    const startLoadLicense = async (licenseId: string | number) => {
        dispatch(setIsLoading(true))
        try {
            const resp = await apiV2.get(`/licenses/${licenseId}`)
            dispatch(loadLicense(loadLicenseAdapter(resp.data)))
            toast.success('Datos del usuario cargados')
        } catch (error: any) {
            toast(proccessError(error.response.data))
            toast.error('Error al cargar los datos de la licencia')
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const startUpdateLicense = async (data: any) => {
        dispatch(setCrudDisabled(true))
        try {
            const resp = await apiV2.patch(`/licenses/${license.code}`, {...camelToSnake(data)})
            toast.success('Licencia actualizada')
            return resp.data
        } catch (error: any) {
            if (error.response.status === 400) {
                toast(proccessError(error.response.data))
                toast.error('La licencia no ha sido actualizada:')
            } else {
                toast.error('La licencia no ha sido actualizada')
            }
        } finally {
            dispatch(setCrudDisabled(false))
        }
    }

    const startCreateLicense = async (data: any) => {
        dispatch(setCrudDisabled(true))
        try {
            const resp = await apiV2.post(`/licenses`, {...camelToSnake(data)})
            toast.success('Licencia creada')
            return resp.data
        } catch (error: any) {
            if (error.response.status === 400) {
                toast(proccessError(error.response.data))
                toast.error('La licencia no ha sido Creada:')
            } else {
                toast.error('La licencia no ha sido Creada')
            }
        } finally {
            dispatch(setCrudDisabled(false))
        }
    }

    const changeStatus = (status:string) => {
        dispatch(setStatus(status))
    }

    const resetLicense = () => {
        dispatch(loadLicense({}))
    }

    const loadRoles = async () => {
        dispatch(setIsLoadingRoles(true))
        try {
            const resp = await apiV2.get(`/roles`,{params:{per_page:10000}})
            toast.success('Roles cargados')
            dispatch(setRoles(loadRolesAdapter(resp.data)))
        } catch (error:any) {
            if (error.response.status === 400) {
                toast(proccessError(error.response.data))
            }
            toast.error('los roles no han sido cargados')
        } finally {
            dispatch(setIsLoadingRoles(false))
        }
    }


    return (
        { resetLicense, changeStatus ,startLoadLicense, startCreateLicense, startUpdateLicense, loadRoles ,isLoading, status, license, crudDisabled, roles, isLoadingRoles }
    )
}
