type LoadFromBrowserStoreProps = (key: string) => any

export const loadFromLocalStore: LoadFromBrowserStoreProps = (key) => {
    try {
        const serialisedState = localStorage.getItem(key);
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

export const loadFromSessionStore: LoadFromBrowserStoreProps = (key) => {
    try {
        const serialisedState = sessionStorage.getItem(key);
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}
