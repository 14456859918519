import { Navigate, Route, Routes } from "react-router-dom"
import { PremiosApp } from "../pages/PremiosApp"
import { CRUDPremios } from "../pages/CRUDPremio"


export const PremiosRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<PremiosApp />} />
            <Route path="crud/:areaId" element={<CRUDPremios />} />
            <Route path="/*" element={<Navigate to='/premios' />} />
        </Routes>
    )
}
