import classNames from 'classnames'
import React from 'react'

type ModalProps = {
    close: () => void
    data?: any
    title: string
    size: string | boolean
    isOpen: boolean
    children: React.ReactNode
}

export const ModalLayout: React.FC<ModalProps> = ({ children, isOpen, size, title, close }) => {
    return (
        <dialog id="my_modal_1" className={classNames("modal", { 'modal-open': isOpen })}>
            <div className={classNames("modal-box", { 'max-w-none': size }, size)}>
                <div className="flex justify-between mb-5">
                    <p className="font-bold text-3xl">{title}</p>
                    <span onClick={close} className="material-symbols-outlined cursor-pointer">close</span>
                </div>
                {children}
            </div>
        </dialog>
    )
}
