export const SURVEY_STATUS: { [key: string]: string } = {
  'active': 'Activo',
  'inactive': 'Inactivo',
  'draft': 'Borrador',
  'closed': 'Cerrado'
}

export const SURVEY_STATUS_OPTIONS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DRAFT: 'draft',
  CLOSED: 'closed'
}