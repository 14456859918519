const messagesMap: any = {
    'The email has already been taken.': "- El correo ya ha sido registrado",
    'The phone has already been taken.': '- El telefono ya ha sido registrado',
    "The avatar id field is required.": " - La foto de perfil es obligatorio",
    "The role id field is required.": '- El rol es obligatorio',
    'The password field is required.': '- La contraseña es obligatoria',
    "The current password is match with old password.": 'La contraseña actual es igual a la anterior contraseña',
    'Token inválido o expirado': 'Token inválido o expirado'
}

export const proccessError = (data: any) => {
    const keys = Object.keys(data)
    return keys.map((key) => messagesMap[data[key]]).join('\n')
}