import { useState } from 'react'
import toast from "react-hot-toast"

import useAxiosPrivate from './useApiV2Private'

let controller:AbortController , signal
export const useOrderList = (companySlug:string, moduleSelected:string) => {

    const apiV2 = useAxiosPrivate()

    const [orders, setOrders] = useState<{id:number, name:string, slug:string}[]>()
    const [isLoadingOrders, setIsloadingOrders] = useState(false)

    const startLoadOrder = async () => {
        setIsloadingOrders(true)
        try {
            if (controller) {
                controller.abort()
              }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/modules/${moduleSelected}/lessons/orders`, { 
                params: { per_page:-1, },
                signal
            })
            const ordersSort = resp.data.sort(({order: orderA}:{order:number},{order:orderB}:{order:number}) => orderA-orderB).map((order:any) => ({...order, orderString: order.order.toString()}))
            setOrders(ordersSort)
            setIsloadingOrders(false)
        } catch (error) {
            setIsloadingOrders(false)
            toast.error('Existe un error en el servidor')
        }
    }

    return {
        isLoadingOrders,
        orders,
        startLoadOrder
    }
}
