import { Navigate, Route, Routes } from "react-router-dom"
import { ModulosApp } from "../pages/ModulosApp"
import { CRUDModulos } from "../pages/CRUDModulos"

export const ModulosRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<ModulosApp />} />
            <Route path="crud" element={<CRUDModulos />} />
            <Route path="/*" element={<Navigate to='/modulos' />} />
        </Routes>
    )
}
