import { useTranslation } from 'react-i18next';
import { VscClose } from 'react-icons/vsc';
import { useRef, useEffect } from 'react';

interface ModalProps {
  children: React.ReactNode;
  closeClick?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  delay?: number;
}

export function NewModal({ children, closeClick, className, delay }: ModalProps): JSX.Element {
  // configuration
  const { t } = useTranslation();
  const transitionContainer = useRef<HTMLDivElement>(null);

  // effects
  useEffect(() => {
    setTimeout(() => {
      if (transitionContainer.current) {
        transitionContainer.current.classList.add('!opacity-100');
      }
    }, delay || 0);
  }, []);

  return (
    <div
      ref={transitionContainer}
      className={`absolute top-0 left-0 h-full w-full flex justify-center items-center opacity-0 bg-[#000000]/50 backdrop-blur-[30px] transition-all duration-200 z-[100] ${
        className || ''
      }`}>
      {children}
      {closeClick && (
        <button
          onClick={() => closeClick(false)}
          className='absolute bottom-[70px] text-[18px] font-medium italic flex items-center'>
          <span className='pb-[3px]'>{t('generics.close')}</span>
          <VscClose className='text-[30px]' />
        </button>
      )}
    </div>
  );
}
