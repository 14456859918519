import dayjs from 'dayjs'

// type Rol = "Administrador" | 'Creador' | 'Soporte'

// interface RolesMap {
//     1:Rol
//     2:Rol
//     3:Rol
// }

// const rolesMap:RolesMap = {
//     1:"Administrador",
//     2:"Creador",
//     3:"Soporte"
// }

// TODO: revisar estructura de datos
// TODO: Mapiar roles
export const loadUsersAdapter = (users: any) => {
    return users.map((user: any) => {
        return ({
            id: user.id,
            name: user.name,
            email: user.email,
            createdAt: dayjs(user.created_at).format('DD/MM/YYYY'),
            updatedAt: dayjs(user.updated_at).format('DD/MM/YYYY'),
            avatarUrl: user.avatar_url || null,
            avatarId: user.avatar_id || null,
            phone: user.phone,
            isActive: user.is_active || false,
            rol: user.roles
        })
    }
    )

}