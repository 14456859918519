import toast from "react-hot-toast"
import { useState } from "react"
import { loadLessonsAdapter } from "../adapters/loadLessons.adapter"
import { LoadLesson } from "../models/cardLesson"
import useAxiosPrivate from "@/hooks/useApiV2Private"

const filterLessonActivate = (lessons:LoadLesson[]) => {
    return lessons.filter((lesson) => lesson.is_active)
}


let controller:AbortController , signal
export const useLoadCompaniesLessons = (companySlug:string, moduleSelected:string) => {

    const apiV2 = useAxiosPrivate()

    const [isLoading, setIsLoading] = useState(false)
    const [lessons, setLessons] = useState<LoadLesson[]>()


    const startLoadLessons = async () => {
        setIsLoading(true)
        try {
            if (controller) {
                controller.abort()
              }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/modules/${moduleSelected}/lessons`, { 
                params: { per_page:-1 }, 
                signal
            })
            setLessons(filterLessonActivate(loadLessonsAdapter(resp.data.items)))
            toast.success('Lecciones cargadas')
            return loadLessonsAdapter(resp.data.items)
        } catch (error) {
            toast.error('Existe un error en el servidor')
        } finally {
            setIsLoading(false)
        }
    }

    return {
        isLoading, lessons, startLoadLessons
    }
}
