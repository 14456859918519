interface ButtonsProps {
  name: string;
}

export const AddButtonPrimary = ({ name, ...props }: React.ComponentProps<'button'> & ButtonsProps) => {
  return (
    <button
      {...props}
      className='rounded-lg bg-primary-l-m text-text-functional-d-d font-semibold flex items-center justify-center text-black w-48 py-4 px-3 ml-4'>
      <span className='material-symbols-outlined text-functional-d-d mr-3.5 rounded-sm'>add</span>
      <p className='font-bold mr-3'>{name}</p>
    </button>
  );
};

export const AddButtonGray = ({ name, ...props }: React.ComponentProps<'button'> & ButtonsProps) => {
  return (
    <button
      {...props}
      className='bg-functional-l-d text-functional-el-l font-semibold flex items-center justify-center text-bg-functional-el-l rounded-lg w-52 py-4 px-3 ml-4'>
      <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5 rounded-sm'>add</span>
      {name}
    </button>
  );
};
