import { Select } from '@/components';
import { SmallSpinner, Spinner, SpinnerSize } from '@/components/Spinners/Spinner';
import { useLoadAreas } from '@/hooks';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TabCompany } from '../layout/TabCompany';
import { CardLicense } from '../components/CardLicense';
import { useAreaLicenses } from '../hooks/useAreaLicenses';
import { LicenseProps } from '@/pages/licenses/models/license.model';
import { currencyFormatter } from '@/utilities/currencyFormatter';
import { featuresMap } from '@/pages/licenses/data/features';
import { SelectLicenseModal } from '../modals/SelectLicense.modal';
import { AreaLicense } from '../models/areaLicense.model';
import toast from 'react-hot-toast';
import { t } from 'i18next';

interface StatusComponentProps {
  status: 'loading' | 'withoutLicense' | 'withLicense' | 'canceled' | 'selectingLicense' | null;
  data: {
    areaLicense?: AreaLicense;
    licenses?: LicenseProps[];
    isLoading: boolean;
    areaId: number | string;
    saveLicense: (areaId: string | number, licenseCode: string) => Promise<void>;
  };
}

interface StatusMessagesProps {
  withoutLicense: React.JSX.Element;
  withLicense: React.JSX.Element;
}

interface StatusMessagesProps {
  [key: string]: React.ReactNode;
}

const StatusComponent: React.FC<StatusComponentProps> = ({ status, data }) => {
  const [isOpen, setIsOpen] = useState<LicenseProps | undefined>();

  if (data.isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <SmallSpinner />
      </div>
    );
  }

  const statusMessages: StatusMessagesProps = {
    withoutLicense: (
      <div className='flex items-start flex-wrap gap-10 '>
        {data?.licenses ? (
          data.licenses.map((license) => {
            return (
              <div key={license.id}>
                <CardLicense
                  price={currencyFormatter(license.currency, license.basePrice)}
                  annualPrice={currencyFormatter(
                    license.currency,
                    (+license.basePrice * +license.annualDiscount) / 100 + +license.basePrice
                  )}
                  title={license.name}
                  users={license.activeUsers}
                  modules={license.activeModules}
                  videos={license.activeVideos}
                  buttonAction={() => setIsOpen(license)}
                  message={'test '}
                  features={license.features.map((feature) => featuresMap[feature].label)}
                />
              </div>
            );
          })
        ) : (
          <Spinner />
        )}
      </div>
    ),
    withLicense: (
      <>
        <article className='bg-functional-el-l h-full overflow-auto rounded-xl'>
          <div className='sticky top-0 flex items-center justify-between px-14 py-7'>
            <div>
              <p className='font-bold text-2xl text-[#1E293B]'>{data.areaLicense?.license?.name}</p>
              <p className='font-medium text-[#5F6060]'>{data?.areaLicense?.license?.description}</p>
            </div>
            <div className='flex'>
              <div>
                <p className='font-semibold text-[#5F6060]'>{`${t('generics.start')} ${
                  data.areaLicense?.startDate
                }`}</p>
                <p className='font-semibold text-[#5F6060]'>{`${t('generics.end')} ${data.areaLicense?.endDate}`}</p>
              </div>
              <p className='font-medium text-[#5F6060] bg-success-l px-2 pt-3 text-center rounded ml-4'>
                {t('generics.activated')}
              </p>
            </div>
          </div>
          <div className='divider mx-3'></div>
          <div>
            <div className='flex justify-around'>
              <ul className='my-3'>
                {data.areaLicense?.license?.features?.map((feature) => (
                  <li key={feature} className='flex items-center'>
                    <span className='material-symbols-outlined mx-2'>done</span> {featuresMap[feature].label}
                  </li>
                )) ?? null}
                <li className='flex items-center'>
                  <span className='material-symbols-outlined mx-2'>done</span>{' '}
                  {`${data.areaLicense?.license?.activeUsers} ${t('generics.users')}`}
                </li>
                <li className='flex items-center'>
                  <span className='material-symbols-outlined mx-2'>done</span>
                  {`${data.areaLicense?.license?.activeModules} ${t('generics.modules')}`}
                </li>
                <li className='flex items-center'>
                  <span className='material-symbols-outlined mx-2'>done</span>
                  {`${data.areaLicense?.license?.activeVideos} ${t('generics.videos')}`}
                </li>
              </ul>
              <ul className='my-3'>
                <li className='flex items-center my-2'>
                  <span className='material-symbols-outlined mx-2'>warning</span>{' '}
                  {`${t('pages.companies.additionalPriceUser')}  ${
                    data.areaLicense?.license
                      ? currencyFormatter(
                          data.areaLicense?.license.currency,
                          data.areaLicense?.license?.pricePerExtraUser
                        )
                      : null
                  }`}
                </li>
                <li className='flex items-center my-2'>
                  <span className='material-symbols-outlined mx-2'>warning</span>
                  {`${t('pages.companies.additionalPriceModule')}  ${
                    data.areaLicense?.license
                      ? currencyFormatter(
                          data.areaLicense?.license.currency,
                          data.areaLicense?.license?.pricePerExtraModule
                        )
                      : null
                  }`}
                </li>
                <li className='flex items-center my-2'>
                  <span className='material-symbols-outlined mx-2'>warning</span>
                  {`${t('pages.companies.additionalPriceVideo')}  ${
                    data.areaLicense?.license
                      ? currencyFormatter(
                          data.areaLicense?.license.currency,
                          data.areaLicense?.license?.pricePerExtraVideo
                        )
                      : null
                  }`}
                </li>
              </ul>
            </div>
          </div>
        </article>
      </>
    )
  };

  return (
    <>
      <div>{status !== null ? statusMessages[status as keyof StatusMessagesProps] : null}</div>
      {isOpen ? (
        <SelectLicenseModal
          {...{
            data: isOpen,
            isOpen: !!isOpen,
            close: () => setIsOpen(undefined),
            save: () => {
              if (isOpen.code) {
                data.saveLicense(data.areaId, isOpen.code);
              } else {
                toast.error(t('pages.companies.notConfirmLicense'));
              }
            },
            label: ''
          }}
        />
      ) : null}
    </>
  );
};

let perPage: number = -1;
export const LicensesCompany = () => {
  const { state } = useLocation();

  const {
    loadAreas,
    areas,
    isLoading: isLoadingAreas,
    resetInitalAreas,
    getArea,
    area,
    resetInitialArea
  } = useLoadAreas(state.companyId);

  const {
    resetLicensesData,
    saveLicense,
    startLoadLicenses,
    startLoadAreaLicense,
    isLoading,
    licenses,
    license,
    status
  } = useAreaLicenses(state.companyId);

  const handleSelectArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
    getArea(event.target.value);
  };

  useEffect(() => {
    loadAreas(perPage);
    return () => {
      resetInitalAreas();
      resetInitialArea();
    };
  }, [state.companyId]);

  useEffect(() => {
    if (area.licenseActive) {
      startLoadAreaLicense(area.id as string);
    } else {
      startLoadLicenses();
    }
    return () => {
      resetLicensesData();
    };
  }, [area?.id]);

  if (isLoadingAreas) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <SmallSpinner />
      </div>
    );
  }

  return (
    <>
      <TabCompany companyId={state?.companyId} />
      <article className='bg-[#F4F8FE] h-full overflow-auto'>
        <div className='bg-functional-el-l flex justify-between items-center px-10'>
          <div className=' flex items-center justify-start py-7'>
            <Link to='/empresas'>
              <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
            </Link>
            <div>
              <p className={`font-bold text-2xl text-functional-d-l`}>{t('pages.companies.licenseArea')}</p>
              <p className={`font-medium text-functional-d-l`}>{t('pages.companies.lecenseMessage')}</p>
            </div>
          </div>
          <div className=''>
            {/* {!isLoadingAreas ? <Select onChange={handleSelectArea} defaultValue={'default'} className="w-52 p-4 h-14 ml-3" options={areas} optionmap={(value) => value.name} optionValueMap={(value) => value.id} optionKeyMap={(value) => value.id} value={!selectedArea ? 'default' : selectedArea} /> : */}
            {!isLoadingAreas ? (
              <Select
                onChange={handleSelectArea}
                value={area?.id ?? 'default'}
                className='w-52 p-4 h-14 ml-3'
                options={areas}
                optionmap={(value) => value.name}
                optionValueMap={(value) => value.id}
                optionKeyMap={(value) => value.id}
              />
            ) : (
              <div className='px-16'>
                <SpinnerSize className='w-10' />
              </div>
            )}
          </div>
        </div>
        <div className='p-10'>
          {area.id ? (
            <StatusComponent
              status={status}
              data={{ areaId: area?.id, isLoading, saveLicense, areaLicense: license, licenses }}
            />
          ) : (
            <div className='justify-center items-center'>
              <p className='text-3xl'>{t('pages.companies.selectArea')}</p>
            </div>
          )}
        </div>
      </article>
    </>
  );
};
