import { DashboardData } from "../models/data.model";


export const loadDataGeneralAdapter = (data: DashboardData) => {
    return ({
        countLessons: data.count_lessons,
        countModules: data.count_modules,
        countUsers: data.count_users,
        percentageLessons: data.percentage_lessons,
        usersCompletedProfile: data.users_completed_profile,
        timeAveragePerDay: data.time_average_per_day,
        usersWith1Lesson: data.users_with_1_lessons,
        userWith2Lessons: data.user_with_2_lessons,
        userWith3Lessons: data.user_with_3_lessons,
        userWithMoreThan3Lessons: data.user_with_more_than_3_lessons,
        userWith1Module: data.user_with_1_module,
        lessonsCompleted: data.lessons_completed,
        lessonsStarted: data.lessons_started,
        countAreas: data.count_areas,
        countPoints: data.count_points,
        pointsRedeemed: data.points_redeemed,
        expectedAreasUsers: data.expected_areas_users,
        companiesCompletedInfo: data.companies_completed_info,
    })
}

export const loadDataManagementAdapter = (data: DashboardData) => {
    return ({
        lessonsStartedVsLessonsCompleted: data.lessons_started_vs_lessons_completed.map(({ lesson_users, users_completed, lesson_name }) =>
            ({ lessonUsers: lesson_users, usersCompleted: users_completed, lessonName: lesson_name })),
        usersAreaVsExpectedAreaUsers: data.users_area_vs_expected_area_users.map(({ area_name, users_area, expected_areas_users }) =>
            ({ areaName: area_name, usersArea: users_area, expectedAreasUsers: expected_areas_users })),
        usersVsLessons: data.users_vs_lessons.map(({ count_users, count_completed_lessons, date }) =>
            ({ countUsers: count_users, countCompletedLessons: count_completed_lessons, date })),
    })
}
