import { createSlice } from '@reduxjs/toolkit';

// creating, viewing, editing,

export const partsSlice = createSlice({
  name: 'partes',
  initialState: {
    status: 'viewing',
    isLoading: false,
    videoSelected: '',
    pagination: {},
    errorMessage: null,
    part: undefined,
    parts: [],
    partSelected: '',
    duration: '',
    videoIncorrectSelected: '',
    isLoadingSavePart: false
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    selectVideo: (state, action) => {
      if (action.payload.label === 'videoIncorrectSelected') {
        state.videoIncorrectSelected = action.payload.videoSelected;
      }
      if (action.payload.label === 'videoSelected') {
        state.videoSelected = action.payload.videoSelected;
        state.duration = action.payload.videoSelected.duration;
      }
    },
    createPart: (state, action) => {
      state.part = action.payload;
      state.isLoading = false;
    },
    loadParts: (state, action) => {
      state.parts = action.payload.items;
      state.pagination = action.payload.pagination;
      state.isLoading = false;
    },
    selectPart: (state, action) => {
      state.partSelected = action.payload;
      state.status = 'editing';
    },
    setSelectPart: (state, action) => {
      state.part = action.payload;
      state.duration = action.payload.duration;
      state.videoIncorrectSelected = action.payload.quiz?.not_correct_video_url;
      state.videoSelected = action.payload.video_url;
      state.status = 'editing';
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    resetPart: (state) => {
      state.part = undefined;
      state.duration = '';
      state.videoIncorrectSelected = '';
      state.videoSelected = '';
      state.isLoading = false;
      state.status = 'viewing';
    },
    resetAll: (state) => {
      state.status = 'viewing';
      state.videoSelected = '';
      state.pagination = {};
      state.errorMessage = null;
      state.part = undefined;
      state.parts = [];
      state.partSelected = '';
      state.duration = '';
      state.videoIncorrectSelected = '';
      state.isLoadingSavePart = false;
    },
    setIsLoadingSavePart: (state, action) => {
      state.isLoadingSavePart = action.payload;
    },
    setPart: (state, action) => {
      state.duration = action.payload.duration;
      state.videoIncorrectSelected = action.payload.videoIncorrectSelected;
      state.videoSelected = action.payload.videoSelected;
    },
    setDeletePart: (state, action) => {
      const ix = state.parts.findIndex(({ slug }: { slug: number }) => slug === action.payload);
      state.parts.splice(ix, 1);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setDeletePart,
  setSelectPart,
  setPart,
  setIsLoading,
  setError,
  selectVideo,
  createPart,
  loadParts,
  selectPart,
  setStatus,
  resetPart,
  resetAll,
  setIsLoadingSavePart
} = partsSlice.actions;
