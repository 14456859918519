import { useState } from "react"
import { loadCompaniesAdapter } from "@/adapters/loadCompanies.adapter"
import useAxiosPrivate from "./useApiV2Private"

export interface CompanyProp {
    id: number
    name: string
    slug: string
}

export const useLoadCampanies = () => {

    const apiV2 = useAxiosPrivate()
    
    const [companies, setCompanies] = useState<CompanyProp[]>()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const loadCompanies = async () => {
        setIsLoading(true)
        try {
            const resp = await apiV2.get(`/companies`, { params: { per_page: 10000 } })
            setCompanies(loadCompaniesAdapter(resp.data))
            return loadCompaniesAdapter(resp.data)
        } catch (error) {
            setErrorMessage('No cargan los datos')
        } finally {
            setIsLoading(false)
        }
    }

    return {loadCompanies, companies, isLoading, errorMessage}

}
