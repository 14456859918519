import { Dispatch, RefObject, SetStateAction } from "react"
import { Objective } from "../interfaces/objectives"
import { TYPE_OBJECTIVES } from "@/pages/modules/constants"


export const patchDataUpdateObjective = async (setTypeSelected: Dispatch<SetStateAction<string | undefined>>, objective: Objective, formRef: RefObject<HTMLFormElement>, setAskActualState: Dispatch<SetStateAction<boolean>>, setOptionsIds: Dispatch<SetStateAction<string[]>>, setaskFinalState: Dispatch<SetStateAction<boolean>>) => {
  setTypeSelected(objective.type)
  const current: HTMLFormElement | null = formRef.current
  if (!current) return
  const selectElement: HTMLSelectElement | null = current.querySelector('.select-type');
  const selectArea: HTMLSelectElement | null = current.querySelector('select[name="area"]');
  const inputObjetiveQuestion: HTMLInputElement | null = current.querySelector('input[name="objectiveTitle"]');
  const inputObjetiveDescription: HTMLInputElement | null = current.querySelector('input[name="objectiveDescription"]');
  // Stablish general data
  if (selectElement) selectElement.value = objective.type.toString()
  if (selectArea) selectArea.value = objective.area.toString()
  if (inputObjetiveQuestion) inputObjetiveQuestion.value = objective.config.question
  if (inputObjetiveDescription) inputObjetiveDescription.value = objective.config.question_description
  await new Promise((resolve) => setTimeout(resolve, 500))
  // Stablish data for numeric objectives
  if (objective.type === TYPE_OBJECTIVES.NUMBER_RANGE) {
    const inputMin: HTMLInputElement | null = current.querySelector('input[name="objectiveMin"]');
    const inputMax: HTMLInputElement | null = current.querySelector('input[name="objectiveMax"]');
    const checkActualState: HTMLInputElement | null = current.querySelector('input[name="objectiveCheckboxActualState"]');
    if (inputMin) inputMin.value = String(objective.config.min_value ?? 0)
    if (inputMax) inputMax.value = String(objective.config.max_value ?? 0)
    if (checkActualState) checkActualState.checked = objective.config.target_active ?? false
    setAskActualState(objective.config.target_active ?? false)
    await new Promise((resolve) => setTimeout(resolve, 500))
    if (objective.config.target_active === true) {
      const inputActualStateQuestion: HTMLInputElement | null = current.querySelector('input[name="actualStateQuestion"]');
      const inputActualStateDescription: HTMLInputElement | null = current.querySelector('input[name="actualStateDescription"]');
      if (inputActualStateQuestion) inputActualStateQuestion.value = objective.config.question_target ?? ''
      if (inputActualStateDescription) inputActualStateDescription.value = objective.config.target_description ?? ''
      setaskFinalState(objective.config.final_active ?? false)
      await new Promise((resolve) => setTimeout(resolve, 500))
      if (objective.config.final_active === true) {
        const checkFinalState: HTMLInputElement | null = current.querySelector('input[name="objectiveCheckboxFinalState"]');
        const inputFinalStateQuestion: HTMLInputElement | null = current.querySelector('input[name="finalStateQuestion"]');
        const inputFinalStateDescription: HTMLInputElement | null = current.querySelector('input[name="finalStateDescription"]');
        if (checkFinalState) checkFinalState.checked = objective.config.final_active ?? false
        if (inputFinalStateQuestion) inputFinalStateQuestion.value = objective.config.final_question ?? ''
        if (inputFinalStateDescription) inputFinalStateDescription.value = objective.config.final_description ?? ''
        return
      }
    }
    return
  }
  if (objective.type === TYPE_OBJECTIVES.SELECT) {
    if (!objective.config.question_options || !Array.isArray(objective.config.question_options)) {
      setOptionsIds([crypto.randomUUID()])
      return
    }
    const length = objective.config.question_options.length
    for (let i = 0; i < length; i++) {
      if (i === 0) continue
      setOptionsIds((prev) => [...prev, crypto.randomUUID()])
    }
    await new Promise((resolve) => setTimeout(resolve, 500))
    const options: string[] = objective.config.question_options
    const inputs: HTMLInputElement[] = Array.from(current.querySelectorAll('.input-option'))
    inputs.forEach((input, index) => {
      input.value = options[index]
    })
    return
  }
}