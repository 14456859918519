

export const OPTION_TYPE_OBJECTIVES: {
  value: string;
  label: string;
}[] = [
  {
    value: 'number-range',
    label: 'Rango numérico'
  },
  {
    value: 'select',
    label: 'Selección'
  }
]

export const TYPE_OBJECTIVES = {
  NUMBER_RANGE: 'number-range',
  SELECT: 'select'
}