import toast from 'react-hot-toast';
import cn from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCrudModule } from '../hooks/useCrudModule';
import { SetImageProps, loadImageWithValidationsProps } from '@/hooks/useLoadImage';
import { Select } from '@/components/Selects/Select';
import { PrimaryButton } from '@/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/Buttons/SecondaryButton';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { InputText } from '@/components/Inputs/InputText';
import { Spinner } from '@/components/Spinners/Spinner';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { TextArea } from '@/components/Inputs/TextArea';
import { Module } from '@/pages/usuarios/models/users.models';
import { Area } from '@/models/areas.models';
import { getEnvVariables } from '@/utilities';
import { t } from 'i18next';
import { ObjectivesBox } from '../components/Objectives/ObjectivesBox';

const defaultValuesAdapter = (module: any): any => {
  return {
    name: module.name,
    description: module.description,
    isActive: module.isActive
  };
};

interface ModuloFormProp {
  image: SetImageProps | undefined;
  status: string;
  module: Module | any; //cambiar
  areas: any; // cambiar
  editing: () => void;
  viewing: () => void;
  updateModule: (data: any) => Promise<
    | {
      message: string;
    }
    | undefined
  >;
  createModule: (data: any) => Promise<
    | {
      message: string;
    }
    | undefined
  >;
  setImage: React.Dispatch<React.SetStateAction<SetImageProps | undefined>>;
  loadImageWithValidations: loadImageWithValidationsProps;
}

interface ModuleProps {
  name: string;
  description: string;
  isActive?: boolean;
}

// TODO: Añadir modificar usuario
const ModuloForm: React.FC<ModuloFormProp> = ({
  loadImageWithValidations,
  status,
  module,
  editing,
  viewing,
  updateModule,
  createModule,
  areas,
  setImage,
  image
}) => {
  const infoStatus: any = {
    viewing: {
      title: t('pages.modules.moduleInformation'),
      message: ''
    },
    editing: {
      title: t('pages.modules.modifyModule'),
      message: t('pages.modules.modifyMessage')
    },
    creating: {
      title: t('pages.modules.addModule'),
      message: t('pages.modules.modifyMessage')
    }
  };

  const [optionsArea, setOptionArea] = useState<string[]>(
    module?.companyAreas?.map(({ id }: { id: number }) => id.toString()) ?? []
  );

  const [disabledButton, setDisabledButton] = useState(false);

  const { VITE_PUBLIC_IMAGE } = getEnvVariables();

  const navigate = useNavigate();

  const areaMapId = areas?.reduce((acc: any, cur: Area) => ({ ...acc, [cur.id]: cur }), {}) || {};

  const isCreating = status === 'creating';

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ModuleProps>({
    defaultValues: { ...defaultValuesAdapter(module) }
  });
  const disabled = status === 'viewing';

  const onSubmit = async (data: any) => {
    setDisabledButton(true);
    if (typeof image?.image_path === 'string') {
      if (status === 'editing' && optionsArea.length > 0) {
        updateModule({ ...data, areas: optionsArea }).then((e) => {
          if (e?.message === 'success') {
            navigate('/modulos');
          }
        });
      } else if (isCreating && optionsArea.length > 0 && image?.image_path) {
        createModule({ ...data, areas: optionsArea }).then((e) => {
          if (e?.message === 'success') {
            navigate('/modulos');
          }
        });
      } else {
        if (optionsArea.length < 1) {
          toast.error(t('pages.modules.errorSelect'));
        } else {
          toast.error(t('pages.modules.notPossible'));
        }
        setDisabledButton(false);
      }
    } else {
      setDisabledButton(false);
      toast.error(t('errorMessage.selectPicture'));
    }
  };

  const handleChooseLogo = (e: any) => {
    e.preventDefault();
    const file = e.target.files?.[0] || undefined;
    if (file) {
      loadImageWithValidations(file);
    }
  };

  const handleChangeStatus = (event: React.FormEvent) => {
    event.preventDefault();
    disabled ? editing() : viewing();
  };

  const handleSelectOptionArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event?.target.value;
    const valueExist = optionsArea.find((option) => value === option);
    if (value && !disabled && !valueExist) {
      setOptionArea((options) => [...options, value]);
    }
  };

  const handleDeleteOptionArea = (value: string) => {
    if (!disabled) {
      setOptionArea((options) => options.filter((option) => option !== value));
    }
  };

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault();
    if (isCreating) {
      navigate('/modulos');
    } else {
      setOptionArea(module?.companyAreas?.map(({ id }: { id: number }) => id.toString()) ?? []);
      reset({ ...defaultValuesAdapter(module) });
      setImage(() => ({ image_id: module.imageId, image_path: `${VITE_PUBLIC_IMAGE}/${module.imageId}` }));
      viewing();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='bg-functional-el-l flex items-center justify-start px-10 py-7'>
          <Link to='/modulos'>
            <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
          </Link>
          <div>
            <p className={`font-bold text-2xl text-functional-d-l`}>{infoStatus[status].title}</p>
            <p className={`font-medium text-functional-d-l`}>{infoStatus[status].message}</p>
          </div>
        </div>
        <div className='m-11 p-10 bg-functional-el-l rounded-3xl shadow-lg'>
          <div className='flex justify-between'>
            <div>
              <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.companies.generalData')}</p>
              <p className='font-medium text-functional-d-l'>{t('pages.modules.enterData')}</p>
            </div>
            {!isCreating ? (
              <PrimaryButton onClick={handleChangeStatus}>
                {disabled ? t('generics.edit') : t('generics.seeInformation')}
              </PrimaryButton>
            ) : (
              <></>
            )}
          </div>
          <div className={cn('grid grid-cols-2', { 'grid-rows-3': true })}>
            <div className='p-4'>
              <InputText
                className='w-5/6'
                disabled={disabled}
                register={() => register('name', { required: t('errorMessage.requiredField') })}
                placeholder={t('generics.name')}
              />
              {errors.name ? <p className='text-warning-d'>{errors.name.message}</p> : null}
            </div>
            <div className='p-4 row-span-2'>
              <TextArea
                disabled={disabled}
                register={() => register('description', { required: t('errorMessage.requiredField') })}
                placeholder={t('generics.description')}
              />
              {errors.description ? <p className='text-warning-d'>{errors.description.message}</p> : null}
            </div>
            <div className='p-4 w-5/6 row-span-2'>
              <TitleInput>{t('pages.modules.moduleImage')}</TitleInput>
              <div className='flex flex-col items-center justify-center'>
                <div className={cn('w-full my-2 h-[150px] row-span-2')}>
                  {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                  <img
                    src={image?.image_path ?? '/imgLayout.png'}
                    alt=''
                    className='row-span-2 w-full h-full object-contain'
                  />
                </div>
                {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
                <input
                  disabled={disabled}
                  type='file'
                  id='actual-btn'
                  accept='image/jpg, image/jpeg, image/png'
                  onChange={handleChooseLogo}
                  hidden
                />
                <label
                  htmlFor='actual-btn'
                  className={cn(
                    'self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14',
                    { 'cursor-no-drop': disabled, 'cursor-pointer': !disabled }
                  )}>
                  {/* <PlusIcon className="h-5 w-5 bg-functional-el-l text-functional-l-d mr-3.5 rounded-sm" /> */}
                  <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5'>add</span>
                  {t('generics.upload')} (512x512px en jpg o png)
                </label>
              </div>
            </div>
            <div className='p-4'>
              <p className='mb-1 text-lg text-black font-semibold'>{t('pages.modules.companyAreas')}:</p>
              <div className='flex flex-col'>
                {/* <Select disabled={disabled} className="w-5/6" options={['Administrador', 'Soporte', 'Creador']} optionValueMap={(value) => value} optionmap={(value) => value} register={() => register('role')} /> */}
                <Select
                  value={'default'}
                  disabled={disabled}
                  className='w-5/6'
                  options={areas}
                  optionValueMap={(value) => value.id}
                  optionmap={(value) => value.name}
                  optionKeyMap={(value) => value.id}
                  onChange={handleSelectOptionArea}
                />
                <div className='flex flex-wrap mt-2 w-5/6'>
                  {optionsArea.length > 0 ? (
                    optionsArea.map((option) => (
                      <div
                        onClick={() => handleDeleteOptionArea(option)}
                        key={Math.random()}
                        className='h-10 mt-2 mr-2 bg-functional-l-l rounded-lg p-1 flex justify-center items-center'>
                        <p>{areaMapId[option]?.name ?? option}</p>
                        <div className='border border-functional-l-l h-full'></div>
                        <i className='mx-1 fa-solid fa-xmark hover:text-error-m' />
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div className='flex items-center p-4'>
              <TitleInput>{t('generics.active')}</TitleInput>
              <div className='mx-2'></div>
              <ToggleSwitch disabled={disabled} message='' register={() => register('isActive')} />
            </div>
          </div>
          {!disabled ? (
            <div className='flex justify-end'>
              <PrimaryButton disabled={disabled || disabledButton} type='submit'>
                {t('pages.modules.saveModule')}
              </PrimaryButton>
              <SecondaryButton className='px-8' disabled={disabled} onClick={handleCancel}>
                {isCreating ? t('generics.goBack') : t('generics.cancel')}
              </SecondaryButton>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export const CRUDModulos = () => {
  const { state } = useLocation();

  const {
    // propiedades
    image,
    module,
    status,
    isLoading,
    areas,
    // Metodos,
    editing,
    viewing,
    updateModule,
    createModule,
    setImage,
    loadImageWithValidations
  } = useCrudModule(state.moduleSelected);

  const loading = !isLoading && areas && areas?.length > 0;
  return (
    <article className='bg-functional-el-m'>
      {loading ? (
        <>
          <ModuloForm
            {...{
              loadImageWithValidations,
              status,
              module,
              image,
              editing,
              viewing,
              updateModule,
              createModule,
              areas,
              setImage
            }}
          />
          {
            status === 'viewing' && (
              <ObjectivesBox moduleSlug={module.slug} areas={areas} />
            )
          }
          {
            status !== 'viewing' && (
              <div className="px-10 pb-10">
                <div className='p-10 bg-functional-el-l rounded-3xl shadow-lg'>
                  <div className="title">
                    <div className='flex justify-between'>
                      <div>
                        <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.modules.objectives')}</p>
                        <p className='font-medium text-functional-d-l'>{t('pages.modules.textCreateDescriptionModule')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </>
      ) : (
        <Spinner />
      )}
    </article>
  );
};
