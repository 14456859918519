import { User } from '@/models/user.model';
import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
    status: 'not-authenticated' | 'authenticated' //not-authenticated - authenticated
    user:User
    errorMessage: Error | null,
    statusHttp:number
}



export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated', //not-authenticated - authenticated
        user:{},
        errorMessage: null,
        statusHttp:0
    },
    reducers: {
        login: (state, { payload }) => {
            state.status = 'authenticated' //not-authenticated - authenticated
            state.user = payload.user
            state.errorMessage = null
            state.statusHttp=200
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated' //not-authenticated - authenticated
            state.user = {}
            state.errorMessage = payload?.errorMessage || null
            state.statusHttp = payload?.statusHttp
        },
        checkingCredentials: (state) => {
            state.status = 'checking'
        },
        clearErrorMessage: (state) => {
            state.errorMessage = null
        },
        updateUser: (state, action) =>{
            state.user = {...state.user, ...action.payload}
        }

    }
});

export const { login, logout, checkingCredentials, clearErrorMessage, updateUser } = authSlice.actions;