import { Response } from "../interfaces/survey.interface"

interface Props {
  response?: Response,
  closeFunction: () => void
}

export const ViewResponse = ({ response, closeFunction }: Props) => {
  return (
    <div className="w-[100%] m-auto max-w-[800px] bg-white p-4 rounded-lg pt-4 pb-4 h-auto max-h-[800px] overflow-auto">
      {/* Título */}
      <h2 className="text-lg font-bold mb-4 min-w-[200px] mt-4">Detalles de la Respuesta</h2>

      {/* ID */}
      <div className="flex flex-col items-center mb-3 text-center gap-x-4">
        <p className="text-lg text-black underline">Id</p>
        <p className="text-lg">{response?.id}</p>
      </div>

      {/* Nombre del usuario */}
      <div className="flex flex-col items-center mb-3 text-center gap-x-4">
        <p className="text-lg text-black underline">Nombre</p>
        <p className="text-lg">{response?.user.name} {response?.user.last_name_father ?? ''} {response?.user.last_name_mother ?? ''}</p>
      </div>

      {/* Respuesta */}
      <div className="flex flex-col items-center mb-3 text-center gap-x-4">
        <p className="text-lg text-black underline">Respuesta</p>
        <p className="text-lg max-w-[500px]">{response?.response_text}</p>
      </div>

      {/* Fecha */}
      <div className="flex flex-col items-center mb-3 text-center gap-x-4">
        <p className="text-lg text-black underline">Fecha</p>
        <p className="text-lg">{new Date(response?.created_at ?? '').toLocaleDateString()}</p>
      </div>

      <button onClick={closeFunction}
        type="button"
        className="block m-auto bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-800 mt-4">Cerrar</button>
    </div>
  )
}
