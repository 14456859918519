import { LicenseProps } from '@/pages/licenses/models/license.model';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    crudDisabled: boolean,
    isLoading: boolean,
    errorMessage: string | null,
    status: 'viewing' | 'editing' | 'creating' | 'loading' | null,
    license: LicenseProps | {}
    isLoadingRoles?: boolean,
    roles: any[]
}

const initialState: InitialStateProps = {
    crudDisabled: false,
    isLoading: false,
    errorMessage: null,
    status: 'viewing',
    license: {},
    isLoadingRoles: false,
    roles: []
}

export const crudLicenseSlice = createSlice({
    name: 'crudLicense',
    initialState,
    reducers: {
        loadLicense: (state, action) => {
            state.license = action.payload
            state.status = 'viewing'
            state.isLoading = false
        },
        createLicense: (state, action) => {
            state.isLoading = false
            state.license = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        resetLicense: (state) => {
            state.license = {}
            state.isLoading = false
        },
        setCrudDisabled: (state, action) => {
            state.crudDisabled = action.payload
        },
        reset: (state) => {
            const newState = initialState;
            state.crudDisabled = newState.crudDisabled;
            state.isLoading = newState.isLoading;
            state.errorMessage = newState.errorMessage;
            state.status = newState.status;
            state.license = newState.license;
            state.isLoadingRoles = newState.isLoadingRoles;
            state.roles = newState.roles;
        },
        setRoles: (state, action) => {
            state.roles = action.payload
            state.isLoadingRoles = false
        },
        setIsLoadingRoles: (state, action) => {
            state.isLoadingRoles = action.payload
        },
    }
});

export const { setRoles, setIsLoadingRoles, loadLicense, reset, createLicense, setCrudDisabled, setIsLoading, resetLicense, setError, setStatus } = crudLicenseSlice.actions;

