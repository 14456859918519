import useAxiosPrivate from "@/hooks/useApiV2Private"
import { useState } from "react"
import toast from 'react-hot-toast'
import { Objective } from "../interfaces/objectives"

export const useObjectives = () => {
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [objectives, setobjectives] = useState<Objective[]>([])  
  const apiV2 = useAxiosPrivate()

  /**
   * Loads objectives for a given module slug.
   * @param moduleSlug - The slug of the module.
   */
  const loadObjectives = async (moduleSlug?: string) => {
    if (!moduleSlug) return;
    setobjectives([]);
    try {
      setisLoading(true);
      const resp = await apiV2.get(`/${moduleSlug}/objectives`);
      const data: Objective[] = resp.data;
      setobjectives(data);
    } catch (err) {
      console.error("Error al cargar los objetivos", err);
      toast.error('Error al cargar los objetivos');
    } finally {
      setisLoading(false);
    }
  }

  /**
   * Deletes an objective from a module.
   * @param moduleSlug - The slug of the module.
   * @param objectiveId - The ID of the objective to delete.
   */
  const deleteObjective = async (moduleSlug: string, objectiveId: string) => {
    if (!moduleSlug || !objectiveId) return;
    try {
      await apiV2.delete(`/${moduleSlug}/objectives/${objectiveId}`);
      toast.success('Objetivo eliminado con éxito');
      await loadObjectives(moduleSlug);
    } catch (err) {
      console.error("Error al eliminar el objetivo", err);
      toast.error('Error al eliminar el objetivo');
    }
  }

  /**
   * Creates an objective for a given module.
   * 
   * @param moduleSlug - The slug of the module.
   * @param data - The data for the objective.
   * @returns A Promise that resolves when the objective is created.
   */
  const createObjective = async (moduleSlug: string, data: any) => {
    if (!moduleSlug || !data) return;
    setisLoading(true);
    try {
      await apiV2.post(`/${moduleSlug}/objectives`, data);
      toast.success('Objetivo creado con éxito');
    }
    catch(err) {
      console.error("Error al crear el objetivo", err);
      toast.error('Error al crear el objetivo');
    }
    finally {
      setisLoading(false);
    }
  }

  /**
   * Updates an objective.
   * @param moduleSlug - The slug of the module.
   * @param objectiveId - The ID of the objective.
   * @param data - The data to update the objective with.
   */
  const updateObjective = async (moduleSlug: string, objectiveId: string, data: any) => {
    if (!moduleSlug || !objectiveId || !data) return;
    setisLoading(true);
    try {
      await apiV2.patch(`/${moduleSlug}/objectives/${objectiveId}`, data);
      toast.success('Objetivo actualizado con éxito');
    }
    catch(err) {
      console.error("Error al actualizar el objetivo", err);
      toast.error('Error al actualizar el objetivo');
    }
    finally {
      setisLoading(false);
    }
  }

  return {
    isLoading,
    loadObjectives,
    createObjective,
    deleteObjective,
    updateObjective,
    objectives
  }
}