import { EmpresaInitial } from "../models";

export const loadCompanyAdapter = (company:EmpresaInitial) => {
    return ({
        ...company,
        default_country_id: company.countries?.find((country) => country.default)?.id ?? null,
        country_ids: company.countries?.map((country) => country.id) ?? [],
        // peopleCounter: 0,
        // counter2: 0,
        // counter3: 0,
        // initialDate: '2023-01-00',
        // lastUpdate: '2023-00-00',
        // typeLicense: 'Premium (1-2500)'
    })
}