export const currencyFormatter = (currency: string, amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ?? 'USD',
    minimumFractionDigits: 2
  }).format(amount);
}

export const toCurrency = (currency: string, v: number) => v?.toLocaleString('en-US', {
  style: "currency",
  currency: currency ?? 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
})