import { License } from "../models/license.model"

export const loadLicensesAdapter = (licenses:License[]) => {
  return licenses.map((license: License) => {
    return {
      code: license.code,
      id: license.id,
      name: license.name,
      description: license.description,
      basePrice:license.base_price,
      currency: license.currency || 'USD',
      activeUsers: license.active_users,
      activeModules:license.active_modules,
      activeVideos: license.active_videos,
      pricePerExtraUser: license.price_per_extra_user,
      pricePerExtraModule: license.price_per_extra_module,
      pricePerExtraVideo: license.price_per_extra_video,
      annualDiscount: license.annual_discount,
      features: license.features,
      role:license.role,
      status:license.status,
    } 
  })
}
