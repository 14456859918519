import { Link } from "react-router-dom"

interface OptionsDropdown {
    message: string
    onclick?: () => void
    id: number
    link?: string
}

interface DropdownProps {
    icon: string
    options: OptionsDropdown[]
}


export const DropdownIcon: React.FC<DropdownProps> = ({ options, icon }) => {
    return (
        <div className="dropdown dropdown-end">
            <span tabIndex={0} className="material-symbols-outlined text-primary-l-m text-4xl mx-2 cursor-pointer">{icon}</span>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                {options.map(({ message, onclick, id, link }) => {
                    return <li key={id} onClick={onclick}>{link ? <Link to={link}>{message}</Link> : <a>{message}</a>}</li>
                })}
            </ul>
        </div>
    )
}
