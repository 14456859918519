import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@/components';
import { TextArea } from '@/components/Inputs/TextArea';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { useModal } from '@/hooks/useModal';
import { AppStore } from '@/redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useCrudParts } from '../hooks/useCrudParts';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { t } from 'i18next';

interface FormPartEditProps {
  lessonSlug: string;
}

interface UseFormProp {
  description: string;
}

const messageRequired = `${t('errorMessage.requiredField')} `;

export const FormFinalPartEdit: React.FC<FormPartEditProps> = ({ lessonSlug }) => {
  const { status, part } = useSelector((state: AppStore) => state.parts);
  const isEditing = status === 'editing';
  const isCreating = status === 'creating';

  const [disabledButton, setDisabledButton] = useState(false);

  const [isReward, setIsReward] = useState<boolean>(!!part?.play_id);

  const { deleteReward, saveEditedPart, saveCreatePart, changeStatusViewing } = useCrudParts(lessonSlug);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<UseFormProp>();

  const { startShowModal } = useModal();

  const onSubmit = async (data: any) => {
    setDisabledButton(true);
    if (isEditing) {
      await saveEditedPart({
        ...part,
        ...data,
        redirections: null,
        quiz: null
      });
    } else if (isCreating) {
      await saveCreatePart({
        ...part,
        ...data,
        redirections: null,
        quiz: null,
        is_root: false
      });
    }
    setDisabledButton(false);
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    changeStatusViewing();
  };

  const handleChangeReward = (e: any) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.selectAward'),
      name: 'ChoosePlay'
    });
  };

  const handleEditReward = (e: any) => {
    e.preventDefault();
    if (e.target.checked) {
      startShowModal({
        title: t('pages.lessons.selectAward'),
        name: 'ChoosePlay'
      });
    } else {
      deleteReward();
    }
  };

  const partSlug = part?.slug;

  useEffect(() => {
    // loadLocalPart(part)
    setValue('description', part?.description);
  }, [lessonSlug, partSlug]);

  useEffect(() => {
    setIsReward(!!part?.play_id);
  }, [part?.play_id]);

  const disabled = false;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p title={isEditing ? part.title : t('generics.new')} className=' text-4xl font-bold line-clamp-2'>{`Configurar ${
        isEditing ? part.title : t('generics.new')
      }`}</p>
      <div className='p-4 w-5/6'>
        <TextArea
          className='w-full'
          disabled={disabled}
          register={() => register('description', { required: messageRequired })}
          placeholder={`${t('generics.description')}*`}
        />
        {errors.description ? <p className='text-warning-d'>{errors.description.message}</p> : null}
      </div>
      <div className='p-4 mt-4 w-5/6'>
        <TitleInput>{t('pages.lessons.selectTypeOptions')}</TitleInput>
        <div className='flex justify-between items-center my-4'>
          <TitleInput>{t('generics.award')}</TitleInput>
          <div className='flex items-center'>
            {isReward ? (
              <button onClick={handleChangeReward} className='justify-self-end mx-4'>
                <span className='material-symbols-outlined'>edit_square</span>
              </button>
            ) : null}
            <ToggleSwitch onChange={handleEditReward} checked={isReward} message='' />
          </div>
        </div>
        <hr />
        {isReward ? <p className='text-xl my-3'>{`${t('pages.lessons.selectedAward')}: ${part?.play?.name}`}</p> : null}
      </div>
      <div className='flex justify-end'>
        <PrimaryButton disabled={disabledButton} type='submit'>
          {t('generics.save')}
        </PrimaryButton>
        <SecondaryButton className='px-5' onClick={handleCancel}>
          {t('generics.cancel')}
        </SecondaryButton>
      </div>
    </form>
  );
};
