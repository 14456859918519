
export const Spinner = () => {
    return (
        <div className="h-screen bg-white">
            <div className="flex justify-center items-center h-full">
                <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
            </div>
        </div>
    )
}

export const SmallSpinner = () => {
    return <div className="bg-white h-20">
        <div className="flex justify-center items-center h-full">
            <img className="h-12 w-12" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
        </div>
    </div>
}

export const SpinnerMedium = () => {
    return (
        <div className="h-40 bg-white">
            <div className="flex justify-center items-center h-full">
                <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
            </div>
        </div>
    )
}

export const SpinnerSize = ({ className }: { className: string }) => {
    return <div className="">
        <div className="flex justify-center items-center h-full">
            <img className={className} src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
        </div>
    </div>
}
