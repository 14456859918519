import { loadAreasAdapter } from "@/adapters/loadAreas.adapter"
import { loadAreaAdapter } from "@/adapters/loadArea.adapter"
import toast from "react-hot-toast"
import { AreaUpdateAndCreateProp } from "@/models/areas.models"
import { useDispatch, useSelector } from "react-redux"
import { AppStore } from "@/redux"
import { setArea, setAreas, setIsloading, toggleActiveArea, setIsLoadingArea, resetArea, resetAreas } from "@/redux/states/areas/areaSlice"
import { paginationAdapter } from "@/adapters/pagination.adapter"
import { usePagination } from "./usePagination"
import useAxiosPrivate from "./useApiV2Private"

let flagToKnowSomethingChange = false
export const useLoadAreas = (companySlug: string) => {

    const apiV2 = useAxiosPrivate()

    const dispatch = useDispatch()

    const { isLoading, area, areas, pagination, isLoadingArea } = useSelector((state: AppStore) => state.areas)

    const { nextPage, previousPage, initialPage, finalPage, changePage, currentPage } = usePagination({ lastPage: pagination.last_page })

    const loadAreas = async (per_page: number) => {
        dispatch(setIsloading(true))
        try {
            if (companySlug) {
                const resp = await apiV2.get(`/companies/${companySlug}/areas`, { params: { per_page, page: currentPage } })
                dispatch(setAreas({ items: loadAreasAdapter(resp.data), pagination: paginationAdapter(resp.data.pagination) }))
            }
            return 'success'
        } catch (error) {
            dispatch(setIsloading(false))
            return 'error'
        }
    }

    const createArea = async (data: AreaUpdateAndCreateProp, pictureCertId?: number | null) => {
        dispatch(setIsloading(true))
        try {
            await apiV2.post(`/companies/${companySlug}/areas`, 
            { 
                name: data.name, 
                info: { desc: data.desc }, 
                is_active: true,
                expected_users: data?.expectedUsers,
                signature_name: data?.signatureName,
                signature_position: data?.signaturePosition,
                certificate_logo: pictureCertId,
                will_grant_certificate: area?.willGrantCertificate
             })
            toast.success('Área creada')
            flagToKnowSomethingChange = !flagToKnowSomethingChange
            return 'success'
        } catch (error) {
            toast.error('El área no ha sido creada correctamente')
            dispatch(setIsloading(false))
            return 'error'
        }
    }

    const updateArea = async (data: AreaUpdateAndCreateProp, pictureCertId?: number | null) => {
        dispatch(setIsloading(true))
        try {
            await apiV2.patch(`/companies/${companySlug}/areas/${area?.id}`, 
            { name: data.name, 
                info: { desc: data.desc }, 
                is_active: area?.isActive,
                expected_users: data?.expectedUsers,
                signature_name: data?.signatureName,
                signature_position: data?.signaturePosition,
                certificate_logo: pictureCertId,
                will_grant_certificate: data.willGrantCertificate
             })
            toast.success('Área actualizada')
            dispatch(setIsloading(false))
            flagToKnowSomethingChange = !flagToKnowSomethingChange
            return 'success'
        } catch (error) {
            toast.error('El área no ha sido actualizada correctamente')
            dispatch(setIsloading(false))
            return 'error'
        }
    }

    const getArea = async (id: string) => {
        dispatch(setIsLoadingArea(true))
        try {
            const resp = await apiV2.get(`/companies/${companySlug}/areas/${id}`)
            dispatch(setArea(loadAreaAdapter(resp.data)))
            return { status: 200, data: loadAreaAdapter(resp.data) }
        } catch (error) {
            toast.error('El área no ha sido cargada, intentelo de nuevo')
            dispatch(setIsLoadingArea(true))
        }
    }

    const toggleActive = async ({ name, id, isActive }: { name: string, id: number, isActive: boolean }) => {
        try {
            await apiV2.patch(`/companies/${companySlug}/areas/${id}`, { name, is_active: !isActive })
            toast.success(`Área ${isActive ? 'desactivada' : 'activada'}`)
            dispatch(toggleActiveArea(id))
            return 'success'
        } catch (error) {
            toast.error('El área no ha sido actualizada correctamente')
            return 'error'
        }
    }

    const resetInitialArea = () => {
        dispatch(resetArea())
    }

    const resetInitalAreas = () => {
        dispatch(resetAreas())
    }

    return {
        loadAreas, updateArea, createArea,
        getArea, toggleActive, resetInitalAreas,
        resetInitialArea, areas, isLoading,
        isLoadingArea, area, nextPage,
        previousPage, initialPage, finalPage,
        changePage, currentPage, pagination,
        flagToKnowSomethingChange
    }

}
