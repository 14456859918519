import { Navigate, Route, Routes } from "react-router-dom"
import { UsuariosApp } from "../pages/UsuariosApp"
import { CRUDUsuarios } from "../pages/CRUDUsuarios"

export const UsuariosRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<UsuariosApp />} />
            <Route path="crud" element={<CRUDUsuarios />} />
            <Route path="/*" element={<Navigate to='/usuarios' />} />
        </Routes>
    )
}
