import { TYPE_OBJECTIVES } from "@/pages/modules/constants"
import { SyntheticEvent } from "react"

interface ResponseValidFormObjectives {
  isValid: boolean,
  data?: any

}

export const validFormObjectives = (typeSelected: string, e: SyntheticEvent, divOptions: HTMLDivElement | null): ResponseValidFormObjectives => {
  const data: any = {}
  if (typeSelected === null || typeSelected === undefined) {
    return { isValid: false }
  }
  data['type'] = typeSelected
  // Validation for type range-number
  const { objectiveMin, objectiveMax, objectiveCheckboxActualState, actualStateQuestion, actualStateDescription, objectiveCheckboxFinalState, finalStateQuestion, finalStateDescription } = e.target as HTMLFormElement
  if (typeSelected === TYPE_OBJECTIVES.NUMBER_RANGE) {
    if (objectiveMin === undefined || objectiveMax === undefined || objectiveCheckboxActualState === undefined) return { isValid: false }
    const minValue = parseInt(objectiveMin.value)
    const maxValue = parseInt(objectiveMax.value)
    if (minValue < 0 || (minValue >= maxValue) || Number.isNaN(minValue) || Number.isNaN(maxValue)) return { isValid: false }
    data['config'] = {}
    data['config']['question_options'] = null
    data['config']['min_value'] = minValue
    data['config']['max_value'] = maxValue
  }
  else if (typeSelected === TYPE_OBJECTIVES.SELECT) {
    if (divOptions === null) return { isValid: false }
    const options = divOptions.querySelectorAll('input')
    const values = Array.from(options).map((option: HTMLInputElement) => option.value)
    if (values.includes('')) return { isValid: false }
    data['config'] = {}
    data['config']['question_options'] = values
    data['config']['min_value'] = null
    data['config']['max_value'] = null
  }

  if (!objectiveCheckboxActualState.checked) {
    data['config']['question_target'] = null
    data['config']['target_description'] = null
    data['config']['final_active'] = false
    data['config']['target_active'] = false
    data['config']['final_question'] = null
    data['config']['final_description'] = null
    return { isValid: true, data }
  }
  if (actualStateQuestion === undefined || actualStateDescription === undefined) return { isValid: false }
  if (actualStateQuestion.value === '' || actualStateDescription.value === '') return { isValid: false }
  data['config']['question_target'] = actualStateQuestion.value
  data['config']['target_description'] = actualStateDescription.value
  data['config']['final_active'] = objectiveCheckboxFinalState.checked
  data['config']['target_active'] = true
  if (!objectiveCheckboxFinalState.checked) {
    data['config']['final_question'] = null
    data['config']['final_description'] = null
    return { isValid: true, data }
  }
  if (finalStateQuestion === undefined || finalStateDescription === undefined) return { isValid: false }
  if (finalStateQuestion.value === '' || finalStateDescription.value === '') return { isValid: false }
  data['config']['final_question'] = finalStateQuestion.value
  data['config']['final_description'] = finalStateDescription.value
  data['config']['target_active'] = true
  return { isValid: true, data }
}