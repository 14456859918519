import { Pagination } from '@/models/pagination.models';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    isLoading: boolean,
    companies: any,
    pagination: Pagination | {},
    errorMessage: string | null,
    per_page: number,
    companySelected: string | null
}

const initialState: InitialStateProps = {
    isLoading: false,
    companies: [],
    pagination: {},
    errorMessage: null,
    per_page: 8,
    companySelected: null
}

export const empresaSlice = createSlice({
    name: 'empresa',
    initialState,
    reducers: {
        loadCompanies: (state, action) => {
            state.companies = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        setIsLoading: (state) => {
            state.isLoading = true
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setSelectCompany: (state, action) => {
            state.companySelected = action.payload
        },
        setResetState: () => initialState
    }
});


// Action creators are generated for each case reducer function
export const { setResetState ,setIsLoading, loadCompanies, setError, setSelectCompany } = empresaSlice.actions;

