import { Navbar } from "@/components/Navbar/Navbar"
import { Sidebar } from "@/components/Sidebar/Sidebar"
import { Spinner } from '@/components/Spinners/Spinner'
import { useConfigDefault } from '@/hooks/useConfigDefault'

export type MainLayoutProps = {
    children: React.ReactElement | React.ReactElement[]
}

const MainLayoutStyle = 'w-screen h-screen bg-[#FFFFFF] flex w-screen'

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {

    const { companies,
        isLoadingSelectCompany,
        isLoading, showSideBar,
        companySlug, isLoadingConfig,
        error, status,
        selectCompany,
        shomSideBarC,
        switchSideBar } = useConfigDefault()

    const handleSwitchSideBar = () => {
        switchSideBar()
    }

    const handleHideSideBar = () => {
        shomSideBarC()
    }

    return (
        <>
            {isLoadingConfig ? <Spinner /> : null}
            {error === 'error' ? <div>Error</div> : null}
            {status === 'success' ?
                <div className={MainLayoutStyle}>
                    {showSideBar ? <Sidebar onClickMenu={handleHideSideBar} /> : null}
                    <div className={`${showSideBar ? 'w-[calc(100%_-_24rem)]' : 'w-full'} h-full`}>
                        <Navbar
                            companySlug={companySlug}
                            selectCompany={selectCompany}
                            onClickMenu={handleSwitchSideBar}
                            companies={companies}
                            isLoading={isLoading} />
                        <div className="h-[calc(100%_-_4rem)] overflow-y-auto">
                            {!(isLoadingSelectCompany) ? children : <Spinner />}
                        </div>
                    </div>
                </div> : null}
        </>
    )
}
export default MainLayout;

