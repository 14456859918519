import { t } from "i18next";

export const headerObjectives = [
  // Type
  { textAlign: 'text-left', name: t('generics.type') },
  // Question
  { textAlign: 'text-left', name: t('generics.question') },
  // areas
  { textAlign: 'text-left', name: t('generics.areas') },
  // status
  { textAlign: 'text-center', name: t('generics.activate') },
  // actions
  { textAlign: 'text-center', name: t('generics.modify') }
]