

export const StatusIndicator: React.FC<{ status?: string }> = ({ status }) => {
  const getStatusStyle = (status?: string) => {
    switch (status) {
      case 'active':
        return { color: 'green', text: 'Activa' };
      case 'inactive':
        return { color: 'red', text: 'Inactiva' };
      case 'draft':
        return { color: 'orange', text: 'Borrador' };
      case 'closed':
        return { color: 'gray', text: 'Cerrada' };
      default:
        return { color: 'black', text: 'Desconocido' };
    }
  };

  const { color, text } = getStatusStyle(status);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: color,
          marginRight: '8px',
        }}
      ></div>
      <p className="text-start">{text}</p>
    </div>
  );
};