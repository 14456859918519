import { SURVEY_STATUS } from "../constants/status"

/**
 * The function `statusConverter` takes an input status string and returns the corresponding value from
 * the `SURVEY_STATUS` object if available, otherwise it returns the input status string itself.
 * @param {string} inputStatus - The `inputStatus` parameter is a string representing the status of a
 * survey.
 * @returns The function `statusConverter` returns the corresponding value from the `SURVEY_STATUS`
 * object if it exists for the inputStatus, otherwise it returns the inputStatus itself.
 */
export const statusConverter = (inputStatus: string): string => {
  const status: string | undefined = SURVEY_STATUS[inputStatus]

  return (status) ? status : inputStatus
}