import { Areas } from "@/models/areas.models"

export const loadAreasAdapter = (data:{items: Areas}) => {
    return data.items.map((area) => ({
        licenseActive: area.license_active,
        name:area.name,
        id: area.id,
        info: area.info,
        userCount: area.users_count ?? 0,
        isActive: area.is_active,
        isPublic: area.is_public,
        expected_users: area.expected_users
    }))
}
