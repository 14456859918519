import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from '@/redux';
import toast from 'react-hot-toast';
import { useLoadImage } from '@/hooks/useLoadImage';
import { useNavigate } from 'react-router-dom';
import { proccessError } from '@/utilities/stringsError';
import useAxiosPrivate from '@/hooks/useApiV2Private';

interface ProfileProp {
   email: string;
   name: string;
}

export const useProfile = () => {
   const apiV2 = useAxiosPrivate();

   const dispatch = useDispatch();

   const navigate = useNavigate();

   const [isLoading, setIsLoading] = useState(false);

   const { image, setImage, uploadImage, loadImageWithValidations } = useLoadImage();

   const updateProfile = async (data: ProfileProp) => {
      setIsLoading(true);
      let respImage: number | undefined;
      try {
         if (image?.file) {
            await uploadImage(image.file).then((e: number) => {
               respImage = e;
            });
         }
         const resp = await apiV2.post(`/my-profile`, { ...data, avatar_id: respImage ?? image?.image_id });

         dispatch(
            updateUser({
               avatar_id: resp.data.avatar_id,
               avatar_path: resp.data.avatar_url,
               default_area_id: resp.data.default_area_id,
               email: resp.data.email,
               name: resp.data.name
            })
         );
      } catch (error) {
         toast.error('Perfil no actualizado');
      } finally {
         setIsLoading(false);
      }
   };

   const updatePassword = async (data: { oldPassword: string; newPassword: string }) => {
      try {
         setIsLoading(true);
         const resp = await apiV2.patch(`/auth/password`, {
            current_password: data.oldPassword,
            new_password: data.newPassword
         });
         if (resp.status === 201) {
            navigate('/perfil');
            toast.success('Contraseña cambiada');
         }
      } catch (error: any) {
         toast(proccessError(error.response.data));
         toast.error('La contraseña no ha sido modificada:');
      } finally {
         setIsLoading(false);
      }
   };

   return { updateProfile, setImage, image, isLoading, updatePassword, loadImageWithValidations };
};
