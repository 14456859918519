interface Pais {
    id:number
    extension: string
    image_path: string
    name: string
    code: string
}

const loadCountriesAdapter = (data:{items:Pais[]}) => {
  return data.items.map((pais) =>  ({
        id:pais?.id,
        name:pais?.name,
        imagePath: pais?.image_path
    })
  )
}

export default loadCountriesAdapter