import toast from 'react-hot-toast'
import { useState } from 'react'

import { Body } from '../models/videos.models.js'
import useAxiosPrivate from '@/hooks/useApiV2Private.js'
import { AppStore } from '@/redux/store.js'
import { useSelector } from 'react-redux'

let controller: AbortController, signal
export const useVideos = () => {

    const { companySlug } = useSelector((state: AppStore) => state.ui)

    const apiV2 = useAxiosPrivate()
    const [videos, setVideos] = useState<Body[]>()
    const [isLoadingVideos, setIsloadingVideos] = useState(false)

    const startLoadVideos = async () => {
        setIsloadingVideos(true)
        try {
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/videos`, {
                signal
            })
            setVideos(resp.data.body)
        } catch (error:any) {
            if (error.response.status === 404) {
                toast.error('La empresa no tiene afiliado un folder de vimeo')
            }
        } finally {
            setIsloadingVideos(false)
        }
    }

    return {
        videos, isLoadingVideos, startLoadVideos
    }
}
