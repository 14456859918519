import { paginationAdapter } from "@/adapters/pagination.adapter";
import useAxiosPrivate from "@/hooks/useApiV2Private";
import { loadLicensesAdapter } from "@/pages/licenses/adapters/loadLicenses.adapter";
import { AppStore } from "@/redux";
import { loadLicense, loadLicenses, resetLicenses, setChangeState, setIsLoading } from "@/redux/states/empresas/areaLicense.slice";
import { useDispatch, useSelector } from "react-redux";
import { loadAreaLicenseAdapter } from "../adapters/loadAreaLicense";
import toast from "react-hot-toast";

let controllerLicenses: AbortController, signalLicenses: AbortSignal
let controllerLicense: AbortController, signalLicense: AbortSignal
let controllerSave: AbortController, signalSave: AbortSignal

export const useAreaLicenses = (companySlug:string) => {

    const dispatch = useDispatch()

    const apiV2 = useAxiosPrivate()

    const { isLoading, licenses, license, status } = useSelector((state: AppStore) => ({...state.areaLicenses}))

    const startLoadLicenses = async () => {
        dispatch(setIsLoading(true));
        try {
            if (controllerLicenses) {
                controllerLicenses.abort()
            }
            controllerLicenses = new AbortController()
            signalLicenses = controllerLicenses.signal
            const resp = await apiV2.get(`/licenses/`, {signal: signalLicenses})
            dispatch(
                loadLicenses({
                    pagination: paginationAdapter(resp.data.pagination),
                    items: loadLicensesAdapter(resp.data.items),
                })
            );
            dispatch(setChangeState('withoutLicense'))
        } catch (error:any) {
            dispatch(setChangeState(null))
            if (error?.response?.status === 404) {
                toast.error('Hubo un problema al cargar las licencias, intentelo de nuevo')
            }
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const startLoadAreaLicense = async (id: string | number) => {
        dispatch(setIsLoading(true));
        try {
            if (controllerLicense) {
                controllerLicense.abort()
            }
            controllerLicense = new AbortController()
            signalLicense = controllerLicense.signal
            const resp = await apiV2.get(`/companies/${companySlug}/areas/${id}/licenses`, {
                signal: signalLicense
            })
            const activeLicense = resp.data.items.find((license:any) => license.status === 'active')
            if (!activeLicense) {
                dispatch(setChangeState('withoutLicense'))
                return
            }
            dispatch(
                loadLicense(loadAreaLicenseAdapter(activeLicense))
            );
            dispatch(setChangeState('withLicense'))
        } catch (error:any) {
            dispatch(setChangeState(null))
            if (error?.response?.status === 404) {
                toast.error('Hubo un problema al cargar la licencia activa del área, intentelo de nuevo')
            }
        } finally{
            dispatch(setIsLoading(false));
        }
    }

    // TODO: Funciones para cancelar la licencia

    const saveLicense = async (id:number | string, code:number|string) => {
        dispatch(setIsLoading(true))
        try {
            if (controllerSave) {
                controllerSave.abort()
            }
            controllerSave = new AbortController()
            signalSave = controllerSave.signal
            const resp = await apiV2.post(`/companies/${companySlug}/areas/${id}/licenses`, {
                license_code: code,
                signal: signalSave
            })
            dispatch(
                loadLicense(loadAreaLicenseAdapter(resp.data))
                ); 
            dispatch(setChangeState('withLicense'))
        } catch (error:any) {
            dispatch(setChangeState(null))
            if (error?.response?.status === 404) {
                toast.error('Hubo un problema al activar la licencia, intentelo de nuevo')
            }
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const changeStatus = (status: 'withoutLicense' | 'withLicense' | 'canceled' | 'selectingLicense' | null) => {
        dispatch(setChangeState(status))
    }

    const resetLicensesData = () => {
        dispatch(resetLicenses())
    }

    return (
        { resetLicensesData ,saveLicense, changeStatus, startLoadLicenses, startLoadAreaLicense, isLoading, licenses, license, status }
    )
}
