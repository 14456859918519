import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        configuration: {},
        showSideBar: true,
        modal: {},
        companySlug: '',
        currentCompany: {}
    },
    reducers: {
        loadConf: (state, action) => {
            state.configuration = { ...action.payload.configuration }
        },
        setShowSideBar: (state, action) => {
            state.showSideBar = action?.payload ?? true
        },
        showModal: (state, action) => {
            state.modal = action.payload
        },
        setCompany: (state, action) => {
            state.currentCompany = action.payload
            state.companySlug = action.payload?.slug
        }

    }
});


// Action creators are generated for each case reducer function
export const { loadConf, setShowSideBar, showModal, setCompany } = uiSlice.actions;

