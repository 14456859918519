import { createSlice } from '@reduxjs/toolkit';

export const crudLessonSlice = createSlice({
    name: 'crudLeccion',
    initialState: {
        isLoading: false,
        errorMessage: null,
        status:'creating',
        lesson:{}
    },
    reducers: {
        loadLesson: (state, action) => {
            state.lesson = action.payload
            state.status = 'viewing'
            state.isLoading = false
        },
        createLesson: (state, action) => {
            state.isLoading = false
            state.lesson = action.payload
            state.status = 'viewing'
        },
        setIsLoading: (state,action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setStatus:(state, action) => {
            state.status = action.payload
        },
        resetLesson: (state) => {
            state.lesson = {}
            state.isLoading = false
        },
    }
});


// Action creators are generated for each case reducer function
export const { createLesson, setIsLoading, setError, loadLesson, setStatus, resetLesson } = crudLessonSlice.actions;

