export interface Pagination {
    total: number
    per_page: number
    current_page: number
    last_page: number
    from: number
    to: number
}

export const paginationAdapter = (pagination: Pagination) => ({
    total: pagination?.total || 0,
    per_page: pagination?.per_page || 0,
    current_page: pagination?.current_page || 0,
    last_page: pagination?.last_page || 0,
    from: pagination?.from || 0,
    to: pagination?.to || 0
})