import { Pagination } from '@/models/pagination.models';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
    isLoading: boolean
    usuarios: any,
    pagination: Pagination | {},
    errorMessage: string | null,
    per_page: number,
    userSelected: string | number | null
    onlyPublic:boolean
}

const initialState: InitialStateProps = {
    isLoading: false,
    usuarios: null,
    pagination: {},
    errorMessage: null,
    per_page: 20,
    userSelected: null,
    onlyPublic:false
}

export const usersSlice = createSlice({
    name: 'usuarios',
    initialState,
    reducers: {
        loadUsers: (state, action) => {
            state.usuarios = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setSelectUser: (state, action) => {
            state.userSelected = action.payload
        },
        setToggleActiveUser: (state, {payload}) => {
            const ix = state.usuarios.findIndex(({id}:{id:number}) => id === payload.id)
            state.usuarios[ix].isActive = payload.isActive
        },
        setDeleteUser: (state, {payload}) =>{
            const ix = state.usuarios.findIndex(({ id }: { id: number }) => id === payload)
            let newUsers = [...state.usuarios]
            newUsers.splice(ix,1)
            state.usuarios = [...newUsers]
            state.isLoading = false
        },
        setToggleOnlyPublic: (state, action) => {
            state.onlyPublic = action.payload
        },
        setResetState:  (state) => ({...initialState, onlyPublic: state.onlyPublic})
    }
});


// Action creators are generated for each case reducer function
export const { setToggleOnlyPublic ,setDeleteUser ,setResetState,setIsLoading, loadUsers, setError, setSelectUser, setToggleActiveUser } = usersSlice.actions;
