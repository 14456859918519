import { usePagination } from "@/hooks/usePagination"
import { AppStore } from "@/redux"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from 'react'

import { setIsLoading, loadModules, setError, setResetState } from "@/redux/states/modules/modules.slice"
import { paginationAdapter } from "@/adapters/pagination.adapter"
import { loadModulesAdapter } from "../adapters/loadModules.adapter"
// import { modulesData } from "../data/modules"
import toast from "react-hot-toast"
import useAxiosPrivate from "@/hooks/useApiV2Private"


let controller:AbortController , signal, oldValue = ''
export const useModules = (companySlug:string) => {

    const apiV2 = useAxiosPrivate()
    
    const dispatch = useDispatch()

    const [value, setValue] = useState('')

    const { isLoading, modules, pagination, per_page } = useSelector((state: AppStore) => state.modules)

    const { nextPage, previousPage, initialPage, finalPage, changePage, currentPage } = usePagination({ lastPage: pagination.last_page })


    const startLoadModules = async () => {
        dispatch(setIsLoading())
        try {
            if (controller) {
                controller.abort()
              }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/modules`, { 
                params: { per_page, page: oldValue === value ? currentPage : 1, search: value }, 
                signal
            })
            oldValue = value
            dispatch(loadModules({ pagination: paginationAdapter(resp.data.pagination), items: loadModulesAdapter(resp.data.items) }))
        } catch (error:any) {
            if (error.response.status === 404) {
                toast.error('Hubo un problema al cargar los módulos, intentelo de nuevo')
            }
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            startLoadModules()
        }, 250)
        return () => {
            clearTimeout(delayDebounceFn)
            dispatch(setResetState())
        }
    }, [per_page, value, currentPage])

    return {
        // paginación 
        nextPage, previousPage, initialPage, finalPage, changePage, currentPage,
        isLoading, modules, pagination, per_page, setError,
        // Busqueda de modulos
        value, setValue
    }
}
