import { useTranslation } from 'react-i18next';

export const TermsConditions: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return currentLanguage === 'es' ? (
    <article className='bg-[#F4F8FE] h-full overflow-auto'>
      <div className='bg-functional-el-l flex items-center sticky top-0 justify-between px-14 py-5'>
        <div>
          <p className='font-bold text-2xl text-[#1E293B]'>T&eacute;rminos y condiciones Simon 2023</p>
        </div>
      </div>
      <div className='mx-14'>
        <p>&nbsp;</p>
        <p className='text-2xl font-bold'>T&eacute;rminos y Condiciones de Uso</p>
        <p className='mb-6 text-justify'>
          Gracias por visitar nuestro sitio web. Le pedimos que lea atentamente los T&eacute;rminos y Condiciones que se
          encuentran en este documento, ya que cualquier uso de este sitio web implica la aceptaci&oacute;n de estos
          t&eacute;rminos y condiciones que se detallan aqu&iacute;.
        </p>
        <p className='mb-6 text-justify'>
          A lo largo de este sitio, los t&eacute;rminos &quot;nosotros&quot;, &quot;nuestro&quot; y
          &quot;Sim&oacute;n&quot; hacen referencia a Sim&oacute;n Inc., y &quot;Usted&quot; se refiere a cualquier
          persona que acceda y/o utilice el presente Sitio Web. Por favor, tenga en cuenta que estos t&eacute;rminos y
          condiciones son aplicables durante su interacci&oacute;n con nuestro sitio web.
        </p>
        <p className='text-2xl font-bold mb-6'>Pol&iacute;tica de Privacidad del Sitio Web</p>
        <p className='mb-4 text-justify'>
          <span lang='es'>
            Nuestra Pol&iacute;tica de Privacidad se basa en el cumplimiento de la normativa de protecci&oacute;n de
            datos personales, incluyendo la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de
            los Particulares, as&iacute; como en la Pol&iacute;tica de Privacidad y Protecci&oacute;n de
            Informaci&oacute;n Personal de Simon. Tanto el responsable de este sitio como los usuarios deben velar por
            su cumplimiento.
          </span>
        </p>
        <p className='mb-4 text-justify'>
          En las diversas plataformas y/o p&aacute;ginas insertas en este sitio, los usuarios solo podr&aacute;n
          proporcionar informaci&oacute;n relativa a su propia persona. No se permite la inclusi&oacute;n o
          inserci&oacute;n de informaci&oacute;n de terceros sin su autorizaci&oacute;n expresa. Por lo tanto, el
          usuario se compromete a eximir a Sim&oacute;n de cualquier responsabilidad que pudiera surgir de acciones u
          omisiones que el usuario realice con respecto a los datos personales de terceros. Esto incluye situaciones
          donde el uso de los datos personales exceda la autorizaci&oacute;n otorgada por el titular o cuando dichos
          datos sean utilizados sin su consentimiento, incluso en el manejo de fotograf&iacute;as de personas
          f&iacute;sicas.
        </p>
        <p className='mb-4 text-justify'>
          En este Sitio, queda prohibida la inserci&oacute;n de datos personales sensibles, conforme a lo establecido en
          la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.
        </p>
        <p className='mb-4 text-justify'>
          Cuando se trata de informaci&oacute;n de menores de edad (menores de 18 a&ntilde;os), solo se permitir&aacute;
          la inserci&oacute;n de informaci&oacute;n m&iacute;nima, como el nombre de pila del menor (sin apellidos) y,
          en caso necesario, su imagen fotogr&aacute;fica. Esto solo ser&aacute; v&aacute;lido si est&aacute;
          justificado en una interacci&oacute;n con las distintas secciones del Sitio Web, siempre y cuando los padres
          (ambos) o tutores legalmente reconocidos otorguen su autorizaci&oacute;n expresa.
        </p>
        <p className='mb-4 text-justify'>
          Por su parte, Simon llevar&aacute; a cabo el manejo de datos personales de acuerdo con el Aviso de Privacidad
          vigente en este Sitio.
        </p>
        <p className='mb-4 text-justify'>
          Cualquier informaci&oacute;n adicional que el usuario inserte en el Sitio deber&aacute; cumplir con los
          lineamientos establecidos en materia de privacidad y protecci&oacute;n de datos.
        </p>
        <p className='mb-4 text-justify'>
          Ten en cuenta que esta Pol&iacute;tica de Privacidad se aplica espec&iacute;ficamente a la plataforma web de
          Simon y a la interacci&oacute;n de los usuarios con la misma.
          <a id='_anchor_1' href='#_msocom_1'>
            [1]
          </a>{' '}
          <a id='_anchor_2' href='#_msocom_2'>
            [2]
          </a>{' '}
          <a id='_anchor_3' href='#_msocom_3'>
            [3]
          </a>{' '}
          <a id='_anchor_4' href='#_msocom_4'>
            [4]
          </a>{' '}
          <a id='_anchor_5' href='#_msocom_5'>
            [5]
          </a>{' '}
          <a id='_anchor_6' href='#_msocom_6'>
            [6]
          </a>
          &nbsp;
        </p>
        <p className='text-2xl font-bold mb-6'>Informaci&oacute;n inserta en el Sitio</p>
        <p className='mb-4 text-justify'>
          A pesar de nuestros esfuerzos significativos por garantizar que la informaci&oacute;n inserta en el Sitio sea
          completa, precisa y verificable, es importante destacar que la plataforma permite que los usuarios introduzcan
          informaci&oacute;n. En consecuencia, no asumimos responsabilidad alguna si la informaci&oacute;n disponible en
          este sitio web resulta inexacta o incompleta. Por esta raz&oacute;n, recomendamos encarecidamente revisarla.
          Cualquier uso de la informaci&oacute;n presente en este Sitio sin previa verificaci&oacute;n recae bajo su
          propio riesgo. Usted manifiesta y acepta que es su responsabilidad supervisar cualquier cambio en el material
          y la informaci&oacute;n contenida en esta plataforma.
          <a id='_anchor_7' href='#_msocom_7'>
            [7]
          </a>
          &nbsp;
        </p>
        <p className='mb-4 text-justify'>
          **(Inserci&oacute;n y uso de material e informaci&oacute;n contenida en la Sitio)**
        </p>
        <p className='mb-4 text-justify'>
          El usuario de Simon se compromete a no introducir en la plataforma ning&uacute;n material o informaci&oacute;n
          sobre el cual no posea derechos. Cualquier comunicaci&oacute;n o material no personal que el usuario transmita
          a trav&eacute;s de Simon, ya sea por correo electr&oacute;nico u otro medio, incluyendo informaci&oacute;n,
          preguntas, comentarios, sugerencias u otro tipo de informaci&oacute;n similar, ser&aacute; considerado como
          informaci&oacute;n de car&aacute;cter p&uacute;blico y no confidencial. En consecuencia, no se podr&aacute;
          ejercer ning&uacute;n derecho de propiedad sobre dicho material o informaci&oacute;n, a menos que se
          establezca expresamente lo contrario en alguna disposici&oacute;n legal o reglamentaria vigente.
        </p>
        <p className='mb-4 text-justify'>
          En todo caso, el usuario otorga a Simon la autorizaci&oacute;n para usar y fijar en la plataforma cualquier
          contenido transmitido o insertado por el usuario. Esta autorizaci&oacute;n abarca cualquier prop&oacute;sito,
          incluyendo, pero no limit&aacute;ndose a su reproducci&oacute;n, divulgaci&oacute;n, transmisi&oacute;n,
          publicaci&oacute;n, retransmisi&oacute;n y fijaci&oacute;n. Cualquiera de estos usos podr&aacute; realizarse
          sin necesidad de compensar al usuario que haya proporcionado la informaci&oacute;n.
        </p>
        <p className='mb-4 text-justify'>
          Al proporcionar cualquier informaci&oacute;n o material, el usuario garantiza que tiene los derechos sobre el
          contenido presentado y que su uso no infringir&aacute; los derechos de terceros ni violar&aacute; ninguna
          disposici&oacute;n jur&iacute;dica aplicable. Sin embargo, es importante mencionar que Simon no est&aacute;
          obligado a utilizar la informaci&oacute;n suministrada por los usuarios.
        </p>
        <p className='mb-4 text-justify'>**(Derechos de Propiedad Intelectual)**</p>
        <p className='mb-4 text-justify'>
          Todos los derechos de autor y otros derechos de propiedad intelectual en todos los textos, im&aacute;genes y
          otros materiales contenidos en la plataforma Simon son propiedad de Simon o han sido incluidos con el permiso
          y autorizaci&oacute;n del due&ntilde;o relevante.
        </p>
        <p className='mb-4 text-justify'>
          Las marcas registradas, dise&ntilde;os, personajes, caracteres y marcas de servicio (colectivamente
          &ldquo;Marcas Registradas&rdquo;) desplegados en la plataforma Simon pertenecen a Simon. Ning&uacute;n
          contenido de la plataforma Simon debe interpretarse como un otorgamiento de licencia de uso o derecho de uso
          de cualquier Marca Registrada mostrada en la plataforma. Queda estrictamente prohibido cualquier uso o mal uso
          de las Marcas Registradas mostradas en la plataforma, as&iacute; como en cualquier contenido relacionado,
          excepto aquellos usos expresamente permitidos en los T&eacute;rminos y Condiciones. Se advierte a los usuarios
          que Simon aplicar&aacute; rigurosamente sus derechos de propiedad industrial e intelectual seg&uacute;n lo
          permita la ley.
        </p>
        <p className='mb-4 text-justify'>
          Los usuarios de Simon est&aacute;n autorizados a navegar por la plataforma, reproducir extractos por medio de
          impresiones, descargar informaci&oacute;n al disco duro o con el prop&oacute;sito de distribuir a otros
          individuos, siempre y cuando mantengan intacta la informaci&oacute;n y todos los avisos de derechos de autor y
          de propiedad industrial e intelectual que aparezcan en las reproducciones. No est&aacute; permitida la
          reproducci&oacute;n de ninguna parte de la plataforma con fines de lucro comercial ni su modificaci&oacute;n o
          incorporaci&oacute;n en cualquier otro trabajo, publicaci&oacute;n o sitio web.
        </p>
        <p className='mb-4 text-justify'>**(Enlaces a otros sitios web)**</p>
        <p className='mb-4 text-justify'>
          Los enlaces en la plataforma Simon pueden dirigirlo a sitios web diferentes a la de Simon, por lo cual Simon
          no acepta responsabilidad alguna respecto del contenido, precisi&oacute;n o funci&oacute;n del contenido de
          estos otros sitios web. Los enlaces son proporcionados en buena fe y Simon no puede asumir responsabilidad por
          cualquier cambio subsiguiente en otros sitios web a los cuales se proporciona un enlace. La inclusi&oacute;n
          de cualquier enlace a otros sitios web no implica que sea avalado o autorizado por Simon. Se recomienda a los
          usuarios que est&eacute;n conscientes y sean cuidadosos al leer todos los avisos legales y de privacidad de
          otros sitios web que visiten.
        </p>
        <p className='mb-4 text-justify'>**(Garant&iacute;as y renuncia de responsabilidades)**</p>
        <p className='mb-4 text-justify'>El uso de la plataforma Simon es exclusivamente bajo su propio riesgo.</p>
        <p className='mb-4 text-justify'>
          La plataforma Simon es presentada a usted sobre la base &quot;como es&quot; y &quot;disponible&quot;, por lo
          tanto, Simon no ofrece garant&iacute;as de ning&uacute;n tipo, ya sean expresas, impl&iacute;citas, legales o
          de otro tipo. Esto incluye, pero no se limita a, garant&iacute;as impl&iacute;citas de comerciabilidad y
          aptitud para un prop&oacute;sito particular. Adem&aacute;s, Simon no garantiza ni representa que el material
          en la plataforma sea completo, preciso, oportuno, o que no infrinja los derechos de terceros. Simon no
          garantiza que el servicio de acceso a la plataforma ser&aacute; ininterrumpido o libre de errores, ni que la
          plataforma ser&aacute; segura. Cualquier consejo u opini&oacute;n proporcionados a trav&eacute;s de la
          plataforma Simon no representan garant&iacute;as o certezas, y todas las representaciones y garant&iacute;as
          al respecto quedan expresamente excluidas.
        </p>
        <p className='mb-4 text-justify'>
          Los usuarios no pueden publicar material protegido por las leyes de Derecho de Autor y Propiedad Industrial, a
          menos que sean los propietarios de dicho material o hayan obtenido la autorizaci&oacute;n por escrito para
          usarlo. Adem&aacute;s, los usuarios no pueden enviar ni publicar fotograf&iacute;as, videos ni sonidos de
          menores de edad en la plataforma, a menos que cuenten con la autorizaci&oacute;n de ambos padres debidamente
          acreditados o tutores legalmente declarados.
        </p>
        <p className='text-2xl font-bold mb-6'>
          Exclusi&oacute;n de garant&iacute;as en la informaci&oacute;n del Sitio
        </p>
        <p className='mb-4 text-justify'>
          Toda la informaci&oacute;n que pueda ser proporcionada por Simon en la plataforma es presentada sobre la base
          de que los usuarios la validar&aacute;n y evaluar&aacute;n su conveniencia como fuente de consulta o
          referencia para realizar cambios en su estilo de vida y el de sus allegados. En este sentido, Simon no otorga
          garant&iacute;as de ning&uacute;n tipo, ya sea de manera expl&iacute;cita, impl&iacute;cita o legal, en
          relaci&oacute;n con la informaci&oacute;n proporcionada en la plataforma.
        </p>
        <p className='mb-4 text-justify'>
          Los usuarios deben tener en cuenta que la informaci&oacute;n disponible en la plataforma web de Simon es
          producto de la interacci&oacute;n y contribuci&oacute;n de diversos usuarios, y por lo tanto, Simon no asume
          responsabilidad por la precisi&oacute;n, integridad o actualidad de dicha informaci&oacute;n. Es importante
          que los usuarios revisen y validen la informaci&oacute;n por s&iacute; mismos antes de tomar decisiones
          basadas en la misma.
        </p>
        <p className='mb-4 text-justify'>
          Simon no garantiza que el material en la plataforma cumpla con todas las expectativas de uso del usuario ni se
          hace responsable de posibles interrupciones, errores o virus que puedan afectar el acceso a la plataforma o su
          seguridad.
        </p>
        <p className='mb-4 text-justify'>
          Nos reservamos el derecho de restringir, suspender o terminar el acceso a la plataforma web de Simon o a
          cualquier parte de la misma en cualquier momento y sin previo aviso.
        </p>
        <p className='text-2xl font-bold mb-6'>Renuncia de responsabilidades</p>
        <p className='mb-4 text-justify'>
          Ni Simon ni ninguna otra parte involucrada en la creaci&oacute;n, producci&oacute;n o gesti&oacute;n de esta
          plataforma tendr&aacute;n responsabilidad alguna por cualquier uso directo, incidental, consecuente,
          indirecto, da&ntilde;os, gastos, p&eacute;rdidas o responsabilidad resultantes de su acceso, uso,
          inhabilitaci&oacute;n del uso o cambios en el contenido de la plataforma, o que surjan de cualquier otro
          acceso a la plataforma a trav&eacute;s de enlaces externos o de cualquier acci&oacute;n que tomemos o
          decidamos no tomar como resultado de la recepci&oacute;n de correos electr&oacute;nicos.
        </p>
        <p className='mb-4 text-justify'>
          Ni Simon ni ninguna otra parte involucrada en la creaci&oacute;n, producci&oacute;n o entrega de esta
          plataforma tendr&aacute;n la obligaci&oacute;n de actualizar el material y los servicios disponibles en la
          misma, ni de corregir, actualizar o realizar lanzamientos en relaci&oacute;n con la informaci&oacute;n
          contenida en ella. Cualquier contenido presente en la plataforma est&aacute; sujeto a cambios sin previo
          aviso.
        </p>
        <p className='mb-4 text-justify'>
          Adem&aacute;s, Simon no asume responsabilidad alguna por cualquier p&eacute;rdida causada por virus u otros
          elementos maliciosos que puedan afectar su dispositivo o propiedad debido a la utilizaci&oacute;n, acceso o
          descarga de cualquier material de la plataforma. Si decide descargar materiales de la plataforma, lo hace bajo
          su propio riesgo.
        </p>
        <p className='mb-4 text-justify'>
          En concordancia con lo anterior, usted renuncia a cualquier acci&oacute;n o derecho que pueda tener contra
          Simon, las empresas afiliadas, sus empleados, ejecutivos, representantes y proveedores por cualquier
          insatisfacci&oacute;n, da&ntilde;o o perjuicio causado por el uso de la plataforma.
        </p>
        <p className='text-2xl font-bold mb-6'>Actividades Prohibidas</p>
        <p className='mb-4 text-justify'>
          En el uso de la plataforma de Simon, est&aacute; estrictamente prohibido llevar a cabo cualquier acto u
          omisi&oacute;n que, seg&uacute;n el criterio de Simon, viole los principios morales y las buenas costumbres,
          y/o que pueda considerarse ilegal o que est&eacute; prohibido por cualquier ley aplicable a esta plataforma.
          Esto incluye, de manera enunciativa pero no limitativa:
        </p>
        <ul className='list-disc px-10 mb-4'>
          <li className='text-justify'>
            Cualquier acci&oacute;n que pueda infringir los derechos de privacidad de las personas, la confidencialidad
            y los derechos de propiedad intelectual.
          </li>
          <li className='text-justify'>
            Utilizar la plataforma para difamar o calumniar a Simon, sus colaboradores o cualquier otra persona,
            as&iacute; como actuar de manera que perjudique la reputaci&oacute;n de Simon.
          </li>
          <li className='text-justify'>
            Cargar archivos que contengan virus u otros elementos da&ntilde;inos que puedan afectar la propiedad de
            Simon o la propiedad de otros usuarios.
          </li>
          <li className='text-justify'>
            Publicar o transmitir a la plataforma cualquier material no autorizado que, en nuestra opini&oacute;n, cause
            molestias, perjuicios o viole sistemas o redes de seguridad (ya sean de Simon o de terceros), as&iacute;
            como contenido difamatorio, racista, obsceno, amenazante, pornogr&aacute;fico o cualquier otro elemento
            ilegal.
          </li>
          <li className='text-justify'>
            Realizar acciones u omisiones cuyas consecuencias constituyan violaciones de leyes, regulaciones o
            normativas, as&iacute; como aquellas que afecten los derechos de terceros o de Simon.
          </li>
        </ul>
        <p className='text-2xl font-bold mb-6'>Cookies</p>
        <p className='mb-4 text-justify'>
          Simon utiliza tecnolog&iacute;a de seguimiento como &quot;cookies&quot;. La pol&iacute;tica de Simon sobre el
          uso de cookies est&aacute; disponible en la secci&oacute;n de Pol&iacute;tica de Privacidad e
          Informaci&oacute;n Personal de esta plataforma.
        </p>
        <p className='text-2xl font-bold mb-6'>Jurisdicci&oacute;n y ley aplicable</p>
        <p className='mb-4 text-justify'>
          Los productos, materiales y la informaci&oacute;n proporcionada en esta plataforma Simon est&aacute;n
          destinados para usuarios, consumidores y clientes de Simon en M&eacute;xico.
        </p>
        <p className='mb-4 text-justify'>
          Simon no garantiza que los productos y el contenido de esta plataforma sean apropiados o est&eacute;n
          disponibles en otros lugares fuera de M&eacute;xico.
        </p>
        <p className='mb-4 text-justify'>
          Le recomendamos contactar a su distribuidor local para obtener m&aacute;s informaci&oacute;n sobre la
          disponibilidad de los productos en su pa&iacute;s. Las representaciones visuales de los productos en esta
          plataforma pueden no ser a escala real y los colores de los embalajes pueden variar.
        </p>
        <p className='mb-4 text-justify'>
          Toda la informaci&oacute;n y material proporcionado en esta plataforma est&aacute; dirigido exclusivamente a
          usuarios en M&eacute;xico. Simon no asegura que los productos y el contenido de esta plataforma sean
          apropiados o est&eacute;n disponibles en otros lugares fuera de M&eacute;xico. Al utilizar o acceder a esta
          plataforma, se establece una relaci&oacute;n e interacci&oacute;n entre el usuario y Simon que puede tener
          implicaciones legales. Por lo tanto, el usuario acepta que la interpretaci&oacute;n y cumplimiento de los
          t&eacute;rminos y condiciones se regir&aacute;n por la legislaci&oacute;n federal vigente aplicable en
          M&eacute;xico, y en su caso, por la legislaci&oacute;n vigente en la Ciudad de M&eacute;xico. En caso de
          controversia, las partes involucradas se someter&aacute;n a la competencia de las instancias institucionales y
          tribunales de la Ciudad de M&eacute;xico. Las partes renuncian desde ahora a cualquier fuero que pudiera
          corresponderles en funci&oacute;n de sus domicilios presentes o futuros.
        </p>
        <p className='text-2xl font-bold mb-6'>Actualizaci&oacute;n del Aviso Legal</p>
        <p className='mb-4 text-justify'>
          Nos reservamos el derecho de realizar modificaciones o correcciones a este aviso legal en cualquier momento.
          Le recomendamos visitar esta p&aacute;gina peri&oacute;dicamente para revisar la informaci&oacute;n
          actualizada, as&iacute; como cualquier nueva informaci&oacute;n adicional.
        </p>
        <p className='mb-4 text-justify'>Derechos de autor &copy; Simon 2023. Todos los derechos reservados.</p>
        <p>&nbsp;</p>
      </div>
    </article>
  ) : (
    <article className='bg-[#F4F8FE] h-full overflow-auto'>
      <div className='bg-functional-el-l flex items-center sticky top-0 justify-between px-14 py-5'>
        <div>
          <p className='font-bold text-2xl text-[#1E293B]'>Terms and Conditions Simon 2023</p>
        </div>
      </div>
      <div className='mx-14'>
        <p>&nbsp;</p>
        <p className='text-2xl font-bold'>Terms and Conditions of Use</p>
        <p className='mb-6 text-justify'>
          Thank you for visiting our website. We ask that you carefully read the Terms and Conditions outlined in this
          document, as any use of this website implies acceptance of these terms and conditions detailed here.
        </p>
        <p className='mb-6 text-justify'>
          Throughout this site, the terms "we," "our," and "Simon" refer to Simon Inc., and "You" refers to anyone who
          accesses and/or uses this Website. Please note that these terms and conditions apply during your interaction
          with our website.
        </p>
        <p className='text-2xl font-bold mb-6'>Website Privacy Policy</p>
        <p className='mb-4 text-justify'>
          <span lang='es'>
            Our Privacy Policy is based on compliance with personal data protection regulations, including the Federal
            Law on the Protection of Personal Data Held by Individuals, as well as Simon's Privacy and Personal
            Information Protection Policy. Both the operator of this site and the users must ensure compliance with it.
          </span>
        </p>
        <p className='mb-4 text-justify'>
          On the various platforms and/or pages embedded on this site, users may only provide information regarding
          themselves. The inclusion or insertion of third-party information without their express authorization is not
          permitted. Therefore, the user agrees to hold Simon harmless from any liability that may arise from actions or
          omissions related to the personal data of third parties. This includes situations where the use of personal
          data exceeds the authorization granted by the owner or when such data is used without consent, even in
          handling photographs of individuals.
        </p>
        <p className='mb-4 text-justify'>
          Sensitive personal data insertion is prohibited on this site, as established by the Federal Law on the
          Protection of Personal Data Held by Individuals.
        </p>
        <p className='mb-4 text-justify'>
          When it comes to information about minors (under 18 years of age), only minimal information, such as the first
          name of the minor (without last names), and, if necessary, their photographic image, will be allowed. This
          will only be valid if justified in an interaction with different sections of the Website, provided that both
          parents or legally recognized guardians grant their express authorization.
        </p>
        <p className='mb-4 text-justify'>
          Simon will handle personal data in accordance with the current Privacy Notice on this Site.
        </p>
        <p className='mb-4 text-justify'>
          Any additional information that the user inserts on the Site must comply with the privacy and data protection
          guidelines.
        </p>
        <p className='mb-4 text-justify'>
          Please note that this Privacy Policy specifically applies to Simon's web platform and users' interaction with
          it.
        </p>
        <p className='text-2xl font-bold mb-6'>Information Inserted on the Site</p>
        <p className='mb-4 text-justify'>
          Despite our significant efforts to ensure that the information inserted on the Site is complete, accurate, and
          verifiable, it is important to note that the platform allows users to input information. Consequently, we
          assume no responsibility if the information available on this website is inaccurate or incomplete. For this
          reason, we strongly recommend reviewing it. Any use of the information present on this Site without prior
          verification is at your own risk. You acknowledge and agree that it is your responsibility to monitor any
          changes in the material and information contained on this platform.
        </p>
        <p className='mb-4 text-justify'>(Insertion and Use of Material and Information on the Site)</p>
        <p className='mb-4 text-justify'>
          Simon's user commits not to introduce any material or information into the platform for which they do not have
          rights. Any non-personal communication or material that the user transmits through Simon, whether by email or
          any other means, including information, questions, comments, suggestions, or similar information, will be
          considered as public and non-confidential information. Therefore, no property rights can be exercised over
          such material or information, unless expressly stated otherwise in any current legal or regulatory provision.
        </p>
        <p className='mb-4 text-justify'>
          In any case, the user grants Simon the authorization to use and fix any content transmitted or inserted by the
          user on the platform. This authorization covers any purpose, including but not limited to reproduction,
          disclosure, transmission, publication, retransmission, and fixation. Any of these uses may be carried out
          without compensating the user who provided the information.
        </p>
        <p className='mb-4 text-justify'>
          By providing any information or material, the user warrants that they have rights to the presented content and
          that its use will not infringe on the rights of third parties or violate any applicable legal provisions.
          However, it is important to mention that Simon is not obligated to use the information provided by users.
        </p>
        <p className='mb-4 text-justify'>(Intellectual Property Rights)</p>
        <p className='mb-4 text-justify'>
          All copyright and other intellectual property rights in all texts, images, and other materials contained on
          the Simon platform are owned by Simon or have been included with the relevant owner's permission.
        </p>
        <p className='mb-4 text-justify'>
          The trademarks, designs, characters, and service marks (collectively "Trademarks") displayed on the Simon
          platform belong to Simon. No content on the Simon platform should be construed as a grant of a license for the
          use or right to use any Trademark displayed on the platform. Any use or misuse of the Trademarks displayed on
          the platform, as well as any related content, except those uses expressly permitted in the Terms and
          Conditions, is strictly prohibited. Users are advised that Simon will vigorously enforce its industrial and
          intellectual property rights as permitted by law.
        </p>
        <p className='mb-4 text-justify'>
          Simon's users are authorized to browse the platform, reproduce excerpts through printing, download information
          to the hard drive, or for the purpose of distributing to other individuals, provided that they keep the
          information and all copyright and industrial and intellectual property notices appearing on the reproductions
          intact. Reproduction of any part of the platform for commercial profit is not allowed, nor is its modification
          or incorporation into any other work, publication, or website.
        </p>
        <p className='mb-4 text-justify'>(Links to Other Websites)</p>
        <p className='mb-4 text-justify'>
          Links on the Simon platform may direct you to websites other than Simon's, so Simon accepts no responsibility
          regarding the content, accuracy, or functionality of the content on these other websites. Links are provided
          in good faith, and Simon cannot assume responsibility for any subsequent changes on other websites linked to.
          The inclusion of any links to other websites does not imply endorsement or authorization by Simon. Users are
          advised to be aware and careful when reading all legal notices and privacy policies of other websites they
          visit.
        </p>
        <p className='mb-4 text-justify'>(Warranties and Disclaimer of Liability)</p>
        <p className='mb-4 text-justify'>The use of the Simon platform is solely at your own risk</p>
        <p className='mb-4 text-justify'>
          The Simon platform is provided to you on an "as is" and "available" basis; therefore, Simon makes no
          warranties of any kind, whether express, implied, legal, or otherwise. This includes, but is not limited to,
          implied warranties of merchantability and fitness for a particular purpose. Furthermore, Simon does not
          warrant or represent that the material on the platform is complete, accurate, timely, or does not infringe on
          the rights of third parties. Simon does not guarantee that access to the platform will be uninterrupted or
          error-free, nor that the platform will be secure. Any advice or opinions provided through the Simon platform
          do not represent warranties or certainties, and all representations and warranties are expressly excluded.
        </p>
        <p className='mb-4 text-justify'>
          Users may not post material protected by copyright and industrial property laws unless they are the owners of
          such material or have obtained written authorization to use it. Additionally, users may not send or post
          photographs, videos, or sounds of minors on the platform unless they have the express authorization of both
          duly accredited parents or legally declared guardians.
        </p>
        <p className='text-2xl font-bold mb-6'>Exclusion of Warranties on Site Information</p>
        <p className='mb-4 text-justify'>
          All information provided by Simon on the platform is presented on the basis that users will validate it and
          evaluate its suitability as a source for consulting or referencing for making changes in their lifestyle and
          that of their acquaintances. In this regard, Simon does not provide any warranties, whether explicit, implied,
          or legal, regarding the information provided on the platform.
        </p>
        <p className='mb-4 text-justify'>
          Users should be aware that the information available on Simon's web platform is the product of interaction and
          contribution from various users, and therefore, Simon assumes no responsibility for the accuracy,
          completeness, or timeliness of such information. It is important for users to review and validate the
          information themselves before making decisions based on it. Simon does not guarantee that the material on the
          platform meets all user usage expectations, and it is not responsible for any interruptions, errors, or
          viruses that may affect access to the platform or its security.
        </p>
        <p className='mb-4 text-justify'>
          We reserve the right to restrict, suspend, or terminate access to Simon's web platform or any part thereof at
          any time and without prior notice.
        </p>
        <p className='text-2xl font-bold mb-6'>Disclaimer</p>
        <p className='mb-4 text-justify'>
          Neither Simon nor any other party involved in the creation, production, or management of this platform shall
          have any liability for any direct, incidental, consequential, indirect, damages, expenses, losses, or
          liability resulting from your access, use, inability to use, or changes to the content of the platform, or
          arising from any other access to the platform through external links or any action we take or decide not to
          take as a result of receiving emails.
        </p>
        <p className='mb-4 text-justify'>
          Neither Simon nor any other party involved in the creation, production, or delivery of this platform shall
          have an obligation to update the material and services available on it, nor to correct, update, or release in
          connection with the information contained therein. Any content on the platform is subject to change without
          notice.
        </p>
        <p className='mb-4 text-justify'>
          Additionally, Simon assumes no responsibility for any loss caused by viruses or other malicious elements that
          may affect your device or property due to the use, access, or download of any material from the platform. If
          you choose to download materials from the platform, you do so at your own risk.
        </p>
        <p className='mb-4 text-justify'>
          In accordance with the above, you waive any action or right you may have against Simon, its affiliated
          companies, employees, executives, representatives, and suppliers for any dissatisfaction, damage, or harm
          caused by the use of the platform.
        </p>
        <p className='text-2xl font-bold mb-6'>Prohibited Activities</p>
        <p className='mb-4 text-justify'>
          In the use of Simon's platform, it is strictly prohibited to engage in any act or omission that, in Simon's
          judgment, violates moral principles and good customs, and/or that may be considered illegal or prohibited by
          any law applicable to this platform. This includes, but is not limited to:
        </p>
        <ul className='list-disc px-10 mb-4'>
          <li className='text-justify'>
            Any action that may infringe on the privacy rights of individuals, confidentiality, and intellectual
            property rights.
          </li>
          <li className='text-justify'>
            Using the platform to defame or slander Simon, its collaborators, or any other person, as well as acting in
            a way that harms Simon's reputation.
          </li>
          <li className='text-justify'>
            Uploading files that contain viruses or other harmful elements that may affect Simon's property or the
            property of other users.
          </li>
          <li className='text-justify'>
            Posting or transmitting to the platform any unauthorized material that, in our opinion, causes annoyance,
            harm, or violates security systems or networks (whether of Simon or third parties), as well as defamatory,
            racist, obscene, threatening, pornographic, or any other illegal content.
          </li>
          <li className='text-justify'>
            Taking actions or omissions whose consequences constitute violations of laws, regulations, or norms, as well
            as those that affect the rights of third parties or Simon.
          </li>
        </ul>
        <p className='text-2xl font-bold mb-6'>Cookies</p>
        <p className='mb-4 text-justify'>
          Simon uses tracking technology such as 'cookies.' Simon's policy on the use of cookies is available in the
          Privacy and Personal Information section of this platform.
        </p>
        <p className='text-2xl font-bold mb-6'>Jurisdiction and Applicable Law</p>
        <p className='mb-4 text-justify'>
          The products, materials, and information provided on this Simon platform are intended for users, consumers,
          and customers of Simon in Mexico.
        </p>
        <p className='mb-4 text-justify'>
          Simon does not guarantee that the products and content of this platform are appropriate or available elsewhere
          outside of Mexico.
        </p>
        <p className='mb-4 text-justify'>
          We recommend contacting your local distributor for more information on the availability of products in your
          country. Visual representations of products on this platform may not be to scale, and packaging colors may
          vary.
        </p>
        <p className='mb-4 text-justify'>
          All information and material provided on this platform is exclusively directed to users in Mexico. Simon does
          not guarantee that the products and content of this platform are appropriate or available elsewhere outside of
          Mexico. By using or accessing this platform, a relationship and interaction are established between the user
          and Simon that may have legal implications. Therefore, the user agrees that the interpretation and compliance
          with the terms and conditions will be governed by the current federal legislation applicable in Mexico, and if
          applicable, by the current legislation in Mexico City. In case of dispute, the parties involved will submit to
          the jurisdiction of institutional instances and courts in Mexico City. The parties hereby waive any
          jurisdiction that may correspond to them based on their present or future domiciles.
        </p>
        <p className='text-2xl font-bold mb-6'>Legal Notice Update</p>
        <p className='mb-4 text-justify'>
          We reserve the right to make modifications or corrections to this legal notice at any time. We recommend
          visiting this page periodically to review updated information, as well as any new additional information.
        </p>
        <p className='mb-4 text-justify'>Copyright © Simon 2023. All rights reserved.</p>
        <p>&nbsp;</p>
      </div>
    </article>
  );
};
