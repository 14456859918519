import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import cn from 'classnames';

import { AppStore } from '@/redux';

import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { Select } from '@/components/Selects/Select';
import { PrimaryButton } from '@/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/Buttons/SecondaryButton';
import { InputText } from '../components/InputText';
import { TextArea } from '../components/TextArea';
import { TitleInput } from '../components/TitleInput';
import { CreateCompanyProps } from '../models/crearEmpresa.model';
import { useCrudCompany } from '../hooks';
import { EmpresaInitial } from '../models';
import { Spinner } from '@/components/Spinners/Spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getEnvVariables } from '@/utilities';
import { loadImageWithValidationsProps } from '@/hooks';
import { TabCompany } from '../layout/TabCompany';
import { t } from 'i18next';

const defaultValuesAdapter = (company: EmpresaInitial): any => {
  return {
    name: company.name,
    description: company.description,
    isActive: company.is_active,
    vimeo_folder_id: company.vimeo_folder_id,
    default_country_id: `${company.default_country_id}`
  };
};

interface PaisProps {
  id: number;
  name: string;
  imagePath?: string;
}
interface EmpresaFormProp {
  image: any;
  company: any;
  status: string;
  paises: PaisProps[];
  updateCompany: any;
  editing: any;
  viewing: any;
  usuarios: any;
  uploadImage: any;
  createCompany: any;
  setImage: any;
  loadImageWithValidations: loadImageWithValidationsProps;
  crudDisabled: boolean;
}

const EmpresaForm: React.FC<EmpresaFormProp> = ({
  loadImageWithValidations,
  crudDisabled,
  setImage,
  image,
  company,
  status,
  paises,
  updateCompany,
  editing,
  viewing,
  createCompany
}) => {
  const { VITE_PUBLIC_IMAGE } = getEnvVariables();

  const [optionsCountries, setOptionCountries] = useState<PaisProps[] | []>(
    paises.filter(({ id }) => company?.country_ids?.includes(id))
  );

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    watch
  } = useForm<CreateCompanyProps>({
    defaultValues: { ...defaultValuesAdapter(company) }
  });

  const infoStatus: any = {
    viewing: {
      title: t('pages.companies.companyInformation'),
      message: ''
    },
    editing: {
      title: t('pages.companies.editCompany'),
      message: t('pages.companies.editMessage')
    },
    creating: {
      title: t('pages.companies.addCompany'),
      message: t('pages.companies.addMessage')
    }
  };

  const onSubmit = async (data: any) => {
    if (optionsCountries.length > 0) {
      if (typeof image?.image_path === 'string') {
        status === 'editing'
          ? updateCompany({ ...data, optionsCountries })
          : status === 'creating' && createCompany({ ...data, optionsCountries });
      } else {
        toast.error(t('errorMessage.selectLogo'));
      }
    } else {
      setError('address', { message: t('errorMessage.requiredField') });
    }
  };

  const handleChangeStatus = (event: React.FormEvent) => {
    event.preventDefault();
    disabled ? editing() : viewing();
  };

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault();
    if (isCreating) {
      navigate('/empresas');
    } else {
      reset({ ...defaultValuesAdapter(company) });
      setOptionCountries(paises.filter(({ id }) => company?.country_ids?.includes(id)));
      setImage(() => ({ image_id: company.image_id, image_path: `${VITE_PUBLIC_IMAGE}/${company.image_id}` }));
      viewing();
    }
  };

  const handleChooseLogo = (e: any) => {
    e.preventDefault();
    const file = e.target.files?.[0] || undefined;
    if (file) {
      loadImageWithValidations(file);
    }
  };

  const handleSelectCountry = (e: React.FormEvent) => {
    e.preventDefault();
    clearErrors('address');
    const event = e as React.ChangeEvent<HTMLSelectElement>;
    const value = event.target.value;
    const country = paises.find((pais) => pais.id === Number(value));
    if (country && !optionsCountries.find((option) => option.id === country.id)) {
      setOptionCountries((countries) => [...countries, country]);
    }
  };

  const handleDeleteOptionCountry = (option: PaisProps) => {
    if (!disabled) {
      setOptionCountries((countries) => countries.filter((country) => country.id !== option.id));
      if (`${option.id}` === watch('default_country_id')) {
        setValue('default_country_id', undefined);
      }
    }
  };

  const isCreating = status === 'creating';
  const disabled = status === 'viewing';

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='bg-functional-el-l flex items-center justify-start px-10 py-7'>
          <Link to='/empresas'>
            <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
          </Link>
          <div>
            <p className={`font-bold text-2xl text-functional-d-l`}>{infoStatus[status].title}</p>
            <p className={`font-medium text-functional-d-l`}>{infoStatus[status].message}</p>
          </div>
        </div>
        <div className='m-11 p-10 bg-functional-el-l rounded-3xl shadow-lg'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.companies.generalData')}</p>
              <p className='font-medium text-functional-d-l'>{t('pages.companies.enterData')}</p>
            </div>
            {status !== 'creating' ? (
              <PrimaryButton onClick={handleChangeStatus}>
                {disabled ? t('generics.edit') : t('generics.seeInformation')}
              </PrimaryButton>
            ) : null}
          </div>
          <div className={cn('grid grid-cols-2', { 'grid-rows-5': !isCreating, 'grid-rows-4': isCreating })}>
            <div className='p-4'>
              <InputText
                disabled={disabled}
                register={() => register('name', { required: t('errorMessage.nameCompanyRequired'), minLength: 3 })}
                placeholder={t('generics.name')}
              />
              {errors.name ? <p className='text-warning-d'>{errors.name.message}</p> : null}
            </div>
            <div className='p-4 row-span-2'>
              <p className='mb-1 text-lg text-black font-semibold'>{t('generics.countries')}:</p>
              <div className='flex flex-col'>
                {/* <Select disabled={disabled} className="w-5/6" options={['Administrador', 'Soporte', 'Creador']} optionValueMap={(value) => value} optionmap={(value) => value} register={() => register('role')} /> */}
                <Select
                  disabled={disabled}
                  className='w-5/6 disabled:cursor-no-drop'
                  options={[...paises]}
                  optionmap={(value) => value.name}
                  optionKeyMap={(value) => value.id}
                  onChange={handleSelectCountry}
                  optionValueMap={(value) => value.id}
                  // register={() => register('address', { required: 'Seleccione en país' })}
                />
                {errors.address ? <p className='text-warning-d'>{errors.address.message}</p> : null}
                <div className='mt-2 w-5/6 h-48 overflow-auto'>
                  {optionsCountries.length > 0 ? (
                    optionsCountries.map((option) => {
                      return (
                        <div
                          key={option.id}
                          className='h-10 mt-2 mr-2 bg-functional-l-l rounded-lg p-1 flex justify-between items-center'>
                          <input
                            {...register('default_country_id', { required: t('errorMessage.selectCountry') })}
                            value={option.id}
                            disabled={disabled}
                            type='radio'
                          />
                          <p>{option.name}</p>
                          <i
                            onClick={() => handleDeleteOptionCountry(option)}
                            className='mx-1 fa-solid fa-xmark hover:text-error-m'
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
                {errors.default_country_id ? (
                  <p className='text-warning-d'>{errors.default_country_id.message}</p>
                ) : null}
              </div>
            </div>
            <div className='p-4 row-span-2'>
              <TextArea
                disabled={disabled}
                register={() => register('description', { required: t('errorMessage.completeDescription') })}
                placeholder={t('generics.description')}
              />
              {errors.description ? <p className='text-warning-d'>{errors.description.message}</p> : null}
            </div>
            <div className='row-span-2 p-4'>
              <TitleInput>{t('generics.logo')}</TitleInput>
              <div className='flex flex-col items-center justify-center w-5/6'>
                <div className={cn('w-full my-2 h-[150px] row-span-2')}>
                  <img
                    src={image?.image_path ?? '/imgLayout.png'}
                    alt=''
                    className='row-span-2 w-full h-full object-contain'
                  />
                </div>
                <input
                  disabled={disabled}
                  type='file'
                  id='actual-btn'
                  accept='image/jpg, image/jpeg, image/png'
                  onChange={handleChooseLogo}
                  hidden
                />
                <label
                  htmlFor='actual-btn'
                  className={cn(
                    'disabled:cursor-no-drop self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14',
                    { 'cursor-no-drop': disabled, 'cursor-pointer': !disabled }
                  )}>
                  <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5'>add</span>
                  {t('generics.upload')} (512x512px en jpg o png)
                </label>
              </div>
            </div>
            <div className='p-4'>
              <InputText
                disabled={disabled}
                register={() => register('vimeo_folder_id')}
                placeholder={t('pages.companies.folderId')}
              />
              <p className='text-bg-functional-l-d'></p>
              {errors.vimeo_folder_id ? <p className='text-warning-d'>{errors.vimeo_folder_id.message}</p> : null}
            </div>
            <div className='flex items-center p-4'>
              <TitleInput>{t('generics.active')}</TitleInput>
              <div className='mx-2'></div>
              <ToggleSwitch disabled={disabled} message='' register={() => register('isActive')} />
              {errors.isActive ? <p className='text-warning-d'>{errors.isActive.message}</p> : null}
            </div>
          </div>
          {!disabled ? (
            <div className='flex justify-end'>
              <PrimaryButton disabled={disabled || crudDisabled} type='submit'>
                {t('pages.companies.saveCompany')}
              </PrimaryButton>
              <SecondaryButton className='px-10' disabled={disabled || crudDisabled} onClick={handleCancel}>
                {isCreating ? t('generics.goBack') : t('generics.cancel')}
              </SecondaryButton>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export const CRUDEmpresas = () => {
  const { paises } = useSelector((state: AppStore) => state.crudEmpresa);

  const { state } = useLocation();

  const {
    crudDisabled,
    company,
    status,
    usuarios,
    image,
    isLoading,
    isLoadingCountries,
    loadImageWithValidations,
    editing,
    setImage,
    viewing,
    uploadImage,
    updateCompany,
    createCompany
  } = useCrudCompany(state?.companyId);

  const showFormCompny = !isLoading && !isLoadingCountries;

  const isCreating = status === 'creating';

  return (
    <>
      <TabCompany companyId={state?.companyId} isCreating={isCreating} />
      <article className='bg-functional-el-m'>
        {showFormCompny ? (
          <EmpresaForm
            {...{
              crudDisabled,
              loadImageWithValidations,
              setImage,
              company,
              status,
              usuarios,
              paises,
              editing,
              viewing,
              uploadImage,
              updateCompany,
              image,
              createCompany
            }}
          />
        ) : (
          <Spinner />
        )}
        {status !== 'creating' && state?.companyId ? (
          <div className='pb-10'>
            {/* AREAS DE LA EMPRESA */}
            {/* <Areas {...{ companySlug: state.companyId }} /> */}
          </div>
        ) : (
          <></>
        )}
      </article>
    </>
  );
};
