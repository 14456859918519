export const termsConditionsRewardHtml = <>
    <p><strong>T&eacute;rminos y Condiciones de Uso y Cobro de Premios en la Aplicaci&oacute;n "Sim&oacute;n"</strong></p>
    <p>&nbsp;</p>
    <ol>
        <li><strong>Aceptaci&oacute;n de T&eacute;rminos y Condiciones</strong></li>
    </ol>
    <p>Al utilizar la aplicaci&oacute;n "Sim&oacute;n" y participar en el sistema de premios basado en puntos, el usuario acepta autom&aacute;ticamente todos los t&eacute;rminos y condiciones establecidos en el presente documento. Si el usuario no est&aacute; de acuerdo con estos t&eacute;rminos, se le solicita abstenerse de usar la aplicaci&oacute;n.</p>
    <p>&nbsp;</p>
    <ol start={2}>
        <li><strong>Sistema de Puntos y Premios</strong></li>
    </ol>
    <p>2.1. El usuario podr&aacute; acumular puntos al utilizar la aplicaci&oacute;n "Sim&oacute;n" de acuerdo con las reglas establecidas por la empresa.</p>
    <p>2.2. Los puntos acumulados por el usuario se utilizar&aacute;n para el canje de premios previamente establecidos por la empresa.</p>
    <p><strong>&nbsp;</strong></p>
    <ol start={3}>
        <li><strong>Acumulaci&oacute;n de Puntos</strong></li>
    </ol>
    <p>3.1. Los puntos podr&aacute;n ser acumulados al completar acciones espec&iacute;ficas dentro de la aplicaci&oacute;n, como responder preguntas correctamente, participar en actividades designadas y cumplir con otros requisitos determinados por la empresa.</p>
    <p>3.2. La empresa se reserva el derecho de ajustar la cantidad de puntos otorgados por cada acci&oacute;n y de modificar las reglas para la acumulaci&oacute;n de puntos en cualquier momento, previo aviso a los usuarios.</p>
    <p>&nbsp;</p>
    <ol start={4}>
        <li><strong>Canje de Premios</strong></li>
    </ol>
    <p>4.1. Los premios disponibles para canjear ser&aacute;n determinados por la empresa y podr&aacute;n variar en cantidad, tipo y valor.</p>
    <p>4.2. El usuario podr&aacute; canjear sus puntos acumulados por premios en la aplicaci&oacute;n "Sim&oacute;n". Los t&eacute;rminos espec&iacute;ficos para el canje de cada premio estar&aacute;n disponibles en la descripci&oacute;n de dicho premio.</p>
    <p>4.3. La empresa se reserva el derecho de modificar los premios disponibles para canjear, as&iacute; como los requisitos para el canje, previo aviso a los usuarios.</p>
    <p>&nbsp;</p>
    <ol start={5}>
        <li><strong>Uso Responsable y Cumplimiento de Reglas</strong></li>
    </ol>
    <p>5.1. El usuario se compromete a utilizar la aplicaci&oacute;n "Sim&oacute;n" de manera responsable y cumplir con las reglas establecidas por la empresa.</p>
    <p>5.2. El incumplimiento de las reglas o el uso inapropiado de la aplicaci&oacute;n podr&aacute; resultar en la p&eacute;rdida de puntos acumulados, la suspensi&oacute;n de la cuenta del usuario o la cancelaci&oacute;n de su participaci&oacute;n en el sistema de premios, a criterio de la empresa.</p>
    <p>&nbsp;</p>
    <ol start={6}>
        <li><strong>Privacidad y Datos Personales</strong></li>
    </ol>
    <p>6.1. La empresa se compromete a proteger la privacidad de los usuarios y a tratar los datos personales de acuerdo con su Pol&iacute;tica de Privacidad. El usuario acepta y reconoce que al utilizar la aplicaci&oacute;n "Sim&oacute;n", est&aacute; de acuerdo con los t&eacute;rminos de la Pol&iacute;tica de Privacidad de la empresa.</p>
    <p>&nbsp;</p>
    <ol start={7}>
        <li><strong>Modificaciones a los T&eacute;rminos y Condiciones</strong></li>
    </ol>
    <p>La empresa se reserva el derecho de modificar los presentes t&eacute;rminos y condiciones en cualquier momento. Los cambios ser&aacute;n notificados a los usuarios a trav&eacute;s de la plataforma o por otros medios adecuados. El uso continuado de la aplicaci&oacute;n despu&eacute;s de dichas modificaciones constituir&aacute; la aceptaci&oacute;n de los nuevos t&eacute;rminos y condiciones.</p>
    <p>&nbsp;</p>
    <ol start={8}>
        <li><strong>Contacto</strong></li>
    </ol>
    <p>Para cualquier consulta o inquietud relacionada con los t&eacute;rminos y condiciones, el usuario puede ponerse en contacto con el equipo de soporte de la empresa a trav&eacute;s de los canales designados.</p>
    <p>&nbsp;</p>
</>

export const termsConditionsRewardText = `
Términos y Condiciones de Uso y Cobro de Premios en la Aplicación "Simón"

 

Aceptación de Términos y Condiciones
Al utilizar la aplicación "Simón" y participar en el sistema de premios basado en puntos, el usuario acepta automáticamente todos los términos y condiciones establecidos en el presente documento. Si el usuario no está de acuerdo con estos términos, se le solicita abstenerse de usar la aplicación.

 

Sistema de Puntos y Premios
2.1. El usuario podrá acumular puntos al utilizar la aplicación "Simón" de acuerdo con las reglas establecidas por la empresa.

2.2. Los puntos acumulados por el usuario se utilizarán para el canje de premios previamente establecidos por la empresa.

 

Acumulación de Puntos
3.1. Los puntos podrán ser acumulados al completar acciones específicas dentro de la aplicación, como responder preguntas correctamente, participar en actividades designadas y cumplir con otros requisitos determinados por la empresa.

3.2. La empresa se reserva el derecho de ajustar la cantidad de puntos otorgados por cada acción y de modificar las reglas para la acumulación de puntos en cualquier momento, previo aviso a los usuarios.

 

Canje de Premios
4.1. Los premios disponibles para canjear serán determinados por la empresa y podrán variar en cantidad, tipo y valor.

4.2. El usuario podrá canjear sus puntos acumulados por premios en la aplicación "Simón". Los términos específicos para el canje de cada premio estarán disponibles en la descripción de dicho premio.

4.3. La empresa se reserva el derecho de modificar los premios disponibles para canjear, así como los requisitos para el canje, previo aviso a los usuarios.

 

Uso Responsable y Cumplimiento de Reglas
5.1. El usuario se compromete a utilizar la aplicación "Simón" de manera responsable y cumplir con las reglas establecidas por la empresa.

5.2. El incumplimiento de las reglas o el uso inapropiado de la aplicación podrá resultar en la pérdida de puntos acumulados, la suspensión de la cuenta del usuario o la cancelación de su participación en el sistema de premios, a criterio de la empresa.

 

Privacidad y Datos Personales
6.1. La empresa se compromete a proteger la privacidad de los usuarios y a tratar los datos personales de acuerdo con su Política de Privacidad. El usuario acepta y reconoce que al utilizar la aplicación "Simón", está de acuerdo con los términos de la Política de Privacidad de la empresa.

 

Modificaciones a los Términos y Condiciones
La empresa se reserva el derecho de modificar los presentes términos y condiciones en cualquier momento. Los cambios serán notificados a los usuarios a través de la plataforma o por otros medios adecuados. El uso continuado de la aplicación después de dichas modificaciones constituirá la aceptación de los nuevos términos y condiciones.

 

Contacto
Para cualquier consulta o inquietud relacionada con los términos y condiciones, el usuario puede ponerse en contacto con el equipo de soporte de la empresa a través de los canales designados.

 
`