import { CardInfoPrimary, CardInfoSecondary } from './components/CardInfo';
import { useMemo, useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { useDashboard } from './hooks/useDashboard';
import { Select } from '@/components';
import { InputDate } from '@/components/Inputs/InputText';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { t } from 'i18next';
import { Loader } from './components/Loader.tsx';

interface Dates {
  dateStart: string;
  dateEnd: string;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, Filler);

export const optionsBarAreas = {
  maintainAspectRatio: false,
  indexAxis: 'y' as const,
  barThickness: 30,
  beginAtZero: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  },
  elements: {
    bar: {
      borderWidth: 3
    }
  },
  responsive: true,
  interaction: {
    intersect: false
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any) => {
          if (context.datasetIndex === 0) {
            return `${t('generics.registeredUsers')} : ${context.formattedValue} `;
          } else {
            const allUsers = context.parsed._stacks.x[0] + context.parsed._stacks.x[1];
            return `${t('generics.expectedUsers')} : ${allUsers} `;
          }
        }
      }
    },
    legend: {
      display: false,
      position: 'top' as const
    },
    title: {
      display: true,
      text: ''
    }
  }
};

export const optionsBarLessons = {
  maintainAspectRatio: false,
  indexAxis: 'y' as const,
  barThickness: 30,
  beginAtZero: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  },
  elements: {
    bar: {
      borderWidth: 3
    }
  },
  responsive: true,
  interaction: {
    intersect: false
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any) => {
          if (context.datasetIndex === 0) {
            return `${t('pages.companies.usersInLesson')} : ${context.formattedValue} `;
          } else {
            const allUsers = context.parsed._stacks.x[0] + context.parsed._stacks.x[1];
            return `${t('pages.companies.usersEnded')} : ${allUsers} `;
          }
        }
      }
    },
    legend: {
      display: false,
      position: 'top' as const
    },
    title: {
      display: true,
      text: ''
    }
  }
};

export const optionsLine = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false
  },
  stacked: false,
  plugins: {
    title: {
      display: true
    }
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false
      }
    }
  }
};

interface Frequency {
  dateProps?: [number, 'day' | 'month' | 'year'];
  id: number;
  value: string;
}

export const DashboardApp = () => {
  const [dates, setDates] = useState<Dates>({
    dateStart: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    dateEnd: dayjs().format('YYYY-MM-DD')
  });

  const frequency: Frequency[] = [
    { dateProps: [7, 'day'], id: 1, value: t('generics.thisWeek') },
    { dateProps: [1, 'month'], id: 2, value: t('generics.thisMonth') },
    { dateProps: [1, 'year'], id: 3, value: t('generics.thisYear') },
    { dateProps: [3, 'month'], id: 4, value: t('generics.thisQuarter') },
    { dateProps: [6, 'month'], id: 5, value: t('generics.thisSemester') },
    { id: 6, value: t('generics.dateRange') }
  ];
  const idDates = '6';

  const [activateDates, setActivateDates] = useState<boolean>(false);

  const { isLoadingGeneral, isLoadingEngagement, startLoadData, dataGeneral, dataEngagement, isSuperAdmin, startSelectAllCompanies, selectAllCompanies, resetData } =
    useDashboard();

  const handleSelectAllCompanies = (e: React.ChangeEvent<HTMLInputElement>) => {
    startSelectAllCompanies(e.target.checked);
  };

  const handleSelectFrequency = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (idDates === value) {
      setActivateDates(true);
    } else {
      setDates((_) => {
        const [time, unit] = frequency.find((item) => item.id === Number(value))?.dateProps ?? [7, 'day'];
        return {
          dateEnd: dayjs().format('YYYY-MM-DD'),
          dateStart: dayjs().subtract(time, unit).format('YYYY-MM-DD')
        };
      });
      setActivateDates(false);
    }
  };

  const handleSelectInitialDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDates((dates) => ({ ...dates, dateStart: value }));
  };

  const handleSelectFinalDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDates((dates) => ({ ...dates, dateEnd: value }));
  };

  useEffect(() => {
    startLoadData({ ...dates });
    return () => {
      resetData();
    };
  }, [dates, selectAllCompanies]);

  const dataUserVsLessons = useMemo(() => {
    return (
      dataEngagement?.usersVsLessons?.reduce(
        (acc: any, item: any) => {
          return {
            labels: [...acc.labels, item.date],
            datasets: [
              {
                ...acc.datasets[0],
                data: [...acc.datasets[0].data, item.countUsers]
              },
              {
                ...acc.datasets[1],
                data: [...acc.datasets[1].data, item.countCompletedLessons]
              }
            ]
          };
        },
        {
          labels: [],
          datasets: [
            {
              label: t('generics.users'),
              data: [],
              fill: true,
              borderColor: '#134686',
              backgroundColor: 'rgba(236, 238, 247,0.5)',
              yAxisID: 'y'
            },
            {
              label: t('generics.lessons'),
              data: [],
              fill: true,
              borderColor: '#F9D748',
              backgroundColor: 'rgba(247, 245, 237,0.5)',
              yAxisID: 'y1'
            }
          ]
        }
      ) || { labels: [], datasets: [] }
    );
  }, [dataEngagement?.usersVsLessons]);

  const dataLessonsStartedVsLessonsCompleted = useMemo(() => {
    return (
      dataEngagement?.lessonsStartedVsLessonsCompleted?.reduce(
        (acc: any, item: any) => {
          return {
            labels: [...acc.labels, item.lessonName],
            datasets: [
              {
                ...acc.datasets[0],
                data: [...acc.datasets[0].data, item.usersCompleted]
              },
              {
                ...acc.datasets[1],
                data: [...acc.datasets[1].data, item.lessonUsers - item.usersCompleted]
              }
            ]
          };
        },
        {
          labels: [],
          datasets: [
            {
              label: t('pages.companies.usersInLesson'),
              data: [],
              fill: true,
              borderColor: '#F9D53E',
              backgroundColor: '#F9D53E',
              borderRadius: 10
            },
            {
              label: t('pages.companies.usersEnded'),
              data: [],
              fill: true,
              borderColor: '#F7EFCF',
              backgroundColor: '#F7EFCF',
              borderRadius: 10
            }
          ]
        }
      ) || { labels: [], datasets: [] }
    );
  }, [dataEngagement?.lessonsStartedVsLessonsCompleted]);

  const dataUsersAreaVsExpectedAreaUsers = useMemo(() => {
    return (
      dataEngagement?.usersAreaVsExpectedAreaUsers?.reduce(
        (acc: any, item: any) => {
          return {
            labels: [...acc.labels, item.areaName],
            datasets: [
              {
                ...acc.datasets[0],
                data: [...acc.datasets[0].data, item.usersArea]
              },
              {
                ...acc.datasets[1],
                data: [...acc.datasets[1].data, item.expectedAreasUsers - item.usersArea]
              }
            ]
          };
        },
        {
          labels: [],
          datasets: [
            {
              label: t('generics.registeredUsers'),
              data: [],
              fill: true,
              borderColor: '#00387C',
              backgroundColor: '#00387C',
              borderRadius: 10
            },
            {
              label: t('generics.expectedUsers'),
              data: [],
              fill: true,
              borderColor: '#D0E3FA',
              backgroundColor: '#D0E3FA',
              borderRadius: 10
            }
          ]
        }
      ) || { labels: [], datasets: [] }
    );
  }, [dataEngagement?.lessonsStartedVsLessonsCompleted]);

  const addPublicUsers = () => {
    if (dataGeneral && Array.isArray(dataGeneral.usersAreaVsExpectedAreaUsers)) {
      const plublicArea = 'Público';
      const findPublicArea = dataGeneral.usersAreaVsExpectedAreaUsers.find((area: any) => area.areaName === plublicArea);
      if (findPublicArea) {
        const summarizeUsers = findPublicArea.usersArea + dataGeneral.expectedAreasUsers;
        if (summarizeUsers > 0) return summarizeUsers;
      }
    }
    return 0;
  };

  const dataLessonsStartedVsLessonsCompletedLength = dataLessonsStartedVsLessonsCompleted.labels.length;

  const boxHeigthAreas =
    dataUsersAreaVsExpectedAreaUsers > 8 ? Math.floor((381 * dataUsersAreaVsExpectedAreaUsers.labels.length) / 8) : 381;

  // const secondsToMinutesString = (seconds: number) => {
  //   return dayjs.unix(seconds).format('mm:ss');
  // };

  const { dateEndMin, dateEndMax, dateStartMin, dateStartMax } = useMemo(() => {
    const dateStart = dayjs(dates.dateStart);
    const dateEnd = dayjs(dates.dateEnd);
    const dateEndMax = dayjs().format('YYYY-MM-DD');
    const dateEndMin = dateStart.format('YYYY-MM-DD');
    const dateStartMax = dateEnd.format('YYYY-MM-DD');
    const dateStartMin = dateEnd.subtract(1, 'year').format('YYYY-MM-DD');
    return {
      dateEndMin,
      dateEndMax,
      dateStartMin,
      dateStartMax
    };
  }, [dates]);

  return (
    <>
      <div className='flex items-center justify-between px-6 py-7 w-full'>
        <div>
          <p className='font-bold text-2xl text-[#1E293B]'>Dashboard</p>
          <p className='font-medium text-[#5F6060]'>{t('pages.dashboard.generalAdmim')}</p>
        </div>
        <div className='w-1/2 flex justify-end items-center'>
          {isSuperAdmin ? (
            <>
              <div className='mx-2'>
                <label className='label cursor-pointer'>
                  <span className='label-text'>{t('pages.dashboard.allCompanies')}</span>
                  <input
                    onChange={handleSelectAllCompanies}
                    type='checkbox'
                    checked={selectAllCompanies}
                    className='checkbox mx-2'
                  />
                </label>
              </div>
            </>
          ) : null}
          <Select
            onChange={handleSelectFrequency}
            options={frequency}
            optionmap={(value) => value.value}
            optionKeyMap={(value) => value.id}
            optionValueMap={(value) => value.id}
          />
          {activateDates ? (
            <div className='flex justify-center items-center'>
              <InputDate
                min={dateStartMin}
                max={dateStartMax}
                value={dates.dateStart}
                className='mx-1'
                placeholder={t('generics.initialDate')}
                onChange={handleSelectInitialDate}
              />
              <InputDate
                min={dateEndMin}
                max={dateEndMax}
                value={dates.dateEnd}
                className='mx-1'
                placeholder={t('generics.finalDate')}
                onChange={handleSelectFinalDate}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className='bg-functional-el-m px-5 pb-10'>
        {/* Cartas de datos generales de la empresa  */}
        {!isLoadingGeneral && dataGeneral ? (
          <>
            <div className='grid grid-cols-4 py-5 '>
              <CardInfoPrimary
                textOne={t('generics.lessons')}
                textTwo={dataGeneral?.countLessons ?? 0}
                textTree={`${dataGeneral?.countModules ?? 0} ${t('generics.modules')}`}
              />
              <CardInfoPrimary
                textOne={t('generics.users')}
                textTwo={dataGeneral?.countUsers ?? 0}
                textTree={`${dataGeneral?.percentageLessons ?? 0}% ${t('generics.seeVideos')}`}
              />
              <CardInfoPrimary
                textOne={t('pages.dashboard.usersAllData')}
                textTwo={dataGeneral?.usersCompletedProfile ?? 0}
                textTree={`${dataGeneral?.companiesCompletedInfo ?? 0} ${t('pages.dashboard.withCompanies')}`}
              />
              <CardInfoPrimary
                textOne={t('generics.pointsGenerated')}
                textTwo={dataGeneral?.countPoints ?? 0}
                textTree={`${dataGeneral?.pointsRedeemed ?? 0} ${t('generics.exchanged')}`}
              />
              {/* <CardInfoPrimary
                textOne={t('pages.dashboard.plataformTime')}
                textTwo={secondsToMinutesString(data?.timeAveragePerDay ?? 0)}
                textTree={t('generics.dailyMinutes')}
              /> */}

            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}

        {!isLoadingEngagement && dataEngagement ? (
          <>
            <div className='my-5 bg-functional-el-l p-10 rounded-2xl'>
              <div className='flex justify-between items-center'>
                <div>
                  <p className='font-bold text-2xl text-[#07203E]'>{t('pages.dashboard.useUsers')}</p>
                  <p> {t('pages.dashboard.presentAmount')}</p>
                </div>
                <div className='flex items-center'>
                  <div className='mx-2'>
                    <p className=' text-2xl text-[#07203E]'>{t('pages.dashboard.totalUsers')}</p>
                    <p className='font-bold text-2xl text-[#07203E]'>{dataGeneral?.countUsers ?? 0}</p>
                  </div>
                  <div className='mx-2'>
                    <p className=' text-2xl text-[#07203E]'>{t('pages.dashboard.lessonsStarted')}</p>
                    <p className='font-bold text-2xl text-[#07203E]'>{dataGeneral?.countLessons ?? 0}</p>
                  </div>
                </div>
              </div>
              {/* <Line options={options} data={dataDummy} />; */}
              <Line options={optionsLine} data={dataUserVsLessons} />;
            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}

        {!isLoadingGeneral && dataGeneral ? (
          <>
            <div className='grid grid-cols-5 py-5 '>
              <CardInfoSecondary
                textOne={t('pages.dashboard.usersWhoHave')}
                textTwo={dataGeneral?.usersWith1Lesson ?? 0}
                textTree={t('pages.dashboard.takenLesson')}
              />
              <CardInfoSecondary
                textOne={t('pages.dashboard.usersWhoHave')}
                textTwo={dataGeneral?.userWith2Lessons ?? 0}
                textTree={t('pages.dashboard.takenTwoLessons')}
              />
              <CardInfoSecondary
                textOne={t('pages.dashboard.usersWhoHave')}
                textTwo={dataGeneral?.userWith3Lessons ?? 0}
                textTree={t('pages.dashboard.takenThreeLessons')}
              />
              <CardInfoSecondary
                textOne={t('pages.dashboard.usersWhoHave')}
                textTwo={dataGeneral?.userWithMoreThan3Lessons ?? 0}
                textTree={t('pages.dashboard.takenMoreThreeLessons')}
              />
              <CardInfoSecondary
                textOne={t('generics.users')}
                textTwo={dataGeneral?.userWith1Module ?? 0}
                textTree={t('pages.dashboard.finishedModule')}
              />
            </div>
          </>) :
          (
            <>
              <Loader />
            </>
          )}

        {!isLoadingEngagement && dataEngagement ? (
          <>
            <div className='grid grid-cols-2'>
              <div className='p-3'>
                <p className='font-bold text-2xl text-[#07203E]'>{t('pages.dashboard.mostTakenLessons')}</p>
                <p>{t('pages.dashboard.lessonMessage')}</p>
              </div>
              <div className='p-3'>
                <p className='font-bold text-2xl text-[#07203E]'>{t('pages.dashboard.usersForArea')}</p>
                <p>{t('pages.dashboard.usersMessage')}</p>
              </div>
              <div className='bg-functional-el-l p-5 rounded-2xl mr-2'>
                <div className='flex items-center justify-between'>
                  <div>
                    <p className='font-bold text-xl text-[#07203E]'>{dataGeneral?.countLessons ?? 0}</p>
                    <p className='text-lg text-[#07203E]'>{t('generics.lessons')}</p>
                  </div>
                  <div className='flex items-center justify-center'>
                    <div>
                      <p className='font-bold text-xl text-[#07203E]'>{dataGeneral?.lessonsStarted ?? 0}</p>
                      <p className='text-lg text-[#07203E]'>{t('pages.dashboard.lessonsStarted')}</p>
                    </div>
                    <div className='mx-2 w-5 rounded-full bg-[#F7EFCF] h-5'></div>
                  </div>
                  <div className='flex items-center justify-center'>
                    <div>
                      <p className='font-bold text-xl text-[#07203E]'>{dataGeneral?.lessonsCompleted ?? 0}</p>
                      <p className='text-lg text-[#07203E]'>{t('pages.dashboard.finishedLessons')}</p>
                    </div>
                    <div className='mx-2 w-5 rounded-full bg-[#F9D53E] h-5'></div>
                  </div>
                </div>
                <div className='h-[800px] max-h-[400px] overflow-auto relative'>
                  {/* <div className={`!h-[${dataLessonsStartedVsLessonsCompletedLength > 8 ? Math.floor(381 * dataLessonsStartedVsLessonsCompleted.labels.length / 8) : 381}px] absolute w-full`}> */}
                  {/* <div className={`!h-[381px] absolute w-full`}> */}
                  <div
                    className={classNames(`absolute w-full`)}
                    style={{
                      height:
                        dataLessonsStartedVsLessonsCompletedLength > 8
                          ? String(dataLessonsStartedVsLessonsCompletedLength * 50) + 'px'
                          : '400px'
                    }}>
                    {/* <Bar className={`!h-[${dataLessonsStartedVsLessonsCompleted.labels > 8 ? 300 + ((dataLessonsStartedVsLessonsCompleted.labels - 8) * 900) : 300}px]`} options={optionsBar} data={dataLessonsStartedVsLessonsCompleted} />; */}
                    <Bar options={optionsBarLessons} data={dataLessonsStartedVsLessonsCompleted} />;
                  </div>
                </div>
              </div>
              <div className='bg-functional-el-l p-5 rounded-2xl ml-2'>
                <div className='flex items-center justify-between'>
                  <div>
                    <p className='font-bold text-xl text-[#07203E]'>{dataGeneral?.countAreas ?? 0}</p>
                    <p className='text-lg text-[#07203E]'>{t('generics.areas')}</p>
                  </div>
                  <div className='flex items-center justify-center'>
                    <div>
                      <p className='font-bold text-xl text-[#07203E]'>{addPublicUsers()}</p>
                      <p className='text-lg text-[#07203E]'>{t('generics.expectedUsers')}</p>
                    </div>
                    <div className='mx-2 w-5 rounded-full bg-[#D0E3FA] h-5'></div>
                  </div>
                  <div className='flex items-center justify-center'>
                    <div>
                      <p className='font-bold text-xl text-[#07203E]'>{dataGeneral?.countUsers ?? 0}</p>
                      <p className='text-lg text-[#07203E]'>{t('generics.registeredUsers')}</p>
                    </div>
                    <div className='mx-2 w-5 rounded-full bg-[#00387C] h-5'></div>
                  </div>
                </div>
                <div className='h-[800px] max-h-[400px]  overflow-auto relative'>
                  <div className={classNames(`absolute w-full`, `!h-[${boxHeigthAreas}px]`)}>
                    <Bar options={optionsBarAreas} data={dataUsersAreaVsExpectedAreaUsers} />;
                  </div>
                </div>
              </div>
            </div>
          </>) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};
