import { Module } from "@/pages/usuarios/models/users.models"

export const loadModuleAdapter = (module: Module) => {
    return {
        id: module.id,
        name: module.name,
        description: module.description,
        slug: module.slug,
        imageId: module.image_id,
        lessonsCount: module.lessons_count,
        companyAreas: module.company_areas,
        imagePath: module.image_path,
        isActive:module.is_active
    }
}