import { t } from "i18next"
import { useEffect, useState } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { useSurveyInfo } from "../hooks/useSurveyInfo";
import { MdDelete, MdSearch } from "react-icons/md";
import { useParams } from "react-router-dom";
import { StatusIndicator } from "./StatusIndicator";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ViewResponse } from "./ViewResponse";
import { Loader } from "@/pages/dashboard/components/Loader";
import { Response } from "../interfaces/survey.interface";

export const SurveyInfoPage = () => {

  const { navigateToInteractions, loadSurveyInfo, isLoadingSurveyInfo, surveyInfo, isLoadingResponses, responses, currentPage, lastPage, nextPage, previousPage, firstPage, questionId, totalPages, deleteResponse, loadResponses } = useSurveyInfo()
  const { areaId, surveySlug } = useParams()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentResponse, setCurrentResponse] = useState<Response | undefined>(undefined)

  useEffect(() => {
    if (!areaId || !surveySlug) return navigateToInteractions()
    loadSurveyInfo(parseInt(areaId), surveySlug)
  }, [])

  // Close Modal
  const onCloseModal = () => {
    setIsOpen(false)
    setCurrentResponse(undefined)
  };

  // Handle Edit response and open modal
  const handleEditResponse = (response: Response) => {
    setCurrentResponse(response)
    setIsOpen(true)
  }

  // Function to handle the percentage of the progress bar
  const handlePercentage = (total: number, answered: number) => {
    if (total === 0 || isNaN(total)) return 0
    return (answered / total) * 100
  }

  //  Function to delete an answer
  const handleDeleteAnswer = async (responseId: number) => {
    try {
      if (areaId && surveySlug && questionId) {
        await deleteResponse(parseInt(areaId), surveySlug, questionId, responseId)
        await loadResponses(parseInt(areaId), surveySlug, questionId)
      }
    }
    catch (err) {
      console.error('Error eliminando la respuesta', err)
    }
  }

  return (
    <>
      <div className="w-full text-center min-w-[900px] h-auto">
        <div className="w-full rounded-lg h-auto py-4 px-10 bg-white flex justify-between gap-x-[30px]">
          <div className="w-full justify-between items-center">
            <h2 className='text-[30px] font-semibold text-start text-black'>{surveyInfo?.title}</h2>
            <StatusIndicator status={surveyInfo?.status} />
          </div>
        </div>
        <div className="w-full h-auto  py-4 px-10 mt-4 bg-[#f5f5f5] min-h-[calc(100vh-180px)]">
          {
            !isLoadingSurveyInfo && surveyInfo && Array.isArray(surveyInfo.questions) && surveyInfo.questions[0] && (
              <>
                <p className="text-start text-black text-xl font-bold mt-4">{surveyInfo.questions[0].total_answered} {t('pages.interactions.haveAnswered')}</p>
                <p className="text-start text-sm">{t('pages.interactions.totalAnswersInThisSurvey')}</p>
                <div className="bg-white w-full rounded-lg p-4 mt-4 h-auto shadow-lg">
                  {
                    surveyInfo.questions[0].options.map((option) => (
                      <div className="flex w-full mb-4" key={option.title}>
                        <p className="text-start text-black text-[16px] w-[420px]">{option.option_text}</p>
                        {/* Progress Bar */}
                        <Progress percent={handlePercentage(surveyInfo.questions[0].total_answered, option.total_answered)}
                          style={{
                            height: '20px',
                          }}
                          theme={{
                            active: {
                              symbol: String(option.total_answered),
                              color: '#fbc630'
                            }
                          }} />
                      </div>
                    ))
                  }

                </div>
              </>
            )
          }
          {
            isLoadingSurveyInfo && (
              <Loader />
            )
          }
          {
            !isLoadingResponses && responses && responses.length > 0 && (
              <>
                <p className="text-start text-black text-xl font-bold mt-4">{t('pages.interactions.answersByUser')}</p>
                <p className="text-start text-sm">{t('pages.interactions.usersDetails')}</p>
                <div className="bg-white w-full rounded-lg p-4 mt-4 h-auto shadow-lg">
                  <div className='relative h-auto min-h-[300px] w-full rounded-lg overflow-hidden bg-white overflow-y-auto p-4'>
                    <div className='sticky w-full flex justify-between top-0 bg-[#ededed] z-[2] rounded-3xl mb-[10px]'>
                      <div className={`${headerCell} min-w-[70px]`}>Id</div>
                      <div className={`${headerCell} w-full max-w-[250px]`}>{t('pages.interactions.name')}</div>
                      <div className={`${headerCell} w-full`}>{t('pages.interactions.answer')}</div>
                      <div className={`${headerCell} min-w-[150px] max-w-[250px]`}>{t('pages.interactions.date')}</div>
                      <div className={`${headerCell} min-w-[150px] max-w-[150px]`}>{t('pages.interactions.actions')}</div>
                    </div>
                    {
                      responses.map((response) => (
                        <div className={`w-full flex justify-between bg-white border-b border-gray-200`} key={response.id}>
                          <div className={`${bodycell} min-w-[70px]`}>{response.id}</div>
                          <div className={`${bodycell} w-full max-w-[250px]`}>{response.user.name}</div>
                          <div className={`${bodycell} w-full`}>{response.response_text}</div>
                          <div className={`${bodycell} min-w-[150px] max-w-[250px]`}>{new Date(response.created_at).toLocaleDateString()}</div>
                          <div className={`${bodycell} min-w-[150px] max-w-[150px] justify-center`}>
                            <button
                              onClick={() => handleEditResponse(response)}
                              className={`${buttonAction} text-white bg-primary-l-m hover:bg-primary-l-d`}
                              title="Editar"
                              type="button">
                              <MdSearch />
                            </button>
                            <button
                              onClick={() => handleDeleteAnswer(response.id)}
                              className={`${buttonAction} bg-red-800 text-white hover:bg-red-900`}
                              title="Borrar"
                              type="button">
                              <MdDelete />
                            </button>
                          </div>
                        </div>
                      ))
                    }

                  </div>

                </div>
                {
                  areaId && surveySlug && questionId && (
                    <div className='flex justify-center mt-4'>
                      {
                        currentPage > 1 && (
                          <>
                            <button type="button"
                              onClick={() => {
                                firstPage(parseInt(areaId), surveySlug, questionId)
                              }}>
                              <span className="material-symbols-outlined cursor-pointer text-3xl">first_page</span>
                            </button>
                            <button type="button"
                              onClick={() => {
                                previousPage(parseInt(areaId), surveySlug, questionId)
                              }}
                            >
                              <span className="material-symbols-outlined cursor-pointer text-3xl">keyboard_arrow_left</span>
                            </button>
                          </>
                        )
                      }
                      <p className='p-2'>{currentPage}-{totalPages}</p>
                      {
                        currentPage < totalPages && (
                          <>
                            <button type="button"
                              onClick={() => {
                                nextPage(parseInt(areaId), surveySlug, questionId)
                              }}
                            >
                              <span className="material-symbols-outlined cursor-pointer text-3xl">keyboard_arrow_right</span>
                            </button>
                            <button type="button"
                              onClick={() => {
                                lastPage(parseInt(areaId), surveySlug, questionId)
                              }}
                            >
                              <span className="material-symbols-outlined cursor-pointer text-3xl">last_page</span>
                            </button>
                          </>
                        )
                      }
                    </div>
                  )
                }
              </>
            )
          }

          {
            isLoadingResponses && (
              <Loader />
            )
          }

          <button type="button" onClick={navigateToInteractions} className="block m-auto bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-800 mt-4">{t('generics.goBack')}</button>

        </div>
      </div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <ViewResponse closeFunction={onCloseModal} response={currentResponse} />
      </Modal>
    </>
  )
}


const headerCell = 'text-left min-h-[50px] px-4 font-semibold flex items-center truncate';
const buttonAction = 'bg-primary-l-m text-[20px] p-2 rounded-lg right-0 mr-2';
const bodycell = 'relative text-left min-h-[50px] px-4 flex items-center truncate';