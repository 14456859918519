import { useEffect } from 'react';
import { useLoadImage } from '@/hooks/useLoadImage';
import { AppStore } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { proccessError } from '@/utilities/stringsError';
import { loadLesson, resetLesson, setIsLoading, setStatus } from '@/redux/states/lessons/crudLesson.slice';
import { loadLessonAdapter } from '../adapters/loadLesson.adapter';
import { useOrderList } from '@/hooks/useOrder';
import useAxiosPrivate from '@/hooks/useApiV2Private';
import { useLoadCompaniesLessons } from './useLoadCompaniesLessons';
interface UseCrudLessonProps {
  lessonSelected: string;
  moduleSelected: string;
}

export const useCrudLesson = ({ moduleSelected, lessonSelected }: UseCrudLessonProps) => {
  const dispatch = useDispatch();
  const apiV2 = useAxiosPrivate();

  const { isLoading, status, lesson } = useSelector((state: AppStore) => state.crudLesson);
  const { companySlug } = useSelector((state: AppStore) => state.ui);

  const {
    isLoading: isLoadingLessons,
    lessons,
    startLoadLessons
  } = useLoadCompaniesLessons(companySlug, moduleSelected);

  const { image, setImage, uploadImage, loadImageWithValidations } = useLoadImage();

  const { orders, startLoadOrder, isLoadingOrders } = useOrderList(companySlug, moduleSelected);

  const startloadLesson = async () => {
    dispatch(setIsLoading(true));
    try {
      const resp = await apiV2.get(`/companies/${companySlug}/modules/${moduleSelected}/lessons/${lessonSelected}`);
      dispatch(loadLesson(loadLessonAdapter(resp.data)));
      setImage({
        image_id: resp.data.web_image_id,
        image_path: resp.data.web_image_path
      });
      toast.success('Datos de la lección cargados');
    } catch (error) {
      toast.error('Error en el servidor');
    }
  };

  const createLesson = async (data: any) => {
    delete data.image;
    let respImage: number | undefined | void;
    try {
      if (image?.file) {
        await uploadImage(image.file).then((e) => {
          respImage = e;
        });
      }
      await apiV2.post(`/companies/${companySlug}/modules/${moduleSelected}/lessons`, {
        name: data.name,
        description: data.description,
        is_active: data.isActive || false,
        order: data?.order !== 'default' ? parseInt(data.order) : 0,
        web_image_id: respImage,
        mobile_image_id: respImage,
        type: 'lesson',
        require_auth: data.require_auth,
        next_lesson_slug: data.nextLessonSlug
      });
      toast.success('Lección creada con éxito');
      return { message: 'success' };
    } catch (error: any) {
      if (error.response.status === 400) {
        toast(proccessError(error.response.data));
        toast.error('La lección no ha sido creado:');
      }
    }
  };

  const updateLesson = async (data: any) => {
    delete data.image;
    let respImage: number | undefined | void;
    try {
      if (image?.file) {
        await uploadImage(image.file).then((e) => {
          respImage = e;
        });
      }
      await apiV2.patch(`/companies/${companySlug}/modules/${moduleSelected}/lessons/${lessonSelected}`, {
        ...lesson,
        name: data.name,
        description: data.description,
        is_active: data.isActive,
        order: data.order,
        web_image_id: respImage ?? image?.image_id,
        mobile_image_id: respImage ?? image?.image_id,
        require_auth: data.require_auth,
        next_lesson_slug: data.nextLessonSlug
      });
      toast.success('Lección actualizada con éxito');
      return { message: 'success' };
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast(proccessError(error.response.data));
        toast.error('El módulo no ha sido modificado:');
      }
    }
  };

  const editing = () => {
    dispatch(setStatus('editing'));
  };

  const viewing = () => {
    dispatch(setStatus('viewing'));
  };

  useEffect(() => {
    startLoadOrder();
  }, [status]);

  useEffect(() => {
    startLoadLessons();
    if (lessonSelected) {
      startloadLesson();
    } else {
      dispatch(setStatus('creating'));
    }
    return () => {
      dispatch(resetLesson());
    };
  }, [lessonSelected, moduleSelected]);

  return {
    image,
    lesson,
    status,
    orders,
    isLoading,
    isLoadingOrders,
    moduleSelected,
    isLoadingLessons,
    lessons,
    uploadImage,
    startLoadLessons,
    editing,
    viewing,
    updateLesson,
    createLesson,
    setImage,
    loadImageWithValidations
  };
};
