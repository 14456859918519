
import { useForm, SubmitHandler } from 'react-hook-form';

import { boxFormStyle, pStyle } from '../styles/login.styled';
import { InputAttrs, LoginProp, View } from '../models/login.models';
import { InputLabel } from '@/components/Inputs/InputText';


export type LoginAppProps = {
}

const inputAttrMap: InputAttrs[] = [
    { name: 'email', key: 1, placeholder: 'Correo electrónico: *', message: 'El correo es requerido' },
    { name: 'password', key: 2, placeholder: 'Contraseña: *', message: 'La contraseña es requerida' }
]

interface LoginProps {
    startLogin: any
    setView: React.Dispatch<React.SetStateAction<View>>
}


const Login: React.FC<LoginProps> = ({ startLogin, setView }) => {

    const { register, handleSubmit, formState: { errors }, } = useForm<LoginProp>()


    const onSubmit: SubmitHandler<LoginProp> = (data) => {
        startLogin(data)
    }

    const handleForgotPassword = () => {
        setView('forgotPassword')
    }

    return <div className={boxFormStyle}>
        <div className='w-8/12'>
            <p className={pStyle}>Ingreso</p>
            <img src="/lineas/simon_plataforma-13.svg" width={'50%'} />
            <p className='text-[#898B92] w-3/4 my-4'>Si no tienes una cuenta escribe a dime@holasimon.com</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                {inputAttrMap.map(({ name, key, placeholder, message }) => {
                    return <div key={key}>
                        <InputLabel placeholder={placeholder} name={name} type={name} register={{ ...register(name, { required: { value: true, message } }) }} />
                        <p className={errors?.[name]?.message ? '' : 'hidden' + ' text-[#ffdcec]'} >{errors?.[name]?.message}</p>
                    </div>
                }
                )}
                <div className='flex justify-between my-2 mx-1'>
                    <label className='text-[#383838]'>
                        <input type="checkbox" {...register('rememberMe')} className='mx-1' />
                        Recúerdame
                    </label>
                    <p onClick={handleForgotPassword} className='cursor-pointer text-[#F9D53E] text-right'>¿Olvidaste tu contraseña?</p>
                </div>
                <button className='cursor-pointer bg-[#F9D53E] my-10 w-full rounded-xl h-12' type="submit">Ingresar</button>
            </form>
        </div>
    </div>
};

export default Login;
