import { Pagination } from '@/models/pagination.models';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateLessons {
    isLoading: boolean
    lessons: any
    pagination: Pagination | {}
    errorMessage: string | null
    per_page:number
    moduleSelected: string
}

const initialStateLessons:InitialStateLessons = {
    isLoading: false,
    lessons: undefined,
    pagination: {},
    errorMessage: null,
    per_page: 9,
    moduleSelected: ''
}

export const lessonsSlice = createSlice({
    name: 'lecciones',
    initialState: initialStateLessons,
    reducers: {
        loadLessons: (state, action) => {
            state.lessons = action.payload.items
            state.pagination = action.payload.pagination
            state.isLoading = false
        },
        setIsLoading: (state,action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setSelectModule: (state, action) => {
            state.moduleSelected = action.payload
            state.lessons = undefined
        },
        setInitialState: () => initialStateLessons
    }
});


// Action creators are generated for each case reducer function
export const { setInitialState ,setIsLoading, loadLessons, setError, setSelectModule } = lessonsSlice.actions;

