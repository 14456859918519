import { createSlice } from '@reduxjs/toolkit';

export const personalizationCompanySlice = createSlice({
    name: 'empresa',
    initialState: {
        isLoading: false,
        errorMessage: null,
        status: 'editing',
        company: {},
        crudDisabled: false
    },
    reducers: {
        setCrudDisabled: (state,action) => {
            state.crudDisabled = action.payload
        },
        loadCompany: (state, action) => {
            state.company = action.payload
            // state.status = 'viewing'
            state.isLoading = false
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
            state.isLoading = false
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        resetCompany: (state) => {
            state.company = {}
            state.isLoading = false
        }
    }
});


// Action creators are generated for each case reducer function
export const { setCrudDisabled, setError, setIsLoading, setStatus, resetCompany, loadCompany } = personalizationCompanySlice.actions;

