import { PrimaryButton, SecondaryButton } from '@/components';
import { InputText } from '@/components/Inputs/InputText';
import { TextArea } from '@/components/Inputs/TextArea';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { SmallSpinner } from '@/components/Spinners/Spinner';
import { useLoadAreas } from '@/hooks/useLoadAreas';
import fileService, { IUploadFileResponse } from '@/shared/services/apis/file.service';
import { IHttpCallResponse } from '@/shared/services/apis/http-call.service';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { RiDeleteBin6Line, RiFolderUploadFill } from 'react-icons/ri';
import { Oval } from 'react-loader-spinner';
interface AreaFormProps {
  desc: string;
  name: string;
  expectedUsers: number;
  nameCertificate: string;
  signatureName: string;
  signaturePosition: string;
  willGrantCertificate: boolean;
}

interface CreateAreaProps {
  save: () => void;
  close: () => void;
  companySlug: string;
  idArea?: string;
  isPublic?: boolean;
}

interface IPictureArea {
  id: number | null;
  path: string;
}

enum UploadType {
  BANNER = 'banner',
  CERTIFICATE = 'certificate'
}

export const CreateArea: React.FC<CreateAreaProps> = ({ close, companySlug, idArea, isPublic }) => {
  const { createArea, getArea, updateArea, isLoadingArea } = useLoadAreas(companySlug);

  const [pictureAreaCert, setPictureAreaCert] = useState<IPictureArea>({
    id: null,
    path: ''
  });
  const [loadingCert, setLoadingCert] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    const resp = idArea ? updateArea(data, pictureAreaCert.id) : createArea(data, pictureAreaCert.id);
    resp.then((e) => {
      if (e === 'success') return close();
    });
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    close();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<AreaFormProps>();

  const loadAreaDefault = async (idArea: string) => {
    const resp = await getArea(idArea);
    const { name, info, expectedUsers, signatureName, signaturePosition, certificateLogo, certificateLogoPath, willGrantCertificate } = resp?.data ?? {
      name: '',
      desc: '',
      expectedUsers: 0,
      signatureName: '',
      signaturePosition: '',
      certificateLogo: null,
      certificateLogoPath: '',
      willGrantCertificate: false
    };
    setValue('name', name ?? '');
    setValue('desc', info?.desc ?? '');
    setValue('expectedUsers', expectedUsers ?? 0);
    setValue('signatureName', signatureName ?? '');
    setValue('signaturePosition', signaturePosition ?? '');
    setPictureAreaCert({ id: certificateLogo, path: certificateLogoPath });
    setValue('willGrantCertificate', willGrantCertificate ?? false);
  };

  const willGrantCertificateWatch = watch('willGrantCertificate');

  const uploadImage = async (imageFile: File, uploadType: UploadType): Promise<void> => {
    const fileSize1MB = 1166400;

    if (imageFile.type !== 'image/jpeg' && imageFile.type !== 'image/png') {
      toast.error(t('pages.webinars.errorTypeImg'));
      return;
    }

    if (imageFile.size > fileSize1MB) {
      toast.error(t('pages.webinars.errorSizeImg'));
      return;
    }

    /* if (uploadType === UploadType.BANNER) setLoadingBanner(true); */
    if (uploadType === UploadType.CERTIFICATE) setLoadingCert(true);

    const fileResponse: IHttpCallResponse<IUploadFileResponse> = await fileService.uploadFile(imageFile);

    if (fileResponse.successful && fileResponse.data) {
      /*       if (uploadType === UploadType.BANNER) {
              setPictureArea({ id: fileResponse.data.file_id, path: fileResponse.data.path })
              setLoadingBanner(false);
              return;
            } */
      setPictureAreaCert({ id: fileResponse.data.file_id, path: fileResponse.data.path })
      setLoadingCert(false);
      return;
    }

    /* if (uploadType === UploadType.BANNER) setLoadingBanner(false); */
    if (uploadType === UploadType.CERTIFICATE) setLoadingCert(false);

    toast.error(t('pages.lessons.errorUploadPicture'));
  };

  useEffect(() => {
    if (idArea) {
      loadAreaDefault(idArea);
    }
  }, [idArea]);

  return (
    <>
      {!isLoadingArea ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            className='w-5/6'
            placeholder={t('generics.name')}
            disabled={isPublic}
            register={() => register('name', { required: t('errorMessage.requiredField') })}
          />
          {errors.name ? <p className='text-warning-d'>{errors.name.message}</p> : null}
          <TextArea
            placeholder={t('generics.description')}
            register={() => register('desc', { required: t('errorMessage.requiredField') })}
          />
          {errors.desc ? <p className='text-warning-d'>{errors.desc.message}</p> : null}
          <InputText
            className='w-5/6'
            placeholder={t('generics.expectedUsers')}
            type='number'
            register={() => register('expectedUsers', { required: t('errorMessage.requiredField') })}
          />
          {errors.expectedUsers ? <p className='text-warning-d'>{errors.expectedUsers.message}</p> : null}

          {/* Certificates variables */}
          <h3 className='text-xl mt-5 text-black font-bold'>{t('generics.certificates')}</h3>
          <hr className='mb-3 border-black' />
          <p className='mb-1 text-lg text-black font-semibold'>
            {t('pages.companies.willGrantCertificateQuestion')}
          </p>
          {/* Switch */}
          <ToggleSwitch
            message=''
            register={() => register('willGrantCertificate')}
          />
          {
            willGrantCertificateWatch && (
              <>
                <div className='mt-2'></div>
                <InputText
                  className='w-5/6'
                  placeholder={t('generics.nameAndSignature')}
                  type='text'
                  register={() => register('signatureName', { required: t('errorMessage.requiredField') })}
                />
                {errors.signatureName ? <p className='text-warning-d'>{errors.signatureName.message}</p> : null}
                <div className='mt-2'></div>
                <InputText
                  className='w-5/6'
                  placeholder={t('generics.occupation')}
                  type='text'
                  register={() => register('signaturePosition', { required: t('errorMessage.requiredField') })}
                />
                {errors.signaturePosition ? <p className='text-warning-d'>{errors.signaturePosition.message}</p> : null}
                <label className='flex flex-col w-[40%]'>
                  <span className='text-[18px] font-semibold mt-4 mb-3 text-black'>Banner certificados:</span>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        uploadImage(event.target.files[0], UploadType.CERTIFICATE);
                      }
                    }}
                    className='hidden'
                  />
                  <div className='relative w-full h-[100px] cursor-pointer'>
                    {pictureAreaCert.path ? (
                      <div className='relative'>
                        <img src={pictureAreaCert.path} alt='img-part' className='w-full h-full object-cover rounded-lg mx-auto shadow-md' />
                        <button
                          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            event.preventDefault();
                            setPictureAreaCert({ id: null, path: '' });
                          }}
                          className='absolute top-[-10px] right-[-10px] bg-red-500 text-[30px] text-white p-2 rounded-lg'>
                          <RiDeleteBin6Line />
                        </button>
                      </div>
                    ) : (
                      <img src={'/imgLayout.png'} alt='img-void' className='w-full h-full object-cover rounded-lg mx-auto shadow-md' />
                    )}
                    {loadingCert && (
                      <div className='absolute top-0 h-full w-full flex items-center justify-center'>
                        <Oval height='60' width='60' color='black' secondaryColor='#adb5bd' strokeWidth={5} strokeWidthSecondary={5} />
                      </div>
                    )}
                    <div className='absolute bottom-[-10px] right-[-10px] bg-primary-l-m p-3 text-[30px] rounded-full'>
                      <RiFolderUploadFill />
                    </div>
                  </div>
                </label>
              </>
            )
          }

          <div className='modal-action'>
            {<PrimaryButton type='submit'>{t('generics.accept')}</PrimaryButton>}
            {
              <SecondaryButton className='px-5' onClick={handleCancel}>
                {t('generics.cancel')}
              </SecondaryButton>
            }
          </div>
        </form>
      ) : (
        <SmallSpinner />
      )}
    </>
  );
};
