
import { Rewards } from "../models/rewards.models"

export const loadRewardsAdapter = (rewards:Rewards) => {
    return rewards.map((reward) => {
        return ({
            id: reward.id,
            name: reward.name,
            isActive: reward.is_active,
            points: reward.points,
            image_id: reward.image_id,
            image_path: reward.image_path
        })
    }
    )

}