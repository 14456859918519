import { Reward } from "../models/rewards.models"

export const loadRewardAdapter = (reward: Reward) => {
    return ({
        id: reward.id,
        name: reward.name,
        isActive: reward.is_active,
        points: reward.points,
        image_id: reward.image_id,
        image_path: reward.image_path,
        terms_conditions:reward.terms_conditions,
        delivery_policy:reward.delivery_policy,
        available: reward.available,
        exchanged:reward.exchanged,
        unit_cost: reward.unit_cost
    })
}
