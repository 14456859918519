import { useEffect, useState } from 'react';
import { STATE_OBJECTIVES, STATE_OBJECTIVES_OPTIONS } from '../../constants/statesObjectives';
import { ShowObjectives } from './ShowObjectives';
import { CreateObjective } from './CreateObjective';
import { useObjectives } from './hooks/useObjectives';
import { SmallSpinner } from '@/components/Spinners/Spinner';
import { Objective } from './interfaces/objectives';
import { EditObjective } from './EditObjective';

export const ObjectivesBox: React.FC<any> = ({ moduleSlug, areas, }) => {
  // This state defines if the user is creating, editing or seeing the objectives
  const [stateObjectives, setstateObjectives] = useState(STATE_OBJECTIVES.SEE)
  const [objectiveToEdit, setobjectiveToEdit] = useState<Objective | undefined>(undefined)
  // Hook get objectives
  const { isLoading, loadObjectives, objectives, createObjective, deleteObjective, updateObjective } = useObjectives()

  useEffect(() => {
    if (stateObjectives === STATE_OBJECTIVES.SEE){
      loadObjectives(moduleSlug)
    }
  }, [stateObjectives])
  /**
   * Updates the state of the objectives.
   * @param state - The new state value.
   */
  const updateStateObjectives = (state: string) => {    
    if (!STATE_OBJECTIVES_OPTIONS.includes(state)) return    
    setstateObjectives(state);
  }

  /**
   * Starts editing the specified objective.
   * 
   * @param objective - The objective to be edited.
   */
  const startEditObjective = (objective: Objective) => {
    if (!objective) return
    setobjectiveToEdit(objective)
    updateStateObjectives(STATE_OBJECTIVES.EDIT)
  }

  return (
    <>
    {!isLoading && (
        <div className="px-10 pb-10">
          {
            stateObjectives === STATE_OBJECTIVES.SEE && (
              <ShowObjectives updateStateObjectives={updateStateObjectives} areas={areas} objectives={objectives} deleteObjective={deleteObjective} moduleSlug={moduleSlug} startEditObjective={startEditObjective} updateObjective={updateObjective} />
            )}
          {
            stateObjectives === STATE_OBJECTIVES.CREATE && (
              <CreateObjective updateStateObjectives={updateStateObjectives} areas={areas} createObjective={createObjective} moduleSlug={moduleSlug}/>
            )}
          {
            stateObjectives === STATE_OBJECTIVES.EDIT && objectiveToEdit && (
              <EditObjective updateStateObjectives={updateStateObjectives} objective={objectiveToEdit} areas={areas} updateObjective={updateObjective} moduleSlug={moduleSlug} />
            )
          }
        </div >
    )}
    {
      isLoading && (
        <div className="flex justify-center items-center h-50">
            <SmallSpinner />
        </div>
      )
    }
    </>
  )
}