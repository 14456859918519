import { AppStore } from "@/redux"
import { useDispatch, useSelector } from "react-redux"
import { paginationAdapter } from "@/adapters/pagination.adapter"
import { usePagination } from "@/hooks/usePagination"
import { useState } from "react"
import { loadUsers, setDeleteUser, setIsLoading, setResetState, setSelectUser, setToggleActiveUser, setToggleOnlyPublic } from "@/redux/states/users/users.slice"
import { loadUsersAdapter } from "../adapters/loadUsers.adapter"
import toast from "react-hot-toast"
import useAxiosPrivate from "@/hooks/useApiV2Private"

let controller: AbortController, signal, oldValue = ''
export const useUsers = (companySlug: string) => {

    const apiV2 = useAxiosPrivate()

    const [value, setValue] = useState('')

    const dispatch = useDispatch()

    const { isLoading, usuarios, pagination, per_page, onlyPublic } = useSelector((state: AppStore) => state.users)

    const { nextPage, previousPage, initialPage, finalPage, changePage, currentPage } = usePagination({ lastPage: pagination.last_page })
    const startLoadUsers = async () => {
        dispatch(setIsLoading(true))
        try {
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/users`, {
                params: { 
                    only_public: onlyPublic,
                    per_page, 
                    page: oldValue === value ? currentPage : 1, 
                    search: value, 
                },
                signal
            })
            oldValue = value
            dispatch(loadUsers({ pagination: paginationAdapter(resp.data.pagination), items: loadUsersAdapter(resp.data.items) }))
        } catch (error) {
            dispatch(setIsLoading(false))
        } 
    }

    const toggleActiveUser = async ({ id, isActive }: { id: number, isActive: boolean }) => {
        try {
            const resp = await apiV2.post(`/users/${id}/status/toggle`, { is_active: !isActive })
            dispatch(setToggleActiveUser(({id, isActive:resp.data.is_active })))
            toast.success(`Usuario ${isActive ? 'desactivado' : 'activado'}`)
        } catch (error) {
            toast.error(`El usuario no se ha podido ${isActive ? 'desactivar' : 'activar'}`)
        }
    }

    const selectUser = (id: string | number | undefined) => {
        dispatch(setSelectUser(id))
    }

    const startResetState = () => {
        dispatch(setResetState())
    }

    const startDeleteUser = async (id:number) =>{
        dispatch(setIsLoading(true))
        try {
            await apiV2.delete(`/users/${id}`)
            dispatch(setDeleteUser(id))
        } catch (error) {
            toast.error('Existe un error en el servidor al eliminar el premio')
            dispatch(setIsLoading(false))
        } 
    }

    const selectOnlyPublic = (isSelected:boolean) => {
        dispatch(setToggleOnlyPublic(isSelected))
    }

    return {
        // propiedades
        isLoading,
        usuarios,
        pagination,
        per_page,
        value,
        currentPage,
        onlyPublic,
        // Metodos
        startLoadUsers,
        selectUser,
        // Metodos de paginación
        nextPage,
        previousPage,
        initialPage,
        finalPage,
        changePage,
        // Metodos para busqueda de usuarios
        setValue,
        toggleActiveUser,
        startDeleteUser,
        selectOnlyPublic,
        startResetState,
    }

}