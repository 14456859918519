import { PrimaryButton } from '@/components'
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch'
import classNames from 'classnames'
import { t } from 'i18next'
import { headerObjectives } from '../../constants'
import { STATE_OBJECTIVES } from '../../constants/statesObjectives'
import { Objective } from './interfaces/objectives'
import { transformArea, transformType } from './helpers/transformType'
import { Area } from '@/models/areas.models'
import { useState } from 'react'

type Props = {
  updateStateObjectives: (state: string) => void
  objectives: Objective[]
  areas: Area[]
  deleteObjective: (moduleSlug: string, objectiveId: string) => void
  moduleSlug: string
  startEditObjective: (objective: Objective) => void
  updateObjective: (moduleSlug: string, objectiveId: string, data: any) => Promise<void>
}

export const ShowObjectives = ({ updateStateObjectives, objectives, areas, deleteObjective, moduleSlug, startEditObjective, updateObjective }: Props) => {

  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  // Handle click create objective
  const handleCreateObjective = () => {
    updateStateObjectives(STATE_OBJECTIVES.CREATE)
  }

  /**
   * Handles the change of status for an objective.
   * 
   * @param objective - The objective to update.
   */
  const handleChangeStatus = async (objective: Objective) => {
    if (isUpdating) return
    try {
      setIsUpdating(true)
      const objectiveUpdated = { ...objective, active: !objective.active }
      delete objectiveUpdated.id
      await updateObjective(moduleSlug, String(objective.id), objectiveUpdated)
      const objectiveFound = objectives.find((objective: Objective) => objective.id === objective.id)
      if (objectiveFound) {
        objectiveFound.active = !objectiveFound.active
      }
    }
    catch (err) {
      console.error('Error al cambiar el estado del objetivo', err)
    }
    finally {
      setIsUpdating(false)
    }
  }

  return (
    <div className='p-10 bg-functional-el-l rounded-3xl shadow-lg'>
      {/* Title */}
      <div className="title">
        <div className='flex justify-between'>
          <div>
            <p className='font-bold text-xl text-text-functional-d-l'>{t('pages.modules.objectives')}</p>
            <p className='font-medium text-functional-d-l'>{t('pages.modules.enterDataObjectives')}</p>
          </div>
          <PrimaryButton
            onClick={handleCreateObjective}
          >
            {t('pages.modules.createObjective')}
          </PrimaryButton>
        </div>
      </div>
      {/* Table */}
      <table className='table-auto w-full rounded-none mt-[30px]'>
        <thead className='text-black'>
          <tr className=' '>
            {headerObjectives.map(({ name, textAlign }) => (
              <th
                key={name}
                className={classNames(
                  'first:rounded-l-2xl last:rounded-r-2xl bg-functional-el-m text-lg p-3',
                  textAlign
                )}>
                {name}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table body */}
        <tbody>
          {
            objectives.map((objective: Objective, index: number) => (
              <tr key={index} className='border border-functional-el-l border-b-functional-l-d'>
                <td className='py-2'>{transformType(objective.type)}</td>
                <td className='py-2'>{objective.config.question}</td>
                <td className=' py-2'>{transformArea(objective.area, areas)}</td>
                <td className='text-center py-2'>
                  <ToggleSwitch
                    message=''
                    checked={objective.active}
                    onChange={() => handleChangeStatus(objective)}
                  />
                </td>
                <td className='text-center py-2'>
                  {/* Actions delete and edit */}
                  <button
                    type='button'
                    title='Editar'
                    className='cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined'
                    onClick={() => startEditObjective(objective)}
                  >
                    edit_square
                  </button>
                  <button
                    type='button'
                    title={t('generics.delete')}
                    className='cursor-pointer bg-functional-l-d p-2 text-center mx-1 rounded text-functional-el-l material-symbols-outlined'
                    onClick={() => deleteObjective(moduleSlug, String(objective.id))}
                  >
                    delete
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
