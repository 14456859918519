import { Link, useLocation } from 'react-router-dom';
import { Select } from '..';
import { SmallSpinner } from '../Spinners/Spinner';
import { CompanyProp } from '@/hooks/useLoadCampanies';
import { t } from 'i18next';

const routesMapString: any = {
  contrasena: t('generics.password'),
  dashboard: 'Dashboard',
  empresas: t('generics.companies'),
  usuarios: t('generics.users'),
  perfil: t('generics.profile'),
  crud: t('generics.editData'),
  lecciones: t('generics.lessons'),
  partes: t('generics.steps'),
  premios: t('generics.awards'),
  modulos: t('generics.modules'),
  personalizar: t('generics.personalize'),
  licencias: t('generics.licenses'),
  areas: t('generics.areas')
};
interface NavbarProps {
  onClickMenu: () => void;
  isLoading: boolean;
  companies: CompanyProp[] | undefined;
  companySlug: string;
  selectCompany: (slug: string) => void;
}

export const Navbar: React.FC<NavbarProps> = ({ selectCompany, onClickMenu, isLoading, companies, companySlug }) => {
  const { pathname, state } = useLocation();
  const listPath = pathname.split('/').filter((path) => path !== '');
  let currentLink = '';
  const Breadcrumb = listPath.map((path) => {
    currentLink = currentLink + `/${path}`;
    return (
      <li key={path}>
        <Link className=' font-medium text-lg' to={currentLink} state={state}>
          {routesMapString[path]}
        </Link>
      </li>
    );
  });

  const handleSelectCompany = (e: React.ChangeEvent<HTMLSelectElement>) => {
    selectCompany(e.target.value);
  };

  return (
    <header className='flex h-16 items-center px-4 shadow-lg relative z-50 justify-between'>
      <div className='flex items-center'>
        <span onClick={onClickMenu} className='material-symbols-outlined font-bold text-3xl cursor-pointer'>
          menu
        </span>
        <div className='text-sm breadcrumbs mx-3'>
          <ul>{Breadcrumb}</ul>
        </div>
      </div>
      {listPath.length === 1 ? (
        !isLoading ? (
          <Select
            onChange={handleSelectCompany}
            defaultValue={'default'}
            className='w-auto'
            options={companies}
            optionmap={(value) => value.name}
            optionValueMap={(value) => value.slug}
            optionKeyMap={(value) => value.id}
            value={companySlug || 'default'}
          />
        ) : (
          <SmallSpinner />
        )
      ) : null}
    </header>
  );
};
