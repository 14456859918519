export const users = [{
        "id": 34,
        "name": "Rodmar Zavala",
        "email": "rodmar.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    },
    {
        "id": 658,
        "name": "Rodmar Zavala 1",
        "email": "rodmar06.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 56,
        "name": "Rodmar Zavala 2",
        "email": "rodmar6.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 98,
        "name": "Rodmar Zavala 3",
        "email": "rodmar98.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 367,
        "name": "Rodmar Zavala 4",
        "email": "rodmaru.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 78,
        "name": "Rodmar Zavala 5",
        "email": "rodmarf.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 45,
        "name": "Rodmar Zavala 6",
        "email": "rodmar54.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 12,
        "name": "Rodmar Zavala 7",
        "email": "rodmar21.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 34,
        "name": "Rodmar Zavala 8",
        "email": "rodmarnt.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 34,
        "name": "Rodmar Zavala 9",
        "email": "rodmar7.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 345,
        "name": "Rodmar Zavala 10",
        "email": "rodmar3.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 34234,
        "name": "Rodmar Zavala 11",
        "email": "rodmar5.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }, {
        "id": 344556,
        "name": "Rodmar Zavala 12",
        "email": "rodmar4789.zavala@gmail.com",
        "email_verified_at": null,
        "created_at": "2023-07-05T17:57:30.000000Z",
        "updated_at": "2023-07-05T17:57:30.000000Z",
        "created_by": "1",
        "updated_by": "1",
        "is_active": null,
        "avatar_id": null,
        "deleted_at": null,
        "phone": null,
        "roles": []
    }
]