import { Area } from '@/models/areas.models';

export const loadUserAdapter = (user: any) => {
  return {
    code: user.code,
    avatar_url: user.avatar_url,
    company: user.companies[0],
    email: user.email,
    name: user.name,
    isActive: user.is_active,
    default_area_id: user.default_area_id,
    phone: user.phone,
    roles: user.roles,
    id: user.id,
    avatar_id: user.avatar_id,
    company_areas: user.company_areas.map((area: Area) => ({ name: area.name, id: area.id }))
  };
};
